import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import { errorHandler } from "../../../../helper-methods";
import { getAllFaqMiscellaneous } from "../../../../http/http-calls";
import CustomPagination from "../CustomPagination";

const Miscellaneous = () => {
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    pageNumber: 0,
    limit: 12,
    search: "",
  });
  const [loading, setLoading] = useState(false);

  const _getAllFaqMiscellaneous = async (payload) => {
    try {
      setLoading(true);
      const res = await getAllFaqMiscellaneous(payload);

      setData(res?.faqMiscellaneous?.length ? res.faqMiscellaneous : []);
      setDataCount(res?.totalCount || 0);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    setDataPayload(newDataPayload);
    _getAllFaqMiscellaneous(newDataPayload);
  };

  useEffect(() => {
    _getAllFaqMiscellaneous(dataPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="plCardWrap">
        <CardHeader style={{ paddingBottom: 14 }}>
          Additional Resources
        </CardHeader>

        <CardBody style={{ padding: "10px" }}>
          <div className="categoriesWrap">
            {data?.length ? (
              data.map((each) => (
                <a
                  key={each._id}
                  href={each.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h5 className="my-1">{each.title || "N/A"}</h5>
                </a>
              ))
            ) : (
              <div className="text-center w-100 my-3">
                {loading ? <Spinner /> : "There is no data to display"}
              </div>
            )}
          </div>
          <CustomPagination
            data={data}
            dataCount={dataCount}
            pageNumber={dataPayload?.pageNumber}
            pageSize={dataPayload?.limit}
            onPageChange={(pageNo) => _onPageChange(pageNo)}
            isPageStartFromOne
            isHideForLessData
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Miscellaneous;
