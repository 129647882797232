import { UPLOAD_FILE_PERCENTAGE } from "../actions";

const initialState = {
  percentage: '0'
}

export const uploadFilePercentageReducer = (state = initialState, action) => {
    let newState = { ...state };
    switch(action.type) {
        case UPLOAD_FILE_PERCENTAGE: {
          if(action.payload.percentage === '100' || Number(action.payload.percentage) > 99) {
            newState = initialState;
          } else {
            newState = {
              percentage: action.payload.percentage
            }
          }
            break;
        }
        default: {}
    }
    return newState;
}

