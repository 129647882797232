import { getToken } from "./token-interceptor";
import { handleErrorIfAvailable } from "../error-handler/index";
import { structureQueryParams } from "../helper-methods";

export const makeGetRequest = async (
  url,
  attachToken = false,
  params = null
) => {
  let queryString = "";
  if (params) {
    queryString = structureQueryParams(params);
  }
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (attachToken) {
    try {
      const authToken = await getToken();
      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }
    } catch (e) {
      console.log(e);
    }
  }
  try {
    const response = await fetch(url + queryString, {
      method: "GET",
      headers: headers,
    });
    const isErrorAvailable = await handleErrorIfAvailable(response);
    if (!isErrorAvailable) {
      const jsonResponse = await response.json();
      if (jsonResponse.error === false) {
        return jsonResponse;
      } else {
        console.log(jsonResponse);
        throw jsonResponse;
      }
    } else {
      return;
    }
  } catch (error) {
    console.log("XHR GET Error: ", error);
    throw error;
  }
};

export const makePostRequest = async (
  url,
  attachToken = false,
  params = {}
) => {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (attachToken) {
    try {
      const authToken = await getToken();
      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }
    } catch (e) {
      console.log("Error fetching auth token: ", e);
    }
  }
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(params),
    });
    const isErrorAvailable = await handleErrorIfAvailable(response);
    if (!isErrorAvailable) {
      const jsonResponse = await response.json();
      if (jsonResponse.error === false) {
        return jsonResponse;
      } else {
        console.log(jsonResponse);
        throw jsonResponse;
      }
    } else {
      return;
    }
  } catch (error) {
    console.log("XHR POST Error: ", error);
    throw error;
  }
};

export const makePutRequest = async (url, attachToken = false, params = {}) => {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (attachToken) {
    try {
      const authToken = await getToken();
      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }
    } catch (e) {
      console.log("Error fetching auth token: ", e);
    }
  }
  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(params),
      })
        .then((res) => {
          handleErrorIfAvailable(res);
          return res.json();
        })
        .then(
          (jsonResponse) => {
            if (jsonResponse.error === false) {
              resolve(jsonResponse);
            } else {
              console.log(jsonResponse);
              reject(jsonResponse);
            }
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      console.log("handleErrorIfAvailable >>", e);

      reject();
    }
  });
};

export const makeDeleteRequest = async (
  url,
  attachToken = false,
  params = {}
) => {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (attachToken) {
    try {
      const authToken = await getToken();
      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }
    } catch (e) {
      console.log("Error fetching auth token: ", e);
    }
  }
  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(params),
      })
        .then((res) => {
          handleErrorIfAvailable(res);
          return res.json();
        })
        .then(
          (jsonResponse) => {
            if (jsonResponse.error === false) {
              resolve(jsonResponse);
            } else {
              console.log(jsonResponse);
              reject(jsonResponse);
            }
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      console.log("handleErrorIfAvailable >>", e);

      reject();
    }
  });
};

// export const uploadFileMultiPart = async (
//   attachToken = false,
//   formData,
//   mediaType = "image"
// ) => {
//   let headers = {};

//   if (attachToken) {
//     try {
//       const authToken = await getToken();
//       if (authToken) {
//         headers["Authorization"] = "Bearer " + authToken;
//       }
//     } catch (e) {
//       console.log("Error fetching auth token: ", e);
//     }
//   }

//   return new Promise((resolve, reject) => {
//     try {
//       fetch(cloudinaryURL, {
//         method: "POST",
//         headers: headers,
//         body: formData,
//       })
//         .then((res) => {
//           handleErrorIfAvailable(res);
//           return res.json();
//         })
//         .then(
//           (jsonResponse) => {
//             resolve(jsonResponse);
//           },
//           (error) => {
//             reject(error);
//           }
//         )
//         .catch((error) => {
//           reject(error);
//         });
//     } catch (e) {
//       console.log(e);
//       reject();
//     }
//   });
// };

export const uploadFile = async (url, attachToken = false, formData) => {
  let headers = {};
  if (attachToken) {
    try {
      const authToken = await getToken();
      if (authToken) {
        headers["Authorization"] = "Bearer " + authToken;
      }
    } catch (e) {
      console.log("Error fetching auth token: ", e);
    }
  }
  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: "POST",
        headers: headers,
        body: formData,
      })
        .then(
          (res) => {
            handleErrorIfAvailable(res);
            return res.json();
          },
          (error) => {
            reject(error);
          }
        )
        .then(
          (jsonResponse) => {
            resolve(jsonResponse);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      console.log(e);
      reject();
    }
  });
};
