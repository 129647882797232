import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

class BulkUploadInfo extends Component {
  _closeModal = () => {
    this.props.toggle();
  };

  render() {
    const { isOpen, data, success, type } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this._closeModal()}
        centered
        scrollable
        className="uploadModal"
      >
        <ModalHeader toggle={() => this._closeModal()}>
          Upload Status
        </ModalHeader>
        <ModalBody>
          <ul className="uploadStatusWrap">
            <li>
              <div className="uploadItem success">
                <div className="statusIcon">
                  <img
                    src={require("../../../assets/img/successful.png")}
                    alt="successful"
                    height={18}
                  />
                </div>
                <div>
                  <h6 className="text-success">Success</h6>
                  {success !== undefined ? (
                    <p className="text-success">
                      {success} Data uploaded Successfully
                    </p>
                  ) : (
                    <p className="text-danger">No {type} uploaded!</p>
                  )}
                </div>
              </div>
              <div className="statusIndicator">
                <img
                  src={require("../../../assets/img/success-check.png")}
                  alt="check"
                />
              </div>
            </li>
            <li>
              <div className="uploadItem primary">
                <div className="statusIcon">
                  <img
                    src={require("../../../assets/img/copy.png")}
                    alt="copy"
                    height={18}
                  />
                </div>
                <div>
                  <h6 className="text-primary">Duplicates</h6>
                  {data && data?.duplicates && data?.duplicates.length > 0 ? (
                    data?.duplicates?.map((item, index) => {
                      return (
                        <p className="text-primary">
                          Row No: {item.row}-
                          <Link to={item.data}> {item.data} </Link>
                        </p>
                      );
                    })
                  ) : (
                    <p className="text-success">No Duplicates!</p>
                  )}
                </div>
              </div>
              <div className="statusIndicator">
                <img
                  src={require("../../../assets/img/cross.png")}
                  alt="check"
                />
              </div>
            </li>
            <li>
              <div className="uploadItem warning">
                <div className="statusIcon">
                  <img
                    src={require("../../../assets/img/warning.png")}
                    alt="warning"
                    height={18}
                  />
                </div>
                <div>
                  <h6 className="text-warning">Data Error/Insufficient</h6>
                  {data?.errors && data?.errors.length > 0 ? (
                    data?.errors?.map((item) => {
                      return (
                        <p className="text-warning">
                          Row No: {item.row} - {item.data}
                        </p>
                      );
                    })
                  ) : (
                    <p className="text-success">No Missing Required Datas!</p>
                  )}
                </div>
              </div>
              <div className="statusIndicator">
                <img
                  src={require("../../../assets/img/cross.png")}
                  alt="check"
                />
              </div>
            </li>
            <li>
              <div className="uploadItem danger">
                <div className="statusIcon">
                  <img
                    src={require("../../../assets/img/alert.png")}
                    alt="alert"
                    height={18}
                  />
                </div>
                <div>
                  <h6 className="text-danger">Warnings</h6>
                  {data?.warnings && data?.warnings.length > 0 ? (
                    data?.warnings?.map((item) => {
                      return (
                        <p className="text-danger">
                          Row No: {item.row} - {item.data}
                        </p>
                      );
                    })
                  ) : (
                    <p className="text-success">No Missing Required Datas!</p>
                  )}
                </div>
              </div>
              <div className="statusIndicator">
                <img
                  src={require("../../../assets/img/cross.png")}
                  alt="check"
                />
              </div>
            </li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => this._closeModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default BulkUploadInfo;
