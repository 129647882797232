import { store } from "../redux/store";

export const getToken = () => {
  return new Promise((resolve) => {
    let token = null;
    // const oldState = store.getState();
    // const state = { ...oldState };
    // Try to get token from state
    const { userData } = store.getState();

    if (userData && userData.token) {
      token = userData.token;
      // token = `eyJhbGciOiJIUzI1NiIsInR5sbahbaskjbvjdsCI6IkpXVCJ9.eyJpZCI6IjYzOTk3ZGU5MjQ3NWQ5MGNhNTIwNWI0NyIsIl9pZCI6IjYzOTk3ZGU5MjQ3NWQ5MGNhNTIwNWI0NyIsIm5hbWUiOnsiZmlyc3QiOiJTYWJ5YXNhY2hpIiwibGFzdCI6IjEiLCJmdWxsIjoiU2FieWFzYWNoaSAxIn0sImVtYWlsIjoic2FieWFzYWNoaSthMUBsb2dpYy1zcXVhcmUuY29tIiwicGhvbmUiOiIoKzEpODIzOTAxMzI2OSIsInR5cGUiOiJBZ2VudCIsIndvcmtUeXBlIjoiQXR0b3JuZXkiLCJwcm9maWxlUGljdHVyZSI6Imh0dHBzOi8vY2xvc2V3aXNlLWRldi1tZWRpYS1zdG9yYWdlLWJ1Y2tldC5zMy5hbWF6b25hd3MuY29tLzE3MDE5NTA4OTY0NzE_QVdTQWNjZXNzS2V5SWQ9QUtJQVJSV1JSSTZEV0EzMzRZNEUmRXhwaXJlcz0xNzA5MDI0MDM1JlNpZ25hdHVyZT10WHM2bkJabkZXVnlEekh5UVo2JTJCUkFmNDVNcyUzRCZjYWNoZUlkPTE3MDE5NTA4OTY0NzEiLCJjdXN0b21lclN0cmlwZUlkIjoiY3VzX015bkF4eHVDOFkzTVF0Iiwic3RyaXBlSWQiOiJhY2N0XzFNRXBaajJjWmEyQ1dsTlkiLCJpc0NhcmRBZGRlZCI6dHJ1ZSwiaXNTdWJzY3JpYmVkIjp0cnVlLCJ0aW1lWm9uZSI6IkFtZXJpY2EvTmV3X1lvcmsiLCJfc3Vic2NyaXB0aW9uIjp7ImlzQXV0byI6dHJ1ZSwicGxhbiI6IlByby1QbHVzIiwiYmlsbGluZ0N5Y2xlIjozMCwiaXNOb3RhcnlQcm9tbyI6dHJ1ZSwiaXNCZXRhTW9kZSI6dHJ1ZSwiX2lkIjoiNjM5OTdkZTkyNDc1ZDkwY2E1MjA1YjRhIiwiYW1vdW50Ijo0Nywic3RhcnQiOiIyMDI0LTAxLTA0VDA2OjA5OjA5Ljc5N1oiLCJlbmQiOiIyMDI0LTEyLTMwVDA2OjA5OjA5LjAwMFoiLCJpc0V4cGlyZWQiOmZhbHNlLCJpc05vdGFyeVByb21vRXhwaXJlZCI6ZmFsc2UsImlkIjoiNjM5OTdkZTkyNDc1ZDkwY2E1MjA1YjRhIn0sImlzR29vZ2xlQ2FsZW5kYXJTaWduSW5Eb25lIjpmYWxzZSwiaXNXZWxjb21lVmlkZW8iOnRydWUsImlzVGVybXNBbmRDb25kaXRpb25zQWNjZXB0ZWQiOnRydWUsImlzV2hpdGVMYWJlbFVzZXIiOmZhbHNlLCJpc1RpdGxlQ29tcGFueSI6ZmFsc2UsImdsZWFwSGFzaCI6ImVkNGI1M2Q0OWYzMmQwMmYwMjY3MjU3Yjg3N2FkNzc5NzYyNDhmNzliNjA3NTcyYWU4NTdkNjM1NTY2MTExNmUiLCJpYXQiOjE3MDkwMDk2MzUsImV4cCI6MTcxMTYwMTYzNX0.4LhidwQuJgS2uxZtdQebL-BCessZoyWodFdM5WhtuSw`;
    }
    resolve(token);
  });
};
