import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import SkeletonLoading from "./Skeletons/SkeletonLoading";
import useScreenWidth from "./HelpModule/useScreenWidth";
import SvgIcons from "./SvgIcons";

export default function InfoCard({ infoCardArray, loading, page }) {
  console.log(infoCardArray);
  const { isForMobile } = useScreenWidth();
  return (
    <Row className={`mt-4 mb-${loading ? 3 : 0}`}>
      {loading ? (
        <SkeletonLoading
          type="card"
          count={page === "income" ? 4 : 2}
          height={isForMobile ? 130 : 76}
          width={isForMobile ? 150 : 420}
          page={page}
        />
      ) : (
        infoCardArray.length > 0 &&
        infoCardArray.map((info) => (
          <Col xl={3} lg={6} xs={6}>
            <Card className="infoCard">
              <CardBody>
                <div className="infoContent">
                  <span>{info.name}</span>
                  <h4 className={info?.h4classname}>{info.value}</h4>
                </div>
                <div className="icon">
                  {/* <img src={info?.image} alt={info.name} /> */}
                  <SvgIcons type={info?.icon} />
                </div>
              </CardBody>
            </Card>
          </Col>
        ))
      )}
    </Row>
  );
}
