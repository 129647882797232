import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { sendEmailVerificationLink } from "../../../http/http-calls";
import { showToast } from "../../../helper-methods";

const EmailVerificationModal = ({ isOpen, data, toggle }) => {
  // console.log("data: ", data);

  const _handleSubmit = async () => {
    try {
      const payload = {
        handle: data?.handle,
        site:
          window.location.hostname !== "localhost"
            ? window.location.host
            : "customizedwl.logic-square.com",
      };
      await sendEmailVerificationLink(payload);

      toggle();
      showToast("Email verification link sent", "success");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={() => toggle()}>Email Not Verified!</ModalHeader>
      <ModalBody className="modalContent">
        <p className="text-center">
          Send the verification email to{" "}
          <span style={{ fontWeight: 600 }}>{data?.handle}</span> ?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => toggle()}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => _handleSubmit()}>
          Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EmailVerificationModal;
