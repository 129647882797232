import cuid from "cuid";
import { uploadPhotoToCloudinary } from "../helper-methods";

export class UploadQueueManager {
  static uploadQueue = [];

  static addMediaToQueue = (media, mediaType, bucketName) => {
    console.log(
      "UploadQueueManager -> staticaddMediaToQueue -> media, mediaType",
      media,
      mediaType,
      bucketName
    );
    const uploadId = cuid();
    // Add to queue
    const mediaToUpload = {
      data: { media, mediaType },
      uploadId,
      bucketName,
    };
    UploadQueueManager.uploadQueue.push(mediaToUpload);
    // Start upload process
    UploadQueueManager.startUploadProcess(mediaToUpload);
    // Return back uploadId
    EventEmitter.dispatch(
      "upload-queue-count-change",
      UploadQueueManager.uploadQueue.length
    );
    return uploadId;
  };

  static startUploadProcess = async (media) => {
    try {
      const uploadLink = await UploadQueueManager.uploadMedia(
        media.data.media,
        media.data.mediaType,
        media.bucketName
      );
      EventEmitter.dispatch(`upload-complete-for-${media.uploadId}`, {
        ...media,
        fileUrl: uploadLink,
      });
      // Remove media from queue
      UploadQueueManager.removeFromQueue(media.uploadId);
      // Emit queue count change event
      EventEmitter.dispatch(
        "upload-queue-count-change",
        UploadQueueManager.uploadQueue.length
      );
    } catch (error) {
      EventEmitter.dispatch(`upload-failed-for-${media.uploadId}`, {
        ...media,
      });
    }
  };

  static onCompleteUploadProcess = (callback) => {
    console.log("staticonCompleteUploadProcess -> callback", callback);
    EventEmitter.subscribe("complete-upload-process", callback);
    // EventEmitter.unsubscribe("complete-upload-process");
  };

  static onUploadComplete = (uploadId, callback) => {
    // Emit complete event
    EventEmitter.subscribe(`upload-complete-for-${uploadId}`, callback);
  };

  static onUploadFailed = (uploadId, callback) => {
    // Emit complete event
    EventEmitter.dispatch("upload-queue-count-change", 0);
    // EventEmitter.dispatch("media-file-upload-completed", callback);
    EventEmitter.subscribe(`upload-failed-for-${uploadId}`, callback);
  };

  static onQueueCountChange = (callback) => {
    EventEmitter.subscribe("upload-queue-count-change", callback);
  };

  static removeFromQueue = (uploadId) => {
    const indexOfMedia = UploadQueueManager.uploadQueue.findIndex(
      (media) => media.uploadId === uploadId
    );
    UploadQueueManager.uploadQueue.splice(indexOfMedia, 1);
    if (
      !UploadQueueManager.uploadQueue ||
      !UploadQueueManager.uploadQueue.length
    ) {
      console.log("uploadQueue :>> ", UploadQueueManager.uploadQueue);
      EventEmitter.dispatch("complete-upload-process");
      // setTimeout(() => {
      //   EventEmitter.events = {};
      // }, 500);
    }
  };

  static uploadMedia = (media, mediaType, bucketName) => {
    console.log("staticuploadMedia -> media, mediaType", media, mediaType,bucketName);
    return new Promise(async (resolve, reject) => {
      switch (mediaType) {
        case "image": {
          const imageUrl = await uploadPhotoToCloudinary(
            media.file,
            "blob",
            null,
            "image",
            bucketName
          );
          resolve(imageUrl);
          break;
        }
        case "pdf": {
          try {
            const pdfUrl = await uploadPhotoToCloudinary(
              media.file,
              "blob",
              null,
              "pdf",
              bucketName
            );
            resolve(pdfUrl);
            break;
          } catch (error) {
            reject(error);
          }
          break;
        }
        case "video": {
          try {
            const videoUrl = await uploadPhotoToCloudinary(
              media.file,
              "video",
              null,
              "video",
              bucketName
            );
            resolve(videoUrl);
            break;
          } catch (error) {
            reject(error);
          }
          break;
        }
        default:
      }
    });
  };
}

/**
 * To communicate through events
 */
const EventEmitter = {
  events: {},
  dispatch: function (event, data = null) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Doesn't exist, so just return
      return;
    } else {
      // Exists
      // Process all bound callbacks
      this.events[event].forEach((callback) => callback(data));
    }
  },
  subscribe: function (event, callback) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Not subscribed yet, so make it an array so that further callbacks can be pushed
      this.events[event] = [];
    }
    // Push the current callback
    this.events[event].push(callback);
  },
};
