import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  CustomInput,
  Collapse,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  deepClone,
  showToast,
  getPhoneNumberFromBrackets,
  errorHandler,
} from "../../../helper-methods";
import { RegexConfig } from "../../../config/RegexConfig";
import { countryCodes } from "../../../config/country-codes";
import {
  addClientTeamMember,
  checkTeamMemberCanChangeEmail,
  editClientTeamMember,
} from "../../../http/http-calls";
import { clientPermissionConfig } from "../../../config";
import AddToOtherTeam from "./company-clients/addToOtherTeam";

let memberIdFromStep1Response = null;
class AddClientinSigningCompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        name: {
          value: "",
          error: null,
          isDirty: false,
        },
        email: {
          value: "",
          error: null,
          isDirty: false,
        },
        phone: {
          value: "",
          error: null,
          isDirty: false,
        },
        phoneCountry: {
          value: "+1",
          error: null,
          isDirty: false,
        },
        loginCredential: {
          value: false,
          error: null,
          isDirty: false,
        },
      },
      dashboard: {
        canUpdateClosingStatusOrScheduling: false,
        canEditOrderEntry: false,
        canReviewDocumentsOrOrderDetail: false,
        canCreateOrder: false,
      },
      connectCompanies: {
        canView: false,
        canAdd: false,
        canUpdate: false,
      },
      payment: {
        canView: false,
        canPay: false,
      },
      lenderClient: {
        canView: false,
        canAdd: false,
        canUpdate: false,
      },
      isOpenCollapse: "",
      step: 1,
      isEmailEditAble: false,
    };
  }

  addToOtherTeam = React.createRef();

  _resetModalState = () => {
    const defaultValue = {
      value: "",
      error: null,
      isDirty: false,
    };

    this.setState({
      isOpenCollapse: "",
      formFields: {
        name: {
          ...defaultValue,
        },
        email: {
          ...defaultValue,
        },
        phone: {
          ...defaultValue,
        },
        phoneCountry: {
          value: "+1",
          isValid: false,
          isDirty: false,
        },
        loginCredential: {
          value: false,
          error: null,
          isDirty: false,
        },
      },
      dashboard: {
        canUpdateClosingStatusOrScheduling: false,
        canEditOrderEntry: false,
        canReviewDocumentsOrOrderDetail: false,
        canCreateOrder: false,
      },
      connectCompanies: {
        canView: false,
        canAdd: false,
        canUpdate: false,
      },
      payment: {
        canView: false,
        canPay: false,
      },
      lenderClient: {
        canView: false,
        canAdd: false,
        canUpdate: false,
      },
      // isOpenCollapse: "",
    });
  };

  _closeModal = (action) => {
    console.log("in _closeModal");
    this._resetModalState();
    this.props.toggle();

    if (this.state.step === 2) {
      this.props.resetTable();
      this.addToOtherTeam?.current?._resetData(); // reset step 2 state changes
    }
  };

  _checkEmailEditPermission = async (data) => {
    if (data?._id) {
      const res = await checkTeamMemberCanChangeEmail(data._id, this.props.id);
      if (!res.error) {
        // console.log("res?.data?.isEmailEditable >>", res?.isEmailEditable);
        this.setState({ isEmailEditAble: res?.isEmailEditable });
      }
    }
  };

  componentDidMount() {
    const { isOpen, data } = this.props;
    if (isOpen && data) {
      // console.log("data>>>>", data);
      this._checkEmailEditPermission(data);
      this._setFormData(data);
    } else {
      this.setState({ isEmailEditAble: true });
    }
  }

  _setFormData = (data) => {
    const { formFields } = deepClone(this.state);

    formFields["name"].value = data.name.full;

    formFields["phone"].value = data.phone
      ? getPhoneNumberFromBrackets(data.phone)
      : "";

    formFields["email"].value = data.email ? data.email : "";

    formFields["loginCredential"].value = data?.loginCredential
      ? data.loginCredential
      : false;

    this.setState({
      masterSchedule: {
        canView: data.permissionSettings.masterSchedule.canView,
      },
      dashboard: {
        canUpdateClosingStatusOrScheduling:
          data.permissionSettings.dashboard.canUpdateClosingStatusOrScheduling,
        canEditOrderEntry: data.permissionSettings.dashboard.canEditOrderEntry,
        canReviewDocumentsOrOrderDetail:
          data.permissionSettings.dashboard.canReviewDocumentsOrOrderDetail,
        canCreateOrder: data.permissionSettings.dashboard.canCreateOrder,
      },
      connectCompanies: {
        canView: data.permissionSettings.connectCompanies.canView,
        canAdd: data.permissionSettings.connectCompanies.canAdd,
        canUpdate: data.permissionSettings.connectCompanies.canUpdate,
      },
      payment: {
        canView: data.permissionSettings.payment.canView,
        canPay: data.permissionSettings.payment.canPay,
      },
      lenderClient: {
        canView: data.permissionSettings.lenderClient.canView,
        canAdd: data.permissionSettings.lenderClient.canAdd,
        canUpdate: data.permissionSettings.lenderClient.canUpdate,
      },
    });
    this.setState({ formFields, loading: false });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "name": {
              if (formFields[key]?.value?.trim().length) {
                if (
                  formFields[key]?.value?.trim().length > 1 &&
                  /^[0-9!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(
                    //eslint-disable-line
                    String(formFields[key]?.value).toLowerCase()
                  )
                ) {
                  formFields[key].error =
                    "*Name containing only number and special character are not allowed";
                  isFormValid = false;
                  formFields[key].isDirty = true;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (formFields[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _saveUserData = async (type = null) => {
    await this._markAllFieldDirty();
    this.setState({ loading: true });

    const isFormValid = await this._validateForm();
    const resp = await this.addToOtherTeam?.current?._sendData();
    if (!isFormValid) {
      this.setState({ loading: false });
      return;
    }

    const { formFields } = deepClone(this.state);
    const splitName = formFields.name.value.trim().split(" ");
    const countryDialCode = countryCodes.find(
      (obj) => obj.dial_code === formFields.phoneCountry.value
    )?.code;

    let data = {
      name: {
        first: splitName.slice(0, 1).join(" "),
        last: splitName[1] ? splitName.slice(1).join(" ") : "",
      },
      email: this.state.isEmailEditAble ? formFields.email.value : undefined,
      phone: `(${formFields.phoneCountry.value})${formFields.phone.value}`,
      phoneCountry: countryDialCode,
      permissionSettings: {
        dashboard: this.state.dashboard,
        connectCompanies: this.state.connectCompanies,
        payment: this.state.payment,
        lenderClient: this.state.lenderClient,
      },
      loginCredential: formFields?.loginCredential?.value,
    };

    // case: edit already added member
    if (this.props.data) {
      data["teamIds"] = resp;

      this._updateUser(
        { ...data, clientId: this.props.id },
        this.props.data.id
      );
    } else {
      // case: add new memeber
      if (this.state.step === 2 && memberIdFromStep1Response && resp) {
        // subcase: if currentStep === 2, then will make an edit user api call only to save team details
        const payload = {
          teamIds: resp,
        };

        this._updateUser(
          { ...payload, clientId: this.props.id },
          memberIdFromStep1Response
        );
        await this.addToOtherTeam?.current?._resetData(); // reset step 2 state changes
      } else if (this.state.step === 1) {
        // subcase: if we're in step 1, then make a create user api to create user, get newly created member'/user's id,
        // store in 'memberIdFromStep1Response' and move to step 2
        await this._createUser({ ...data, clientId: this.props.id }, type);
      }
    }
  };
  // _callAddOrEditApi = (payload) => {
  //   if (this.props.data) {
  //     this._updateUser(
  //       { ...payload, clientId: this.props.id },
  //       this.props.data.id
  //     );
  //   } else {
  //     this._createUser({ ...payload, clientId: this.props.id });
  //   }
  // };

  _createUser = (payload, type = null) => {
    return new Promise(async (resolve) => {
      try {
        const res = await addClientTeamMember(payload);
        memberIdFromStep1Response = res?.user?.id;

        if (type && type === "next") {
          showToast("New member created!", "success");
          this.setState({ step: 2 }); // move to next step
        } else {
          showToast("Team member added successfully", "success");
          this.props.resetTable();
          this._closeModal();
        }

        this.setState({ loading: false, step: 2 });
        resolve(true);
      } catch (error) {
        errorHandler(error);
        this.setState({ loading: false });
      }
    });
  };

  _updateUser = (payload, id) => {
    return new Promise(async (resolve) => {
      try {
        await editClientTeamMember(id, payload);

        if (this.props.data) {
          showToast("Team member details updated successfully", "success");
        } else {
          showToast("Team member added successfully", "success");
        }
        this.props.resetTable();
        this._closeModal();
        this.setState({ loading: false });
        resolve(true);
      } catch (error) {
        errorHandler(error);
        this.setState({ loading: false });
      }
    });
  };

  _onChangePermission = (key, each, value) => {
    this.setState({
      [key]: {
        ...this.state[key],
        [each]: value,
      },
    });
  };

  _handleCollapse = (value) => {
    // console.log("value", value);
    this.setState({
      isOpenCollapse: this.state.isOpenCollapse !== value ? value : "",
    });
  };

  _renderPermission = () => {
    return clientPermissionConfig.map((each, index) => {
      return (
        <div key={index}>
          <div
            className={`permissionWrapper ${
              this.state.isOpenCollapse === each.value ? "open" : ""
            }`}
          >
            <div
              onClick={() => this._handleCollapse(each.value)}
              className="permissionHead"
            >
              <i
                className={`fa fa-${
                  this.state.isOpenCollapse ? "minus" : "plus"
                }`}
              />

              <span>{each.label}</span>
            </div>
            <Collapse
              className="permissionBody"
              isOpen={this.state.isOpenCollapse === each.value}
            >
              {each.permissions.map((permission, permissionIndex) => {
                return (
                  <div key={permission?.label + permissionIndex}>
                    <CustomInput
                      type="checkbox"
                      checked={this.state[each.value][permission.value]}
                      onChange={(e) =>
                        this._onChangePermission(
                          this.state.isOpenCollapse,
                          permission.value,
                          e.target.checked
                        )
                      }
                      id={
                        each.value + permission.value + index + permissionIndex
                      }
                      label={permission.label}
                    />

                    {/* <FormGroup>
                      <Input
                        type="checkbox"
                        checked={this.state[each.value][permission.value]}
                        onChange={(e) =>
                          this._onChangePermission(
                            this.state.isOpenCollapse,
                            permission.value,
                            e.target.checked
                          )
                        }
                      />{" "}
                      <Label>{permission.label}</Label>
                    </FormGroup> */}
                  </div>
                );
              })}
            </Collapse>
          </div>
        </div>
      );
    });
  };

  nextStep = async () => {
    await this._markAllFieldDirty();
    const isFormValid = await this._validateForm();
    console.log("pop", isFormValid);
    if (isFormValid) {
      this.setState({ step: 2 });
    }
    if (this.state.step === 2) {
      const resp = await this.addToOtherTeam.current._sendData();
      resp && this._saveUserData(resp);
    }
  };

  render() {
    const { formFields, loading } = deepClone(this.state);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this._closeModal()}>
          {this.props.data ? "Edit" : "Add"} Team Members
        </ModalHeader>
        <ModalBody>
          {this.state.step === 1 && (
            <>
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={formFields.name.value}
                  onChange={(e) =>
                    this._onChangeFormField("name", e.target.value)
                  }
                />
                <Label>Name</Label>
                {formFields.name.error && (
                  <div className="validation-error">
                    {formFields.name.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup className="floatingLabel">
                <Input
                  type="email"
                  placeholder=" "
                  value={formFields.email.value}
                  onChange={(e) =>
                    this._onChangeFormField("email", e.target.value)
                  }
                  disabled={!this.state.isEmailEditAble}
                />
                <Label>Email</Label>
                {formFields.email.error && (
                  <div className="validation-error">
                    {formFields.email.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup className="floatingLabel withInputGroup">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>+1</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder=" "
                    value={formFields.phone.value}
                    onChange={(e) =>
                      this._onChangeFormField("phone", e.target.value)
                    }
                  />
                  <Label>Phone</Label>
                </InputGroup>
                {formFields.phone.error && (
                  <div className="validation-error">
                    {formFields.phone.error}
                  </div>
                )}
              </FormGroup>
              <Label>Permissions</Label>
              {this._renderPermission()}

              <CustomInput
                key={"loginCredential"}
                type="checkbox"
                id={`memberPermissionCheckbox_loginCredential`}
                label={"Send Login Credential"}
                inline
                checked={formFields.loginCredential.value}
                onChange={(e) =>
                  this._onChangeFormField("loginCredential", e.target.checked)
                }
              />
            </>
          )}

          {this.state.step === 2 && (
            <AddToOtherTeam
              ref={this.addToOtherTeam}
              manageLoading={(e) => this.setState({ loading: e })}
              data={formFields.name}
              type={this.props.data ? "Update" : "Add"}
              newClientData={{ id: this.props.id }}
              onSuccess={() => this.props.resetTable()}
              stopTeamMemberCall={true}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {this.state.step === 1 && (
            <Button
              color="primary"
              disabled={loading}
              onClick={() => this._saveUserData()}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              {this.props.data ? "Update" : "Add"}
            </Button>
          )}

          {!this.props.data &&
            (this.state.step === 1 ? (
              <Button
                color="primary"
                size="lg"
                onClick={() => this._saveUserData("next")}
                disabled={loading}
              >
                {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
                Next
              </Button>
            ) : (
              <Button
                color="primary"
                size="lg"
                onClick={() => this._saveUserData()}
                disabled={loading}
              >
                {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
                Add
              </Button>
            ))}
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddClientinSigningCompanyModal;
