import { CustomInput, FormGroup, Input, Label } from "reactstrap";

export const InputField = ({
  name,
  value,
  placeholder,
  type,
  onChange,
  onBlur,
  disabled,
  fieldType = "",
}) => (
  <>
    <Input
      type={type}
      placeholder=" "
      value={value}
      name={name}
      onChange={(event) => onChange(name, event, fieldType)}
      onBlur={() => onBlur(name, fieldType)}
      disabled={disabled}
    />
    <Label>{placeholder}</Label>
  </>
);

export const SelectField = ({
  name,
  value,
  error,
  isDirty,
  options,
  onChange,
  onBlur,
  label,
  disabled,
  fieldType = "",
  errorClassName = "validation-error",
}) => (
  <FormGroup className="floatingLabel custom-select-wrapper">
    <Input
      type="select"
      name={name}
      onChange={(event) => onChange(name, event, fieldType)}
      value={value}
      onBlur={() => onBlur(name, fieldType)}
      disabled={disabled}
    >
      <option value="">Select</option>
      {options.map((option, index) => (
        <option key={index} value={option.value || option.abbreviation}>
          {option.label || option.name}
        </option>
      ))}
    </Input>
    <Label>{label}</Label>
    {error?.length && isDirty ? (
      <span className={errorClassName}>{error}</span>
    ) : null}
  </FormGroup>
);

export const CheckboxField = ({
  id,
  label,
  onChange,
  checked,
  onBlur,
  name,
}) => (
  <CustomInput
    type="checkbox"
    id={id}
    label={label}
    onChange={(event) => onChange(name, event)}
    checked={checked}
    onBlur={() => onBlur(name)}
  />
);
