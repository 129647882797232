import React from "react";
import ReactDatetime from "react-datetime";
import { Button, Input } from "reactstrap";
import { capitalize } from "../../../../helper-methods";
import moment from "moment";
import RenderInput from "./RenderInput";

const CalendarToolbar = ({ label, view, date, views, onView, onNavigate }) => (
  <div className="calendarToolbar">
    <div className="calendarTool">
      <Button color="link" onClick={() => onNavigate("today", moment()?._d)}>
        <i className="fa fa-calendar-o" /> Today
      </Button>
      <Button
        color="link"
        className="arrowBtn"
        onClick={() => {
          onNavigate("prev", moment(date).subtract(1, view)?._d);
        }}>
        <img src={require('../../../../assets/img/arrowLeft.svg').default} alt="arrow left" />
      </Button>
      <Button
        color="link"
        className="arrowBtn"
        onClick={() => onNavigate("next", moment(date).add(1, view)?._d)}>
        <img src={require('../../../../assets/img/arrowRight.svg').default} alt="arrow right" />
      </Button>
    </div>

    <div className="chooseDate">
      <ReactDatetime
        renderInput={(props, openCalendar, closeCalendar) => (
          <RenderInput openCalendar={openCalendar} label={label} />
        )}
        value={date ? date : ""}
        onChange={(e) => (e?._d ? onNavigate("date", e._d) : {})}
        closeOnSelect={true}
        timeFormat={false}
      />
    </div>

    <div className="calendarFilter custom-select-wrapper">
      <Input type="select" value={view} onChange={(e) => onView(e.target.value)}>
        {views.map((view) => (
          <option key={view} value={view}>
            {capitalize(view)}
          </option>
        ))}
      </Input>
    </div>
  </div>
);

export default CalendarToolbar;
