import { Suspense, useState } from "react";
import {
  Card,
  Input,
  Button,
  Table,
  UncontrolledPopover,
  PopoverBody,
  Collapse,
  CardHeader,
  Label,
} from "reactstrap";
import SignerPopover from "./popover";
import {
  agentTodayOrFutureDate,
  enableTimeBetweenStartTimeAndEndTime,
  formatAddressInSingleText,
  formatCurrencyValue,
  getDropdownColor,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
  capitalize,
  showToast,
} from "../../../../helper-methods";
import useScreenWidth from "../HelpModule/useScreenWidth";
import CopyToClipboard from "react-copy-to-clipboard";
import SkeletonLoading from "../Skeletons/SkeletonLoading";
import { useSelector } from "react-redux";
import { DateTimeShowWithClosingDetails } from "../Date-time-show";

const SignerAppointment = (props) => {
  const {
    closingDetails,
    updateClosingDetailsByIdStatus,
    userType,
    userData,
    _onToggleClientMembers,
  } = props;

  const reduxData = useSelector((state) => state?.loaderData);

  // closingDetails => main data
  // userType props data => agent / client / company
  // updateClosingDetails => parent function for data update

  const [isOpenCollapse, setIsOpenCollapse] = useState(true);

  const { isForMobile } = useScreenWidth();

  // render Agent share
  const _renderAgentShare = (data) => {
    return (
      <>
        <span>
          {data?.agentFeeSharingAmount
            ? formatCurrencyValue(data?.agentFeeSharingAmount)
            : formatCurrencyValue(0)}
        </span>{" "}
        <span id={`agentSharePopOver_${data?._id}`} className="signerName">
          <i
            className="fa fa-info-circle"
            style={{ fontSize: "14px", color: "blue", cursor: "pointer" }}
          />
        </span>
        <UncontrolledPopover
          trigger="legacy"
          placement="bottom"
          target={`agentSharePopOver_${data?._id}`}
          className="signerPopover"
        >
          <PopoverBody>
            <Table className="responsive-sm">
              <thead>
                <tr>
                  <th>Agent Fee</th>
                  <th>Processing Fee</th>
                  <th>My Share</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {data?.agentFee
                      ? formatCurrencyValue(data?.agentFee)
                      : formatCurrencyValue(0)}
                  </td>
                  <td>
                    {data?.agentFee && data?.agentFeeSharingAmount
                      ? formatCurrencyValue(
                          data?.agentFee - data?.agentFeeSharingAmount
                        )
                      : formatCurrencyValue(0)}
                  </td>
                  <td>
                    {data?.agentFeeSharingAmount
                      ? formatCurrencyValue(data?.agentFeeSharingAmount)
                      : formatCurrencyValue(0)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </PopoverBody>
        </UncontrolledPopover>
      </>
    );
  };

  const _isDisabledCheckStatusDropdown = (itemData) => {
    // enable arrived option from status for that day
    let result = enableTimeBetweenStartTimeAndEndTime(
      itemData.appointmentDate,
      itemData.closingAddress?.timeZone
    );
    return !result;
  };

  // console.log("closingDetails >>", closingDetails);

  const _renderAgentSigner = () => (
    <div className="userContent">
      {closingDetails?._borrower?.length ? (
        <Suspense fallback={<></>}>
          <SignerPopover
            data={closingDetails?._borrower}
            targetId={closingDetails?._id}
            screenType={!isForMobile ? "table" : "mobile"}
            displayType="signerDetails"
          />
        </Suspense>
      ) : null}

      <DateTimeShowWithClosingDetails closingDetails={closingDetails} />
    </div>
  );

  const _renderStatusDropDown = () => {
    return (
      <>
        <Input
          type="select"
          name="status"
          className={`status ${getDropdownColor(closingDetails?.status)}`}
          value={closingDetails?.status}
          onChange={(event) =>
            updateClosingDetailsByIdStatus(closingDetails, {
              status: event.target.value,
            })
          }
        >
          {userType === "agent" ? (
            <>
              <option key="CCA" value="CCA">
                Can't Complete
              </option>
              <option
                key="Pending"
                value="Pending"
                disabled={!closingDetails.isCreatedForAgent}
              >
                Pending
              </option>
              <option
                key="Closed"
                value="Closed"
                disabled={
                  agentTodayOrFutureDate(closingDetails) &&
                  closingDetails?.status !== "Arrived"
                }
              >
                Closed
              </option>
              <option
                key="Cancelled"
                value="Cancelled"
                disabled={!closingDetails.isCreatedForAgent}
              >
                Cancelled
              </option>
              <option
                key="DNC"
                value="DNC"
                disabled={
                  agentTodayOrFutureDate(closingDetails) &&
                  closingDetails?.status !== "Arrived"
                }
              >
                Did not close
              </option>
              <option
                key="Arrived"
                value="Arrived"
                disabled={
                  _isDisabledCheckStatusDropdown(closingDetails) ||
                  closingDetails.status === "Closed"
                }
              >
                Arrived
              </option>
            </>
          ) : (
            <>
              <option key="CCA" value="CCA" disabled={true}>
                Can't Complete
              </option>
              <option
                key="Pending"
                value="Pending"
                disabled={
                  userType !== "company" && closingDetails?._agent
                    ? true
                    : false
                }
              >
                Pending
              </option>
              <option
                key="Closed"
                value="Closed"
                disabled={
                  userType === "company" ? closingDetails?.isSavedAsDraft : true
                }
              >
                Closed
              </option>
              <option
                key="Cancelled"
                value="Cancelled"
                disabled={
                  userType === "company"
                    ? false
                    : closingDetails?._agent
                    ? true
                    : false
                }
              >
                Cancelled
              </option>
              <option
                key="DNC"
                value="DNC"
                disabled={
                  userType === "company" ? closingDetails?.isSavedAsDraft : true
                }
              >
                Did not close
              </option>
              <option key="Arrived" value="Arrived" disabled={true}>
                Arrived
              </option>
            </>
          )}
        </Input>
        <div className="downArrow">
          <i className="fa fa-chevron-down"></i>
        </div>
      </>
    );
  };

  return (
    <div className="CustomTableWrapper withAccordian">
      {!isForMobile ? (
        // For Table View (Desktop View)
        <Suspense fallback={<></>}>
          <Table striped responsive className="hideMobile">
            <thead>
              <tr>
                <th>Signer</th>
                <th>File#</th>
                {userType === "company" && <th>Added By</th>}
                <th>Product Type</th>
                <th>Signing Type</th>
                {userType === "company" && !userData?.user?.isTitleCompany && (
                  <th>Company Fee</th>
                )}
                {userType !== "company" && (
                  <th>
                    {closingDetails?.isAgentWebsiteClosing &&
                    closingDetails?.isCreatedForAgent
                      ? closingDetails?._mainForwardAgent
                        ? "Agent Share" // agentFeeSharingAmount
                        : "My Share" // add i btn -> show agentFeeSharingAmount, agentFee - agentFeeSharingAmount
                      : "Fee"}
                  </th>
                )}
                <th>
                  {userType === "company" ? "Appointment Location" : "Location"}
                </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {reduxData?.isVisible ? (
                <SkeletonLoading
                  type="table"
                  height={50}
                  rows={1}
                  col={userType === "company" ? 9 : 8}
                />
              ) : (
                <tr>
                  <td>
                    <div className="tableUserInfo">{_renderAgentSigner()}</div>
                  </td>
                  <td>
                    <div style={{ width: 90 }}>
                      {closingDetails?.fileNumber || "N/A"}
                    </div>
                  </td>
                  {userType === "company" && (
                    <td>
                      {closingDetails?._createdBy?.name
                        ? closingDetails?._createdBy?.name?.full
                        : closingDetails?._createdByClient?.companyName ||
                          "N/A"}
                    </td>
                  )}
                  <td>
                    <div style={{ width: 110 }}>
                      <span
                        style={{
                          marginRight: 6,
                        }}
                      >
                        {closingDetails?.loanType &&
                        closingDetails?.loanType?.length > 0
                          ? closingDetails?.loanType?.indexOf("Other") > -1
                            ? closingDetails?.loanTypeOther
                            : null
                          : null}

                        {closingDetails?.loanType
                          ?.filter((each) => each !== "Other")
                          ?.map((item) =>
                            item?.replace(/([a-z])([A-Z])/g, "$1 $2")
                          )
                          .join(", ")}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div style={{ width: 110 }}>
                      <span
                        style={{
                          marginRight: 6,
                        }}
                      >
                        {closingDetails?.signingType}
                      </span>
                    </div>
                  </td>
                  {userType === "company" &&
                    !userData?.user?.isTitleCompany && (
                      <td>
                        <span style={{ width: 140 }}>
                          {closingDetails?.signingCompanyFee
                            ? formatCurrencyValue(
                                closingDetails?.signingCompanyFee
                              )
                            : formatCurrencyValue(0)}
                        </span>
                      </td>
                    )}
                  {userType !== "company" && (
                    <td>
                      <span style={{ width: 60 }}>
                        {closingDetails?.isAgentWebsiteClosing &&
                        closingDetails?.isCreatedForAgent
                          ? closingDetails?._mainForwardAgent
                            ? closingDetails?.agentFeeSharingAmount
                              ? formatCurrencyValue(
                                  closingDetails?.agentFeeSharingAmount
                                )
                              : formatCurrencyValue(0) // agentFeeSharingAmount
                            : _renderAgentShare(closingDetails) // add i btn -> show agentFeeSharingAmount, agentFee - agentFeeSharingAmount
                          : closingDetails?.signingCompanyFee ||
                            closingDetails?.agentFee
                          ? formatCurrencyValue(
                              closingDetails?.signingCompanyFee ||
                                closingDetails?.agentFee
                            )
                          : formatCurrencyValue(0)}
                      </span>
                    </td>
                  )}
                  <td>
                    <div
                      style={{
                        width: 150,
                      }}
                    >
                      {formatAddressInSingleText(
                        closingDetails?.closingAddress
                      ) || "N/A"}
                    </div>
                    <Button
                      color="link"
                      className="p-0"
                      title={formatAddressInSingleText(
                        closingDetails?.closingAddress
                      )}
                      onClick={() =>
                        openGoogleMapOnNewTab(closingDetails?.closingAddress)
                      }
                    >
                      <img
                        src={
                          require("../../../../assets/img/map-marker.svg")
                            .default
                        }
                        alt="map icon"
                        className="mr-1"
                      />
                      Map
                    </Button>
                  </td>
                  <td>
                    <div className="customSelectWrap" style={{ width: 118 }}>
                      {_renderStatusDropDown()}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Suspense>
      ) : (
        // For List View (Mobile View)
        <Suspense fallback={<></>}>
          <Card className="responsiveTableCard hideDesktop">
            {reduxData?.isVisible ? (
              <SkeletonLoading type="card" count={1} height={600} width={340} />
            ) : (
              <>
                <CardHeader>
                  <div className="tableUserInfo">{_renderAgentSigner()}</div>
                </CardHeader>
                <ul className="cardInfo">
                  <li>
                    <div className="dataWrap">
                      <Label>File#: </Label>
                      <div style={{ fontWeight: 600 }}>
                        {closingDetails?.fileNumber || "N/A"}
                      </div>
                    </div>

                    {console.log("userType", userType)}
                    {userType === "company" ? (
                      <div className="dataWrap">
                        <Label>Added By</Label>
                        <div style={{ fontWeight: 600 }}>
                          {closingDetails?._createdBy?.name
                            ? closingDetails?._createdBy?.name?.full
                            : closingDetails?._createdByClient?.companyName ||
                              "N/A"}
                        </div>
                      </div>
                    ) : null}
                  </li>

                  <li>
                    <div className="dataWrap">
                      <Label>
                        {closingDetails?.isAgentWebsiteClosing &&
                        closingDetails?.isCreatedForAgent
                          ? closingDetails?._mainForwardAgent
                            ? "Agent Share" // agentFeeSharingAmount
                            : "My Share" // add i btn -> show agentFeeSharingAmount, agentFee - agentFeeSharingAmount
                          : "Fee: "}{" "}
                      </Label>
                      <div style={{ fontWeight: 600 }}>
                        {closingDetails?.isAgentWebsiteClosing && // closing is from agent website
                        closingDetails?.isCreatedForAgent // Closing is created by agent
                          ? closingDetails?._mainForwardAgent //
                            ? closingDetails?.agentFeeSharingAmount
                              ? formatCurrencyValue(
                                  closingDetails?.agentFeeSharingAmount
                                )
                              : formatCurrencyValue(0) // agentFeeSharingAmount
                            : _renderAgentShare(closingDetails) // add i btn -> show agentFeeSharingAmount, agentFee - agentFeeSharingAmount
                          : closingDetails?.signingCompanyFee ||
                            closingDetails?.agentFee
                          ? formatCurrencyValue(
                              closingDetails?.signingCompanyFee ||
                                closingDetails?.agentFee
                            )
                          : formatCurrencyValue(0)}
                      </div>
                    </div>

                    <div className="dataWrap">
                      <Label>Signing Type: </Label>

                      <div style={{ fontWeight: 600 }}>
                        <span
                          style={{
                            marginRight: 6,
                          }}
                        >
                          {closingDetails?.signingType}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dataWrap">
                      <Label>Product Type: </Label>
                      <div style={{ fontWeight: 600 }}>
                        <span
                          style={{
                            marginRight: 6,
                          }}
                        >
                          {closingDetails?.loanType &&
                          closingDetails?.loanType?.length > 0
                            ? closingDetails?.loanType?.indexOf("Other") > -1
                              ? closingDetails?.loanTypeOther
                              : null
                            : null}

                          {closingDetails?.loanType
                            ?.filter((each) => each !== "Other")
                            ?.map((item) =>
                              item?.replace(/([a-z])([A-Z])/g, "$1 $2")
                            )
                            .join(", ")}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dataWrap">
                      <Label>Location </Label>

                      <div className="d-flex justify-content-between">
                        <div
                          style={{
                            flex: 1,
                            marginRight: 10,
                            fontWeight: 600,
                          }}
                        >
                          {formatAddressInSingleText(
                            closingDetails.closingAddress
                          )}
                        </div>
                        <div className="d-flex">
                          <Button
                            color="link"
                            className="p-0"
                            onClick={() =>
                              // 248 MARINA SHORES COURT SUMMERVILLE SC 29486
                              openGoogleMapOnNewTab(
                                closingDetails?.closingAddress
                              )
                            }
                          >
                            <img
                              src={
                                require("../../../../assets/img/Google_Maps_Logo_2020.svg")
                                  .default
                              }
                              alt="map icon"
                              className="mr-3"
                            />
                          </Button>
                          <Button
                            color="link"
                            className="p-0"
                            onClick={() =>
                              openAppleMapOnNewTab(
                                closingDetails?.closingAddress
                              )
                            }
                          >
                            <img
                              src={
                                require("../../../../assets/img/Apple_Maps_(WatchOS).svg")
                                  .default
                              }
                              alt="map icon"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dataWrap d-flex align-items-center justify-content-between">
                      <Label className="mb-0">Status </Label>
                      <div className="customSelectWrap">
                        {_renderStatusDropDown()}
                      </div>
                    </div>
                  </li>
                </ul>
              </>
            )}
          </Card>
        </Suspense>
      )}
      <Collapse className="accordianCollapse" isOpen={isOpenCollapse}>
        {reduxData?.isVisible ? (
          <SkeletonLoading type="card" count={1} height={150} width={1500} />
        ) : (
          <>
            <ul className="clientDetails">
              {userType !== "client" ? (
                <li>
                  <div className="userInfo">
                    <div className="userImage">
                      <img
                        src={
                          closingDetails?._signingCompany?.logo
                            ? closingDetails._signingCompany.logo
                            : closingDetails?._client?.logo
                            ? closingDetails?._client?.logo
                            : require("../../../../assets/img/default_user_icon.svg")
                                .default
                        }
                        alt="logo"
                      />
                    </div>
                    <div className="userContent">
                      <span>Client</span>
                      <h5>
                        {userType === "company"
                          ? closingDetails?._client?.companyName
                          : userType === "agent"
                          ? closingDetails?._signingCompany?.companyName ||
                            closingDetails?._client?.name?.full ||
                            closingDetails?._assistant?.name?.full ||
                            "N/A"
                          : closingDetails?.lender
                          ? capitalize(closingDetails?.lender)
                          : "N/A"}
                      </h5>
                    </div>
                  </div>
                </li>
              ) : null}

              {closingDetails?._clientAssistantTeam?.teamName ? (
                <li>
                  <span>Team Name</span>

                  {userType === "company" ? (
                    <h5>
                      <Button
                        color="link"
                        className="fs-12"
                        onClick={() =>
                          _onToggleClientMembers(true, closingDetails?._client)
                        }
                      >
                        {closingDetails?._clientAssistantTeam?.teamName ||
                          "N/A"}
                      </Button>
                    </h5>
                  ) : (
                    <h5>
                      {closingDetails?._clientAssistantTeam?.teamName || "N/A"}
                    </h5>
                  )}
                </li>
              ) : null}

              {userType !== "company" && (
                <li>
                  <span>
                    {" "}
                    {userType === "agent" ? "POC " : "Contact "} Name
                  </span>
                  <h5>
                    {userType === "agent"
                      ? closingDetails?.isAgentWebsiteClosing
                        ? closingDetails?._client?.name?.full
                          ? capitalize(closingDetails?._client?.name?.full)
                          : "N/A"
                        : closingDetails?.personOfContact?.name
                        ? capitalize(closingDetails?.personOfContact?.name)
                        : "N/A"
                      : capitalize(closingDetails?.personOfContact?.name) ||
                        "N/A"}
                  </h5>
                </li>
              )}
              {userType === "company" && (
                <li>
                  <span>POC Name/Email</span>
                  <h5>
                    {capitalize(closingDetails?.personOfContact?.name) || "N/A"}
                  </h5>
                  <h5>
                    {/* {closingDetails?.personOfContact?.email.length > 0
                      ? closingDetails?.personOfContact?.email?.join(", ")
                      : "N/A"} */}
                    {closingDetails?.personOfContact?.email.length > 0
                      ? closingDetails?.personOfContact?.email?.map((each) => (
                          <>
                            <a
                              key={each}
                              href={`mailto:${each}`}
                              style={{ display: "block" }}
                            >
                              {each}
                            </a>
                          </>
                        ))
                      : "N/A"}
                  </h5>
                </li>
              )}

              {userType !== "company" && (
                <>
                  <li>
                    <span>
                      {" "}
                      {userType === "agent" ? "POC " : "Contact "} Email
                    </span>

                    <h5>
                      {closingDetails?.isAgentWebsiteClosing
                        ? closingDetails?._client?.email
                          ? closingDetails?._client?.email
                          : "N/A"
                        : closingDetails?.personOfContact?.email.length > 0 &&
                          closingDetails?.personOfContact?.email[0] !== ""
                        ? closingDetails?.personOfContact?.email?.join(", ")
                        : "N/A"}
                    </h5>
                  </li>
                  <li>
                    <span>Signing Type</span>{" "}
                    <h5>{closingDetails?.signingType || "N/A"}</h5>
                  </li>
                </>
              )}
              <li>
                <span>Witness Number</span>
                <h5>{closingDetails?.witnessCount || "N/A"}</h5>
              </li>

              {userType !== "company" && (
                <li>
                  <span>Tracking Info</span>
                  <h5>
                    {closingDetails?.shippingCompany ? (
                      <span>{closingDetails?.shippingCompany} - </span>
                    ) : null}
                    {closingDetails?.trackingInfo ? (
                      <CopyToClipboard
                        text={closingDetails.trackingInfo}
                        onCopy={() => showToast("Copied", "success")}
                        style={{ cursor: "pointer" }}
                      >
                        <span>{closingDetails.trackingInfo}</span>
                      </CopyToClipboard>
                    ) : (
                      "N/A"
                    )}
                  </h5>
                </li>
              )}
              {userType === "company" && (
                <>
                  <li>
                    <span>Property Address</span>
                    <h5>
                      {formatAddressInSingleText(
                        closingDetails?._borrower[0]?.address
                      ) || "N/A"}
                    </h5>
                  </li>
                  <li>
                    <span>Tracking Info</span>
                    <h5>
                      {closingDetails?.shippingCompany ? (
                        <span>{closingDetails?.shippingCompany} - </span>
                      ) : null}
                      {closingDetails?.trackingInfo ? (
                        <CopyToClipboard
                          text={closingDetails.trackingInfo}
                          onCopy={() => showToast("Copied", "success")}
                          style={{ cursor: "pointer" }}
                        >
                          <span>{closingDetails.trackingInfo}</span>
                        </CopyToClipboard>
                      ) : (
                        "N/A"
                      )}
                    </h5>
                  </li>
                  <li>
                    <span>Funds Collected</span>
                    <h5>
                      <div className="d-flex flex-wrap">
                        <Input
                          type="Select"
                          name="agentFundsCollected"
                          style={{ height: 35, width: 50 }}
                          // value="true"
                          value={
                            closingDetails.agentFundsCollected ? "Yes" : "No"
                          }
                          disabled={closingDetails.isSavedAsDraft}
                          readOnly
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Input>
                      </div>
                    </h5>
                  </li>
                </>
              )}

              {closingDetails?.signingType === "RON" ? (
                <li>
                  <span>RON Platform Types</span>{" "}
                  <h5>
                    {closingDetails?.ronPlatformTypes?.length
                      ? closingDetails?.ronPlatformTypes?.map(
                          (each) => each + ", "
                        )
                      : "N/A"}
                  </h5>
                </li>
              ) : null}
            </ul>
            <ul className="questionPoint">
              {closingDetails?.questions.length > 0 &&
                closingDetails?.questions.map((question) => (
                  <li className={question.reply ? "yes" : "No"}>
                    {question.query}
                    <div className="answer">
                      {" "}
                      {question.reply ? (
                        <img
                          src={
                            require("../../../../assets/img/checkIcon.svg")
                              .default
                          }
                          alt="yes check"
                        />
                      ) : (
                        <img
                          src={require("../../../../assets/img/cross.png")}
                          alt="No cross"
                        />
                      )}{" "}
                      {question.reply ? "Yes" : "No"}
                    </div>
                  </li>
                ))}
            </ul>
          </>
        )}
      </Collapse>

      <div className="text-center">
        <Button
          color="link"
          className={`accordianButton ${isOpenCollapse ? "" : "show"}`}
          onClick={() => setIsOpenCollapse((prev) => !prev)}
        >
          {isOpenCollapse ? "Hide" : "Show"} Other Details
          <img
            src={require("../../../../assets/img/arrow-blue.svg").default}
            alt="details"
          />
        </Button>
      </div>
    </div>
  );
};

export default SignerAppointment;
