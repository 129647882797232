import React from "react";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import { agentClosingStatus } from "../../../config";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const AgentDashboardFilter = ({
  filters,
  dateRangeValue,
  _filterOnChange,
  _onChangeDatePicker,
}) => {
  return (
    <div className="filterWrapper">
      <FormGroup className="searchTable">
        <Label>Search</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <img
                src={require("../../../assets/img/searchIcon.svg").default}
                alt="searchIcon"
              />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            name="search"
            value={filters.search}
            onChange={(e) => _filterOnChange("search", e.target.value, true)}
            placeholder="Search Signer, File"
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="dateRange">
        <Label>Date Range</Label>
        <DateRangePicker
          // clearIcon={null}
          className="dateRange"
          format="MM-dd-y"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          onChange={(dateRangeValue) => _onChangeDatePicker(dateRangeValue)}
          value={dateRangeValue}
        />
      </FormGroup>
      <FormGroup>
        <Label>Status</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            name="status"
            value={filters.status}
            onChange={(e) => _filterOnChange("status", e.target.value)}
          >
            <option value="">All</option>
            {agentClosingStatus.map((obj) => (
              <option key={obj.value} value={obj.value}>
                {obj.label}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>
    </div>
  );
};

export default AgentDashboardFilter;
