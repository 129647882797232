import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import {
  Badge,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../../../config";
import {
  capitalize,
  checkPermission,
  deepClone,
  errorHandler,
  formatAddressInSingleText,
  formatPhoneNumber,
  openGoogleMapOnNewTab,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  deleteRequestConnectWithCompany,
  getAllCompanyConnectByClient,
} from "../../../http/http-calls";
import ScreenWidthHOC from "./ScreenWidthHOC";

const InviteCompany = lazy(() => import("../components/InviteCompany"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));

class SigningCompanies extends React.Component {
  state = {
    data: [],
    dataCount: 0,
    dataHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "name", label: "Name", noSort: true },
      { id: "scheduling", label: "General Email", noSort: true },
      { id: "main", label: "Phone", noSort: true },
      { id: "location", label: "Location", noSort: true },
      { id: "connectStatus", label: "Connection Status", noSort: true },
      { id: "status", label: "Status", noSort: true },
      {
        id: "action",
        label: "Action",
        noSort: true,
        style: { width: 100, textAlign: "center" },
      },
    ],
    dataCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    isOpenInviteCompany: {
      isOpen: false,
      data: null,
    },
    loading: {
      showTableLoading: false,
      requestLoading: false,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    filters: {
      search: "",
    },
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = this.state;
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _getAllCompanyConnectByClient = async () => {
    try {
      this._manageLoading("showTableLoading", true);

      const { filters, tableConfig } = deepClone(this.state);

      if (filters.search && filters.search.trim().length)
        Object.assign(tableConfig, { search: filters.search.trim() });

      const res = await getAllCompanyConnectByClient(tableConfig);

      this.setState({
        data: res?.company?.length ? res?.company : [],
        dataCount: res?.totalCount || 0,
      });
      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;

    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllCompanyConnectByClient();
    });

    document.querySelector(".content").scrollIntoView();
  };

  componentDidMount = () => {
    // this._paginate();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      // this._paginate();
      this._resetFilter();
      refreshFunc("client-signing-company");
    });
    HeaderEventEmitter.subscribe("invite", () => {
      this._toggleInviteCompany(true);
    });

    document.querySelector("#scroll").scrollTo(0, 0);
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = this.state;
    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      } else {
        this._paginate();
      }
    });
  };

  _toggleInviteCompany = (isOpen = false, data) => {
    const { isOpenInviteCompany } = deepClone(this.state);
    isOpenInviteCompany.isOpen = isOpen;
    isOpenInviteCompany.data = data;
    this.setState({ isOpenInviteCompany });
  };

  _dataFormat = (cell, row, header) => {
    const { loading } = deepClone(this.state);

    switch (header) {
      case "name": {
        return (
          <>
            <div
              className="tableUserInfo"
              onClick={() =>
                row?._company?._id
                  ? this.props.history.push(
                      `/client/signing-company-details/${row._company._id}`
                    )
                  : {}
              }
            >
              <div className="userImage">
                <img
                  src={row._company?.logo || DEFAULT_COVER_PICTURE}
                  onError={(e) => (e.target.value = DEFAULT_COVER_PICTURE)}
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="signerName">
                  {row._company !== undefined
                    ? capitalize(row?._company?.companyName)
                    : row?.companyName
                    ? capitalize(row.companyName)
                    : "N/A"}
                </span>
              </div>
            </div>
          </>
        );
      }
      case "scheduling": {
        return (
          <>
            <div style={{ width: 200 }}>
              {row._company === undefined
                ? row.email
                : row._company?.email
                ? row._company?.email
                : "N/A"}
            </div>
          </>
        );
      }
      case "main": {
        return (
          <>
            <div style={{ width: 200 }}>
              {row._company?.phone
                ? formatPhoneNumber(row._company.phone)
                : row?.phone
                ? formatPhoneNumber(row.phone)
                : "N/A"}
            </div>
          </>
        );
      }
      case "action": {
        return (
          <>
            {row._company === undefined
              ? checkPermission("connectCompanies", "canUpdate") && (
                  <Button
                    color="link"
                    className="actionBtn"
                    onClick={() => this._toggleInviteCompany(true, row)}
                  >
                    <img
                      src={require("../../../assets/img/pencil.svg").default}
                      alt="pencil"
                    />
                  </Button>
                )
              : null}
            {row?.status === "accepted" &&
              checkPermission("connectCompanies", "canUpdate") &&
              row.isDeletable && (
                <Button
                  color="link"
                  onClick={() =>
                    this._clientDeleteRequestConnectWithCompany(
                      row?._company?._id
                    )
                  }
                  disabled={loading.requestLoading ? true : false}
                >
                  <img
                    src={require("../../../assets/img/deleteIcon.svg").default}
                    alt="delete"
                  />
                </Button>
              )}
          </>
        );
      }
      case "location": {
        return row._company?.address ? (
          <>
            <div style={{ width: 200 }}>
              {formatAddressInSingleText(row?._company?.address)}
            </div>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openGoogleMapOnNewTab(row?._company?.address)}
            >
              <img
                src={require("../../../assets/img/map-marker.svg").default}
                alt="map icon"
                className="mr-1"
              />
              Map
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      case "connectStatus": {
        return cell ? (
          cell === "requested" ? (
            <Badge color="success" className="outline" pill>
              Request{" "}
              {`
            ${
              row.connectResquestBy === this.props.userData?.user?.type
                ? "Sent"
                : "Received"
            }
            `}
            </Badge>
          ) : cell === "accepted" ? (
            <Badge color="success" className="outline" pill>
              Connected
            </Badge>
          ) : (
            <Badge color="warning" className="outline" pill>
              {capitalize(cell)}
            </Badge>
          )
        ) : (
          "N/A"
        );
      }
      case "status": {
        return row._company === undefined ? (
          <Badge color="warning" className="outline" pill>
            Not joined yet
          </Badge>
        ) : row._company?.isActive ? (
          <Badge color="success" className="outline" pill>
            Active
          </Badge>
        ) : (
          <Badge color="warning" className="outline" pill>
            Inactive
          </Badge>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _clientDeleteRequestConnectWithCompany = async (id) => {
    try {
      this._manageLoading("requestLoading", "Delete");

      await deleteRequestConnectWithCompany(id);
      await this._getAllCompanyConnectByClient();
      showToast("Connection has been Deleted Successfully", "success");

      this._manageLoading("requestLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("requestLoading", false);
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    const { loading } = deepClone(this.state);

    switch (header) {
      case "name": {
        return row ? (
          <>
            <div
              className="tableUserInfo"
              onClick={() =>
                row?._company?._id
                  ? this.props.history.push(
                      `/client/signing-company-details/${row._company._id}`
                    )
                  : {}
              }
            >
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._company?.logo
                      ? row?._company?.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="signerName">
                  {row?._company !== undefined
                    ? capitalize(row?._company?.companyName)
                    : row?.companyName
                    ? capitalize(row?.companyName)
                    : "N/A"}
                </span>
              </div>
            </div>

            <>
              {row._company === undefined
                ? checkPermission("connectCompanies", "canUpdate") && (
                    <Button
                      color="link"
                      className="actionBtn"
                      onClick={() => this._toggleInviteCompany(true, row)}
                    >
                      <img
                        src={require("../../../assets/img/pencil.svg").default}
                        alt="pencil"
                      />
                    </Button>
                  )
                : null}
              {row?.status === "accepted" &&
                checkPermission("connectCompanies", "canUpdate") && (
                  <Button
                    color="link"
                    onClick={() =>
                      this._clientDeleteRequestConnectWithCompany(
                        row?._company?._id
                      )
                    }
                    disabled={loading.requestLoading ? true : false}
                  >
                    <img
                      src={
                        require("../../../assets/img/deleteIcon.svg").default
                      }
                      alt="delete"
                    />
                  </Button>
                )}
            </>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>General Email </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._company === undefined
                ? row?.email
                : row?._company?.email
                ? row?._company?.email
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Phone </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._company?.phone
                ? formatPhoneNumber(row?._company.phone)
                : row?.phone
                ? formatPhoneNumber(row?.phone)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <div className="d-flex justify-content-between">
              <Label>Location </Label>
              {row?._company?.address ? (
                <Button
                  color="link"
                  className="p-0 fs-12"
                  title={formatAddressInSingleText(row?._company?.address)}
                  onClick={() => openGoogleMapOnNewTab(row?._company?.address)}
                >
                  <img
                    src={require("../../../assets/img/map-marker.svg").default}
                    alt="map icon"
                    className="mr-1"
                  />
                  Map
                </Button>
              ) : null}
            </div>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._company?.address ? (
                <>{formatAddressInSingleText(row?._company?.address)}</>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Connection Status </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.connectStatus ? (
                row?.connectStatus === "requested" ? (
                  <Badge color="success" className="outline" pill>
                    Request{" "}
                    {`
            ${
              row?.connectResquestBy === this.props.userData?.user?.type
                ? "Sent"
                : "Received"
            }
            `}
                  </Badge>
                ) : row?.connectStatus === "accepted" ? (
                  <Badge color="success" className="outline" pill>
                    Connected
                  </Badge>
                ) : (
                  <Badge color="warning" className="outline" pill>
                    {capitalize(row?.connectStatus)}
                  </Badge>
                )
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._company === undefined ? (
                <Badge color="warning" className="outline" pill>
                  Not joined yet
                </Badge>
              ) : row?._company?.isActive ? (
                <Badge color="success" className="outline" pill>
                  Active
                </Badge>
              ) : (
                <Badge color="warning" className="outline" pill>
                  Inactive
                </Badge>
              )}
            </div>
          </div>
        </li>
      </ul>
    );
  };

  _rowStyleFormat = (row, rowIdx) => {
    if (row?.whiteLabelRequestStatus === "Done") {
      return {
        backgroundColor: "#d7eafd",
      };
    }
    return {};
  };
  _rowClassName = (row, rowIdx) => {
    if (row?.whiteLabelRequestStatus === "Done") {
      return "isWhiteLabel";
    }
    return "";
  };

  _persistFilter = () => {
    try {
      const { tableConfig } = this.state;

      if (tableConfig.pageNumber !== 1) {
        let data = { tableConfig };
        localStorage.clientSigningCompaniesPage = JSON.stringify(data);
      } else {
        delete localStorage.clientSigningCompaniesPage;
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.clientSigningCompaniesPage) {
      try {
        const filters = JSON.parse(localStorage.clientSigningCompaniesPage);

        this.setState({ tableConfig: filters?.tableConfig }, () => {
          this._getAllCompanyConnectByClient();
        });
      } catch (e) {
        this._getAllCompanyConnectByClient();
      }
    } else {
      this._getAllCompanyConnectByClient();
    }
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          search: "",
        },
      },
      () => {
        this._paginate();
      }
    );
  };

  render() {
    const {
      data,
      dataHeaderKeys,
      dataCount,
      loading,
      tableConfig,
      isOpenInviteCompany,
      filters,
      dataCardHeaderKeys,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <>
        <div id="scroll">
          <div className="content">
            <div className="responsiveTitle">
              <h2>
                {" "}
                Signing Companies{" "}
                {loading.showTableLoading ? <Spinner /> : null}
              </h2>

              <div className="rightSide">
                <Button
                  color="link"
                  onClick={() => {
                    this._resetFilter();
                    // this._paginate();
                    refreshFunc("client-signing-company-responsive");
                  }}
                >
                  <img
                    id="client-signing-company-responsive"
                    src={require("../../../assets/img/refresh.svg").default}
                    alt="refresh"
                  />
                </Button>

                {checkPermission("connectCompanies", "canAdd") && (
                  <div>
                    <Button
                      onClick={() => this._toggleInviteCompany(true)}
                      className="floatingButton"
                      color="primary"
                    >
                      Invite
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {/* search filed for responsive will show below 767px */}
            <div className="mobileSearchFiled">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      src={
                        require("../../../assets/img/searchIcon.svg").default
                      }
                      alt="searchIcon"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="search"
                  value={filters.search}
                  onChange={(e) =>
                    this._filterOnChange("search", e.target.value, true)
                  }
                  placeholder="Enter"
                />
              </InputGroup>
            </div>
            <div className="filterContainer responsiveFilter">
              <div className="filterIcon">
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="filter icon"
                />
                Filter by
              </div>
              <div className="filterWrapper">
                <FormGroup className="searchTable">
                  <Label>Search</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <img
                          src={
                            require("../../../assets/img/searchIcon.svg")
                              .default
                          }
                          alt="searchIcon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Enter"
                      value={filters.search}
                      onChange={(e) =>
                        this._filterOnChange("search", e.target.value, true)
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </div>
            {isForMobile ? (
              <div className="hideDesktop">
                <Suspense fallback={<></>}>
                  <CustomCard
                    isPageStartFromOne={true}
                    pageNumber={tableConfig.pageNumber}
                    tableData={data}
                    headerKeys={dataCardHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={dataCount}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    rowSelection={false}
                    rowStyleFormat={(row, rowIdx) =>
                      this._rowStyleFormat(row, rowIdx)
                    }
                    // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                    showTableLoading={loading.showTableLoading}
                    cardHeaderFormat={this._cardHeaderFormat}
                    cardDataFormat={this._cardDataFormat}
                  />
                </Suspense>
              </div>
            ) : (
              <div className="hideMobile">
                <Suspense fallback={<></>}>
                  {dataHeaderKeys?.length && (
                    <CustomTable
                      striped
                      isPageStartFromOne={true}
                      pageNumber={tableConfig.pageNumber}
                      tableData={data}
                      headerKeys={dataHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={dataCount}
                      rowSelection={false}
                      rowClassName={(row, rowIdx) =>
                        this._rowClassName(row, rowIdx)
                      }
                      pageSize={tableConfig.limit}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      onSortChange={(sortName, sortOrder) => this._paginate()}
                      showTableLoading={loading.showTableLoading}
                    />
                  )}
                </Suspense>
              </div>
            )}
          </div>

          {/* {isOpenInviteCompany?.isOpen && ( */}
          <Suspense fallback={<></>}>
            <InviteCompany
              isOpen={isOpenInviteCompany.isOpen}
              data={isOpenInviteCompany.data}
              toggle={() => this._toggleInviteCompany()}
              onSuccess={() => this._paginate()}
            />
          </Suspense>
          {/* )} */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(ScreenWidthHOC(SigningCompanies));
