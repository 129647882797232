import { ADD_SETTINGS, CLEAR_SETTINGS } from "./action-types";

export const addSettings = (settings) => {
  console.log("qa",settings)
  return {
    type: ADD_SETTINGS,
    payload: {
      settings,
    },
  };
};

export const clearSettings = () => {
  return {
    type: CLEAR_SETTINGS,
  };
};
