import React, { useEffect } from "react";
import { agentGenerateLinkdinToken } from "../../../http/http-calls";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const LinkedInPage = () => {
  useEffect(() => {
    const handleAuthCallback = async () => {
      console.log("ytyt", window.location.search);
      // Parse the authorization code from the URL
      const code = new URLSearchParams(window.location.search).get("code");

      try {
        const response = await agentGenerateLinkdinToken({ code });

        if (!response.error) {
          history.push("/agent/marketing/social-media-management");
        }
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    if (window.location.search) {
      handleAuthCallback();
      console.log("efvewvwv");
    }
  }, []);

  return <></>;
};

export default LinkedInPage;
