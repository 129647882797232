import { useEffect, useState } from "react";

const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isForMobile, setIsForMobile] = useState(false);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setIsForMobile(window.innerWidth < 992);
  };

  useEffect(() => {
    // Set the initial screen width
    setScreenWidth(window.innerWidth);
    setIsForMobile(window?.innerWidth < 992);

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return { screenWidth, isForMobile };
};

export default useScreenWidth;
