import React from "react";
import {
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { agentClosingStatus } from "../../../config";
import { capitalize } from "../../../helper-methods";
import usaStates from "../../../config/usa_states_titlecase";
import SearchableDropdown from "./searchableDropdown";

const ClosingDashboardFilter = ({
  filters,
  dateRangeValue,
  filterDropdownValues,
  _filterOnChange,
  _onChangeDatePicker,
  getAllWorkingAgentsForSigningCompany,
  getAllListClients,
  agents,
  clients,
}) => {
  return (
    <div className="filterWrapper">
      <FormGroup className="searchTable">
        <Label>Search</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <img
                src={require("../../../assets/img/searchIcon.svg").default}
                alt="searchIcon"
                loading="lazy"
              />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Ex.Lender or Product Officer or Order No."
            value={filters.search}
            onChange={(e) => _filterOnChange("search", e.target.value, true)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="dateRange">
        <Label>Date Range</Label>
        <DateRangePicker
          className="dateRange"
          format="MM-dd-y"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          disabled={filters?.showUpcomingAppointment}
          onChange={(dateRangeValue) => _onChangeDatePicker(dateRangeValue)}
          value={dateRangeValue}
        />
      </FormGroup>
      <FormGroup>
        <Label>Status</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            name="status"
            value={filters.status}
            onChange={(e) => _filterOnChange("status", e.target.value)}
          >
            <option value="">All</option>
            {agentClosingStatus.map((obj) => (
              <option key={obj.value} value={obj.value}>
                {obj.label}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>
      <FormGroup style={{ minWidth: "200px" }}>
        <Label>Client</Label>
        <div className="custom-select-wrapper">
          <SearchableDropdown
            fetchData={(search) => getAllListClients(search)}
            onSelectChange={(value) => _filterOnChange("client", value)}
            options={clients}
            selectedData={filters?.client}
          />
        </div>
      </FormGroup>
      <FormGroup style={{ minWidth: "150px" }}>
        <Label>Client Teams</Label>
        <div
          className={`custom-select-wrapper ${
            filters?.client === "" ? "disabled" : ""
          }`}
        >
          <Input
            type="select"
            placeholder=" "
            value={filters?.clientTeam}
            onChange={(event) =>
              _filterOnChange("clientTeam", event.target.value)
            }
            disabled={filters?.client === ""}
            name="clientTeamsId"
          >
            <option value="">All Teams</option>
            {filterDropdownValues?.clientTeamsList?.map((team) => (
              <option key={team?._id} value={team?._id}>
                {team?.teamName ? capitalize(team?.teamName) : ""}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>
      <FormGroup style={{ minWidth: "200px" }}>
        <Label>Agent</Label>
        <div className="custom-select-wrapper">
          <SearchableDropdown
            fetchData={(search) => getAllWorkingAgentsForSigningCompany(search)}
            onSelectChange={(value) => _filterOnChange("agentAssigned", value)}
            options={agents}
            selectedData={filters?.agentAssigned}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <Label>State</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            name="state"
            value={filters.state}
            onChange={(e) => _filterOnChange("state", e.target.value)}
          >
            <option value="">All</option>
            {usaStates.map((state) => (
              <option key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="checkbox"
          id="unassigned"
          name="unassigned"
          checked={filters?.unassigned}
          onChange={(e) => _filterOnChange("unassigned", e.target.checked)}
          label="Show Unassigned Only"
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="checkbox"
          id="showDraft"
          name="showDraft"
          checked={filters?.showDraft}
          onChange={(e) => _filterOnChange("showDraft", e.target.checked)}
          label="Show Draft Only"
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="checkbox"
          id="showUpcomingAppointment"
          name="showUpcomingAppointment"
          checked={filters?.showUpcomingAppointment}
          onChange={(e) =>
            _filterOnChange("showUpcomingAppointment", e.target.checked)
          }
          label="Show Upcoming Appointments"
          className="text-nowrap"
        />
      </FormGroup>
    </div>
  );
};

export default ClosingDashboardFilter;
