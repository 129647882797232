export const LoanType = [
    "Refinance",
    "HomeEquity",
    "AttorneyPhone",
    "Purchase",
    "LoanMod",
    "ReverseMtg",
    "HybridESign",
    "DeedInLieu",
    "SingleDoc",
    "LoanApplication",
    "Other",
  ];

  export const LoanTypeNotOthers = [
    "Refinance",
    "HomeEquity",
    "AttorneyPhone",
    "Purchase",
    "LoanMod",
    "ReverseMtg",
    "HybridESign",
    "DeedInLieu",
    "SingleDoc",
    "LoanApplication",
  ];