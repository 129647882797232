import { errorHandler, logout } from "../helper-methods/index";

export const handleErrorIfAvailable = (httpResponse) => {
  // console.log("httpResponse >>", httpResponse);
  return new Promise((resolve) => {
    switch (httpResponse.status) {
      case 401: {
        // Token expired
        logout();
        errorHandler("Token expired. Please login again.", 401);
        resolve(true);
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        resolve(false);
      }
    }
  });
};
