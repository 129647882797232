import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "link", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [],
    ],
  };
  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "link",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  handleChange(value) {
    this.props.onChange(value);
  }
  render() {
    return (
      <div>
        <ReactQuill
          value={this.props.content || ""}
          name="renterRefundPolicy"
          onChange={this.handleChange}
          className="editor"
          modules={this.modules}
          formats={this.formats}
          readOnly={this.props.readOnly || false}
          onBlur={() => this.props.onBlur && this.props.onBlur()}
          onFocus={() => this.props.onFocus && this.props.onFocus()}
        />
      </div>
    );
  }
}

export default TextEditor;
