/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Label,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Collapse,
  CustomInput,
  Card,
  CardBody,
} from "reactstrap";
import SvgIcons from "../SvgIcons";
import { clientPermissionConfig } from "../../../../config";
import {
  deepClone,
  errorHandler,
  getPhoneNumberFromBrackets,
  showToast,
  splitName,
} from "../../../../helper-methods";
import { RegexConfig } from "../../../../config/RegexConfig";
import {
  addClientMembers,
  getClientTeamMembers,
} from "../../../../http/http-calls";

const initialObject = {
  id: {
    value: "",
  },
  mode: {
    value: "add",
    error: null,
    isDirty: false,
  }, // can be 'add', 'edit' or 'delete'
  // will change it to 'edit' -> on initial page load form set and to 'delete' after deleting a particular object
  name: {
    value: "",
    error: null,
    isDirty: false,
  },
  email: {
    value: "",
    error: null,
    isDirty: false,
  },
  phone: {
    value: "",
    error: null,
    isDirty: false,
  },
  permissions: {
    dashboard: {
      canUpdateClosingStatusOrScheduling: false,
      canEditOrderEntry: false,
      canReviewDocumentsOrOrderDetail: false,
      canCreateOrder: false,
    },
    payment: {
      canView: false,
      canPay: false,
    },
    lenderClient: {
      canAdd: false,
      canUpdate: false,
      canView: false,
    },
  },
};

const TeamNewMember = forwardRef(
  ({ data, manageLoading, type, newClientData }, ref) => {
    const [newTeamMember, setNewTeamMember] = useState([initialObject]);
    const [teamMemberDataInitial, setTeamMemberDataInitial] = useState({});
    const [membersToDelete, setMembersToDelete] = useState([]);
    const [isOpenCollapse, setIsOpenCollapse] = useState("");

    const _resetData = () => {
      setNewTeamMember([initialObject]);
      setMembersToDelete([]);
      setIsOpenCollapse("");
    };

    const addNewMember = () => {
      setNewTeamMember((prevMembers) => [...prevMembers, initialObject]);
    };

    const deleteNewMember = (index) => {
      const newTeamMembers = [...newTeamMember];
      const newMembersToDelete = [...membersToDelete];

      const memberToDelete = newTeamMembers?.splice(index, 1);
      memberToDelete[0]["mode"] = {
        value: "delete",
        error: null,
        isDirty: false,
      };

      console.log({ memberToDelete });
      newMembersToDelete.push(...memberToDelete);

      console.log({ newMembersToDelete });
      setNewTeamMember(newTeamMembers);
      setMembersToDelete(newMembersToDelete);

      // setNewTeamMember((prevMembers) => {
      //   const updatedMembers = deepClone(prevMembers);
      //   updatedMembers.splice(index, 1);
      //   return updatedMembers;
      // });
    };

    const onChangePermission = (key, index, each, value) => {
      const updatedMembers = deepClone(newTeamMember);
      updatedMembers[index]["permissions"][key][each] = value;
      setNewTeamMember(updatedMembers);
    };

    const renderPermission = (item, memberIndex) => {
      return clientPermissionConfig.map((each, index) => (
        <div
          key={`${each.value}${memberIndex}`}
          className={`permissionWrapper ${
            isOpenCollapse === each.value + memberIndex ? "open" : ""
          }`}
        >
          <div
            onClick={() => handleCollapse(each.value + memberIndex)}
            className="permissionHead"
          >
            <i
              className={`fa fa-${
                isOpenCollapse === each.value + memberIndex ? "minus" : "plus"
              }`}
            />
            <span>{each.label}</span>
          </div>

          <Collapse
            className="permissionBody"
            isOpen={isOpenCollapse === each.value + memberIndex}
          >
            {each.permissions.map((permission, permissionIndex) => (
              // console.log("first", item.permissions[each.value]),
              <CustomInput
                key={`${each.value}${permission.value}${index}${permissionIndex}${memberIndex}`}
                type="checkbox"
                checked={item?.permissions[each.value][permission.value]}
                onChange={(e) =>
                  onChangePermission(
                    each.value,
                    memberIndex,
                    permission.value,
                    e.target.checked
                  )
                }
                id={`${each.value}${permission.value}${index}${permissionIndex}${memberIndex}`}
                label={permission.label}
              />
            ))}
          </Collapse>
        </div>
      ));
    };

    const handleCollapse = (value) => {
      setIsOpenCollapse((prevValue) => (prevValue !== value ? value : ""));
    };

    const onChangeFields = (key, index, value) => {
      const updatedMembers = deepClone(newTeamMember);
      updatedMembers[index][key].value = value;
      setNewTeamMember(updatedMembers);
    };

    const _markAllFieldDirty = () => {
      console.log("dssff");
      return new Promise((resolve, reject) => {
        // const newIsDirty = { ...isDirty };
        const newTeamMembers = [...newTeamMember];
        console.log("dsd", newTeamMembers);
        newTeamMembers.forEach((each) => {
          Object.keys(each).forEach((e) => {
            if (e !== "permissions") {
              each[e].isDirty = true;
            }
          });
        });

        setNewTeamMember(newTeamMembers);
        resolve(true);
      });
    };

    const _createPayload = (payloadData) => {
      return new Promise((resolve) => {
        // let payload = [];
        const teamMembers = [];
        // payload["teamMembers"] =
        payloadData?.forEach((each) => {
          const member = {
            // id: each?.id?.value,
            // mode: each?.mode?.value || "",
            // clientId: newClientData?.id ? newClientData?.id : data?.id,
            // clientId: "660f91a29f1a37235cc55010",
            // will get from previous step api call response
            name: splitName(each?.name?.value),
            email: each?.email?.value,
            phone: "(+1)" + each?.phone?.value,
            phoneCountry: "US",
            permissionSettings: each?.permissions,
          };

          teamMembers.push(member);
        });

        resolve(teamMembers);
      });
    };

    const _onSubmit = async () => {
      try {
        manageLoading(true);

        const newTeamMembers = [...newTeamMember];
        const newMembersToDelete = [...membersToDelete];

        await _markAllFieldDirty();

        const isFormValid = await _validateFormfields({
          newTeamMembers,
        });

        console.log(
          "changed",
          !isValueChanged(newTeamMember),
          isFormValid,
          type !== "add"
        );

        if (!isValueChanged(newTeamMember) && isFormValid && type !== "add") {
          manageLoading(false);
          return true;
        }
        if (!isFormValid) {
          manageLoading(false);
          return false;
        }
        let payload = {};
        const payloadAssitent = await _createPayload(newTeamMember);
        payload["clientId"] = newClientData?.id ? newClientData?.id : data?.id;
        payload["assistants"] = payloadAssitent;
        try {
          await addClientMembers(payload);
          showToast("Team added successfully", "success");
          _resetData();
          manageLoading(false);

          return true;
        } catch (error) {
          showToast(
            error?.reason || "Error occurred during team operation",
            "error"
          );
          return false;
        }
      } catch (err) {
        errorHandler(err);
        manageLoading(false);
        return false;
      }
    };

    const _onBlurFormFields = (key, index) => {
      const newTeamMembers = [...newTeamMember];
      newTeamMembers[index][key].isDirty = true;
      setNewTeamMember(newTeamMembers);
      _validateFormfields({ newTeamMembers });

      // setIsFormDirty(true);
    };
    const _validateFormfields = ({ newTeamMembers }) => {
      return new Promise((resolve) => {
        let isFormValid = true;
        const newErrors = {};
        console.log("ae", newTeamMembers);
        newTeamMembers.forEach((newFormField) => {
          Object.keys(newFormField)?.forEach((key) => {
            console.log(newFormField, key);
            switch (key) {
              case "name": {
                if (newFormField[key]?.value?.trim()?.length) {
                  if (
                    newFormField[key].value.length >= 2 &&
                    newFormField[key].value.length <= 50
                  ) {
                    // newErrors[key] = null;
                    // newIsDirty[key] = false;
                    newFormField[key].isDirty = false;
                    newFormField[key].error = null;
                  } else {
                    newFormField[key] =
                      "*Minimum 2 characters and Maximum 50 characters allowed ";
                    isFormValid = false;
                  }
                } else {
                  // newErrors[key] = "*Required";
                  // isFormValid = false;
                  newFormField[key].isDirty = true;
                  newFormField[key].error = "*Required";
                  isFormValid = false;
                }

                break;
              }
              case "email": {
                if (newFormField[key].value.trim().length) {
                  if (
                    RegexConfig.email.test(
                      String(newFormField[key].value).toLowerCase()
                    )
                  ) {
                    newFormField[key].isDirty = false;
                    newFormField[key].error = null;
                  } else {
                    newFormField[key].isDirty = true;
                    newFormField[key].error = "*Invalid Email";
                    isFormValid = false;
                  }
                } else {
                  newFormField[key].isDirty = true;
                  newFormField[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "phone": {
                if (newFormField[key].value.length) {
                  if (
                    RegexConfig.phone.test(
                      String(newFormField[key].value).toLowerCase()
                    )
                  ) {
                    newFormField[key].isDirty = false;
                    newFormField[key].error = null;
                  } else {
                    isFormValid = false;
                    newFormField[key].isDirty = true;
                    newFormField[key].error = "*Invalid Phone Number";
                  }
                } else {
                  newFormField[key].isDirty = true;
                  newFormField[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          });
        });

        resolve(isFormValid);
      });
    };

    useImperativeHandle(ref, () => ({
      _onSubmit,
    }));

    useEffect(() => {
      const fetchData = async () => {
        const Id = newClientData?.id ? newClientData?.id : data?.id;
        // const Id = "660f91a29f1a37235cc55010";
        manageLoading(true);
        try {
          let response = await getClientTeamMembers({ clientId: Id });
          // Handle response data as needed
          _setFields(response.users);
          // setTeamMember(response.users);
          manageLoading(false);
        } catch (error) {
          errorHandler(error);
          manageLoading(false);
        }
      };

      fetchData();
      console.log("first1", newClientData, data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _setFields = (responseData) => {
      const updatedMembers = [...newTeamMember];

      responseData.length > 0 &&
        responseData.forEach((data, index) => {
          updatedMembers[index] = {
            ...updatedMembers[index],
            id: {
              value: data._id,
            },
            mode: {
              value: "edit",
            },
            name: {
              value: data?.name?.full || "",
            },
            phone: {
              value: data.phone ? getPhoneNumberFromBrackets(data.phone) : "",
            },
            email: {
              value: data.email ? data.email : "",
            },
            permissions: {
              // masterSchedule: {
              //   canView: data.permissionSettings.masterSchedule.canView,
              // },
              dashboard: {
                canUpdateClosingStatusOrScheduling:
                  data.permissionSettings.dashboard
                    .canUpdateClosingStatusOrScheduling,
                canEditOrderEntry:
                  data.permissionSettings.dashboard.canEditOrderEntry,
                canReviewDocumentsOrOrderDetail:
                  data.permissionSettings.dashboard
                    .canReviewDocumentsOrOrderDetail,
              },
              payment: {
                canView: data.permissionSettings.payment.canView,
                canPay: data.permissionSettings.payment.canPay,
              },
              lenderClient: {
                canAdd: data.permissionSettings.lenderClient.canAdd,
                canUpdate: data.permissionSettings.lenderClient.canUpdate,
                canView: data.permissionSettings.lenderClient.canView,
              },
            },
          };
        });
      console.log("state", updatedMembers);
      setNewTeamMember(updatedMembers);
      setTeamMemberDataInitial(updatedMembers);
    };

    const isValueChanged = (formFields) => {
      let isChanged = false;

      formFields?.forEach((each, index) => {
        for (const field in each) {
          if (field === "name" || field === "email" || field === "phone") {
            if (
              each?.[field]?.value !==
              teamMemberDataInitial?.[index]?.[field]?.value
            ) {
              isChanged = true;
            }
          } else if (field === "permissions") {
            // Check if permissions array length is greater than teamMemberDataInitial
            if (
              each[field]?.length !==
              teamMemberDataInitial?.[index]?.[field]?.length
            ) {
              isChanged = true;
            } else {
              // If lengths are the same, compare individual permission properties
              Object.keys(each[field])?.forEach((subEach) => {
                Object.keys(each[field][subEach])?.forEach((key) => {
                  if (
                    each[field][subEach]?.[key] !==
                    teamMemberDataInitial?.[index]?.[field]?.[subEach]?.[key]
                  ) {
                    isChanged = true;
                  }
                });
              });
            }
          }
        }
      });

      return isChanged;
    };

    return (
      <>
        <Button
          className="ml-auto d-block"
          color="primary"
          size="md"
          onClick={addNewMember}
        >
          Add New Member
        </Button>
        {/* {console.log("ere", data, type, newTeamMember)} */}
        {newTeamMember.map((item, memberIndex) => (
          <Card className="mt-3" key={memberIndex}>
            <CardBody>
              <div className="text-right mb-3">
                {newTeamMember.length > 1 && (
                  <Button
                    className="remove pb-2"
                    color="link"
                    onClick={() => deleteNewMember(memberIndex)}
                  >
                    <SvgIcons type="remove" />
                  </Button>
                )}
              </div>
              <FormGroup className="floatingLabel">
                <Input
                  placeholder=" "
                  value={item.name.value}
                  onChange={(e) =>
                    onChangeFields("name", memberIndex, e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("name", memberIndex)}
                  autoComplete="off"
                />
                <Label>Name</Label>
                {newTeamMember[memberIndex]?.name?.error && (
                  <div className="validation-error">
                    {newTeamMember[memberIndex]?.name?.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup className="floatingLabel">
                <Input
                  placeholder=" "
                  value={item.email.value}
                  onChange={(e) =>
                    onChangeFields("email", memberIndex, e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("email", memberIndex)}
                  autoComplete="off"
                />
                <Label>Email</Label>
                {newTeamMember[memberIndex]?.email?.error && (
                  <div className="validation-error">
                    {newTeamMember[memberIndex]?.email?.error}
                  </div>
                )}
              </FormGroup>
              <FormGroup className="floatingLabel withInputGroup">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>+1</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder=" "
                    value={item.phone.value}
                    onChange={(e) =>
                      onChangeFields("phone", memberIndex, e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("phone", memberIndex)}
                  />
                  <Label>Phone</Label>
                </InputGroup>
                {newTeamMember[memberIndex]?.phone?.error && (
                  <div className="validation-error">
                    {newTeamMember[memberIndex]?.phone?.error}
                  </div>
                )}
              </FormGroup>
              <Label>Permissions</Label>
              {renderPermission(item, memberIndex)}
            </CardBody>
          </Card>
        ))}
      </>
    );
  }
);

export default TeamNewMember;
