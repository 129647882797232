import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

class FeatureNotSupportedModal extends Component {
  _closeModal = () => {
    this.props.toggle();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        centered
        scrollable
      >
        <ModalHeader toggle={() => this._closeModal()}></ModalHeader>
        <ModalBody>
          <p className="text-center mb-0">
            This feature is Not Supported on your Device.
          </p>
        </ModalBody>
      </Modal>
    );
  }
}

export default FeatureNotSupportedModal;
