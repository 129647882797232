import React from "react";
import { useState } from "react";
import { Button } from "reactstrap";
import {
  openUrlOnNewTab,
  showToast,
  errorHandler,
} from "../../../helper-methods";
import { reDoUserKycLink } from "../../../http/http-calls";
import SvgIcons from "./SvgIcons";

const ReKYCCard = ({
  hideReKYCBanner,
  doNotShowAgain,
  hideCancelButton = false,
  hideDoNotShowButton = false,
}) => {
  const [loading, setLoading] = useState(false);

  const _getAndRedirectUserReKycLink = async () => {
    setLoading(true);

    reDoUserKycLink()
      .then((res) => {
        setLoading(false);
        if (res?.accountLink?.url) {
          openUrlOnNewTab(res.accountLink.url);
        } else {
          showToast("Server error. Try again after sometime.", "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  const _hideReKYCBanner = () => {
    hideReKYCBanner();
  };

  return (
    <div className="completeKyc reKyc" color="danger">
      <div className="kycContent">
        <h1>Update Your KYC</h1>

        <p className="text-danger mb-3">
          We're updating the way Stripe verifies business information for US
          connected accounts accepting payments. To prevent disruptions to your
          account:
        </p>
        <p className="text-danger mb-3 mt-0">
          Please update your KYC information by April 16, 2024. After this date,
          payments and payouts will be paused for accounts with outstanding
          requirements.
        </p>
        <Button
          color="primary"
          size="sm"
          disabled={loading}
          onClick={() => _getAndRedirectUserReKycLink()}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Click
          here to update the KYC
        </Button>

        {!hideDoNotShowButton && (
          <Button
            color="danger"
            outline
            size="sm"
            disabled={loading}
            onClick={() => doNotShowAgain()}
          >
            Do not show it again
          </Button>
        )}
      </div>
      {!hideCancelButton && (
        <Button
          color="link"
          className="remove"
          disabled={loading}
          onClick={() => _hideReKYCBanner()}
        >
          <SvgIcons type="cross" />
        </Button>
      )}
    </div>
  );
};

export default ReKYCCard;
