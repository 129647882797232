import React, { Suspense } from "react";
import {
  Button,
  Card,
  CardBody,
  CustomInput,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { checkPermission } from "../../../helper-methods";
import { capitalize } from "@material-ui/core";
import TextEditor from "../components/TextEdittor";

const OrderDetailsInstructions = ({
  editing,
  loading,
  formFields,
  closingDetails,
  signingInstruction,
  isFocusTextEditor,
  editModeOn,
  onChangeFormField,
  updateClosingDetailsByIdStatus,
  updateClosing,
  updateFocusTextEditor,
}) => {
  return (
    <div className="CardWrapper">
      <div className="CardTitle">
        <h2 className="sectionTtile">
          {" "}
          Order Instructions
          {editing?.instructions ? (
            <>
              <Button
                color="link"
                disabled={loading?.cardUpdateLoading}
                onClick={() => updateClosing("instructions")}
              >
                {loading?.cardUpdateLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : null}{" "}
                <img
                  src={require("../../../assets/img/checkBlueIcon.svg").default}
                  alt="save"
                />
              </Button>
              <Button
                color="link"
                onClick={() => editModeOn("instructions", false)}
              >
                <img
                  src={require("../../../assets/img/close.svg").default}
                  height={16}
                  alt="close"
                />
              </Button>
            </>
          ) : (
            <Button
              color="link"
              onClick={() => editModeOn("instructions", true)}
              disabled={!checkPermission("dashboard", "canEditOrderEntry")}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
          )}
        </h2>
      </div>
      <Card>
        <CardBody>
          <CustomInput
            className="mb-2"
            type="checkbox"
            id="isScanBackNeeded"
            label="Scan Backs Needed"
            checked={formFields?.isScanBackNeeded?.value}
            onChange={(event) =>
              updateClosingDetailsByIdStatus(closingDetails, {
                isScanBackNeeded: event.target.checked,
              })
            }
            disabled
          />
          {editing?.instructions ? (
            <FormGroup className="floatingLabel custom-select-wrapper">
              <Input
                type="select"
                value={formFields?.instructionId?.value}
                name="instructionId"
                onChange={(event) =>
                  onChangeFormField("instructionId", event.target.value)
                }
              >
                <option value="">select</option>
                {signingInstruction?.map((each) => (
                  <option key={each?._id} value={each?._id}>
                    {capitalize(each?.name)}
                  </option>
                ))}
              </Input>
              <Label>Instructions Type</Label>
            </FormGroup>
          ) : null}

          <FormGroup
            className={`mb-0 floatingLabel reactQuill ${
              formFields.instructions.value !== "<p><br></p>" ||
              isFocusTextEditor
                ? "valueAdded"
                : ""
            }`}
          >
            <Suspense fallback={<></>}>
              {editing?.instructions ? (
                <TextEditor
                  content={formFields?.instructions?.value}
                  onChange={(event) => onChangeFormField("instructions", event)}
                  onFocus={() => updateFocusTextEditor("focus")}
                  onBlur={() => updateFocusTextEditor("blur")}
                />
              ) : (
                <TextEditor
                  readOnly={true}
                  content={closingDetails?.instructions}
                  onChange={() => {}} // caution: don't remove
                />
              )}
            </Suspense>
            <Label>Instructions</Label>
          </FormGroup>
        </CardBody>
      </Card>
    </div>
  );
};

export default OrderDetailsInstructions;
