/* eslint-disable react/jsx-no-target-blank */
import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Badge, Button, Label } from "reactstrap";
import { BASE_URL } from "../../../config";
import {
  deepClone,
  formatCurrencyValue,
  formatDateHideYearIfSame,
  openUrlOnNewTab,
  getLoggedInUserId,
  errorHandler,
} from "../../../helper-methods";
import { getAllAgentBillings } from "../../../http/http-calls";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { billingHeaderKeys } from "../../../config/agentConfig";

class BillingAgent extends React.Component {
  state = {
    subscription: null,
    billingList: [],
    billingTotalCount: 0,
    billingHeaderKeys: billingHeaderKeys,
    billingCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    loading: {
      showTableLoading: false,
    },
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount = () => {
    this._getAllAgentBillings();
  };

  _getAllAgentBillings = () => {
    this._manageLoading("showTableLoading", true);

    const { tableConfig } = deepClone(this.state);

    const payload = {
      ...tableConfig,
    };

    getAllAgentBillings(payload)
      .then((res) => {
        this.setState(
          {
            subscription: res?.subscription || {},
            billingList: res?.billings || [],
            billingTotalCount: res?.totalCount || 0,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._getAllAgentBillings();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    console.log("sortName, sortOrder :", sortName, sortOrder);

    this._paginate();
  };

  _dataFormat = (cell, row, header) => {
    console.log("2345 ", row);
    switch (header) {
      case "createdAt": {
        return cell ? formatDateHideYearIfSame(cell) : "N/A";
      }
      case "currentSubscription": {
        return cell ? (
          <>
            <div style={{ width: 300 }}>
              {row.isNotaryPromo && "Promo"} Subscription from{" "}
              {(cell.start && formatDateHideYearIfSame(cell.start)) || "N/A"} to{" "}
              {(cell.end && formatDateHideYearIfSame(cell.end)) || "N/A"}
            </div>
            {row?.isUpcoming ? (
              <Badge color="primary" id="prefered">
                Upcoming plan
              </Badge>
            ) : null}
          </>
        ) : (
          "N/A"
        );
      }
      case "totalBillingAmount": {
        return cell || cell === 0 ? formatCurrencyValue(cell) : "N/A";
      }
      case "plan": {
        return row?.currentSubscription?.plan
          ? row?.currentSubscription?.plan
          : "N/A";
      }
      case "downloadAction": {
        return !cell ? (
          <div>
            <Button
              color="link"
              outline
              onClick={() => this._downloadData(row)}
            >
              Download
              <img
                src={require("../../../assets/img/download_blue.png")}
                alt="download"
              />
            </Button>
          </div>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _goToSubscriptionPage = () => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/subscription`);
  };

  _downloadData = (data) => {
    const apiUrl = `${BASE_URL}/agent/download/subscription-invoice?userId=${getLoggedInUserId()}&billingId=${
      data?._id
    }`;
    openUrlOnNewTab(apiUrl);
  };

  _cardHeaderFormat = (cell, row, header) => {
    console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row.logo
                      ? row.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    console.log("row: ", row);
    console.log("row: ", row?.plan);
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Billing Date: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.createdAt
                ? formatDateHideYearIfSame(row?.createdAt)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Amount: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.totalBillingAmount || row?.totalBillingAmount === 0
                ? formatCurrencyValue(row?.totalBillingAmount)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Plan: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.currentSubscription?.plan
                ? row?.currentSubscription?.plan
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Description: </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.currentSubscription ? (
                <>
                  <div style={{ width: 300 }}>
                    {row?.isNotaryPromo && "Promo"} Subscription from{" "}
                    {(row?.currentSubscription?.start &&
                      formatDateHideYearIfSame(
                        row?.currentSubscription?.start
                      )) ||
                      "N/A"}{" "}
                    to{" "}
                    {(row?.currentSubscription?.end &&
                      formatDateHideYearIfSame(
                        row?.currentSubscription?.end
                      )) ||
                      "N/A"}
                  </div>
                  {row?.isUpcoming ? (
                    <Badge color="primary" id="prefered">
                      Upcoming plan
                    </Badge>
                  ) : null}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap text-center">
            <Button
              color="link"
              outline
              onClick={() => this._downloadData(row)}
            >
              Download
              <a href={row?.invoicePdf} target="_blank">
                <img
                  src={require("../../../assets/img/download_blue.png")}
                  alt="download"
                />
              </a>
            </Button>
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      subscription,
      billingList,
      billingTotalCount,
      billingHeaderKeys,
      tableConfig,
      loading,
      // billingCardHeaderKeys,
    } = this.state;
    const { isForMobile } = this.props;
    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>My Billing</h2>

          <div className="rightSide">
            {subscription && !subscription.isExpired ? (
              <>
                <div>
                  Next Billing On:{" "}
                  <span style={{ fontWeight: 600 }}>
                    {(subscription.end &&
                      formatDateHideYearIfSame(subscription.end)) ||
                      "N/A"}
                  </span>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {subscription && !subscription.isExpired ? (
          <div className="d-none d-lg-block mb-4">
            Next Billing On:{" "}
            <span style={{ fontWeight: 600 }}>
              {(subscription.end &&
                formatDateHideYearIfSame(subscription.end)) ||
                "N/A"}
            </span>
          </div>
        ) : null}
        {isForMobile ? (
          <Suspense fallback={<></>}>
            <div className="hideDesktop">
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={billingList}
                dataFormat={this._dataFormat}
                totalCount={billingTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                rowSelection={false}
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={this._cardHeaderFormat}
                cardDataFormat={this._cardDataFormat}
              />
            </div>
          </Suspense>
        ) : (
          <Suspense fallback={<></>}>
            <div className="hideMobile">
              {billingHeaderKeys && billingHeaderKeys.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={billingList}
                  headerKeys={billingHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={billingTotalCount}
                  rowSelection={false}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) =>
                    this._onSortChange(sortName, sortOrder)
                  }
                  showTableLoading={loading.showTableLoading}
                />
              )}
            </div>
          </Suspense>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(ScreenWidthHOC(BillingAgent));
