import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { customDateRangeConfig } from "../../../config";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { capitalize } from "@material-ui/core";
import { useSelector } from "react-redux";
import SearchableDropdown from "./searchableDropdown";

const AgentCompanySummariesFilter = ({
  filters,
  _setDateRangeValue,
  _filterOnChange,
  filterDropdownValues,
  getAllListClientsforAgent,
  // clients,
}) => {
  const userData = useSelector((state) => state?.userData);

  return (
    <>
      <div className="filterWrapper">
        <FormGroup>
          <Label>Date Added</Label>
          <div className="custom-select-wrapper">
            <Input
              type="select"
              value={filters.customDateRangeValue}
              name="customDateRangeValue"
              onChange={(e) => _setDateRangeValue(e.target.value)}
            >
              {customDateRangeConfig.map((obj) => (
                <option key={obj.value} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </Input>
          </div>
        </FormGroup>
        <FormGroup className="dateRange">
          <Label>Date Range</Label>
          <DateRangePicker
            isOpen={
              !filters.dateRangeValue &&
              filters.customDateRangeValue === "customDateRange"
                ? true
                : false
            }
            className="dateRange"
            format="MM-dd-y"
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            onChange={(dateRangeValue) =>
              _filterOnChange("dateRangeValue", dateRangeValue)
            }
            value={filters.dateRangeValue}
          />
        </FormGroup>
        <FormGroup>
          <Label>Client</Label>
          <div className="custom-select-wrapper">
            <SearchableDropdown
              fetchData={(search) => getAllListClientsforAgent(search)}
              onSelectChange={(value) => _filterOnChange("client", value)}
              options={filterDropdownValues.clients}
            />
          </div>
        </FormGroup>

        {userData?.type === "signingcompany" && (
          <FormGroup style={{ minWidth: "150px" }}>
            <Label>Client Teams</Label>
            <div
              className={`custom-select-wrapper ${
                filters?.client === "" ? "disabled" : ""
              }`}
            >
              <Input
                type="select"
                placeholder=" "
                value={filters?.clientTeam}
                onChange={(event) =>
                  _filterOnChange("clientTeam", event.target.value)
                }
                disabled={filters?.client === ""}
                name="clientTeamsId"
              >
                <option value="">All Teams</option>
                {filterDropdownValues?.clientTeamsList?.map((team) => (
                  <option key={team?._id} value={team?._id}>
                    {team?.teamName ? capitalize(team?.teamName) : ""}
                  </option>
                ))}
              </Input>
            </div>
          </FormGroup>
        )}
      </div>
    </>
  );
};

export default AgentCompanySummariesFilter;
