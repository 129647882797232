import React, { Suspense, lazy } from "react";
import { Button, Label } from "reactstrap";
import {
  capitalize,
  formatCurrencyValue,
  formatDateOnlyAsPerTimeZone,
} from "../../../helper-methods";
const SignerPopover = lazy(() => import("./common/popover"));

const AgentNotarialLogCardDataFormat = ({
  row,
  _clientName,
  _onToggleAddNotarialModalAgent,
  _deleteNotarialLog,
  loading,
}) => {
  return (
    <>
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Signer </Label>
            <div className="text-primary " style={{ fontWeight: 600 }}>
              {row?.isManuallyCreatedByAgent
                ? (row?._borrower &&
                    row?._borrower.length > 0 &&
                    capitalize(row?._borrower[0]?.name?.full)) ||
                  "N/A"
                : (row?._closing?._borrower &&
                    capitalize(row?._closing?._borrower[0]?.name?.full)) ||
                  "N/A"}{" "}
              {row?.isManuallyCreatedByAgent
                ? row?._borrower &&
                  row?._borrower?.length > 1 && (
                    <>
                      <Suspense fallback={<></>}>
                        <SignerPopover
                          data={row?._borrower}
                          targetId={row?._id}
                          displayType="onlySignerNames"
                        />
                      </Suspense>
                    </>
                  )
                : row?._closing?._borrower &&
                  row?._closing?._borrower?.length > 1 && (
                    <>
                      <Suspense fallback={<></>}>
                        <SignerPopover
                          data={row?._closing?._borrower}
                          targetId={row?._id}
                          displayType="onlySignerNames"
                        />
                      </Suspense>
                    </>
                  )}
            </div>
          </div>
          <div className="dataWrap">
            <Label>File Number </Label>
            <div style={{ fontWeight: 600 }}>
              {row && row?.isManuallyCreatedByAgent ? (
                row?.purpose
              ) : (
                <>{row?._closing?.fileNumber || "N/A"}</>
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Client </Label>
            <div style={{ fontWeight: 600 }}>{_clientName(row)}</div>
          </div>
          <div className="dataWrap">
            <Label>Date </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.closingAppointmentDate
                ? formatDateOnlyAsPerTimeZone(
                    row?.closingAppointmentDate,
                    row?._closing?.closingAddress?.timeZone
                  )
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>NOTARIAL ACTS </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.notarialActs || row?.notarialActs === 0
                ? row?.notarialActs
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>AMOUNT PER ACT </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.amountPerAct || row?.amountPerAct === 0
                ? formatCurrencyValue(row?.amountPerAct)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>TOTAL POTENTIAL </Label>
            <div style={{ fontWeight: 600 }}>
              {row
                ? row?.isManuallyCreatedByAgent
                  ? row?.agentFee
                    ? row?.notarialFees
                      ? row?.notarialFees > row?.agentFee
                        ? formatCurrencyValue(row?.notarialFees)
                        : formatCurrencyValue(row?.agentFee)
                      : row?.agentFee > row?.plTotal
                      ? formatCurrencyValue(row?.agentFee)
                      : formatCurrencyValue(row?.plTotal)
                    : formatCurrencyValue(row?.plTotal)
                  : row?._closing?.agentFee > row?.plTotal
                  ? formatCurrencyValue(row?._closing?.agentFee)
                  : formatCurrencyValue(row?.plTotal)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>
              TOTAL
              <span style={{ fontSize: 10 }}>
                (NOT TO EXCEED ORDER TOTAL)
              </span>{" "}
            </Label>
            <div style={{ fontWeight: 600 }}>
              {row
                ? row?.isManuallyCreatedByAgent
                  ? formatCurrencyValue(row?.plTotal)
                  : row?._closing?.agentFee > row?.plTotal
                  ? formatCurrencyValue(row?.plTotal)
                  : formatCurrencyValue(row?._closing?.agentFee)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <Label>Action</Label>
          <div style={{ fontWeight: 600 }}>
            {row?.isManuallyCreatedByAgent ? (
              <div style={{ width: 60 }}>
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => _onToggleAddNotarialModalAgent(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>

                <Button
                  color="link"
                  className="remove"
                  onClick={() => _deleteNotarialLog(row.id)}
                  disabled={loading.statusChangeLoading ? true : false}
                >
                  <img
                    src={require("../../../assets/img/deleteIcon.svg").default}
                    alt="delete"
                  />
                </Button>
              </div>
            ) : (
              !row?.isManuallyCreatedByAgent &&
              row?._closing?.isCreatedForAgent && (
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => _onToggleAddNotarialModalAgent(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>
              )
            )}
            {!row?.isManuallyCreatedByAgent &&
              !row?._closing?.isCreatedForAgent && <span>-</span>}
          </div>
        </li>
      </ul>
    </>
  );
};

export default AgentNotarialLogCardDataFormat;
