import { memo, useCallback, useMemo, useState } from "react";
import {
  PopoverHeader,
  PopoverBody,
  Table,
  Label,
  Popover,
  Badge,
} from "reactstrap";
import {
  capitalize,
  formatCurrencyValue,
  formatDateAndTime,
} from "../../../../helper-methods";

const usePopover = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const togglePopover = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return [isOpen, togglePopover];
};

const SignerPopover = (props) => {
  const { data, targetId, screenType, displayType } = props; // displayType: signifies what data to show, or more specifically which set of popover to render on screen

  // Use the custom hook to manage popover state
  const [popoverOpenList, togglePopoverList] = usePopover(false);
  const [popoverOpenTable, togglePopoverTable] = usePopover(false);

  // console.log("data[0]?.name?.full >>", displayType);

  const renderPopoverContent = useMemo(() => {
    switch (displayType) {
      case "signerDetails": {
        return (
          <>
            <span
              id={`borrower_table_agent_dashboard_${targetId}`}
              onClick={
                screenType === "mobile" ? togglePopoverList : togglePopoverTable
              }
              className="signerName"
            >
              {data[0]?.name?.full ? data[0]?.name?.full : "N/A"}
              {data?.length > 1 ? `+${data?.length - 1}` : null}
            </span>

            {data?.length > 0 ? (
              screenType === "mobile" ? (
                <Popover
                  trigger="legacy"
                  placement="bottom"
                  target={`borrower_table_agent_dashboard_${targetId}`}
                  className="signerPopover"
                  toggle={togglePopoverList}
                  isOpen={popoverOpenList}
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {data?.map((each) => (
                      <ul key={each?._id} className="dataList">
                        <li>
                          <Label>Name</Label>
                          <div>{each?.name?.full || "N/A"}</div>
                        </li>
                        <li>
                          <Label>Email</Label>
                          <div>{each?.email || "N/A"}</div>
                        </li>
                        <li>
                          <Label>Phone</Label>
                          <div>
                            {each?.phone?.home || each?.phone?.work || "N/A"}
                          </div>
                        </li>
                        <li>
                          <Label>Language</Label>
                          <div>{each?.language || "N/A"}</div>
                        </li>
                        <li>
                          <Label>Time Zone</Label>
                          {each?.timeZone || "N/A"}
                        </li>
                      </ul>
                    ))}
                  </PopoverBody>
                </Popover>
              ) : screenType === "table" ? (
                <>
                  <Popover
                    trigger="legacy"
                    placement="bottom"
                    target={`borrower_table_agent_dashboard_${targetId}`}
                    className="signerPopover"
                    toggle={togglePopoverTable}
                    isOpen={popoverOpenTable}
                  >
                    <PopoverHeader>Signers</PopoverHeader>
                    <PopoverBody>
                      <Table className="responsive-sm">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Language</th>
                            <th>Time Zone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((each) => (
                            <tr key={each._id}>
                              <td>{each?.name?.full || "N/A"}</td>
                              <td>{each?.email || "N/A"}</td>
                              <td>
                                {each?.phone?.home ||
                                  each?.phone?.work ||
                                  "N/A"}
                              </td>
                              <td>{each?.language || "N/A"}</td>
                              <td>{each?.timeZone || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </PopoverBody>
                  </Popover>
                </>
              ) : null
            ) : (
              "null"
            )}
          </>
        );
      }
      case "onlySignerNames": {
        return (
          <>
            {data && data?.length > 1 && (
              <>
                <span
                  id={`PopoverLegacy${targetId}`}
                  className="themeColor cursorPointer"
                  style={{ fontWeight: "600" }}
                >
                  +{data?.length - 1}
                </span>
                <Popover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverLegacy${targetId}`}
                  className="signerPopover"
                  toggle={togglePopoverList}
                  isOpen={popoverOpenList}
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {data?.map((item, index) => (
                      <p key={index}>{capitalize(item?.name?.full)}</p>
                    ))}
                  </PopoverBody>
                </Popover>
              </>
            )}
          </>
        );
      }
      case "onlySignerPhones": {
        return (
          <>
            {data && data?.length > 1 && (
              <>
                <span
                  id={`PopoverPhone${targetId}`}
                  className="themeColor cursorPointer"
                  style={{ fontWeight: "600" }}
                >
                  +{data?.length - 1}
                </span>
                <Popover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverPhone${targetId}`}
                  className="signerPopover"
                  toggle={togglePopoverList}
                  isOpen={popoverOpenList}
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {data?.map((item, index) => (
                      <>
                        <p key={index}>
                          alternate: {capitalize(item?.phone?.alternate)}
                        </p>
                        <p key={index}>work: {capitalize(item?.phone?.work)}</p>
                      </>
                    ))}
                  </PopoverBody>
                </Popover>
              </>
            )}
          </>
        );
      }
      case "templates": {
        return (
          <>
            <Popover
              trigger="legacy"
              placement="bottom"
              target={`PopoverLegacy_id_${targetId}`}
              className="signerPopover"
              toggle={togglePopoverList}
              isOpen={popoverOpenList}
            >
              <PopoverHeader>Receiver's</PopoverHeader>
              <PopoverBody
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                <Table className="responsive-sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length ? (
                      data?.map((each) => (
                        <tr>
                          <td>{each?.nameOrCompanyname}</td>
                          <td>{formatDateAndTime(each?.createdAt)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>No data to display</tr>
                    )}
                  </tbody>
                </Table>
              </PopoverBody>
            </Popover>
          </>
        );
      }
      case "tags": {
        return (
          <Popover
            trigger="legacy"
            placement="bottom"
            target={`agent_client_tags${targetId}`}
            className="signerPopover"
            toggle={togglePopoverList}
            isOpen={popoverOpenList}
            style={{ maxWidth: 250 }}
          >
            <PopoverHeader>Tags</PopoverHeader>
            <PopoverBody>
              {data?.map((each) => (
                <Badge key={each} color="primary" pill>
                  {each}
                </Badge>
              ))}
            </PopoverBody>
          </Popover>
        );
      }
      case "agentShare": {
        return (
          <Popover
            trigger="legacy"
            placement="bottom"
            target={`agentSharePopOver_${targetId}`}
            className="signerPopover"
            toggle={togglePopoverList}
            isOpen={popoverOpenList}
          >
            <PopoverBody>
              <Table className="responsive-sm">
                <thead>
                  <tr>
                    <th>Agent Fee</th>
                    <th>Processing Fee</th>
                    <th>My Share</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {data?.agentFee
                        ? formatCurrencyValue(data?.agentFee)
                        : formatCurrencyValue(0)}
                    </td>
                    <td>
                      {data?.agentFee && data?.agentFeeSharingAmount
                        ? formatCurrencyValue(
                            data?.agentFee - data?.agentFeeSharingAmount
                          )
                        : formatCurrencyValue(0)}
                    </td>
                    <td>
                      {data?.agentFeeSharingAmount
                        ? formatCurrencyValue(data?.agentFeeSharingAmount)
                        : formatCurrencyValue(0)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </PopoverBody>
          </Popover>
        );
      }
      default:
        return null;
    }
  }, [
    data,
    displayType,
    screenType,
    targetId,
    popoverOpenList,
    popoverOpenTable,
  ]);
  return <>{renderPopoverContent}</>;
};

export default memo(SignerPopover);
