import React from "react";
import { Input, Button, FormGroup, Label } from "reactstrap";
// react component used to create a calendar with events on it

// dependency plugin for react-big-calendar
import moment from "moment";
import momenttz from "moment-timezone";
import { connect } from "react-redux";
import {
  companyClosingMasterSchedule,
  companyGetListTeamsOfClient,
  getAllFiltersData,
} from "../../../http/http-calls";
import {
  deepClone,
  showToast,
  checkPermission,
  getLoggedInUserId,
  structureQueryParams,
  openUrlOnNewTab,
  formatOnlyDateMoment,
  capitalize,
  refreshFunc,
  errorHandler,
} from "../../../helper-methods";
import GoogleCalendarSyncModal from "../components/google-calendar-sync-modal";
import { makeGetRequest } from "../../../http/http-service";
import { updateUserObj } from "../../../redux/actions/user-data";
import { agentClosingStatus, BASE_URL } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ReactBigCalendar from "../components/calendar/ReactBigCalendar";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import SvgIcons from "../components/SvgIcons";
import { datetime, RRule } from "rrule";

class ClosingMasterScheduleCompany extends React.Component {
  state = {
    events: [],
    eventsBackup: [],
    alert: null,
    filters: {
      agent: "",
      client: "",
      status: "",
      clientTeam: "",
    },

    calendarView: "week",
    // by default dateRange set according to calendarView - day
    dateRange: {
      // yesterday - end of day Date
      // startDate: moment().subtract(1, "week").endOf("week"),
      // startDate: moment().endOf("week"),
      // today - end of day Date
      // endDate: moment().endOf("week"),
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
    },
    isOpenGoogleCalendarSyncModal: false,
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    loading: {
      showDataLoading: false,
      isEmailLoading: false,
    },
    filterDropdownValues: {
      clients: [],
      agents: [],
      clientTeamsList: [],
    },
    isClassAdded: false,
    isGoogleCalendarSignInDone: false,
  };

  //When any event present in calender then click on this event go to details page
  _selectedEvent = (event) => {
    // console.log(event)
    if (event?.id) {
      this.props.history.push(`/signingcompany/closing-details/${event.id}`);
    } else if (event?.googleEvent?.htmlLink) {
      window.open(event.googleEvent.htmlLink, "_blank");
    }
    // alert(event.title);
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _eventColors = (event, start, end, isSelected) => {
    return {
      style: { backgroundColor: event.agent === "" ? "#EE8157" : "#6CD098" },
    };
  };

  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    filters[type] = value;

    if (type === "client") {
      filters["clientTeam"] = "";
      if (value?.length) {
        this._getCompanyListTeamsOfClient(value);
      } else {
        this.setState({ clientTeamsList: [] });
      }
    }

    this.setState({ filters }, () => {
      this._filterEventList();
      this._persistFilter();
    });
  };

  //For eventlist set in state
  _filterEventList = () => {
    let filterConditions = [];
    let { filters, eventsBackup, events } = deepClone(this.state);
    console.log("TCL: _filterInvitationList -> filters", filters);
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filterConditions.push(key);
      }
    });
    events = [];
    events = eventsBackup;
    console.log("TCL: _filterInvitationList -> invitationList", events);
    if (filterConditions.length) {
      if (filters.agent && filters.agent.length) {
        events = events.filter((each) => {
          console.log("TCL: _filterInvitationList -> each", each);
          return each?.agent?.id === filters.agent;
        });
      }
      if (filters.client && filters.client.length) {
        // console.log("LoanType checking", filters.loanType);
        events = events.filter((each) => {
          console.log("TCL: _filterInvitationList -> each", each);
          // return each?.client?.name?.full === filters.client;
          return each?.client?.id === filters.client;
        });
      }

      if (filters.status && filters.status.length) {
        console.log("status checking", filters.status.toLowerCase());
        events = events.filter((each) => {
          console.log("each,filters :", each);
          return each?.status?.toLowerCase() === filters.status.toLowerCase();
        });
      }

      if (filters?.clientTeam && filters?.clientTeam?.length) {
        events = events.filter((each) => {
          return each?.clientAssistantTeam === filters.clientTeam;
        });
      }

      console.log("invitationList :", events);
      this.setState({ events });
    } else {
      this.setState({ events: eventsBackup });
    }
  };

  //For download all data in schedule
  _downloadData = (action = "download") => {
    const { events, filters, calendarView } = deepClone(this.state);

    if (!events.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }
    let date = this._dateRange(calendarView);

    const payload = {
      userId: getLoggedInUserId(),
      action,
      startDate: formatOnlyDateMoment(date[0]),
      endDate: formatOnlyDateMoment(date[1]),
    };

    if (filters.status) payload["status"] = filters.status;
    if (filters.client) payload["clientId"] = filters.client;
    if (filters.agent) payload["agentId"] = filters.agent;

    const queryParams = structureQueryParams(payload);

    const apiUrl = `${BASE_URL}/signingcompany/download/closing-master-schedule${queryParams}`;

    if (action === "download") {
      openUrlOnNewTab(apiUrl);
    } else if (action === "email") {
      this._manageLoading("isEmailLoading", true);
      makeGetRequest(apiUrl, true)
        .then((res) => {
          this._manageLoading("isEmailLoading", false);
          showToast("Email sent successfully.", "success");
        })
        .catch((error) => {
          console.log("error>>", error);
          this._manageLoading("isEmailLoading", false);
          errorHandler(error);
        });
    }
  };

  //For filter reset
  _resetFilter = () => {
    this.setState(
      {
        filters: {
          titleCompany: "",
          agent: "",
          location: "",
          type: "",
          status: "",
          client: "",
          clientTeam: "",
        },
      },
      () => {
        this._filterEventList();
        refreshFunc("company-closing-master-schedule-responsive");
      }
    );
  };

  //Fetch team data when client filter changed
  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      const { filterDropdownValues } = deepClone(this.state);

      filterDropdownValues["clientTeamsList"] = res?.teams;

      this.setState({ filterDropdownValues });
    } catch (err) {
      errorHandler(err);
    }
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount() {
    if (checkPermission("masterSchedule", "canView")) {
      this._getAllFiltersData();
      this._companyClosingMasterSchedule();
      HeaderEventEmitter.subscribe("reset-filter", () => {
        this._resetFilter();
        refreshFunc("company-closing-master-schedule");
      });
      HeaderEventEmitter.subscribe("calendar-sync", () => {
        // this._toggleGoogleCalendarSyncModal(true);
        // refreshFunc("company-closing-master-schedule");
        this._companyClosingMasterSchedule();
      });
    } else {
      this.props.history.goBack();
    }
  }

  //This function used for agent and client data fetch for filter dropdown
  _getAllFiltersData = () => {
    getAllFiltersData()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        if (res?.clients) {
          filterDropdownValues["clients"] =
            res?.clients?.map((item) => ({
              label: item.companyName,
              value: item._id,
            })) || [];
        }
        filterDropdownValues["agents"] = res?.agents || [];
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _dateRange = () => {
    const { dateRange, calendarView, navigationDate } = deepClone(this.state);
    switch (calendarView) {
      case "month": {
        let middate = new Date(
          (dateRange.startDate.getTime() + dateRange.endDate.getTime()) / 2
        );
        let endDate = moment(middate).endOf("month")._d;
        let startDate = moment(middate).startOf("month")._d;
        //  console.log(startDate,endDate)
        return [startDate, endDate];
      }
      case "day": {
        let givenDate = moment(dateRange?.endDate?._d);
        // console.log(moment().isSame(givenDate, "day"));
        if (dateRange?.endDate?._d) {
          if (moment().isSame(givenDate, "day")) {
            const currentDate = moment().endOf("day")._d;
            const startOfDate = moment().startOf("day")._d;

            console.log(startOfDate, currentDate);
            return [startOfDate, currentDate];
          } else {
            let startDate = moment(dateRange.startDate)
              .add(1, "days")
              .startOf("day")._d;
            let endDate = moment(dateRange.endDate).endOf("day")._d;

            return [startDate, endDate];
          }
        } else {
          let startDate = moment(navigationDate)
            .startOf("month")
            .startOf("week")._i;
          let endDate = moment(navigationDate).endOf("month").endOf("week")._i;

          this.setState({
            dateRange: {
              startDate,
              endDate,
            },
          });
          return [startDate, endDate];
        }
      }
      case "week": {
        const startDate = moment(dateRange.startDate).add(1, "days");
        return [startDate, dateRange.endDate];
      }
      default: {
        return dateRange;
      }
    }
  };

  //This function need when date range changed and when click on sync button in connect google calender modal
  _companyClosingMasterSchedule = async () => {
    this._manageLoading("showDataLoading", true);

    try {
      const { calendarView } = deepClone(this.state);
      const dateRange = this._dateRange(calendarView);
      const payload = {
        startDate: formatOnlyDateMoment(dateRange[0]),
        endDate: formatOnlyDateMoment(dateRange[1]),
      };

      const res = await companyClosingMasterSchedule(payload);

      let obj = [];

      if (res?.closings?.length) {
        obj = res.closings.map((item) => {
          const {
            appointmentDate,
            closingAddress,
            _client,
            _agent,
            _borrower,
            closingNumber,
            status,
            id,
          } = item;
          const startDateString = momenttz(appointmentDate)
            .tz(closingAddress?.timeZone || "America/New_York")
            .format("MM/DD/YYYY hh:mm:ss a");
          const endDateString = momenttz(appointmentDate)
            .tz(closingAddress?.timeZone || "America/New_York")
            .add(30, "minutes")
            .format("MM/DD/YYYY hh:mm:ss a");
          const startDate = new Date(startDateString);
          const endDate = new Date(endDateString);
          // const title = _borrower?.[0]?.name?.last?.trim().length
          //   ? capitalize(_borrower[0].name.last.trim())
          //   : _borrower?.[0]?.name?.full?.trim().length
          //   ? capitalize(_borrower[0].name.full.trim())
          //   : closingNumber || "N/A";
          const title = _borrower[0]?.name?.full?.trim()
            ? capitalize(_borrower[0]?.name?.full?.trim())
            : capitalize(_borrower[0]?.name?.first?.trim()) ||
              closingNumber ||
              "N/A";
          const clientAssistantTeam = item?._clientAssistantTeam || "";

          return {
            id,
            start: startDate,
            end: endDate,
            client: _client,
            agent: _agent || "",
            status,
            title,
            allDay: false,
            clientAssistantTeam,
          };
        });
      }

      if (res?.events?.length) {
        const formattedGoogleEvents = res.events.map((googleEvent) => {
          const { recurrence } = googleEvent;
          if (recurrence && recurrence.length) {
            console.log("googleEvent >>", googleEvent);
            const rruleString = recurrence[0]?.trim();
            const startDate = moment.utc(googleEvent.start);

            // const endState = moment.utc(googleEvent.end);
            const endState = moment.utc(this.state.dateRange.endDate);

            // const rule = RRule.fromString(`${rruleString}`);
            console.log("endState >>", endState);

            var options = RRule.parseString(rruleString);
            options.dtstart = datetime(
              startDate.year(),
              startDate.month() + 1,
              startDate.date(),
              startDate.hour(),
              startDate.minute(),
              startDate.second()
            );
            options.until = datetime(
              endState.year(),
              endState.month() + 1,
              endState.date(),
              endState.hour(),
              endState.minute(),
              endState.second()
            );

            console.log("options >>", options);

            const rule = new RRule(options);

            const filterDates = rule
              .all()
              .filter((date) => {
                if (this.state.calendarView === "day") {
                  return moment(date).isSame(
                    moment(this.state.dateRange.endDate),
                    "day"
                  );
                }
                return moment(date).isBetween(
                  this.state.dateRange.startDate,
                  this.state.dateRange.endDate,
                  "day",
                  "[]"
                );
              })
              .map((data) => ({
                googleEvent,
                title: googleEvent.summary || googleEvent.eventId,
                start: new Date(data),
                end: moment(data)
                  .set({
                    hour: moment(googleEvent.end).hour(),
                    minute: moment(googleEvent.end).minute(),
                    second: moment(googleEvent.end).second(),
                  })
                  .toDate(),
                allDay: false,
              }));

            console.log("filterDates >>", filterDates);

            return filterDates;
          } else {
            return {
              googleEvent,
              title: googleEvent.summary || googleEvent.eventId,
              start: new Date(googleEvent.start),
              end: new Date(googleEvent.end),
              allDay: false,
            };
          }
        });

        obj.push(...formattedGoogleEvents.flat());
      }

      const { filterDropdownValues } = this.state;

      if (res?.closings && res?.closings.length) {
        filterDropdownValues["clients"] = res?.closings
          .map((closing) => ({
            label: closing?._client?.companyName,
            value: closing?._client?.id,
          }))
          .filter(
            (item, index, self) =>
              self.findIndex((obj) => obj.value === item.value) === index
          );
      }

      this.props.updateUserObj({
        isGoogleCalendarSignInDone: res?.isGoogleCalendarSignInDone,
      });

      this.setState(
        {
          events: obj,
          eventsBackup: obj,
          filterDropdownValues,
          isGoogleCalendarSignInDone: res?.isGoogleCalendarSignInDone || false,
        },
        () => {
          this._filterEventList();
        }
      );

      this._manageLoading("showDataLoading", false);
    } catch (error) {
      console.error("Error:", error);
      errorHandler(error);
      this._manageLoading("showDataLoading", false);
    }
  };

  //For change Calender view
  _onViewChange = (calendarView) => {
    this.setState({ calendarView });
  };

  _onRangeChange = (newDateRange, view) => {
    const { calendarView } = deepClone(this.state);
    //  console.log(newDateRange)
    let startDate, endDate;

    switch (view || calendarView) {
      case "day": {
        startDate = moment(newDateRange[0]).subtract(1, "days").endOf("day");
        endDate = moment(newDateRange[0]).endOf("day");
        break;
      }
      case "week": {
        startDate = moment(newDateRange[0]).subtract(1, "days").endOf("day");
        endDate = moment(newDateRange[6]).endOf("day");
        break;
      }
      case "month": {
        startDate = newDateRange.start;
        endDate = newDateRange.end;
        break;
      }
      case "agenda": {
        startDate = newDateRange.start;
        endDate = newDateRange.end;
        break;
      }
      default: {
        // yesterday - end of day Date
        startDate = moment().subtract(1, "days").endOf("day");
        // today - end of day Date
        endDate = moment().endOf("day");
        this._onViewChange("day");
        showToast("Something went wrong. Try again after sometime.", "error");
      }
    }

    this.setState(
      {
        dateRange: {
          startDate,
          endDate,
        },
      },
      () => {
        this._companyClosingMasterSchedule();
      }
    );
  };

  _toggleGoogleCalendarSyncModal = (isOpenGoogleCalendarSyncModal = false) => {
    this.setState({ isOpenGoogleCalendarSyncModal });
  };

  // Filter data set in local storage
  _persistFilter = () => {
    const { filters } = this.state;
    if (filters && (filters.agent || filters.client || filters.status)) {
      localStorage.companyschedule = JSON.stringify(filters);
    } else {
      delete localStorage.companyschedule;
    }
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  render() {
    const {
      filters,
      calendarView,
      loading,
      filterDropdownValues,
      isOpenGoogleCalendarSyncModal,
      events,
      isClassAdded,
      upgradeAccountModal,
      isGoogleCalendarSignInDone,
    } = this.state;

    console.log("userData >>", this.props?.userData?.user?.isAssistant);

    return (
      <>
        <div className="content">
          {this.state.alert}

          <div className="responsiveTitle">
            <h2>Master Schedule</h2>

            <div className="rightSide">
              <Button
                id="company-closing-master-schedule-responsive"
                color="link"
                onClick={this._resetFilter}
              >
                <SvgIcons type="refresh" />
              </Button>
              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <SvgIcons type={"filterIcon"} />
              </Button>
              {/* Hide for assistant */}
              {!this.props?.userData?.user?.isAssistant ? (
                <GoogleCalendarSyncModal
                  fetchData={this._companyClosingMasterSchedule}
                />
              ) : null}
            </div>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <SvgIcons type="filterIcon" />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup>
                <Label>Agent</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="agent"
                    value={filters.agent}
                    onChange={(e) =>
                      this._filterOnChange("agent", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterDropdownValues.agents.map((agents) => (
                      <option key={agents.id} value={agents.id}>
                        {(agents.name &&
                          agents.name.full &&
                          capitalize(agents.name.full)) ||
                          "N/A Company"}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Client</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="client"
                    value={filters.client}
                    onChange={(e) =>
                      this._filterOnChange("client", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterDropdownValues?.clients?.length
                      ? filterDropdownValues.clients.map((client, index) =>
                          client ? (
                            <option key={index} value={client.value}>
                              {capitalize(client.label)}
                            </option>
                          ) : null
                        )
                      : null}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup style={{ minWidth: "150px" }}>
                <Label>Client Teams</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    placeholder=" "
                    value={filters?.clientTeam}
                    onChange={(event) =>
                      this._filterOnChange("clientTeam", event.target.value)
                    }
                    disabled={filters?.client === ""}
                    name="clientTeamsId"
                  >
                    <option value="">All Teams</option>
                    {filterDropdownValues?.clientTeamsList?.map((team) => (
                      <option key={team?._id} value={team?._id}>
                        {team?.teamName ? capitalize(team?.teamName) : ""}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="status"
                    value={filters.status}
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {agentClosingStatus.map((obj) => (
                      <option key={obj.value} value={obj.value}>
                        {obj.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <Button
                color="link"
                outline
                className="downloadButton ml-auto"
                onClick={() => this._downloadData()}
              >
                Download
                <SvgIcons type={"download"} />
              </Button>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="downloadButtonPos">
            <Button color="link" outline onClick={() => this._downloadData()}>
              Download
              <SvgIcons type={"download"} />
            </Button>
          </div>

          <div className="calendarWrap">
            <ReactBigCalendar
              events={events}
              calendarView={calendarView}
              loading={loading.showDataLoading}
              onNavigate={(date) => this.setState({ navigationDate: date })}
              onView={(view) => this._onViewChange(view)}
              onRangeChange={(dateRange, view) =>
                this._onRangeChange(dateRange, view)
              }
              eventPropGetter={this._eventColors}
              onSelectEvent={(event) => this._selectedEvent(event)}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (payload) => dispatch(updateUserObj(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClosingMasterScheduleCompany);
