import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";
import useScreenWidth from "../../HelpModule/useScreenWidth";

const SkeletonLoadingCompanySettings = ({ borderRadius = 8, count = 1 }) => {
  const { isForMobile } = useScreenWidth();

  const renderSkeletonGroup = (skeletons) => (
    <div className="d-flex mb-3">
      {skeletons.map(({ width, height, marginRight }, index) => (
        <div key={index} style={{ width, marginRight }}>
          <Skeleton height={height} width="100%" />
        </div>
      ))}
    </div>
  );

  return (
    <Row>
      {Array.from({ length: count }).map((_, index) => (
        <Col md={12} key={`SkeletonSettingsBox_${index}`}>
          <div
            className="settings-skeleton-box p-3 mb-3"
            style={{
              borderRadius,
              border: "1px solid #ddd",
              padding: "20px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Skeleton
              height={20}
              width={isForMobile ? "60%" : "40%"}
              className="mb-2"
            />
            <Skeleton height={20} width="25%" className="mb-4" />
            <Skeleton
              height={20}
              width={isForMobile ? "60%" : "30%"}
              className="mb-2"
            />

            {!isForMobile ? (
              <>
                {renderSkeletonGroup([
                  { width: "15%", height: 20, marginRight: "60px" },
                  { width: "15%", height: 20, marginRight: "60px" },
                  { width: "15%", height: 20, marginRight: "0" },
                ])}

                {renderSkeletonGroup([
                  { width: "35%", height: 20, marginRight: "100px" },
                  { width: "35%", height: 20, marginRight: "0" },
                ])}

                {renderSkeletonGroup([
                  { width: "15%", height: 20, marginRight: "260px" },
                  { width: "15%", height: 20, marginRight: "0" },
                ])}

                {renderSkeletonGroup([
                  { width: "35%", height: 50, marginRight: "100px" },
                  { width: "35%", height: 50, marginRight: "0" },
                ])}
              </>
            ) : (
              <>
                {renderSkeletonGroup([
                  { width: "55%", height: 20, marginRight: "60px" },
                  { width: "55%", height: 20, marginRight: "60px" },
                ])}
                <div style={{ width: "35%" }} className="mb-4">
                  <Skeleton height={20} width="100%" />
                </div>

                <div style={{ width: "80%" }} className="mb-2">
                  <Skeleton height={20} width="100%" />
                </div>
                <div style={{ width: "30%" }} className="mb-2">
                  <Skeleton height={20} width="100%" />
                </div>
                <Skeleton height={50} width="95%" className="mb-4" />

                <div style={{ width: "80%" }} className="mb-2">
                  <Skeleton height={20} width="100%" />
                </div>
                <div style={{ width: "30%" }} className="mb-2">
                  <Skeleton height={20} width="100%" />
                </div>
                <Skeleton height={50} width="95%" className="mb-4" />
              </>
            )}

            <Skeleton
              height={20}
              width={isForMobile ? "70%" : "30%"}
              className="mb-2"
            />
            <Skeleton
              height={20}
              width={isForMobile ? "50%" : "20%"}
              className="mb-3"
            />

            <div
              className="d-flex justify-content-center align-items-center mb-3"
              style={{ height: "100%" }}
            >
              <div
                style={{
                  width: "45%",
                  marginLeft: isForMobile ? "50px" : "180px",
                }}
              >
                <Skeleton height={60} width={isForMobile ? "60%" : "30%"} />
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default SkeletonLoadingCompanySettings;
