import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonTable from "./SkeletonTable";
import CustomLoader from "../custom/CustomLoader";

import SkeletonCard from "./SkeletonCard";
import SkeletonLoadingClientSettings from "./SkeletonLoadingClientSettings";
import SkeletonLoadingCompanySettings from "./SkeletonLoadingCompanySeetings";
import SkeletonLoadingAgentSettings from "./SkeletonLoadingAgentSettings";
import SkeletonLoadingSubscription from "./SkeletonLoadingSubscription";

const SkeletonLoading = ({
  type,
  count = 20,
  rows = 20,
  col = 6,
  height = undefined,
  width = undefined,
  borderRadius = undefined,
  page,
}) => {
  switch (type) {
    case "card": {
      return (
        <SkeletonCard
          count={count}
          height={height}
          borderRadius={borderRadius}
          width={width}
          page={page}
        />
      );
    }

    case "table":
      return (
        <SkeletonTable rows={rows} col={col} height={height} width={width} />
      );

    case "companySettings":
      return (
        <SkeletonLoadingCompanySettings count={count} borderRadius={borderRadius} />
      );

      case "clientSettings":
      return (
        <SkeletonLoadingClientSettings count={count} borderRadius={borderRadius} />
      );

      case "agentSettings":
        return (
          <SkeletonLoadingAgentSettings count={count} borderRadius={borderRadius} />
        );

      case "SkeletonLoadingSubscription" :
        return(
          <SkeletonLoadingSubscription  count={count} borderRadius={borderRadius}/>
        )

    default:
      return (
        <div className="noContentFound text-center">
          <CustomLoader />
        </div>
      );
  }
};

export default SkeletonLoading;
