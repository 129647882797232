import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";
import useScreenWidth from "../../HelpModule/useScreenWidth";

const SkeletonLoadingClientSettings = ({ borderRadius = 8, count = 1 }) => {
  const { isForMobile } = useScreenWidth();

  const renderSkeletonGroup = (skeletons) => (
    <div className="d-flex mb-3">
      {skeletons.map(({ width, height, marginRight }, index) => (
        <div key={index} style={{ width, marginRight }}>
          <Skeleton height={height} width="100%" />
        </div>
      ))}
    </div>
  );

  return (
    <Row>
      {Array.from({ length: count }).map((_, index) => (
        <Col md={12} key={`SkeletonSettingsBox_${index}`}>
          <Skeleton
            height={isForMobile ? 90 : 60}
            width="100%"
            className="mb-2"
          />
          {!isForMobile ? (
            <div
              className="settings-skeleton-box p-3 mb-3"
              style={{
                borderRadius,
                border: "1px solid #ddd",
                padding: "20px",
                backgroundColor: "#f9f9f9",
              }}
            >
              {renderSkeletonGroup([
                { width: "60%", height: 120, marginRight: "40px" },
                { width: "60%", height: 120, marginRight: "0" },
              ])}
            </div>
          ) : (
            <>
              <Skeleton height={170} width="100%" className="mb-2" />
              <Skeleton height={170} width="100%" className="mb-2" />
            </>
          )}
          <Skeleton
            height={isForMobile ? 60 : 20}
            width="100%"
            className="mb-2"
          />
        </Col>
      ))}
    </Row>
  );
};

export default SkeletonLoadingClientSettings;
