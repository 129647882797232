import React from "react";
import { Button, Label } from "reactstrap";
import {
  capitalize,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateOnlyAsPerTimeZone,
  formatNumberInShort,
  openGoogleMapOnNewTab,
} from "../../../helper-methods";

const MileageLogReportingAgentCardDataFormat = ({ row }) => {
  return (
    <>
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Date </Label>
            <div>
              {row?.travelDate
                ? formatDateOnlyAsPerTimeZone(
                    row?.travelDate,
                    row?._closing?.closingAddress?.timeZone
                  )
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Total Miles</Label>
            <div>
              {row?.distance || row?.distance === 0
                ? Number(row?.distance) < 1
                  ? row?.distance
                  : formatNumberInShort(row?.distance)
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Deduction </Label>
            <div>
              {row?.amount || row?.amount === 0
                ? formatCurrencyValue(row?.amount)
                : "N/A"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Comments </Label>
            <div>{row?.comments ? capitalize(row?.comments) : "N/A"}</div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <div className="d-flex justify-content-between">
              <Label>Start Location: </Label>

              <Button
                color="link"
                className="fs-12"
                title={formatAddressInSingleText(row?.startAddress)}
                onClick={() => openGoogleMapOnNewTab(row?.startAddress)}
              >
                <img
                  src={require("../../../assets/img/map-marker.svg").default}
                  alt="map icon"
                  className="mr-1"
                />
                Map
              </Button>
            </div>

            {formatAddressInSingleText(row?.startAddress)}
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <div className="d-flex justify-content-between">
              <Label>Stop Location: </Label>
              {row?.stopAddress ? (
                <Button
                  color="link"
                  className="fs-12"
                  title={formatAddressInSingleText(row?.stopAddress)}
                  onClick={() => openGoogleMapOnNewTab(row?.stopAddress)}
                >
                  <img
                    src={require("../../../assets/img/map-marker.svg").default}
                    alt="map icon"
                    className="mr-1"
                  />
                  Map
                </Button>
              ) : null}
            </div>

            {row?.stopAddress ? (
              <>{formatAddressInSingleText(row?.stopAddress)}</>
            ) : (
              "N/A"
            )}
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <div className="d-flex justify-content-between">
              <Label>End Location </Label>
              {row?.endAddress ? (
                <Button
                  color="link"
                  className="fs-12"
                  title={formatAddressInSingleText(row?.endAddress)}
                  onClick={() => openGoogleMapOnNewTab(row?.endAddress)}
                >
                  <img
                    src={require("../../../assets/img/map-marker.svg").default}
                    alt="map icon"
                    className="mr-1"
                  />
                  Map
                </Button>
              ) : null}
            </div>
            {row?.endAddress ? (
              <>{formatAddressInSingleText(row?.endAddress)}</>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    </>
  );
};

export default MileageLogReportingAgentCardDataFormat;
