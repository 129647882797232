import React, { useState } from "react";
import { Button } from "reactstrap";
import {
  handleAuthClick,
  initializeClient,
} from "../../../helper-methods/googleCalendarService";
import {
  calenderGoogleAuth,
  userGoogleCalendarSync,
} from "../../../http/http-calls";
import { errorHandler, showToast } from "../../../helper-methods";
import { APP_URL } from "../../../config";
import myGif from "../../../assets/img/Google Calendar.gif";
import { updateUserObj } from "../../../redux/actions/user-data";
import { useDispatch } from "react-redux";

const CalenderAuthPage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState(false);

  const url = window.location.href;
  const urlObj = new URL(url);
  const redirctUrl = urlObj.searchParams.get("redirctUrl");
  const token = urlObj.searchParams.get("token");

  const dispatch = useDispatch();

  console.log("redirctUrl >>", redirctUrl);

  const _connectHandler = async () => {
    try {
      await initializeClient();
      const res = await handleAuthClick();
      if (res) {
        setLoading(true);
        const response = await calenderGoogleAuth({
          code: res,
          token: token,
          redirectUris: APP_URL,
          //for localhost test
          // redirectUris: "http://localhost:3000",
        });

        dispatch(
          updateUserObj({
            isGoogleCalendarSignInDone: response?.isGoogleCalendarSignInDone,
          })
        );

        // await _userGoogleCalendarSync();
        // props.history.push(`/${userData?.type}/closing-master-schedule`);
      }
    } catch (error) {
      errorHandler(error);
      setLoading(false);
      // props.history.push(`/${userData?.type}/closing-master-schedule`);
    }
    window.location.replace(redirctUrl);
  };

  return (
    <div className="fullPageContainer">
      <div className="resetWrapper">
        <img
          src="https://res.cloudinary.com/www-logic-square-com/image/upload/v1635332672/Jabra%20Fan%20Emailer/closewise-logo.png"
          alt="Closewise Logo"
          className="projectLogo"
        />

        <div className="resetForm">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0",
            }}
          >
            <img
              src={myGif}
              alt="Description of GIF"
              style={{ width: "200px", height: "auto" }}
            />
          </div>

          <p className="text-muted fs-12">
            Disclosure: Closewise's use and transfer of information received
            from Google APIs to any other app will adhere to
            <a
              rel="noreferrer"
              target="_blank"
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            >
              {" "}
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.”"
          </p>

          <div className="buttonContainer">
            <Button
              color="primary"
              className="authButton"
              onClick={() => _connectHandler()}
              disabled={loading}
            >
              <img
                src={require("../../../assets/img/Google-calandar.png")}
                alt="google calandar"
                height={18}
              />{" "}
              Connect google Calendar
            </Button>
          </div>

          <p style={{ fontWeight: "500", textAlign: "center" }}>
            <a href={redirctUrl}>Back to App</a>
          </p>
        </div>

        <div className="loginFooter">
          <span>© 2024 Closewise</span>
        </div>
      </div>
    </div>
  );
};

export default CalenderAuthPage;
