import React, { Suspense, lazy } from "react";
import {
  capitalize,
  checkPermission,
  // errorHandler,
  formatAddressInSingleText,
  formatDateAsPerTimeZOne,
  getDropdownColor,
  // getTimeZoneAbbr,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
} from "../../../helper-methods";
import { Button, Input } from "reactstrap";
import SvgIcons from "./SvgIcons";
import { DateTimeShowWithCellRow } from "./Date-time-show";
const SignerPopover = lazy(() => import("./common/popover"));

const ClosingDashboardDataFormat = ({
  header,
  row,
  cell,
  _onToggleSignerAvailabilityModal,
  _redirectToClosingDetails,
  _onToggleAssignJobModal,
  loading,
  _updateClosingDetailsByIdStatus,
}) => {
  switch (header) {
    case "appointmentDate": {
      return row ? (
        <div className="tableUserInfo">
          <div className="userContent">
            <Suspense fallback={<></>}>
              <SignerPopover
                data={row?._borrower}
                targetId={row._id}
                screenType="table"
                displayType="signerDetails"
              />
            </Suspense>

            {/* <span className="dateTimeFormat">
              {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)},{" "}
              <span>{getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}</span>
            </span> */}
            <DateTimeShowWithCellRow cell={cell} row={row} />

            <Button
              color={`${
                row?.appointmentStatus === "Confirmed" && !row.leftMessage
                  ? "success "
                  : row?.appointmentStatus === "Rescheduled" && !row.leftMessage
                  ? "danger "
                  : row.leftMessage
                  ? "warning"
                  : "danger "
              }`}
              className={`confirmButton `}
              onClick={() => _onToggleSignerAvailabilityModal(true, row)}
            >
              {row?.appointmentStatus === "Confirmed" ? "Confirmed" : "Confirm"}
            </Button>
          </div>
        </div>
      ) : (
        "N/A"
      );
    }

    case "docs": {
      return row ? (
        <div className="docStatus">
          {/* Last Uploaded by Company: $DateTime */}
          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.companyLastDocumentUploadedAt &&
              `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                row?.companyLastDocumentUploadedAt,
                row?.closingAddress?.timeZone
              )}`
            }
          >
            {row?.documents?.length && !row.documents[0].isPrivate ? (
              row.isDocumentCompleted ? (
                <span
                  className="fa fa-circle text-success"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : row?.isDocumentUploadedByCompanyOrClient ? (
                <span
                  className="fa fa-circle text-warning"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : (
                <span
                  className="fa fa-circle text-primary"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )
            ) : (
              <span
                className="fa fa-circle text-primary"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
            <span
              className="value"
              onClick={() =>
                row.isDocumentCompleted && _redirectToClosingDetails(row)
              }
            >
              {row.isDocumentCompleted
                ? "Docs Ready"
                : row?.isDocumentUploadedByCompanyOrClient
                ? "Pending"
                : "Not Ready"}
            </span>
          </Button>

          {/* First downloaded by Agent: DateTime */}
          {/* turns green only when agent downloaded all documents  */}
          {/* isAgentDocumentDownloaded */}
          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.isAgentViewDocumentTime &&
              `First downloaded by Agent: ${formatDateAsPerTimeZOne(
                row?.isAgentViewDocumentTime,
                row?.closingAddress?.timeZone
              )}`
            }
          >
            {row.isAllDocumentsDownloaded ? (
              <span
                className="fa fa-circle text-success"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            ) : (
              <span
                className="fa fa-circle text-warning"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
            <span className="value">
              {row?.isAllDocumentsDownloaded ? "Docs Downloaded" : "Pending"}
            </span>
          </Button>

          {/* Last Uploaded by Agent: DateTime */}

          {/* once a company create a closing and Selects the "scan back is required" check box and assigns the closing, 
              1. The third button will turn red(the rest two will remain gray), 
              2. When the agent uploads any doc and selects "scan back upload completed" it will turn yellow then 
              3. When the company selects "verified agent doc" it will turn green. */}

          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.agentLastDocumentUploadedAt &&
              `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                row?.agentLastDocumentUploadedAt,
                row?.closingAddress?.timeZone
              )}`
            }
          >
            {row.isScanBackNeeded ? (
              row.isDocumentVerified ? (
                <>
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span className="value">Shipping Approved</span>
                </>
              ) : row.isDocumentCompletedByAgent ? (
                <>
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span
                    className="value"
                    onClick={() => _redirectToClosingDetails(row)}
                  >
                    Scan Upload
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="fa fa-circle text-danger"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span className="value">Scan Needed</span>
                </>
              )
            ) : row?.documents?.length && !row.documents[0].isPrivate ? (
              row.isDocumentCompletedByAgent ? (
                row.isDocumentVerified ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Shipping Approved</span>
                  </>
                ) : (
                  <>
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Scan Upload</span>
                  </>
                )
              ) : (
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )
            ) : (
              <span
                className="fa fa-circle"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
          </Button>
        </div>
      ) : (
        "N/A"
      );
    }
    case "fileNumber": {
      return row ? (
        <>
          <div
            className="tableLink"
            style={{
              width: 100,
              whiteSpace: "normal",
              fontWeight: 600,
            }}
            onClick={() => _redirectToClosingDetails(row)}
            disabled={
              !(
                checkPermission(
                  "dashboard",
                  "canReviewDocumentsOrOrderDetail"
                ) || checkPermission("dashboard", "canEditOrderEntry")
              )
            }
          >
            {row.fileNumber || "N/A"}
          </div>
          <div style={{ width: 100 }}>
            <span
              style={{
                marginRight: 6,
                display: "inline-block",
                fontWeight: 600,
              }}
            >
              {" "}
              {row.signingType} {","}
            </span>
            {row.loanType && row.loanType.length > 0 ? (
              row.loanType.indexOf("Other") > -1 ? (
                <span
                  style={{
                    marginRight: 6,
                    display: "inline-block",
                    fontWeight: 600,
                  }}
                >
                  {row?.loanTypeOther} {","}
                </span>
              ) : null
            ) : null}
            {row.loanType
              .filter((each) => each !== "Other")
              .map((item) => (
                <>
                  <span
                    style={{
                      marginRight: 6,
                      display: "inline-block",
                      fontWeight: 600,
                    }}
                  >
                    {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </span>
                </>
              ))}
          </div>
        </>
      ) : (
        "N/A"
      );
    }
    case "_agent": {
      return (
        <div style={{ width: 80, fontWeight: 600 }}>
          {(cell?.name?.full && capitalize(cell?.name?.full)) || (
            <>
              N/A
              <span className="text-primary ml-1">
                {row?._noOfBidding}
              </span>{" "}
            </>
          )}
          <Button
            className="pl-0"
            color="link"
            onClick={() => {
              // !row?.tBD
              _onToggleAssignJobModal(row);
              // : errorHandler({
              // reason:
              // "TBD Order! Please update order with a confirmed closing date from Client Side.",
              // });
            }}
            disabled={!checkPermission("dashboard", "canEditOrderEntry")}
          >
            <div className="pencil">
              <SvgIcons type="editIcon" />
            </div>
          </Button>
        </div>
      );
    }
    case "_client": {
      return cell && cell.companyName ? (
        <>
          <div style={{ width: 90, fontWeight: 600 }}>
            {capitalize(cell.companyName)}
          </div>
          <div style={{ width: 90, fontWeight: 600 }}>
            {row?._clientAssistantTeam?.teamName
              ? capitalize(row?._clientAssistantTeam?.teamName)
              : "N/A"}
          </div>
        </>
      ) : (
        "N/A"
      );
    }
    case "status": {
      return cell ? (
        <div className="customSelectWrap" style={{ width: 110 }}>
          <Input
            type="select"
            name="status"
            className={`status ${getDropdownColor(cell)}`}
            disabled={
              loading.statusChangeLoading ||
              !(
                checkPermission(
                  "dashboard",
                  "canUpdateClosingStatusOrScheduling"
                ) || checkPermission("dashboard", "canEditOrderEntry")
              )
            }
            value={cell}
            onChange={
              (event) =>
                _updateClosingDetailsByIdStatus(row, event.target.value)
              // !row?.tBD
              //   ? this._updateClosingDetailsByIdStatus(row, event.target.value)
              //   : errorHandler({
              //       reason:
              //         "TBD Order! Please update order with a confirmed closing date from Client Side.",
              //     })
            }
          >
            <option key="CCA" value="CCA" disabled={true}>
              Can't Complete
            </option>
            <option key="Pending" value="Pending">
              Pending
            </option>
            <option
              key="Closed"
              value="Closed"
              disabled={(row?.tBD ? true : false) || row?.isSavedAsDraft}
            >
              Closed
            </option>
            <option key="Cancelled" value="Cancelled">
              Cancelled
            </option>
            <option
              key="DNC"
              value="DNC"
              disabled={(row?.tBD ? true : false) || row?.isSavedAsDraft}
            >
              Did not close
            </option>
            <option key="Arrived" value="Arrived" disabled={true}>
              Arrived
            </option>
          </Input>
          <div className="downArrow">
            <i className="fa fa-chevron-down"></i>
          </div>
        </div>
      ) : (
        "N/A"
      );
    }
    case "loanOfficer": {
      return cell ? capitalize(cell) : "N/A";
    }
    case "closingAddress": {
      return (
        <>
          <span style={{ fontWeight: 600, width: 110, display: "block" }}>
            {formatAddressInSingleText(cell) || "N/A"}
          </span>
          <Button
            color="link"
            className="p-0"
            title={formatAddressInSingleText(cell)}
            onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
          >
            <img
              src={
                require("../../../assets/img/Google_Maps_Logo_2020.svg").default
              }
              alt="map icon"
              className="mr-1"
              loading="lazy"
            />
            Google Map
          </Button>
          <Button
            color="link"
            className="p-0"
            title={formatAddressInSingleText(cell)}
            onClick={() => openAppleMapOnNewTab(row.closingAddress)}
          >
            <img
              src={
                require("../../../assets/img/Apple_Maps_(WatchOS).svg").default
              }
              alt="map icon"
              className="mr-1"
              loading="lazy"
            />
            Apple Map
          </Button>
        </>
      );
    }

    default: {
      return cell;
    }
  }
};

export default ClosingDashboardDataFormat;
