export const AGENTSUBSCRIPTIONDATA = [
  {
    plan: "Free",
    heading: "List your profile to be available for Notary Jobs",
    tag: null,
    priceDynamic: false,
    periodDynamic: false,
    listHeading: "Free",
    listData: [
      {
        listItem: "Closewise Marketplace Listing",
        subItem: "",
      },
      {
        listItem: "Unlimited Order tracking",
        subItem: "",
      },
      {
        listItem: "Master Schedule Over",
        subItem: "",
      },
      {
        listItem: "Pending Paid Income (Processing limit)",
        subItem: "",
      },
      {
        listItem: "Auto Mileage Log (Processing limit)",
        subItem: "",
      },
      // {
      //   listItem: "Pending Paid Income",
      //   subItem: "(Processing limit)",
      // },
    ],
  },
  {
    plan: "Premium",
    heading: "Everything you get in FREE plus",
    tag: null,
    priceDynamic: true,
    periodDynamic: true,
    listHeading: "Unlimited Entries",
    listData: [
      {
        listItem:
          "Display Contact Info on NotaryNearMe.com to receive direct jobs",
        subItem: "",
      },
      {
        listItem: "Featured CloseWise Marketplace Listing",
        subItem: "",
      },
      {
        listItem: "AI Order Inputs (Huge Time Saver)",
        subItem: "",
      },
      {
        listItem: "Full Accounting Suite",
        subItem: "",
      },
      {
        listItem: "Enhanced Mileage Tracking",
        subItem: "",
      },
      {
        listItem: "Analytics Summaries",
        subItem: "",
      },
      {
        listItem: "Digital Business Card",
        subItem: "",
      },
      {
        listItem: "Google Calendar Sync",
        subItem: "",
      },
    ],
  },
];

export const COMPANYSUBSCRIPTIONDATA = [
  {
    plan: "Free",
    heading: "Pay As You Grow",
    tag: null,
    price: "$20",
    period: "per month",
    para: "2$ per Closing",
    subPara: "(10 closings included with monthly fee)",
  },
  {
    plan: "Basic",
    heading: "Professional",
    tag: null,
    price: "$50",
    period: "per month",
    para: "$1.75 per closing",
    subPara: "(30 closings included with monthly fee)",
  },
  {
    plan: "Premium",
    heading: "Advanced",
    tag: null,
    price: "",
    period: "per month",
    para: "$1.50 per Closing",
    subPara: "(70 closings included with monthly fee)",
  },
];
