import moment from "moment";
import React, { Suspense, lazy } from "react";
import { Button, Col, Row } from "reactstrap";
import { BASE_URL } from "../../../config";
import { agentScheduleCompanyHeaderKeys } from "../../../config/agentConfig";
import {
  capitalize,
  deepClone,
  deleteLocalStorageData,
  errorHandler,
  formatOnlyDateMoment,
  getLoggedInUserId,
  openUrlOnNewTab,
  refreshFunc,
  showToast,
  structureQueryParams,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  getAllAgentsWorkWith,
  signingCompanyAgentSchedule,
} from "../../../http/http-calls";
import { makeGetRequest } from "../../../http/http-service";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { addListData } from "../../../redux/actions/list";
import { connect } from "react-redux";
import SvgIcons from "../components/SvgIcons";

const SignerPopover = lazy(() => import("../components/common/popover"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));
const AgentScheduleCompanyFilter = lazy(() =>
  import("../components/AgentScheduleCompanyFilter")
);
const AgentScheduleCompanyDataFormat = lazy(() =>
  import("../components/AgentScheduleCompanyDataFormat")
);
const AgentScheduleCompanyCardDataFormat = lazy(() =>
  import("../components/AgentScheduleCompanyCardDataFormat")
);

class AgentScheduleCompany extends React.Component {
  state = {
    dateRangeValue: null,
    filters: {
      agent: "",
      status: "Pending",
    },
    agents: [],
    agentScheduleCompanyTotalCount: 0,
    agentScheduleCompany: [],
    agentScheduleCompanyHeaderKeys: agentScheduleCompanyHeaderKeys,
    agentScheduleCompanyCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      isEmailLoading: false,
    },
    isClassAdded: false,
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._fetchAllAgentSchedule();
    });
  };

  // show/hide mobile filters dynamically
  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  // reset filters and fetch initial data
  _resetFilter = () => {
    const currentDate = "";
    const startOfDate = moment().startOf("day")._d;
    this.setState(
      {
        filters: {
          agent: "",
          status: "Pending",
        },
        agentScheduleCompany: [],
        dateRangeValue: [startOfDate, currentDate],
      },
      () => {
        this._fetchAllAgentSchedule();
        deleteLocalStorageData("agentschedule"); // delete filters data from local storage
      }
    );

    refreshFunc("company-reporting-agent-schedule-responsive");
  };

  componentDidMount = () => {
    this._getAllFiltersData();
    this._setFilterDataFromLocalStorage();

    const currentDate = "";
    const startOfDate = moment().startOf("day")._d;

    this.setState({
      dateRangeValue: [startOfDate, currentDate],
    });

    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("company-reporting-agent-schedule");
    });

    HeaderEventEmitter.subscribe("email", () => {
      this._downloadData("email");
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);

    loading[loaderName] = value;

    this.setState({ loading });
  };

  _fetchAllAgentSchedule = async () => {
    try {
      this._manageLoading("showTableLoading", true);
      const { filters, dateRangeValue } = deepClone(this.state);

      const filterPayload = {};

      if (dateRangeValue) {
        filterPayload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
        filterPayload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
      }

      if (filters.agent && filters.agent.trim().length)
        filterPayload["agentId"] = filters.agent.trim();

      if (filters.status && filters.status.trim().length)
        filterPayload["status"] = filters.status.trim();

      let payload = {
        filters: filterPayload,
      };
      let response = await signingCompanyAgentSchedule(payload);

      this.setState({ agentScheduleCompany: response?.closings || [] });
      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);

      this._manageLoading("showTableLoading", false);
    }
  };

  // get list of agents worked with from backend
  _getAllFiltersData = async (data) => {
    let payload = {};
    if (data) {
      payload = { search: data };
    }

    try {
      const res = await getAllAgentsWorkWith(payload);
      let options =
        res?.agents?.map((item) => ({
          value: item?._id,
          label: item?.name?.full,
        })) || [];
      this.setState({ agents: options || [] });
      // const data = {agents: res?.agents}
      // console.log("this.props", this.props);
      this.props.addListData({ agents: res?.agents });
    } catch (err) {
      errorHandler(err);
    }
  };

  _filterOnChange = (type, value) => {
    console.log(type, value);
    const { filters } = this.state;

    filters[type] = value;

    this.setState({ filters }, () => {
      this._fetchAllAgentSchedule();
      this._persistFilter();
    });
  };

  _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <AgentScheduleCompanyDataFormat header={header} row={row} cell={cell} />
      </Suspense>
    );
  };

  _paginate = (pageNumber, pageSize) => {
    const { tableConfig } = this.state;

    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;

    this.setState({ tableConfig }, () => {
      this._fetchAllAgentSchedule();
    });
  };

  // function to download list of data present in 'agentScheduleCompany' state
  _downloadData = (action) => {
    const { agentScheduleCompany, filters, dateRangeValue } = this.state;

    // if there's no data in 'agentScheduleCompany' -> return and throw error
    if (!agentScheduleCompany.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(), // get userId of logged In user from redux
    };

    if (dateRangeValue) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      if (dateRangeValue !== null && dateRangeValue[1]) {
        payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
      }
    }

    if (filters.agent) payload["agentId"] = filters.agent;

    if (filters.status) payload["status"] = filters.status;

    payload["action"] = action;
    const queryParams = structureQueryParams(payload); // structuring payload in form of url query params

    const apiUrl = `${BASE_URL}/signingcompany/download/agent-schedule${queryParams}`;

    if (action === "download") {
      openUrlOnNewTab(apiUrl);
    } else if (action === "email") {
      HeaderEventEmitter.dispatch("isEmailLoading", true);
      makeGetRequest(apiUrl, true)
        .then((res) => {
          HeaderEventEmitter.dispatch("isEmailLoading", false);
          showToast("Email sent successfully.", "success");
        })
        .catch((error) => {
          HeaderEventEmitter.dispatch("isEmailLoading", false);
          errorHandler(error);
        });
    }
  };

  // persisiting filters in local storage
  _persistFilter = () => {
    const { filters, tableConfig } = this.state;
    if (
      (filters && (filters.agent || filters.status !== "")) ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, tableConfig };
      localStorage.agentschedule = JSON.stringify(data);
    } else {
      delete localStorage.agentschedule;
    }
  };

  // setting filters from local storage on page load
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentschedule) {
      try {
        const filters = JSON.parse(localStorage.agentschedule);
        this.setState(
          { filters: filters?.filters, tableConfig: filters?.tableConfig },
          () => {
            this._fetchAllAgentSchedule();
          }
        );
      } catch (e) {
        this._fetchAllAgentSchedule();
      }
    } else {
      this._fetchAllAgentSchedule();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.agent) delete filters.agent;
    if (filters.status === "") delete filters.status;

    return filters;
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              <div className="userContent">
                <span className="">Signer</span>

                <div
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {(row?._borrower &&
                    capitalize(row?._borrower?.[0]?.name?.full)) ||
                    "N/A"}
                  {/* show the below content only when there are more than 1 signers  */}
                  {row?._borrower?.length > 1 ? (
                    <Suspense fallback={<></>}>
                      <SignerPopover
                        data={row?._borrower}
                        targetId={row?._id}
                        displayType="onlySignerNames"
                      />
                    </Suspense>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <AgentScheduleCompanyCardDataFormat row={row} />;
      </Suspense>
    );
  };

  render() {
    const {
      agentScheduleCompanyHeaderKeys,
      agentScheduleCompany,
      agentScheduleCompanyTotalCount,
      loading,
      dateRangeValue,
      agents,
      isClassAdded,
      filters,
      tableConfig,
      agentScheduleCompanyCardHeaderKeys,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Agent Schedule</h2>

            <div className="rightSide">
              <Button id="company-reporting-agent-schedule-responsive" color="link" onClick={this._resetFilter}>
                {/* <img
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                /> */}
                <SvgIcons type="refresh" />
              </Button>

              <Button
                className="floatingButton"
                color="primary"
                onClick={() => HeaderEventEmitter.dispatch("email")}
                disabled={loading.isEmailLoading}
              >
                Email
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                {/* <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                /> */}
                <SvgIcons type="filterIcon" />
              </Button>
            </div>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${isClassAdded ? "show" : ""
              }`}
          >
            <div className="filterIcon">
              <SvgIcons type="filterIcon" />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>

            <Suspense fallback={<></>}>
              <AgentScheduleCompanyFilter
                filters={filters}
                agents={agents}
                dateRangeValue={dateRangeValue}
                _filterOnChange={this._filterOnChange}
                _onChangeDatePicker={this._onChangeDatePicker}
                _downloadData={this._downloadData}
                getAllFiltersData={(search) => this._getAllFiltersData(search)}
              />
            </Suspense>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>
          <div className="downloadButtonPos">
            <Button
              color="link"
              outline
              onClick={() => this._downloadData("download")}
            >
              Download All
              <SvgIcons type="download" />
            </Button>
          </div>

          <Row>
            <Col md="12">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ marginBottom: 15 }}
              ></div>
              {isForMobile ? (
                <Suspense fallback={<></>}>
                  <div className="hideDesktop">
                    <CustomCard
                      isPageStartFromOne={true}
                      pageNumber={tableConfig.pageNumber}
                      tableData={agentScheduleCompany}
                      headerKeys={agentScheduleCompanyCardHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={agentScheduleCompanyTotalCount}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      rowSelection={false}
                      showTableLoading={loading.showTableLoading}
                      cardHeaderFormat={this._cardHeaderFormat}
                      cardDataFormat={this._cardDataFormat}
                    />
                  </div>
                </Suspense>
              ) : (
                <Suspense fallback={<></>}>
                  <div className="hideMobile">
                    {agentScheduleCompanyHeaderKeys &&
                      agentScheduleCompanyHeaderKeys.length && (
                        <CustomTable
                          striped
                          isPageStartFromOne={true}
                          tableData={agentScheduleCompany}
                          headerKeys={agentScheduleCompanyHeaderKeys}
                          dataFormat={this._dataFormat}
                          totalCount={agentScheduleCompanyTotalCount}
                          rowSelection={false}
                          onPaginate={(pageNumber, pageSize) =>
                            this._paginate(pageNumber, pageSize)
                          }
                          onSortChange={(sortName, sortOrder) =>
                            this._onSortChange(sortName, sortOrder)
                          }
                          showTableLoading={loading.showTableLoading}
                        />
                      )}
                  </div>
                </Suspense>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addListData: (listData) => dispatch(addListData(listData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(AgentScheduleCompany));
