import React, { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";

const BarGraph = ({ data, className = "", height, loading }) => {
  const [options, setOptions] = useState({
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: [
      {
        labels: {
          formatter: (value) => value.toFixed(0),
        },
      },
    ],
    xaxis: {
      categories: [],
    },
    // colors: [],
    tooltip: {
      // shared: false,
      x: {
        show: false,
      },
    },
  });

  const [series, setSeries] = useState([]);

  const _setChartState = (data) => {
    const newOptions = { ...options };
    const newSeries = [...series];
  
    if (data?.series1?.length) {
      newOptions.xaxis = { ...newOptions.xaxis, categories: data.series1.map((each) => each.label) };
  
      newSeries[0] = {
        name: data.tooltipName1,
        data: data.series1.map((each) => each.value),
      };
    }
    
    if (data?.formatter1) {
      if (newOptions.yaxis && newOptions.yaxis.length > 0 && newOptions.yaxis[0].labels) {
        newOptions.yaxis[0] = {
          ...newOptions.yaxis[0],
          labels: {
            ...newOptions.yaxis[0].labels,
            formatter: data.formatter1,
          },
        };
      }
    }
  
    if (Array.isArray(data?.colors)) {
      newOptions.colors = data.colors;
    }
  
    setOptions(newOptions);
    setSeries(newSeries);
  };

  useEffect(() => {
    if (data?.series1?.length) {
      _setChartState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return !loading && series?.[0]?.data?.length ? (
    <div className={className}>
      <Chart options={options} series={series} type="bar" height={height} />
    </div>
  ) : (
    <></>
  );
};

export default memo(BarGraph);
