import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  checkPermission,
  deepClone,
  errorHandler,
  // formatDateAsPerTimeZOne,
  formatOnlyDateMoment,
  // getTimeZoneAbbr,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  getAllClientClosing,
  getAllSigningCompanyForClient,
  updateClosingDetailsById,
  clientGetListTeamsOfClient,
} from "../../../http/http-calls";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { clientOrderHeaderKeys } from "../../../config/stateConfig";
import ClientOrderDashboardFilters from "../components/ClientOrderDashboardFilters";
import ClientOrderDashboardCardDataFormat from "../components/ClientOrderDashboardCardDataFormat";
import ClientOrderDashboardDataFormat from "../components/ClientOrderDashboardDataFormat";
import { DateTimeShowWithCellRow } from "../components/Date-time-show";
const SignerPopover = lazy(() => import("../components/common/popover"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));
const SignerAvailabilityModal = lazy(() =>
  import("../components/signer-availability-modal")
);
const SigningStatusSigningModal = lazy(() =>
  import("../components/signing-status-signing")
);
const ClientAssignOrder = lazy(() => import("../components/ClientAssignOrder"));

class OrderDashboard extends React.Component {
  state = {
    clientOrderList: [],
    clientOrderTotalCount: 0,
    clientOrderHeaderKeys: clientOrderHeaderKeys,
    clientOrderCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
      ``,
    ],
    clientAssignOrder: {
      isOpen: false,
      data: null,
    },
    editAgentModal: {
      isOpen: false,
      data: null,
    },
    signingStatusModal: {
      isOpen: false,
      data: null,
      status: null,
    },
    signerAvailabilityModal: {
      isOpen: false,
      data: null,
    },
    isFilterCollapseOpen: false,
    isEditingAgent: false,
    tablePayload: {
      page: 1,
      rowsPerPage: 10,
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    filters: {
      status: "",
      search: "",
      signingCompanyId: "",
      clientTeam: "",
      state: "",
      startDate: "",
      endDate: "",
      unassigned: false,
    },
    tableConfig: {
      skip: 0,
      limit: 30,
      pageNumber: 1,
    },
    dateRangeValue: null,
    clientTeamsList: [],
    signingCompanies: [],
    agents: [],
    loanType: [],
    isClassAdded: false,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    this.searchTimer = null;

    // this._getAllClientClosing();
    this._setFilterDataFromLocalStorage();
    this._getAllSigningCompanyForClient();
    this._getClientListTeamsOfClient();

    HeaderEventEmitter.subscribe("reset-filter", () => {
      // reset filters
      this._resetFilter();
      refreshFunc("client-order-dashboard");
    });
    HeaderEventEmitter.subscribe("create-closing", () => {
      // redirect to create closing
      this._redirectToCreateOrder();
    });

    // document.querySelector("#scroll").scrollTo(0, 0);
  };

  // redirect to Closing Details
  _redirectToClosingDetails = (data) => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/order-details/${data.id}`);
  };

  // get all signingcompany list
  _getAllSigningCompanyForClient = async () => {
    try {
      const res = await getAllSigningCompanyForClient();

      this.setState({
        signingCompanies: res?.signingCompany?.length
          ? res?.signingCompany
          : [],
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  // get all closings/orders
  _getAllClientClosing = async () => {
    try {
      this._manageLoading("showTableLoading", true);

      const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

      if (filters.status && filters.status.length)
        Object.assign(tableConfig, { status: filters.status });

      if (filters?.clientTeam && filters?.clientTeam?.length)
        Object.assign(tableConfig, {
          clientAssistantTeam: filters?.clientTeam,
        });

      if (filters.state && filters.state.length)
        Object.assign(tableConfig, { state: filters.state });

      if (filters.signingCompanyId && filters.signingCompanyId.length)
        Object.assign(tableConfig, {
          signingCompanyId: filters.signingCompanyId,
        });

      if (filters.unassigned)
        Object.assign(tableConfig, { unassigned: filters.unassigned });

      if (filters.closingDeadline)
        Object.assign(tableConfig, {
          closingDeadline: filters.closingDeadline,
        });

      if (filters.assignedDate)
        Object.assign(tableConfig, { assignedDate: filters.assignedDate });

      if (filters.loanType)
        Object.assign(tableConfig, { loanType: filters.loanType });

      if (filters.search && filters.search.trim().length)
        Object.assign(tableConfig, { search: filters.search.trim() });

      if (dateRangeValue && dateRangeValue[0])
        Object.assign(tableConfig, {
          startDate: formatOnlyDateMoment(dateRangeValue[0]),
        });

      if (dateRangeValue && dateRangeValue[1])
        Object.assign(tableConfig, {
          endDate: formatOnlyDateMoment(dateRangeValue[1]),
        });

      if (
        dateRangeValue &&
        dateRangeValue[0]?.getDate() === dateRangeValue[1]?.getDate()
      )
        Object.assign(tableConfig, { isReverse: true });

      delete tableConfig.sort;
      delete tableConfig.pageNumber;

      const res = await getAllClientClosing(tableConfig);

      this.setState(
        {
          clientOrderList: res?.closings,
          clientOrderTotalCount: res?.totalCount,
        },
        () => {
          this._manageLoading("showTableLoading", false);
        }
      );
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  // function for assignment modal to open with data
  _onToggleClientAssignOrder = (isOpen = false, data = null) => {
    this.setState({
      clientAssignOrder: {
        isOpen,
        data,
      },
    });
  };

  // function for signing status modal for updating closing status
  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  // function for signer availabilty modal for appointment confirmation
  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    if (
      isOpen &&
      (data?._agent ||
        (data?.status !== "Pending" && data?.status !== "Cancelled"))
    ) {
      showToast("You cannot change the date & time.", "error");
      return;
    }

    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  // function for pagination
  _paginate = (pageNumber = 1, pageSize = 30) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;

    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllClientClosing();
    });

    // document.querySelector(".content").scrollIntoView();
  };

  // get list of teams of client
  _getClientListTeamsOfClient = async () => {
    try {
      const clientId = this.props.userData?.user?.id;
      const res = await clientGetListTeamsOfClient(clientId);
      // const res = await clientGetListTeamsOfClient();

      this.setState({ clientTeamsList: res?.teams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate();
  };

  // function to handle on change date picker
  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
    });
  };

  //  function to handle on change of filters
  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      } else {
        this._paginate();
      }
    });
  };

  // function to reset filter
  _resetFilter = () => {
    this.setState(
      {
        filters: {
          status: "",
          search: "",
          signingCompanyId: "",
          clientTeam: "",
          state: "",
          startDate: "",
          endDate: "",
          unassigned: false,
        },
        dateRangeValue: null,
      },
      () => {
        this._paginate();
      }
    );
    refreshFunc("client-order-dashboard-responsive");
  };

  _redirectToCreateOrder = () => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/create-order`);
  };

  // function for redirecting to  closing details
  _redirectToOrderDetails = (data) => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/order-details/${data.id}`);
  };

  // function for updating to closing status by id

  _onChangeOrderStatus = async (data, status) => {
    try {
      if (status !== "Pending" && status !== "Cancelled") {
        errorHandler({ reason: "Invalid status" });
        return;
      }

      this._manageLoading("statusChangeLoading", true);

      const updateAgentRes = await updateClosingDetailsById({
        id: data?._id,
        payload: { status },
      });

      showToast("Status has been updated", "success");

      const { clientOrderList } = this.state;

      const findOrder = clientOrderList.find((each) => each.id === data.id);

      findOrder["status"] = updateAgentRes?.closing?.status;

      this.setState({ clientOrderList });

      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _dataFormat = (cell, row, header) => {
    // document.querySelector(".content").scrollIntoView();

    return (
      <ClientOrderDashboardDataFormat
        header={header}
        row={row}
        cell={cell}
        _onToggleSignerAvailabilityModal={this._onToggleSignerAvailabilityModal}
        _redirectToOrderDetails={this._redirectToOrderDetails}
        _redirectToClosingDetails={this._redirectToClosingDetails}
        _onToggleClientAssignOrder={this._onToggleClientAssignOrder}
        loading={this.state.loading}
        _onChangeOrderStatus={this._onChangeOrderStatus}
      />
    );
  };

  // this function is used to add background color for row
  _rowStyleFormat = (row, rowIdx) => {
    const statusEnum = ["Closed", "DNC", "Cancelled"];
    if (row?.status && statusEnum.includes(row.status)) {
      return { backgroundColor: "#eee" };
    }
    if (row?.isSavedAsDraft) {
      return { backgroundColor: "#edf0ff" };
    }
    if (row?._signingCompany?.whiteLabelRequestStatus === "Done") {
      return {
        backgroundColor: "#d7eafd",
      };
    }
    return {};
  };

  // in mobile view this function shows the card header
  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              {/* <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._lenderClient?.picUrl
                      ? row?._lenderClient?.picUrl
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div> */}
              <div className="userContent flex-0">
                {row?._borrower?.length ? (
                  <>
                    <Suspense fallback={<></>}>
                      <SignerPopover
                        data={row?._borrower}
                        targetId={row?._id}
                        screenType="mobile"
                        displayType="signerDetails"
                      />
                    </Suspense>
                  </>
                ) : null}
                {/* <span className="dateTimeFormat">
                  {formatDateAsPerTimeZOne(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )}
                  ,{" "}
                  <span>
                    {getTimeZoneAbbr(
                      row?.appointmentDate,
                      row.closingAddress?.timeZone
                    )}
                  </span> 
                </span>*/}
                <DateTimeShowWithCellRow
                  cell={row?.appointmentDate}
                  row={row}
                />
              </div>

              <Button
                color={`${
                  row?.appointmentStatus === "Confirmed" && !row?.leftMessage
                    ? "success "
                    : row?.appointmentStatus === "Rescheduled" &&
                      !row?.leftMessage
                    ? "danger "
                    : row?.leftMessage
                    ? "warning"
                    : "danger "
                }`}
                className={`confirmButton btnConfirm`}
                onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
              >
                {row?.appointmentStatus === "Confirmed"
                  ? "Confirmed"
                  : "Confirm"}
              </Button>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  // in mobile view this function shows the card body
  _cardDataFormat = (row) => {
    return (
      <ClientOrderDashboardCardDataFormat
        row={row}
        _redirectToClosingDetails={this._redirectToClosingDetails}
        _onToggleClientAssignOrder={this._onToggleClientAssignOrder}
        _redirectToOrderDetails={this._redirectToOrderDetails}
        loading={this.state.loading}
        _onChangeOrderStatus={this._onChangeOrderStatus}
      />
    );
  };

  // this function is used to store the value in the local storage
  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.clientClosingdashboard = JSON.stringify(data);
    } else {
      delete localStorage.clientClosingdashboard;
    }
  };

  // get data from local storage and set the state
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.clientClosingdashboard) {
      try {
        const filters = JSON.parse(localStorage.clientClosingdashboard);

        this.setState({ tableConfig: filters.tableConfig }, () => {
          this._getAllClientClosing();
        });
      } catch (e) {
        console.log("error", e);
        this._getAllClientClosing();
      }
    } else {
      this._getAllClientClosing();
    }
  };

  render() {
    const {
      clientOrderTotalCount,
      dateRangeValue,
      filters,
      clientOrderHeaderKeys,
      clientOrderList,
      loading,
      signingStatusModal,
      clientAssignOrder,
      signerAvailabilityModal,
      tableConfig,
      signingCompanies,
      isClassAdded,
      clientOrderCardHeaderKeys,
      clientTeamsList,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <div id="scroll">
        <div className="content">
          <div className="responsiveTitle">
            <h2>
              Order Dashboard
              <span>{this.props?.userData?.user?.companyName}</span>
            </h2>

            <div className="rightSide">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="client-order-dashboard-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                  loading="lazy"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                  loading="lazy"
                />
              </Button>

              {checkPermission("dashboard", "canCreateOrder") && (
                <Button
                  color="primary"
                  className="floatingButton"
                  onClick={() => this._redirectToCreateOrder()}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
          <h3 className="mainHeading d-none d-lg-block">
            {this.props?.userData?.user?.companyName}
          </h3>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                    loading="lazy"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="search"
                value={filters?.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
                placeholder="Search Signer, Product type & File"
              />
            </InputGroup>
          </div>
          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
                loading="lazy"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                  loading="lazy"
                />
              </Button>
            </div>

            {/* filter component */}
            <ClientOrderDashboardFilters
              filters={filters}
              clientTeamsList={clientTeamsList}
              // signingCompanies={signingCompanies}
              dateRangeValue={dateRangeValue}
              _filterOnChange={this._filterOnChange}
              _onChangeDatePicker={this._onChangeDatePicker}
            />
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          {/* for mobile view first condition will be executed */}
          {isForMobile ? (
            <div className="hideDesktop">
              <Suspense fallback={<></>}>
                <CustomCard
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  pageSize={tableConfig.limit}
                  tableData={clientOrderList}
                  headerKeys={clientOrderCardHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={clientOrderTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowSelection={false}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </Suspense>
            </div>
          ) : (
            <div className="hideMobile">
              <Suspense fallback={<></>}>
                {clientOrderHeaderKeys && clientOrderHeaderKeys?.length && (
                  <CustomTable
                    pageNumber={tableConfig?.pageNumber}
                    pageSize={tableConfig?.limit}
                    tableData={clientOrderList}
                    headerKeys={clientOrderHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={clientOrderTotalCount}
                    rowSelection={false}
                    sizePerPage={tableConfig?.limit}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    onSortChange={(sortName, sortOrder) =>
                      this._onSortChange(sortName, sortOrder)
                    }
                    rowStyleFormat={(row, rowIdx) =>
                      this._rowStyleFormat(row, rowIdx)
                    }
                    showTableLoading={loading?.showTableLoading}
                    isPageStartFromOne
                  />
                )}
              </Suspense>
            </div>
          )}

          <Suspense fallback={<></>}>
            {/* Assign order */}
            <ClientAssignOrder
              isOpen={clientAssignOrder.isOpen}
              data={clientAssignOrder.data}
              signingCompanies={signingCompanies}
              resetDetails={() => this._getAllClientClosing()}
              toggle={() => this._onToggleClientAssignOrder()}
            />
          </Suspense>

          {/* Signer availability modal for confirm appointment*/}
          <Suspense fallback={<></>}>
            <SignerAvailabilityModal
              isOpen={signerAvailabilityModal.isOpen}
              data={signerAvailabilityModal.data}
              resetDetails={() => this._paginate()}
              toggle={() => this._onToggleSignerAvailabilityModal()}
              AppointmentSchedulerConfig={{
                regular: { disable: false, show: true },
                dateRange: { disable: true, show: true },
                tbd: { disable: false, show: true },
              }}
            />
          </Suspense>

          {/* singing status modal for updating closing status*/}
          <Suspense fallback={<></>}>
            {signingStatusModal.isOpen ? (
              <SigningStatusSigningModal
                isOpen={signingStatusModal.isOpen}
                data={signingStatusModal.data}
                status={signingStatusModal.status}
                resetDetails={() => this._getAllClientClosing()}
                toggle={() => this._onToggleSigningStatusModal()}
              />
            ) : null}
          </Suspense>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(ScreenWidthHOC(OrderDashboard));
