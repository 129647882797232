import React from "react";
import { Button, Input, Label } from "reactstrap";
import {
  capitalize,
  checkPermission,
  // errorHandler,
  formatAddressInSingleText,
  formatDateAsPerTimeZOne,
  getDropdownColor,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
  // showToast,
} from "../../../helper-methods";

const ClosingDashboardCardData = ({
  row,
  _redirectToClosingDetails,
  _onToggleAssignJobModal,
  loading,
  _updateClosingDetailsByIdStatus,
}) => {
  return (
    <ul className="cardInfo">
      <li>
        <div className="dataWrap">
          <Label>Order Details </Label>

          {
            <>
              <div
                className="text-primary"
                style={{ fontWeight: 600 }}
                onClick={() => _redirectToClosingDetails(row)}
                disabled={
                  !(
                    checkPermission(
                      "dashboard",
                      "canReviewDocumentsOrOrderDetail"
                    ) || checkPermission("dashboard", "canEditOrderEntry")
                  )
                }
              >
                {row?.fileNumber || "N/A"}
              </div>

              <div>
                <span
                  style={{
                    marginRight: 6,
                    display: "inline-block",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  {row.signingType} {","}
                </span>
                {row.loanType && row.loanType.length > 0 ? (
                  row.loanType.indexOf("Other") > -1 ? (
                    <span
                      style={{
                        marginRight: 6,
                        display: "inline-block",
                        fontWeight: 600,
                      }}
                    >
                      {row?.loanTypeOther} {","}
                    </span>
                  ) : null
                ) : null}
                {row.loanType
                  .filter((each) => each !== "Other")
                  .map((item) => (
                    <>
                      <span
                        style={{
                          marginRight: 6,
                          display: "inline-block",
                          fontWeight: 600,
                        }}
                      >
                        {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </span>
                    </>
                  ))}
              </div>
            </>
          }
        </div>

        <div className="dataWrap">
          <Label>Agent</Label>
          <div style={{ fontWeight: 600 }}>
            {(row?._agent?.name?.full &&
              capitalize(row?._agent?.name?.full)) || (
              <>
                N/A
                <span className="text-primary ml-1">
                  {row?._noOfBidding}
                </span>{" "}
              </>
            )}
            <Button
              color="link"
              onClick={() => _onToggleAssignJobModal(row)}
              disabled={!checkPermission("dashboard", "canEditOrderEntry")}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
                loading="lazy"
              />
            </Button>
          </div>
        </div>
      </li>

      <li>
        <div className="dataWrap">
          <Label>Client </Label>
          <span
            style={{
              marginLeft: 5,
              fontWeight: 600,
            }}
          >
            {row?._client?.companyName ? (
              <>
                <div style={{ width: 90, fontWeight: 600 }}>
                  {capitalize(row?._client?.companyName)}
                </div>
                <div style={{ width: 90, fontWeight: 600 }}>
                  {row?._clientAssistantTeam?.teamName
                    ? capitalize(row?._clientAssistantTeam?.teamName)
                    : "N/A"}
                </div>
              </>
            ) : (
              "N/A"
            )}
          </span>
        </div>
      </li>
      <li>
        <div className="dataWrap">
          <Label>Docs Status </Label>

          {row ? (
            <div className="docStatus">
              {/* Last Uploaded by Company: $DateTime */}
              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.companyLastDocumentUploadedAt &&
                  `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                    row?.companyLastDocumentUploadedAt,
                    row?.closingAddress?.timeZone
                  )}`
                }
              >
                {row?.documents?.length && !row.documents[0].isPrivate ? (
                  row.isDocumentCompleted ? (
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  ) : row?.isDocumentUploadedByCompanyOrClient ? (
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  ) : (
                    <span
                      className="fa fa-circle text-primary"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )
                ) : (
                  <span
                    className="fa fa-circle text-primary"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
                <span
                  className="value"
                  onClick={() =>
                    row.isDocumentCompleted && _redirectToClosingDetails(row)
                  }
                >
                  {row.isDocumentCompleted
                    ? "Docs Ready"
                    : row?.isDocumentUploadedByCompanyOrClient
                    ? "Pending"
                    : "Not Ready"}
                </span>
              </Button>

              {/* First downloaded by Agent: DateTime */}
              {/* turns green only when agent downloaded all documents  */}
              {/* isAgentDocumentDownloaded */}
              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.isAgentViewDocumentTime &&
                  `First downloaded by Agent: ${formatDateAsPerTimeZOne(
                    row?.isAgentViewDocumentTime,
                    row?.closingAddress?.timeZone
                  )}`
                }
              >
                {row.isAllDocumentsDownloaded ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
                <span className="value">
                  {row?.isAllDocumentsDownloaded
                    ? "Docs Downloaded"
                    : "Pending"}
                </span>
              </Button>

              {/* Last Uploaded by Agent: DateTime */}

              {/* once a company create a closing and Selects the "scan back is required" check box and assigns the closing, 
        1. The third button will turn red(the rest two will remain gray), 
        2. When the agent uploads any doc and selects "scan back upload completed" it will turn yellow then 
        3. When the company selects "verified agent doc" it will turn green. */}

              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.agentLastDocumentUploadedAt &&
                  `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                    row?.agentLastDocumentUploadedAt,
                    row?.closingAddress?.timeZone
                  )}`
                }
              >
                {row.isScanBackNeeded ? (
                  row.isDocumentVerified ? (
                    <>
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Shipping Approved</span>
                    </>
                  ) : row.isDocumentCompletedByAgent ? (
                    <>
                      <span
                        className="fa fa-circle text-primary"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span
                        className="value"
                        onClick={() => _redirectToClosingDetails(row)}
                      >
                        Scan Upload
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fa fa-circle text-danger"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Scan Needed</span>
                    </>
                  )
                ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                  row.isDocumentCompletedByAgent ? (
                    row.isDocumentVerified ? (
                      <>
                        <span
                          className="fa fa-circle text-success"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span className="value">Shipping Approved</span>
                      </>
                    ) : (
                      <>
                        <span
                          className="fa fa-circle text-primary"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span className="value">Scan Upload</span>
                      </>
                    )
                  ) : (
                    <span
                      className="fa fa-circle"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
              </Button>
            </div>
          ) : (
            "N/A"
          )}
        </div>
      </li>

      <li>
        <div className="dataWrap">
          <div className="d-flex justify-content-between">
            <Label>Location </Label>
            <div className="">
              <Button
                color="link"
                className="p-0 mr-2"
                title={formatAddressInSingleText(row?.closingAddress)}
                onClick={() => openGoogleMapOnNewTab(row?.closingAddress)}
              >
                <img
                  src={
                    require("../../../assets/img/Google_Maps_Logo_2020.svg")
                      .default
                  }
                  alt="map icon"
                  className="mr-1"
                  loading="lazy"
                />
                {/* Google Map */}
              </Button>
              <Button
                color="link"
                className="p-0"
                title={formatAddressInSingleText(row?.closingAddress)}
                onClick={() => openAppleMapOnNewTab(row?.closingAddress)}
              >
                <img
                  src={
                    require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                      .default
                  }
                  alt="map icon"
                  className="mr-1"
                  loading="lazy"
                />
                {/* Apple Map */}
              </Button>
            </div>
          </div>

          <>
            <div style={{ fontWeight: 600 }}>
              {formatAddressInSingleText(row?.closingAddress) || "N/A"}
            </div>
          </>
        </div>
      </li>
      <li>
        <div className="dataWrap align-items-center d-flex justify-content-between">
          <Label className="mb-0">Status </Label>

          {row?.status ? (
            <div className="customSelectWrap">
              <Input
                type="select"
                name="status"
                className={`status ${getDropdownColor(row?.status)}`}
                disabled={
                  loading.statusChangeLoading ||
                  !(
                    checkPermission(
                      "dashboard",
                      "canUpdateClosingStatusOrScheduling"
                    ) || checkPermission("dashboard", "canEditOrderEntry")
                  )
                }
                value={row?.status}
                onChange={(event) =>
                  _updateClosingDetailsByIdStatus(row, event.target.value)
                }
              >
                <option key="CCA" value="CCA" disabled={true}>
                  Can't Complete
                </option>
                <option key="Pending" value="Pending">
                  Pending
                </option>
                <option
                  key="Closed"
                  value="Closed"
                  disabled={(row?.tBD ? true : false) || row?.isSavedAsDraft}
                >
                  Closed
                </option>
                <option key="Cancelled" value="Cancelled">
                  Cancelled
                </option>
                <option
                  key="DNC"
                  value="DNC"
                  disabled={(row?.tBD ? true : false) || row?.isSavedAsDraft}
                >
                  Did not close
                </option>
                <option key="Arrived" value="Arrived" disabled={true}>
                  Arrived
                </option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          ) : (
            "N/A"
          )}
        </div>
      </li>
    </ul>
  );
};

export default ClosingDashboardCardData;
