import { UPLOAD_FILE_PERCENTAGE } from "./action-types";

export const uploadFilePercentage = (percentage = '0') => {
  if(percentage.includes('%')) {
    percentage = percentage.slice(0, -1)
  }
  return {
    type: UPLOAD_FILE_PERCENTAGE,
    payload: {
      percentage
    }
  }
}
