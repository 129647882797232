import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Col,
  FormGroup,
  Input,
  ListGroupItem,
  ListGroup,
  CustomInput,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import usaStates from "../../../../config/usa_states_titlecase";
import { validateAddress } from "../../../../lib-frontend/form-validation";
import {
  InputField,
  SelectField,
} from "../../../general/components/common/FormField";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../../helper-methods/googleService";
import {
  debounce,
  deepMergeObjects,
  showToast,
} from "../../../../helper-methods";

const createInitialFieldState = (
  value = "",
  isDirty = false,
  isValid = false,
  error = null,
  needToShow = true
) => ({
  value,
  isDirty,
  isValid,
  error,
  needToShow,
});

const initialObject = {
  addressLine1: createInitialFieldState(),
  addressLine2: createInitialFieldState(),
  city: createInitialFieldState(),
  county: createInitialFieldState(),
  state: createInitialFieldState(),
  zip: createInitialFieldState(),
  country: createInitialFieldState(),
  lat: createInitialFieldState("", false, true, null, false),
  lng: createInitialFieldState("", false, true, null, false),
};

const CreateClosingAddressComponent = forwardRef((props, ref) => {
  const {
    lg = 6,
    md = 6,
    xl = 6,
    sm = 12,
    hideCountryInput = false,
    borrowerAddress,
    closingAddress,
    // closingGoogleAddress,
    // borrowerGoogleAddress,
    isClosingAddressRequired,
    passData,
  } = props;

  const borrowerPlaceholders = {
    addressLine1: "Property Address Line 1",
    addressLine2: "Property Address Line 2",
    zip: "Zip",
    state: "State",
    city: "City",
    county: "County",
    country: "Country",
  };

  const closingPlaceholders = {
    addressLine1: "Appointment Address Line 1",
    addressLine2: "Appointment Address Line 2",
    zip: "Zip",
    state: "State",
    city: "City",
    county: "County",
    country: "Country",
  };

  // const mountedRef = useRef(false);

  const [isAppointmentAddressSame, setIsAppointmentAddressSame] =
    useState(false);

  // borrower address state
  const [initialBorrowerAddress, setInitialBorrowerAddress] = useState(
    JSON.parse(JSON.stringify(initialObject))
  );
  const [googleAddressBorrowerResult, setGoogleBorrowerAddressResult] =
    useState([]);
  const [googleBorrowerSearch, setGoogleBorrowerSearch] = useState({
    value: "",
    error: false,
  });
  const [borrowerAddressDropdownShow, setBorrowerAddressDropdownShow] =
    useState(false);

  // closing address state
  const [initialClosingAddress, setInitialClosingAddress] = useState(
    JSON.parse(JSON.stringify(initialObject))
  );
  const [googleAddressClosingResult, setGoogleClosingAddressResult] = useState(
    []
  );
  const [googleClosingSearch, setGoogleClosingSearch] = useState({
    value: "",
    error: false,
  });
  const [closingAddressDropdownShow, setClosingAddressDropdownShow] =
    useState(false);

  const handleInputChange = (field, event, fieldType) => {
    // console.log("handleInputChangeForBorrower >>", field, event, fieldType);
    console.log("Calling  handleInputChange", initialBorrowerAddress);

    if (field === "googleBorrowerSearch" || field === "googleClosingSearch") {
      _handleGoogleSearchChange(field, event.target.value);
    } else {
      _updateBorrowerInitialValues(field, event.target.value, fieldType);
    }
  };

  const _handleGoogleSearchChange = (field, value) => {
    if (field === "googleClosingSearch") {
      setGoogleClosingSearch({ value, error: false });
    } else {
      setGoogleBorrowerSearch({ value, error: false });
    }
    // _googlePlaceSearch(field, value);
    debouncedGooglePlaceSearch(field, value);
  };

  const _updateBorrowerInitialValues = (field, value, fieldType) => {
    console.log(
      "Calling  _updateBorrowerInitialValues",
      initialBorrowerAddress
    );

    if (fieldType === "closingAddress") {
      setInitialClosingAddress((prevValues) => ({
        ...prevValues,
        [field]: {
          ...prevValues[field],
          value,
          error: "",
        },
      }));
      if (passData !== undefined) {
        // Pass updated data to parent
        passData({
          ...initialClosingAddress,
          [field]: { ...initialClosingAddress[field], value },
        });
      }
    } else {
      setInitialBorrowerAddress((prevValues) => ({
        ...prevValues,
        [field]: {
          ...prevValues[field],
          value,
          error: "",
        },
      }));
    }
  };

  const handleInputBlur = (fieldName, fieldType) => {
    // console.log("handleInputBlur >>", fieldName, fieldType);
    // console.log("Calling  handleInputBlur", initialBorrowerAddress);

    if (fieldType === "closingAddress") {
      setInitialClosingAddress((prevValues) => {
        const formfields = { ...prevValues };
        formfields[fieldName].isDirty = true;
        const { updatedFormfields } = validateAddress(formfields);
        return updatedFormfields;
      });
    } else {
      setInitialBorrowerAddress((prevValues) => {
        const formfields = { ...prevValues };
        formfields[fieldName].isDirty = true;
        const { updatedFormfields } = validateAddress(formfields);
        return updatedFormfields;
      });
    }
  };

  const _markAllDirty = () => {
    // console.log("Calling  _markAllDirty", initialBorrowerAddress);
    // Borrower Address
    let copyBorrowerAddress = JSON.parse(
      JSON.stringify({ ...initialBorrowerAddress })
    );

    Object.keys(initialBorrowerAddress).forEach((fieldName) => {
      copyBorrowerAddress[fieldName].isDirty = true;
    });
    const borrowerAddressValidationResult = validateAddress(
      copyBorrowerAddress,
      false
    );
    console.log(
      "borrowerAddressValidationResult >>",
      borrowerAddressValidationResult?.updatedFormfields
    );
    setInitialBorrowerAddress(
      borrowerAddressValidationResult?.updatedFormfields
    );

    // Closing Address
    let copyClosingAddress = JSON.parse(
      JSON.stringify({ ...initialClosingAddress })
    );
    Object.keys(initialClosingAddress).forEach((fieldName) => {
      copyClosingAddress[fieldName].isDirty = true;
    });
    const closingAddressValidationResult = validateAddress(
      copyClosingAddress,
      isClosingAddressRequired
    );
    setInitialClosingAddress(closingAddressValidationResult?.updatedFormfields);
    return {
      isClosingAddressValid: closingAddressValidationResult?.isFormValid,
      isBorrowerAddressFormValid: borrowerAddressValidationResult?.isFormValid,
      updatedClosingAddress: closingAddressValidationResult?.updatedFormfields,
      updatedBorrowerAddress:
        borrowerAddressValidationResult?.updatedFormfields,
    };
  };

  // Google address section

  const _googlePlaceSearch = async (field, searchValue) => {
    try {
      const googleAddressResult = await googlePlaceSearch(searchValue);
      if (field === "googleClosingSearch") {
        setGoogleClosingAddressResult(googleAddressResult);
      } else {
        setGoogleBorrowerAddressResult(googleAddressResult);
      }
    } catch (error) {
      console.error("Error fetching Google Place Search:", error);
    }
  };

  const debouncedGooglePlaceSearch = useCallback(
    debounce(async (field, value) => {
      _googlePlaceSearch(field, value);
    }, 1000),
    []
  );

  const getPlaceDetail = async (pid, type) => {
    console.log("pid >>", pid);
    try {
      const detail = await googlePlaceDetails(pid.place_id);

      const {
        address,
        city,
        county,
        state: detailState,
        country,
        postal,
        lat,
        lng,
      } = detail;

      const stateAbbreviation =
        usaStates.find((state) => state.name === detailState)?.abbreviation ||
        "";

      if (type === "googleBorrowerSearch") {
        // For borrower address
        setGoogleBorrowerSearch({ value: address, error: "" });
        const updatedFormfields = {
          ...initialBorrowerAddress,
          addressLine1: {
            ...initialBorrowerAddress?.addressLine1,
            value: address,
          },
          addressLine2: {
            ...initialBorrowerAddress?.addressLine2,
            value: "",
          },
          city: { ...initialBorrowerAddress?.city, value: city },
          county: { ...initialBorrowerAddress?.county, value: county },
          state: {
            ...initialBorrowerAddress?.state,
            value: stateAbbreviation,
          },
          country: {
            ...initialBorrowerAddress?.country,
            value: country || "",
          },
          zip: { ...initialBorrowerAddress?.zip, value: postal },
          lat: { ...initialBorrowerAddress?.lat, value: lat },
          lng: { ...initialBorrowerAddress?.lng, value: lng },
        };

        setGoogleBorrowerAddressResult([]);
        setInitialBorrowerAddress(updatedFormfields);
      } else {
        // For closing address
        setGoogleClosingSearch({
          value: address,
          error: "",
        });
        // console.log("initialClosingAddress >>", initialClosingAddress);
        const updatedFormfields = {
          ...initialClosingAddress,
          addressLine1: {
            ...initialClosingAddress?.addressLine1,
            value: address,
          },
          addressLine2: { ...initialClosingAddress?.addressLine2, value: "" },
          city: { ...initialClosingAddress?.city, value: city },
          county: { ...initialClosingAddress?.county, value: county },
          state: {
            ...initialClosingAddress?.state,
            value: stateAbbreviation,
          },
          country: {
            ...initialClosingAddress?.country,
            value: country || "",
          },
          zip: { ...initialClosingAddress?.zip, value: postal },
          lat: { ...initialClosingAddress?.lat, value: lat },
        };

        // console.log("getPlaceDetail updatedFormfields", updatedFormfields);

        setGoogleClosingAddressResult([]);
        setInitialClosingAddress(updatedFormfields);
        if (passData !== undefined) {
          passData({
            ...updatedFormfields,
          });
        }
      }
    } catch (error) {
      console.log("error >>", error);
      showToast("Error fetching place details", "error");
    }
  };

  const _handleSubmit = () => {
    const {
      isClosingAddressValid,
      isBorrowerAddressFormValid,
      updatedClosingAddress,
      updatedBorrowerAddress,
    } = _markAllDirty();

    // Update updatedClosingAddress.addressLine1 with googleClosingSearch?.value
    if (googleClosingSearch?.value) {
      updatedClosingAddress.addressLine1 = {
        ...updatedClosingAddress.addressLine1,
        value: googleClosingSearch.value,
      };
    }

    // Update updatedBorrowerAddress.addressLine1 with googleBorrowerSearch?.value
    if (googleBorrowerSearch?.value) {
      updatedBorrowerAddress.addressLine1 = {
        ...updatedBorrowerAddress.addressLine1,
        value: googleBorrowerSearch.value,
      };
    }

    return {
      isClosingAddressValid,
      isBorrowerAddressFormValid,
      closingAddressResponse: updatedClosingAddress,
      borrowerAddressResponse: updatedBorrowerAddress,
    };
  };

  const _getAddressData = () => {
    // console.log("Calling  _getAddressData", initialBorrowerAddress);
    return {
      ...initialClosingAddress,
    };
  };

  const _formatInitialValues = (data, fieldType) => {
    console.log("_formatInitialValues", fieldType, data);
    // console.log("Calling  _formatInitialValues", initialBorrowerAddress);

    const obj = {
      addressLine1: {
        value:
          typeof data?.addressLine1 === "string" ||
          typeof data?.addressLine === "string"
            ? data?.addressLine1 || data?.addressLine
            : data?.addressLine1?.value ||
              data?.line1?.value ||
              data?.street?.value ||
              data?.line1 ||
              data?.street ||
              data?.addressLine ||
              "",
      },
      addressLine2: {
        value:
          typeof data?.addressLine2 === "string"
            ? data?.addressLine2
            : data?.addressLine2?.value ||
              data?.line2?.value ||
              data?.line2 ||
              "",
      },
      country: {
        value:
          typeof data?.country === "string"
            ? data?.country
            : data?.country?.value || "",
      },
      city: {
        value:
          typeof data?.city === "string" ? data?.city : data?.city?.value || "",
      },
      state: {
        value:
          typeof data?.state === "string"
            ? data?.state
            : data?.state?.value || "",
      },
      county: {
        value:
          typeof data?.county === "string"
            ? data?.county
            : data?.county?.value || "",
      },
      zip: {
        value:
          typeof data?.zip === "string" ? data?.zip : data?.zip?.value || "",
      },
    };

    // console.log(
    //   "JSON.parse(JSON.stringify(initialObject)) >>",
    //   JSON.parse(JSON.stringify(initialObject))
    // );
    console.log("obj _formatInitialValues >>", obj);

    // Merge initialObject and obj using the spread operator
    const mergedObject = deepMergeObjects(
      JSON.parse(JSON.stringify(initialObject)),
      obj
    );

    // console.log("mergedObject >>", mergedObject);

    if (fieldType === "borrowerAddress") {
      setInitialBorrowerAddress(mergedObject);
      setGoogleBorrowerSearch({
        value:
          typeof data?.addressLine1 === "string" ||
          typeof data?.addressLine === "string"
            ? data?.addressLine1 || data?.addressLine
            : data?.addressLine1?.value ||
              data?.line1?.value ||
              data?.street?.value ||
              data?.line1 ||
              data?.street ||
              data?.addressLine ||
              "",
        error: false,
      });
    }

    if (fieldType === "closingAddress") {
      setInitialClosingAddress(mergedObject);
      setGoogleClosingSearch({
        value:
          typeof data?.addressLine1 === "string" ||
          typeof data?.addressLine === "string"
            ? data?.addressLine1 || data?.addressLine
            : data?.addressLine1?.value ||
              data?.line1?.value ||
              data?.street?.value ||
              data?.line1 ||
              data?.street ||
              data?.addressLine ||
              "",
        error: false,
      });
    }
  };

  const _toggleAddressSame = (event) => {
    // console.log("Calling  _toggleAddressSame", initialBorrowerAddress);

    setIsAppointmentAddressSame(event.target.checked);
    if (!event.target.checked) {
      setGoogleBorrowerSearch({ value: "", error: false });
      setInitialBorrowerAddress(initialObject);
    }
  };

  useEffect(() => {
    if (isAppointmentAddressSame) {
      // set initialClosingAddress to initialBorrowerAddress
      setInitialBorrowerAddress(initialClosingAddress);
      // set borrowGoogleSearch to googleClosingSearch
      setGoogleBorrowerSearch(googleClosingSearch);
    }
  }, [isAppointmentAddressSame, googleClosingSearch, initialClosingAddress]);

  useEffect(() => {
    if (closingAddress && Object.values(closingAddress).length) {
      _formatInitialValues(closingAddress, "closingAddress");
    }

    if (borrowerAddress && Object.values(borrowerAddress).length) {
      _formatInitialValues(borrowerAddress, "borrowerAddress");
    }
  }, [closingAddress, borrowerAddress]);

  useImperativeHandle(ref, () => ({
    _handleSubmit,
    _getAddressData,
  }));

  return (
    <>
      {/* Closing Address Part */}
      {Object.keys(initialClosingAddress).map((key, index) => {
        return !hideCountryInput &&
          key === "country" ? null : initialClosingAddress[key].needToShow ? (
          <Col xl={xl} lg={lg} md={md} sm={sm} key={index}>
            {key === "state" ? (
              <SelectField
                key={index}
                name={key}
                options={usaStates}
                value={initialClosingAddress[key].value}
                error={initialClosingAddress[key].error}
                isDirty={initialClosingAddress[key].isDirty}
                onChange={handleInputChange}
                fieldType="closingAddress"
                onBlur={handleInputBlur}
                label="State"
              />
            ) : key === "addressLine1" ? (
              <>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    value={googleClosingSearch?.value}
                    name="searchrchAddress"
                    placeholder=" "
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange("googleClosingSearch", event);
                      setClosingAddressDropdownShow(true);
                    }}
                    onBlur={() => {
                      setTimeout(
                        () => setClosingAddressDropdownShow(false),
                        200
                      );
                    }}
                  />
                  <Label>Appointment Address Line 1</Label>
                </FormGroup>
                {googleAddressClosingResult &&
                googleAddressClosingResult.length &&
                closingAddressDropdownShow ? (
                  <ListGroup flush className="customSearchOptions">
                    {googleAddressClosingResult.map((addr, index) => {
                      return (
                        <ListGroupItem
                          key={addr.place_id || index}
                          className="cursorPointer"
                          onClick={() =>
                            getPlaceDetail(addr, "googleClosingSearch")
                          }
                        >
                          {addr.description}
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                ) : null}
              </>
            ) : (
              <>
                <FormGroup className="floatingLabel">
                  <InputField
                    key={index}
                    name={key}
                    value={initialClosingAddress[key].value}
                    placeholder={closingPlaceholders[key]}
                    type="text"
                    onChange={handleInputChange}
                    fieldType="closingAddress"
                    onBlur={handleInputBlur}
                  />
                  {initialClosingAddress[key].error?.length &&
                  initialClosingAddress[key].isDirty ? (
                    <span className={"validation-error"}>
                      {initialClosingAddress[key].error}
                    </span>
                  ) : null}
                </FormGroup>
              </>
            )}
          </Col>
        ) : null;
      })}

      <Col sm="12" className="mt-2 mb-3">
        <CustomInput
          type="checkbox"
          id="isBorrowerAddressSameAsAppointment_checkbox"
          label="Signing & Property Address Same Location"
          checked={isAppointmentAddressSame}
          onChange={(event) => _toggleAddressSame(event)}
        />
      </Col>

      {/* Borrower Address Part */}
      {Object.keys(initialBorrowerAddress).map((key, index) => {
        return !hideCountryInput &&
          key === "country" ? null : initialBorrowerAddress[key].needToShow ? (
          <Col xl={xl} lg={lg} md={md} sm={sm} key={index}>
            {key === "state" ? (
              <>
                <SelectField
                  key={index}
                  name={key}
                  options={usaStates}
                  value={initialBorrowerAddress[key].value}
                  error={initialBorrowerAddress[key].error}
                  isDirty={initialBorrowerAddress[key].isDirty}
                  onChange={handleInputChange}
                  fieldType="borrowerAddress"
                  onBlur={handleInputBlur}
                  label="State"
                />
              </>
            ) : key === "addressLine1" ? (
              <>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    value={googleBorrowerSearch?.value}
                    name="searchrchAddress"
                    placeholder=" "
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange("googleBorrowerSearch", event);
                      setBorrowerAddressDropdownShow(true);
                    }}
                    onBlur={() => {
                      setTimeout(
                        () => setBorrowerAddressDropdownShow(false),
                        200
                      );
                    }}
                    disabled={isAppointmentAddressSame}
                  />
                  <Label> Property Address Line 1</Label>
                </FormGroup>
                {googleAddressBorrowerResult &&
                googleAddressBorrowerResult.length &&
                borrowerAddressDropdownShow ? (
                  <ListGroup flush className="customSearchOptions">
                    {googleAddressBorrowerResult.map((addr, index) => {
                      return (
                        <ListGroupItem
                          key={addr.place_id || index}
                          className="cursorPointer"
                          onClick={() =>
                            getPlaceDetail(addr, "googleBorrowerSearch")
                          }
                        >
                          {addr.description}
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                ) : null}
              </>
            ) : (
              <>
                <FormGroup className="floatingLabel">
                  <InputField
                    key={index}
                    name={key}
                    value={initialBorrowerAddress[key].value}
                    placeholder={borrowerPlaceholders[key]}
                    type="text"
                    onChange={handleInputChange}
                    fieldType="borrowerAddress"
                    onBlur={handleInputBlur}
                    disabled={isAppointmentAddressSame}
                  />
                </FormGroup>
                {initialBorrowerAddress[key].error?.length &&
                initialBorrowerAddress[key].isDirty ? (
                  <span className={"validation-error"}>
                    {initialBorrowerAddress[key].error}
                  </span>
                ) : null}
              </>
            )}
          </Col>
        ) : null;
      })}
    </>
  );
});

export default memo(CreateClosingAddressComponent);

CreateClosingAddressComponent.propTypes = {
  lg: PropTypes.number,
  md: PropTypes.number,
  xl: PropTypes.number,
  sm: PropTypes.number,
  hideCountryInput: PropTypes.bool,
  borrowerAddress: PropTypes.object,
  closingAddress: PropTypes.object,
  // closingGoogleAddress: PropTypes.object,
  // borrowerGoogleAddress: PropTypes.object,
  isClosingAddressRequired: PropTypes.bool,
  passData: PropTypes.func,
};

CreateClosingAddressComponent.defaultProps = {
  lg: 6,
  md: 6,
  xl: 6,
  sm: 12,
  hideCountryInput: false,
};
