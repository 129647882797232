/**
 * To communicate through events
 */
export const HeaderEventEmitter = {
  events: {},
  dispatch: function (event, data = null) {
    console.log("datat", data);
    try {
      // Check if the specified event is exist / subscribed by anyone
      if (!this.events[event]) {
        // Doesn't exist, so just return
        return;
      }

      // Exists
      // Process all bound callbacks
      this.events[event](data);
    } catch (error) {
      // Doesn't exist, so just return
      return;
    }
  },
  subscribe: function (event, callback) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Not subscribed yet, so make it an array so that further callbacks can be pushed
      this.events[event] = null;
    }

    // Push the current callback
    this.events[event] = callback;
  },
  unsubscribe: function (event) {
    // reset event
    this.events[event] = null;
  },
};
console.log("header", HeaderEventEmitter.events);
// export const subscribeResetFilterEvent = (callback) => {
//   HeaderEventEmitter.subscribe("reset-filter", callback);
// };

// export const unsubscribeResetFilterEvent = () => {
//   HeaderEventEmitter.unsubscribe("reset-filter");
// };

// export const dispatchResetFilterEvent = () => {
//   HeaderEventEmitter.dispatch("reset-filter");
// };
