import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";
import useScreenWidth from "../../HelpModule/useScreenWidth";

const SkeletonLoadingAgentSettings = ({ borderRadius = 8, count = 1 }) => {
  const { isForMobile } = useScreenWidth();

  return (
    <Row>
      {Array.from({ length: count }).map((_, index) => (
        <Col md={12} key={`SkeletonSettingsBox_${index}`}>
          <>
            <div
              className="settings-skeleton-box p-3 mb-3"
              style={{
                borderRadius,
                border: "1px solid #ddd",
                padding: "20px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Skeleton
                height={isForMobile ? 90 : 60}
                width="100%"
                className="mb-1"
              />
              <Skeleton height={370} width="100%" className="mb-2" />
            </div>

            <div
              className="settings-skeleton-box p-3 mb-3"
              style={{
                borderRadius,
                border: "1px solid #ddd",
                padding: "20px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Skeleton
                height={isForMobile ? 90 : 60}
                width="100%"
                className="mb-1"
              />
              <Skeleton height={170} width="100%" className="mb-2" />
            </div>
          </>
        </Col>
      ))}
    </Row>
  );
};

export default SkeletonLoadingAgentSettings;
