import { memo } from "react";
import {
  formatDateAsPerTimeZOne,
  formatDateOnlyAsPerTimeZone,
  getTimeZoneAbbr,
} from "../../../helper-methods";

const DateTimeShowWithClosingDetails = memo(({ closingDetails }) => {
  return closingDetails?.isRangeDated ? (
    // this is only for Date Range
    <>
      {formatDateAsPerTimeZOne(
        closingDetails?.appointmentDate,
        closingDetails?.closingAddress?.timeZone
      )}{" "}
      -{" "}
      {formatDateAsPerTimeZOne(
        closingDetails?.endAppointmentDate,
        closingDetails?.closingAddress?.timeZone
      )}
    </>
  ) : (
    // this is Regular or TBD
    <>
      {closingDetails?.tBD
        ? // show only date
          formatDateOnlyAsPerTimeZone(
            closingDetails?.appointmentDate,
            closingDetails?.closingAddress?.timeZone
          )
        : // for regular show date & time
          formatDateAsPerTimeZOne(
            closingDetails?.appointmentDate,
            closingDetails?.closingAddress?.timeZone
          )}{" "}
      {closingDetails?.tBD ? <b>TBD</b> : null}
    </>
  );
});

const DateTimeShowWithCellRow = memo(({ cell, row }) =>
  row.isRangeDated ? (
    // this is only for Date Range
    <span className="dateTimeFormat">
      {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)},{" "}
      <span>{getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}</span>-{" "}
      {formatDateAsPerTimeZOne(
        row.endAppointmentDate,
        row.closingAddress?.timeZone
      )}
      ,{" "}
      <span>
        {getTimeZoneAbbr(row.endAppointmentDate, row.closingAddress?.timeZone)}
      </span>
    </span>
  ) : (
    // this is Regular or TBD
    <span className="dateTimeFormat">
      {row.tBD
        ? formatDateOnlyAsPerTimeZone(cell, row.closingAddress?.timeZone)
        : formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)}
      , <span>{getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}</span>{" "}
      {row.tBD ? <b>TBD</b> : null}
    </span>
  )
);

export { DateTimeShowWithCellRow, DateTimeShowWithClosingDetails };
