import OneSignal from "react-onesignal";

export default class OneSignalHelper {
  static getDeviceId = () => {
    return new Promise((resolve) => {
      try {
        resolve(OneSignal?.User?.PushSubscription?.id);
      } catch (error) {
        resolve(null);
        console.log({ error });
      }
    });
  };
}
