import React, { useState, useEffect, lazy, Suspense } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import {
  checkPermission,
  errorHandler,
  isObjectPresent,
  showToast,
} from "../../../helper-methods";
import {
  companyGetAllProductTypes,
  getAllCompanySettings,
  updateSigningCompanySetting,
} from "../../../http/http-calls";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import { LoanTypeNotOthers } from "../../../config/loan-types";
import { addSettings } from "../../../redux/actions/settings";
import SkeletonLoading from "../components/Skeletons/SkeletonLoading";

const CustomProductType = lazy(() =>
  import("../components/Modals/Company/CustomProductType")
);
const Quickbooksetting = lazy(() => import("./quickbooksetting"));
const AccountingAutomation = lazy(() => import("./accountingAutomation"));
// const EmailCustomization = lazy(() =>
//   import("../components/emailCustomization")
// );
const JobsSettings = lazy(() => import("../components/jobsSettings"));
const FeesSettings = lazy(() => import("../components/feesSettings"));
const OrderAutomationSettings = lazy(() =>
  import("../components/OrderAutomationSettings")
);
const NotificationSettings = lazy(() =>
  import("../components/NotificationSettings")
);

const Settings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState([]);
  const [loanType, setLoanType] = useState([]);
  const [productTypes, setproductTypes] = useState({});
  const [customProductTypes, setCustomProductTypes] = useState([]);
  const [companySettings, setCompanySettings] = useState({});

  const signingCompany = useSelector((state) => state);
  const dispatch = useDispatch();
  const _toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (isObjectPresent("settings") === false) {
      _getAllCompanySettings();
    } else {
      //add this bcz when this page api not called then data need to set in state from redux
      setCompanySettings(signingCompany?.settings);
    }
    _companyGetAllProductTypes();
    setClient(signingCompany.list.clients);

    loanTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      signingCompany?.userData?.type === "signingcompany" &&
      signingCompany?.userData?.user?.isAssistant
    ) {
      setActiveTab("6");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loanTypes = () => {
    let options = LoanTypeNotOthers.map((item) => ({
      value: item,
      label: item.replace(/([A-Z])/g, " $1").trim(),
    }));

    options.splice(0, 0, { value: "", label: "Select" }); // appending default 'Select' option at start
    setLoanType(options);
  };

  const _handleOnSubmit = async (payload) => {
    try {
      setLoading(true);
      let response = await updateSigningCompanySetting(payload);
      _getAllCompanySettings();
      dispatch(updateUserObj(response?.signingCompany));
      showToast("Updated Successfully", "success");
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  // get company settings data from backend
  const _getAllCompanySettings = async (payload) => {
    if (payload) {
      try {
        setLoading(true);
        let response = await getAllCompanySettings(payload);

        setCompanySettings(response?.companySetting);
        dispatch(addSettings(response?.companySetting));
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        let response = await getAllCompanySettings();

        setCompanySettings(response?.companySetting);
        dispatch(addSettings(response?.companySetting));

        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    }
  };

  // get list of all products from backend
  const _companyGetAllProductTypes = async () => {
    try {
      setLoading(true);
      const res = await companyGetAllProductTypes();
      if (res?.customProductTypes) {
        setCustomProductTypes(res?.customProductTypes);
        _setData(res?.customProductTypes);
      }
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _setData = (data) => {
    // filtering out and storing the custom product types from all products list
    let products =
      (data?.products?.length > 0 &&
        data?.products?.filter((each) => each.isCustomAdded)) ||
      [];

    setproductTypes(products);
  };

  return (
    <div className="content">
      <div className="responsiveTitle">
        <h2>Settings</h2>
      </div>
      <div className="verticalTabs">
        <Nav tabs>
          {checkPermission("settings", "canViewOrEditOrderAutomation") ? (
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => _toggleTab("1")}
              >
                Order Automations
              </NavLink>
            </NavItem>
          ) : null}
          {checkPermission("settings", "canViewOrEditJobsAndFees") ? (
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => _toggleTab("2")}
              >
                Jobs
              </NavLink>
            </NavItem>
          ) : null}
          {checkPermission("settings", "canViewOrEditJobsAndFees") ? (
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => _toggleTab("3")}
              >
                Fees
              </NavLink>
            </NavItem>
          ) : null}
          <NavItem>
            <NavLink
              className={activeTab === "4" ? "active" : ""}
              onClick={() => _toggleTab("4")}
            >
              Custom Product Type
            </NavLink>
          </NavItem>

          {!signingCompany?.userData?.user?.isTitleCompany && (
            <NavItem>
              <NavLink
                className={activeTab === "5" ? "active" : ""}
                onClick={() => _toggleTab("5")}
              >
                Quickbooks Settings
              </NavLink>
            </NavItem>
          )}
          {/* {checkPermission("settings", "canViewOrEditEmailCustomization") ? (
            <NavItem>
              <NavLink
                className={activeTab === "6" ? "active" : ""}
                onClick={() => _toggleTab("6")}
              >
                Email Customization
              </NavLink>
            </NavItem>
          ) : null} */}
          {!signingCompany?.userData?.user?.isTitleCompany &&
          checkPermission("settings", "canViewOrEditAccountingAutomation") ? (
            <NavItem>
              <NavLink
                className={activeTab === "7" ? "active" : ""}
                onClick={() => _toggleTab("7")}
              >
                Accounting Automation
              </NavLink>
            </NavItem>
          ) : null}
          <NavItem>
            <NavLink
              className={activeTab === "8" ? "active" : ""}
              onClick={() => _toggleTab("8")}
            >
              Notifications
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          {/* order automation settings component for bid , response & assignment handling */}
          <TabPane tabId="1">
            <Suspense fallback={<></>}>
              {loading ? (
                <SkeletonLoading type="companySettings" count={1} />
              ) : (
                <OrderAutomationSettings
                  bidAutomationSetting={companySettings?.bidAutomationSetting}
                  handleOnSubmit={_handleOnSubmit}
                  loading={loading}
                />
              )}
            </Suspense>
          </TabPane>
          {/* job settings component  for instructions,scan back required, fees*/}
          <TabPane tabId="2">
            <Suspense fallback={<></>}>
              <JobsSettings
                client={client}
                loanType={loanType}
                getAllCompanySettings={_getAllCompanySettings}
                changeToTab={() => _toggleTab("3")}
                companySettings={companySettings}
              />
            </Suspense>
          </TabPane>
          <TabPane tabId="3">
            <Suspense fallback={<></>}>
              <FeesSettings
                loading={loading}
                companySettings={companySettings}
                getAllCompanySettings={_getAllCompanySettings}
                customProductTypes={customProductTypes}
              />
            </Suspense>
          </TabPane>
          <TabPane tabId="4">
            <Suspense fallback={<></>}>
              <CustomProductType
                companyGetAllProductTypes={_companyGetAllProductTypes}
                customProductTypes={productTypes}
              />
            </Suspense>
          </TabPane>
          {!signingCompany?.userData?.user?.isTitleCompany && (
            <TabPane tabId="5">
              <Suspense fallback={<></>}>
                <Quickbooksetting />
              </Suspense>
            </TabPane>
          )}
          {/* <TabPane tabId="6">
            <Suspense fallback={<></>}>
              <EmailCustomization />
            </Suspense>
          </TabPane> */}
          <TabPane tabId="7">
            <Suspense fallback={<></>}>
              <AccountingAutomation
                companySettings={companySettings}
                getAllCompanySettings={_getAllCompanySettings}
              />
            </Suspense>
          </TabPane>
          <TabPane tabId="8">
            <Suspense fallback={<></>}>
              <NotificationSettings />
            </Suspense>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default Settings;
