import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { formatCurrencyValue } from "../../../helper-methods";
import SkeletonLoading from "./Skeletons/SkeletonLoading";

const AgentNotarialLogCard = ({
  totalNotarialActs,
  totalAmount,
  totalAgentFee,
  loading,
  isForMobile,
}) => {
  return (
    <>
      <Row className="justify-content-center justify-content-xl-start">
        <Col
          xl={3}
          lg={6}
          md={4}
          sm={6}
          xs={6}
          className={`mb-${loading ? 3 : 0}`}
        >
          {loading ? (
            <SkeletonLoading
              type="card"
              count={1}
              height={isForMobile ? 130 : 76}
              width={isForMobile ? 160 : 250}
            />
          ) : (
            <Card className="infoCard">
              <CardBody>
                <div className="infoContent">
                  <span>Notarial Acts</span>
                  <h4>
                    {" "}
                    {totalNotarialActs !== undefined
                      ? totalNotarialActs
                      : "N/A"}
                  </h4>
                </div>
                <div className="icon">
                  <img
                    src={require("../../../assets/img/notaryActs.png")}
                    alt="notaryActs"
                  />
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
        <Col
          xl={3}
          lg={6}
          md={4}
          sm={6}
          xs={6}
          className={`mb-${loading ? 3 : 0}`}
        >
          {loading ? (
            <SkeletonLoading
              type="card"
              count={1}
              height={isForMobile ? 130 : 76}
              width={isForMobile ? 160 : 250}
            />
          ) : (
            <Card className="infoCard">
              <CardBody>
                <div className="infoContent">
                  <span>Total Potential</span>
                  <h4>
                    {totalAmount !== undefined
                      ? formatCurrencyValue(totalAgentFee)
                      : "N/A"}
                  </h4>
                </div>
                <div className="icon">
                  <img
                    src={require("../../../assets/img/potential.png")}
                    alt="notaryActs"
                  />
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
        <Col
          xl={3}
          lg={6}
          md={4}
          sm={12}
          xs={12}
          className={`mb-${loading ? 3 : 0}`}
        >
          {loading ? (
            <SkeletonLoading
              type="card"
              count={1}
              height={isForMobile ? 130 : 76}
              width={isForMobile ? 350 : 250}
            />
          ) : (
            <Card className="infoCard">
              <CardBody>
                <div className="infoContent">
                  <span>Total(Not to exceed order total)</span>
                  <h4>
                    {" "}
                    {totalAmount !== undefined
                      ? formatCurrencyValue(totalAmount)
                      : "N/A"}
                  </h4>
                </div>
                <div className="icon">
                  <img
                    src={require("../../../assets/img/totalAmount.png")}
                    alt="notaryActs"
                  />
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AgentNotarialLogCard;
