import {
  ADD_USER_DATA,
  UPDATE_USER_OBJ,
  UPDATE_ASSISTANT_OBJ,
  CLEAR_USER_DATA,
} from "./action-types";

export const addUserData = (userData) => {
  return {
    type: ADD_USER_DATA,
    payload: {
      userData,
    },
  };
};

export const updateUserObj = (user) => {
  return {
    type: UPDATE_USER_OBJ,
    payload: {
      user,
    },
  };
};

export const updateAssistantObj = (assistant) => {
  return {
    type: UPDATE_ASSISTANT_OBJ,
    payload: {
      assistant,
    },
  };
};

export const clearUserData = () => {
  return {
    type: CLEAR_USER_DATA,
  };
};
