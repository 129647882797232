import React, { Component } from "react";
import { UploadQueueManager } from "../../../upload-queue-manager";
import { Spinner } from "reactstrap";

class MediaQueueViewer extends Component {
  state = { queueCount: 0 };
  componentDidMount() {
    UploadQueueManager.onQueueCountChange((queueCount) => {
      this.setState({ queueCount });
    });
  }
  render() {
    const { queueCount } = this.state;
    // console.log(queueCount)
    return (
      <>
        {queueCount > 0 ? (
          <div id="mediaQueueViewerWrapper">
            <div id="uploadeProgressHeader">
              <Spinner
                style={{
                  width: "1.3rem",
                  height: "1.3rem",
                  marginRight: 12,
                }}
              />
              {queueCount > 1
                ? `${queueCount} Uploads in progress`
                : `${queueCount} Upload in progress`}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default MediaQueueViewer;
