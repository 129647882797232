import React, { Suspense, lazy, useState } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

const UserProfile = lazy(() => import("./user-profile"));
const AchSettings = lazy(() => import("./ach-settings"));
const ClientProfileDetail = lazy(() =>
  import("../components/ClientProfileDetail")
);

const ClientProfile = () => {
  const userData = useSelector((state) => state?.userData);

  const [activeTab, setActiveTab] = useState("1");

  const _toggleTab = (tab = "1") => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  if (userData?.user?.isAssistant) {
    return (
      <div className="content">
        <Suspense fallback={<></>}>
          <UserProfile />
        </Suspense>
      </div>
    );
  }

  return (
    <div className="content">
      <div className="responsiveTitle">
        <h2>Client Profile </h2>
      </div>
      <div className="verticalTabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => {
                _toggleTab("1");
              }}
            >
              Basic Details
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => {
                _toggleTab("2");
              }}
            >
              Bank Info
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Suspense fallback={<></>}>
              <ClientProfileDetail />
            </Suspense>
          </TabPane>
          <TabPane tabId="2">
            <Suspense fallback={<></>}>
              <AchSettings />
            </Suspense>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default ClientProfile;
