import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../../../config";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  errorHandler,
  showToast,
  splitName,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  createLenderClient,
  // getOrderClientById,
  updateLenderClient,
} from "../../../http/http-calls";

const initalFormFields = {
  name: "",
  picUrl: {
    uploadData: null,
    previewBlob: null,
    type: "image",
    uploadUrl: null,
  },
  contactInfo: [
    {
      pointOfContact: "",
      contactNumber: "",
      contactEmail: "",
    },
  ],
};

const AddOrderClient = ({ isOpen, data, toggle, onSuccess, tableConfig }) => {
  const [formFields, setFormFields] = useState(initalFormFields);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModelState = (data = null) => {
    console.log("data", data);
    let contactInfo = [];

    if (data?.contactInfo?.length) {
      data?.contactInfo?.forEach((each) => {
        contactInfo.push({
          contactNumber: each.contactNumber || "",
          pointOfContact: each.pointOfContact || "",
          contactEmail: each.contactEmail || "",
        });
      });
    }

    if (!contactInfo?.length) {
      contactInfo = [
        {
          pointOfContact: "",
          contactNumber: "",
          contactEmail: "",
        },
      ];
    }

    const newFormFields = { ...formFields };

    newFormFields["name"] = data?.name?.full?.trim() || "";
    newFormFields["picUrl"] = {
      uploadData: null,
      previewBlob: null,
      type: "image",
      uploadUrl: data?.picUrl || null,
    };

    newFormFields["contactInfo"] = contactInfo;

    setFormFields(newFormFields);
    setErrors({});
    setIsDirty({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModelState();
    toggle();
  };

  useEffect(() => {
    if (isOpen && data) {
      // _getOrderClientById(data.id);
      _setModelState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const _validateFormFields = ({
    newFormFields,
    newIsDirty,
    isValidateAll = false,
  }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields)?.forEach((key) => {
        if (newIsDirty[key] || isValidateAll) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.trim()?.length) {
                if (
                  newFormFields[key]?.trim()?.length > 1 &&
                  /^[0-9!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(
                    String(newFormFields[key]).toLowerCase()
                  ) //eslint-disable-line
                ) {
                  newErrors[key] =
                    "*Name containing only number and special character are not allowed";
                  isFormValid = false;
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      newFormFields?.contactInfo?.forEach((contact, index) => {
        if (newIsDirty[`pointOfContact_${index}`] || isValidateAll) {
          if (contact.pointOfContact?.trim().length) {
            if (
              RegexConfig.name.test(
                String(contact.pointOfContact).toLowerCase()
              )
            ) {
              newErrors[`pointOfContact_${index}`] = null;
              newIsDirty[`pointOfContact_${index}`] = false;
            } else {
              newErrors[`pointOfContact_${index}`] =
                "*Minimum 2 characters & Maximum 25 characters, number and special character not allowed except '";
              isFormValid = false;
            }
          } else {
            newErrors[`pointOfContact_${index}`] = null;
            newIsDirty[`pointOfContact_${index}`] = false;
          }
        }
        if (newIsDirty[`contactNumber_${index}`] || isValidateAll) {
          if (contact.contactNumber?.trim().length) {
            if (
              RegexConfig.phone.test(
                String(contact.contactNumber).toLowerCase()
              )
            ) {
              newErrors[`contactNumber_${index}`] = null;
              newIsDirty[`contactNumber_${index}`] = false;
            } else {
              newErrors[`contactNumber_${index}`] = "*Invalid phone number";
              isFormValid = false;
            }
          } else {
            newErrors[`contactNumber_${index}`] = "*Required";
            isFormValid = false;
          }
        }
        if (newIsDirty[`contactEmail_${index}`] || isValidateAll) {
          if (contact.contactEmail?.trim().length) {
            if (
              RegexConfig.email.test(String(contact.contactEmail).toLowerCase())
            ) {
              newErrors[`contactEmail_${index}`] = null;
              newIsDirty[`contactEmail_${index}`] = false;
            } else {
              newErrors[`contactEmail_${index}`] = "*Invalid email";
              isFormValid = false;
            }
            let ind = newFormFields.contactInfo.findIndex(
              (item) => item.contactEmail === contact.contactEmail
            );
            if (ind !== index) {
              newErrors[`contactEmail_${index}`] = `*Dupliate Email found at ${
                ind + 1
              }`;
              isFormValid = false;
            }
          } else {
            newErrors[`contactEmail_${index}`] = "*Required";
            isFormValid = false;
          }
        }
      });

      setIsDirty(newIsDirty);
      setErrors(newErrors);

      resolve(isFormValid);
    });
  };

  const _addContactInfo = () => {
    const newFormFields = { ...formFields };

    newFormFields.contactInfo.push({
      pointOfContact: "",
      contactNumber: "",
      contactEmail: "",
    });

    setFormFields(newFormFields);
  };

  const _removeContactInfo = (index) => {
    const newFormFields = { ...formFields };

    newFormFields.contactInfo.splice(index, 1);

    setFormFields(newFormFields);
  };

  const _onChangeFormFields = (key, value, subKey, index) => {
    const newFormFields = { ...formFields };

    if (key === "contactInfo") {
      newFormFields[key][index][subKey] = value;
    } else {
      newFormFields[key] = value;
    }

    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };

    newIsDirty[key] = true;

    setIsDirty(newIsDirty);

    _validateFormFields({ newFormFields, newIsDirty, isValidateAll: false });
  };

  const _onChangeAvatar = (e) => {
    try {
      if (e?.target?.files?.length) {
        const newFormFields = { ...formFields };

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          newFormFields.picUrl["uploadData"] = file;
          newFormFields.picUrl["previewBlob"] = previewBlob;
          newFormFields.picUrl["type"] = fileType;
          newFormFields.picUrl["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        setFormFields(newFormFields);
      }
    } catch (error) {
      errorHandler();
    }
  };

  const _onSubmit = async () => {
    try {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
        isValidateAll: true,
      });

      if (!isFormValid) return;

      setLoading(true);

      const { first, last } = splitName(newFormFields?.name?.trim());

      const payload = {
        name: {
          first,
          last,
        },
        picUrl: "",
        contactInfo: [...newFormFields.contactInfo],
      };

      if (newFormFields?.picUrl?.uploadData) {
        const response = await uploadFileOnServer([
          { ...newFormFields.picUrl },
        ]);

        payload["picUrl"] = response[0].url;

        newFormFields.picUrl["uploadUrl"] = response[0].url;
        newFormFields.picUrl["uploadData"] = null;

        setFormFields(newFormFields);
      } else if (newFormFields?.picUrl?.uploadUrl) {
        payload["picUrl"] = newFormFields?.picUrl?.uploadUrl;
      }

      if (data?.id) {
        await updateLenderClient({ id: data?.id, payload });
        showToast("Client has been updated", "success");
      } else {
        await createLenderClient(payload);
        showToast("Client has been created", "success");
      }

      if (onSuccess) {
        onSuccess(tableConfig);
      }

      _closeModal();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      scrollable
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data ? "Edit" : "Add"} Client
      </ModalHeader>
      <ModalBody>
        <Form>
          <Label className="uploadProfile">
            <Input
              type="file"
              style={{ display: "none" }}
              accept="image/x-png,image/gif,image/jpeg"
              value=""
              onChange={(e) => _onChangeAvatar(e)}
              disabled={loading}
            />
            <img
              src={
                formFields?.picUrl?.previewBlob ||
                formFields?.picUrl?.uploadUrl ||
                DEFAULT_COVER_PICTURE
              }
              alt="Profile Img"
            />
            <i className="fa fa-pencil" />
          </Label>

          <FormGroup className="floatingLabel">
            <Input
              type="text"
              placeholder=" "
              name="name"
              value={formFields?.name}
              onChange={(e) => _onChangeFormFields("name", e.target.value)}
              onBlur={() => _onBlurFormFields("name")}
              disabled={loading}
            />
            <Label>Client Name</Label>
            {errors["name"] ? (
              <div className="validation-error">{errors["name"]}</div>
            ) : null}
          </FormGroup>

          {formFields?.contactInfo?.map((contact, index) => (
            <>
              <div className="clientAdditionalContacts" key={index}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    placeholder=" "
                    name="name"
                    value={contact?.pointOfContact}
                    onChange={(e) =>
                      _onChangeFormFields(
                        "contactInfo",
                        e.target.value,
                        "pointOfContact",
                        index
                      )
                    }
                    onBlur={() => _onBlurFormFields(`pointOfContact_${index}`)}
                    disabled={loading}
                  />
                  <Label>Point of contact</Label>
                  {errors[`pointOfContact_${index}`] ? (
                    <div className="validation-error">
                      {errors[`pointOfContact_${index}`]}
                    </div>
                  ) : null}
                </FormGroup>

                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>+1</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder=" "
                      name="contactNumber"
                      value={contact?.contactNumber}
                      onChange={(e) =>
                        _onChangeFormFields(
                          "contactInfo",
                          e.target.value,
                          "contactNumber",
                          index
                        )
                      }
                      onBlur={() => _onBlurFormFields(`contactNumber_${index}`)}
                      disabled={loading}
                    />
                    <Label>Contact Number</Label>
                  </InputGroup>
                  {errors[`contactNumber_${index}`] ? (
                    <div className="validation-error">
                      {errors[`contactNumber_${index}`]}
                    </div>
                  ) : null}
                </FormGroup>

                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    placeholder=" "
                    name="email"
                    value={contact?.contactEmail}
                    onChange={(e) =>
                      _onChangeFormFields(
                        "contactInfo",
                        e.target.value,
                        "contactEmail",
                        index
                      )
                    }
                    onBlur={() => _onBlurFormFields(`contactEmail_${index}`)}
                    disabled={loading}
                  />
                  <Label>Contact Email</Label>
                  {errors[`contactEmail_${index}`] ? (
                    <div className="validation-error">
                      {errors[`contactEmail_${index}`]}
                    </div>
                  ) : null}
                </FormGroup>
                {formFields?.contactInfo?.length > 1 ? (
                  <Button
                    color="danger"
                    className="btn-round"
                    outline
                    disabled={loading}
                    onClick={() => _removeContactInfo(index)}
                  >
                    Delete
                  </Button>
                ) : null}
              </div>
              {formFields?.contactInfo?.length - 1 === index ? (
                <div className="text-center mb-2 mt-2">
                  <Button
                    color="link"
                    className="fs-12"
                    onClick={() => _addContactInfo()}
                  >
                    +Add Additional Contacts
                  </Button>
                </div>
              ) : null}
            </>
          ))}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => _onSubmit()}
          disabled={loading}
          size="lg"
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
          {data ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddOrderClient;
