import { capitalize } from "@material-ui/core";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import {
  checkPermission,
  errorHandler,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  getAllClientUsers,
  updateClientStatusUser,
} from "../../../http/http-calls";
import useScreenWidth from "../components/HelpModule/useScreenWidth";
import {
  usersCardHeaderKeys,
  usersHeaderKeys,
} from "../../../config/stateConfig";

const ClientAddUserModal = lazy(() =>
  import("../components/ClientAddUserModal")
);
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));

const ClientTeamMemberDataFormat = lazy(() =>
  import("../components/ClientTeamMemberDataFormat")
);
const ClientTeamMemberCardDataFormat = lazy(() =>
  import("../components/ClientTeamMemberCardDataFormat")
);

const ClientTeamMember = () => {
  const history = useHistory();
  const { isForMobile } = useScreenWidth();

  const [users, setUsers] = useState({
    usersData: [],
    usersTotalCount: 0,
  });

  const [clientAddUserModal, setClientAddUserModal] = useState({
    isOpen: false,
    data: null,
  });

  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
    sort: {
      sortBy: "createdAt",
      sortOrder: "desc",
    },
  });

  const [loading, setLoading] = useState({
    statusChangeLoading: false,
    showTableLoading: false,
  });

  const _onToggleClientAddUserModal = (isOpen = false, data = null) => {
    setClientAddUserModal({ isOpen, data });
  };

  const _manageLoading = (loaderName, value) => {
    setLoading({
      ...loading,
      [loaderName]: value,
    });
  };

  useEffect(() => {
    _setFilterDataFromLocalStorage();

    HeaderEventEmitter.subscribe("add-team", () => {
      _onToggleClientAddUserModal(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get list of teamMembers
  const _getAllClientUsers = async (newTableConfig) => {
    try {
      _manageLoading("showTableLoading", true);

      let data = {
        skip: newTableConfig.skip,
        limit: newTableConfig.limit,
      };

      const res = await getAllClientUsers(data);

      _manageLoading("showTableLoading", false);

      setUsers({
        usersData: res?.users,
        usersTotalCount: res?.totalCount,
      });
    } catch (error) {
      errorHandler(error);
      _manageLoading("showTableLoading", false);
    }
  };

  //This function used for team member status update
  const _userStatusUpdate = async (data, value) => {
    try {
      _manageLoading("statusChangeLoading", true);

      const payload = {
        isActive: value === "active" ? true : false,
      };

      await updateClientStatusUser({ id: data.id, payload });

      _getAllClientUsers(tableConfig);
      showToast("Status has been updated", "success");
      _manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      _manageLoading("statusChangeLoading", false);
    }
  };

  //This function used for handle pagination
  const _paginate = (pageNumber, pageSize) => {
    const newTableConfig = { ...tableConfig };

    newTableConfig.skip = (pageNumber - 1) * pageSize;
    newTableConfig.limit = pageSize;
    newTableConfig["pageNumber"] = pageNumber;

    setTableConfig(newTableConfig);

    _persistFilter({ newTableConfig });
    _getAllClientUsers(newTableConfig);
  };

  const _onSortChange = (sortName, sortOrder) => {
    _paginate(1, 10);
  };

  //This function used for permission count show
  const _calculatePermissionCount = (permissionSettings) => {
    let count = 0;
    permissionSettings !== undefined &&
      Object.entries(permissionSettings).forEach(([key, value]) => {
        Object.keys(value).forEach(function (key) {
          if (value[key] === true) count += 1;
        });
      });
    return count;
  };

  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div
              className="tableUserInfo"
              onClick={() =>
                row?._company?._id
                  ? history.push(
                      `/client/signing-company-details/${row._company._id}`
                    )
                  : {}
              }
            >
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._company?.logo
                      ? row?._company?.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="signerName">
                  {row?.name ? capitalize(row?.name?.full) : "N/A"}
                </span>
              </div>
            </div>

            <Button
              title="Edit"
              color="link"
              onClick={() => _onToggleClientAddUserModal(true, row)}
              disabled={!checkPermission("users", "canUpdate")}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
                loading="lazy"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  //For mobile view
  const _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <ClientTeamMemberCardDataFormat
          row={row}
          _calculatePermissionCount={_calculatePermissionCount}
          loading={loading}
          _userStatusUpdate={_userStatusUpdate}
        />
      </Suspense>
    );
  };
  // For table view
  const _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <ClientTeamMemberDataFormat
          header={header}
          cell={cell}
          row={row}
          _calculatePermissionCount={_calculatePermissionCount}
          loading={loading}
          _userStatusUpdate={_userStatusUpdate}
          _onToggleClientAddUserModal={_onToggleClientAddUserModal}
        />
      </Suspense>
    );
  };

  //This function used for manage local storage
  const _persistFilter = ({ newTableConfig }) => {
    if (newTableConfig.pageNumber !== 1) {
      let data = { tableConfig: newTableConfig };
      localStorage.clientTeamMembersPage = JSON.stringify(data);
    } else {
      delete localStorage.clientTeamMembersPage;
    }
  };

  //This function used for localstorage data set in filters state
  const _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.clientTeamMembersPage) {
      try {
        const filters = JSON.parse(localStorage.clientTeamMembersPage);

        const newTableConfig = filters.tableConfig;
        setTableConfig(newTableConfig);

        _getAllClientUsers(newTableConfig);
      } catch (e) {
        console.log("error", e);
        _getAllClientUsers(tableConfig);
      }
    } else {
      _getAllClientUsers(tableConfig);
    }
  };

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>Team Members</h2>

          <div className="rightSide">
            <Button
              color="primary"
              className="floatingButton"
              onClick={() => _onToggleClientAddUserModal(true)}
            >
              Add Team Members
            </Button>
          </div>
        </div>

        {isForMobile ? (
          <div className="hideDesktop">
            <Suspense fallback={<></>}>
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={users?.usersData}
                headerKeys={usersCardHeaderKeys}
                dataFormat={_dataFormat}
                totalCount={users?.usersTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  _paginate(pageNumber, pageSize)
                }
                rowSelection={false}
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={_cardHeaderFormat}
                cardDataFormat={_cardDataFormat}
              />
            </Suspense>
          </div>
        ) : (
          <div className="hideMobile">
            <Suspense fallback={<></>}>
              {usersHeaderKeys && usersHeaderKeys.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={users?.usersData}
                  headerKeys={usersHeaderKeys}
                  dataFormat={_dataFormat}
                  totalCount={users?.usersTotalCount}
                  rowSelection={false}
                  onPaginate={(pageNumber, pageSize) =>
                    _paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) =>
                    _onSortChange(sortName, sortOrder)
                  }
                  showTableLoading={loading.showTableLoading}
                />
              )}
            </Suspense>
          </div>
        )}
      </div>

      {/* Add or edit team member modal */}
      <Suspense fallback={<></>}>
        <ClientAddUserModal
          isOpen={clientAddUserModal?.isOpen}
          data={clientAddUserModal?.data}
          resetTable={(tableConfig) => _getAllClientUsers(tableConfig)}
          toggle={() => _onToggleClientAddUserModal()}
          tableConfig={tableConfig}
        />
      </Suspense>
    </>
  );
};

export default ClientTeamMember;
