import { capitalize } from "@material-ui/core";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, Spinner } from "reactstrap";
import {
  checkPermission,
  errorHandler,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  getAllOrderClients,
  updateOrderClientStatus,
} from "../../../http/http-calls";
import useScreenWidth from "../components/HelpModule/useScreenWidth";
import { orderClientDataHeaderKeys } from "../../../config/stateConfig";
const AddOrderClient = lazy(() => import("../components/AddOrderClient"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));
const OrderClientCardDataFormat = lazy(() =>
  import("../components/OrderClientCardDataFormat")
);

const OrderClientDataFormat = lazy(() =>
  import("../components/OrderClientDataFormat")
);

const dataCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "name", label: "Name" },
];

const OrderClient = () => {
  const { isForMobile } = useScreenWidth();

  const [clients, setClients] = useState({
    clientsData: [],
    clientsDataCount: 0,
  });

  const [addOrderClientModal, setAddOrderClientModal] = useState({
    isOpen: false,
    data: null,
  });

  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
  });

  const [loading, setLoading] = useState({
    showTableLoading: false,
  });

  //loading management function
  const _manageLoading = (loaderName, value) => {
    setLoading({
      ...loading,
      [loaderName]: value,
    });
  };

  //Get client details from this function
  const _getAllOrderClients = async (newTableConfig) => {
    try {
      _manageLoading("showTableLoading", true);

      console.log({ newTableConfig });
      const res = await getAllOrderClients(newTableConfig);

      setClients({
        clientsData: res?.lenderClients?.length ? res?.lenderClients : [],
        clientsDataCount: res?.totalCount || 0,
      });

      _manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      _manageLoading("showTableLoading", false);
    }
  };

  //This is for handle pagination and update state with api call
  const _paginate = (pageNumber = 1, pageSize = 10) => {
    const newTableConfig = { ...tableConfig };

    newTableConfig.skip = (pageNumber - 1) * pageSize;
    newTableConfig.limit = pageSize;
    newTableConfig["pageNumber"] = pageNumber;

    setTableConfig(newTableConfig);

    _persistFilter(newTableConfig);
    _getAllOrderClients(newTableConfig);

    document.querySelector(".content").scrollIntoView();
  };

  useEffect(() => {
    _setFilterDataFromLocalStorage();

    HeaderEventEmitter.subscribe("reset-filter", () => {
      _paginate();
      refreshFunc("client-order-client");
    });
    HeaderEventEmitter.subscribe("add", () => {
      _toggleAddOrderClientModal(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _toggleAddOrderClientModal = (isOpen = false, data = null) => {
    setAddOrderClientModal({ isOpen, data });
  };

  //For update client status
  const _userStatusUpdate = async (row) => {
    try {
      _manageLoading("statusChangeLoading", true);

      await updateOrderClientStatus(row?.id);

      showToast("Status has been updated", "success");
      _getAllOrderClients(tableConfig);

      _manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      _manageLoading("statusChangeLoading", false);
    }
  };

  //Show data in table view
  const _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <OrderClientDataFormat
          header={header}
          row={row}
          loading={loading}
          _userStatusUpdate={_userStatusUpdate}
          _toggleAddOrderClientModal={_toggleAddOrderClientModal}
          cell={cell}
        />
      </Suspense>
    );
  };

  //Show Hedar data
  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row.picUrl
                      ? row.picUrl
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="">
                  {row?.name?.full ? capitalize(row?.name?.full) : "N/A"}
                </span>
              </div>
            </div>

            <Button
              color="link"
              onClick={() => _toggleAddOrderClientModal(true, row)}
              disabled={!checkPermission("lenderClient", "canUpdate")}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="action"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  //show card or mobile view data
  const _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <OrderClientCardDataFormat
          row={row}
          loading={loading}
          _userStatusUpdate={_userStatusUpdate}
        />
      </Suspense>
    );
  };

  // This function used to manage localstorage data
  const _persistFilter = (newTableConfig) => {
    try {
      if (newTableConfig.pageNumber !== 1) {
        let data = { tableConfig: newTableConfig };
        localStorage.clientOrderClientPage = JSON.stringify(data);
      } else {
        delete localStorage.clientOrderClientPage;
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  // Set filter data in state filter from local storage
  const _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.clientOrderClientPage) {
      try {
        const filters = JSON.parse(localStorage.clientOrderClientPage);

        const newTableConfig = filters?.tableConfig;

        setTableConfig(newTableConfig);

        _getAllOrderClients(newTableConfig);
      } catch (e) {
        console.log("error", e);
        _getAllOrderClients(tableConfig);
      }
    } else {
      _getAllOrderClients(tableConfig);
    }
  };

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>Order Client {loading?.showTableLoading ? <Spinner /> : null}</h2>

          <div className="rightSide">
            <Button
              color="link"
              onClick={() => {
                _paginate();
                refreshFunc("client-order-client-responsive");
              }}
            >
              <img
                id="client-order-client-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>

            {checkPermission("lenderClient", "canAdd") ? (
              <>
                <Button
                  color="primary"
                  onClick={() => _toggleAddOrderClientModal(true)}
                  className="floatingButton"
                >
                  Add Client
                </Button>
              </>
            ) : null}
          </div>
        </div>

        {isForMobile ? (
          <div className="hideDesktop">
            <Suspense fallback={<></>}>
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={clients?.clientsData}
                headerKeys={dataCardHeaderKeys}
                dataFormat={_dataFormat}
                totalCount={clients?.clientsDataCount}
                onPaginate={(pageNumber, pageSize) =>
                  _paginate(pageNumber, pageSize)
                }
                rowSelection={false}
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={_cardHeaderFormat}
                cardDataFormat={_cardDataFormat}
              />
            </Suspense>
          </div>
        ) : (
          <div className="hideMobile">
            <Suspense fallback={<></>}>
              {orderClientDataHeaderKeys?.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={clients?.clientsData}
                  headerKeys={orderClientDataHeaderKeys}
                  dataFormat={_dataFormat}
                  totalCount={clients?.clientsDataCount}
                  rowSelection={false}
                  pageSize={tableConfig.limit}
                  onPaginate={(pageNumber, pageSize) =>
                    _paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) => _paginate()}
                  showTableLoading={loading.showTableLoading}
                />
              )}
            </Suspense>
          </div>
        )}
      </div>

      {/* Add or Edit client modal */}
      <Suspense fallback={<></>}>
        <AddOrderClient
          isOpen={addOrderClientModal.isOpen}
          data={addOrderClientModal.data}
          toggle={() => _toggleAddOrderClientModal()}
          onSuccess={(tableConfig) => _getAllOrderClients(tableConfig)}
          tableConfig={tableConfig}
        />
      </Suspense>
    </>
  );
};

export default OrderClient;
