import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  errorHandler,
  formatDateMomentMonthFirst,
  showToast,
} from "../../../helper-methods";
// add note Modal
const AddNotesModal = ({ isOpen, data, toggle, onSaveNotes }) => {
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  // reset state
  const _resetStateModal = () => {
    setNote("");
    setLoading(false);
  };

  // close function
  const _closeModal = () => {
    _resetStateModal();
    toggle();
  };

  // change notes field
  const _onChangeNotes = (value) => {
    setNote(value);
  };

  useEffect(() => {
    if (isOpen && data) {
      if (
        data.hasOwnProperty("payPeriodPaymentNotesClient") &&
        data.payPeriodPaymentNotesClient
      ) {
        // set note data if payPeriodPaymentNotesClient is find
        setNote(data?.payPeriodPaymentNotesClient);
      } else {
        if (data.notes) setNote(data?.notes);
      }
    }
    // on open and on change of data value this is called
  }, [isOpen, data]);

  // save note
  const _onSaveNotes = async () => {
    try {
      setLoading(true);
      // call parent function
      await onSaveNotes(data.id, note?.trim() || "");

      _closeModal();

      showToast("Notes updated", "success");
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={_closeModal} centered scrollbar>
      <ModalHeader toggle={_closeModal}>Notes</ModalHeader>
      <ModalBody>
        <FormGroup className="floatingLabel">
          <Input
            type="textarea"
            rows="5"
            placeholder=" "
            value={note}
            onChange={(e) => _onChangeNotes(e.target.value)}
          />
          <Label> {data?.paymentHistory ? "Description" : "Notes"}</Label>
        </FormGroup>
        {data?.paymentHistory ? (
          <Card>
            <CardHeader>
              {data?.paymentHistory ? <>Payment History</> : null}
            </CardHeader>
            <CardBody>
              {/*  payment history array of objects */}
              {data?.paymentHistory &&
                data?.paymentHistory?.map((item) => (
                  <>
                    {!item?.notes?.length ? (
                      <p style={{ color: "#1B2430", marginBottom: 5 }}>
                        {" "}
                        ${item.paymentAmount} was paid{" "}
                        {item.referenceNumber && "via check#"}
                        {item.referenceNumber} {item.checkDate && "on"}{" "}
                        {formatDateMomentMonthFirst(item.checkDate)}
                      </p>
                    ) : (
                      <>
                      {/* both condition handled if "and" text found or not */}
                        <p>
                          ${item.paymentAmount} was{" "}
                          {item?.notes?.includes("and")
                            ? item.notes.split("and")[0]
                            : item.notes}
                        </p>
                        {item?.notes?.includes("and") && (
                          <p>{item.notes.split("and")[1]}</p>
                        )}
                      </>
                    )}
                  </>
                ))}
            </CardBody>
          </Card>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={_closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={loading}
          size="lg"
          onClick={_onSaveNotes}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNotesModal;
