/* eslint-disable react/jsx-no-target-blank */
import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Label } from "reactstrap";
import {
  formatDate,
  formatCurrencyValue,
  formatDateHideYearIfSame,
  getLoggedInUserId,
  openUrlOnNewTab,
  errorHandler,
} from "../../../helper-methods";
import {
  billingSigningCompany,
  cancelSigningCompanySubscription,
} from "../../../http/http-calls";
import { deepClone, showToast } from "../../../helper-methods";

import { BASE_URL } from "../../../config";
import CustomTable from "../components/CustomTable";
import Swal from "sweetalert2";
import CustomCard from "../components/CustomCard";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { billingDataHeaderKeys } from "../../../config/stateConfig";
import SvgIcons from "../components/SvgIcons";

class Billing extends React.Component {
  state = {
    billingData: [],
    billingDataTotalCount: 0,
    billingDataHeaderKeys: billingDataHeaderKeys,
    billingDataCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    subscription: {},
    loading: {
      showTableLoading: false,
    },
    modalloading: false,
    confirmationModal: {
      isOpen: false,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
  };

  _dataFormat = (cell, row, header) => {
    console.log("12345 ", row);
    switch (header) {
      case "createdAt": {
        return cell ? formatDate(cell) : "N/A";
      }
      case "downloadAction": {
        return !cell ? (
          <div>
            <Button
              color="link"
              outline
              onClick={() => this._downloadData(row)}
            >
              Download
              {/* <img
                src={require("../../../assets/img/download_blue.png")}
                alt="download"
              /> */}
              <SvgIcons type={"download"} />
            </Button>
          </div>
        ) : (
          "N/A"
        );
      }
      case "currentSubscription": {
        return cell ? (
          <>
            Payment for period{" "}
            {(cell.start && formatDateHideYearIfSame(cell.start)) || "N/A"} to{" "}
            {(cell.end && formatDateHideYearIfSame(cell.end)) || "N/A"}
          </>
        ) : (
          "N/A"
        );
      }
      case "plan": {
        return row?.currentSubscription?.plan
          ? row?.currentSubscription?.plan
          : "N/A";
      }
      case "totalBillingAmount": {
        return cell && cell > 0 ? formatCurrencyValue(cell) : <>$0</>;
      }
      default: {
        return cell;
      }
    }
  };

  componentDidMount() {
    this._signingCompanyBilling();
  }

  _signingCompanyBilling() {
    const { tableConfig } = deepClone(this.state);

    this._manageLoading("showTableLoading", true);

    let data = {
      skip: tableConfig.skip,
      limit: tableConfig.limit,
    };

    billingSigningCompany(data)
      .then((res) => {
        // console.log(res);
        this.setState({
          subscription: res?.subscription || {},
          billingData: res?.billings || [],
          billingDataTotalCount: res?.totalCount || null,
        });
        this._manageLoading("showTableLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showDataLoading", false);
      });
  }
  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _cancelSubscription = () => {
    this.setState({ modalloading: true });

    cancelSigningCompanySubscription()
      .then((res) => {
        showToast("Cancelled Successfully", "success");
        this._signingCompanyBilling();
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _onToggleSubscriptionConfirmationModal = () => {
    Swal.fire({
      title: "Confirm",
      text: `Do you want to Cancel this Plan?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        // _deleteDocument(document._id);
        this._cancelSubscription();
      }
    });
  };

  _paginate = (pageNumber, pageSize) => {
    console.log("pageNumber, pageSize :", pageNumber, pageSize);
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    this.setState({ tableConfig }, () => {
      this._signingCompanyBilling();
    });
  };

  _onSortChange = () => {
    this._paginate(1, 10);
  };

  _downloadData = (data) => {
    const apiUrl = `${BASE_URL}/signingcompany/download/subscription-invoice?userId=${getLoggedInUserId()}&billingId=${
      data.id
    }`;
    openUrlOnNewTab(apiUrl);
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Billing Date </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.createdAt
                ? formatDateHideYearIfSame(row?.createdAt)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Amount </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.totalBillingAmount || row?.totalBillingAmount === 0
                ? formatCurrencyValue(row?.totalBillingAmount)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Plan </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.currentSubscription?.plan
                ? row?.currentSubscription?.plan
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Description </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.currentSubscription ? (
                <>
                  Payment for period{" "}
                  {(row?.currentSubscription?.start &&
                    formatDateHideYearIfSame(
                      row?.currentSubscription?.start
                    )) ||
                    "N/A"}{" "}
                  to{" "}
                  {(row?.currentSubscription?.end &&
                    formatDateHideYearIfSame(row?.currentSubscription?.end)) ||
                    "N/A"}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap text-center">
            <Button
              color="link"
              outline
              onClick={() => this._downloadData(row)}
            >
              Download
              <a href={row?.invoicePdf} target="_blank">
                <SvgIcons type={"download"} />
              </a>
            </Button>
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      billingDataHeaderKeys,
      billingDataTotalCount,
      billingData,
      loading,
      subscription,
      tableConfig,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>My Billing</h2>
            <div className="rightSide">
              {subscription !== null || subscription?.isExpired === true ? (
                <div>
                  <div>
                    Next Billing On:{" "}
                    <span style={{ fontWeight: 600 }}>
                      {formatDate(subscription?.end)}
                    </span>
                  </div>

                  {subscription.isAuto && (
                    <Button
                      color="primary"
                      size="md"
                      className="floatingButton"
                      onClick={() =>
                        this._onToggleSubscriptionConfirmationModal()
                      }
                    >
                      CANCEL SUBSCRIPTION
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="d-none d-lg-block">
            {subscription !== null || subscription?.isExpired === true ? (
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ marginBottom: 15 }}
              >
                <div>
                  Next Billing On:{" "}
                  <span style={{ fontWeight: 600 }}>
                    {formatDate(subscription?.end)}
                  </span>
                </div>

                {subscription.isAuto && (
                  <Button
                    color="primary"
                    size="md"
                    onClick={() =>
                      this._onToggleSubscriptionConfirmationModal()
                    }
                  >
                    CANCEL SUBSCRIPTION
                  </Button>
                )}
              </div>
            ) : null}
          </div>

          <Row>
            <Col md="12">
              {isForMobile ? (
                <Suspense fallback={<></>}>
                  <div className="hideDesktop">
                    <CustomCard
                      isPageStartFromOne={true}
                      pageNumber={tableConfig.pageNumber}
                      tableData={billingData}
                      dataFormat={this._dataFormat}
                      totalCount={billingDataTotalCount}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      rowSelection={false}
                      showTableLoading={loading.showTableLoading}
                      cardHeaderFormat={this._cardHeaderFormat}
                      cardDataFormat={this._cardDataFormat}
                    />
                  </div>
                </Suspense>
              ) : (
                <Suspense fallback={<></>}>
                  <div className="hideMobile">
                    {billingDataHeaderKeys && billingDataHeaderKeys.length && (
                      <CustomTable
                        striped
                        isPageStartFromOne={true}
                        tableData={billingData}
                        headerKeys={billingDataHeaderKeys}
                        dataFormat={this._dataFormat}
                        totalCount={billingDataTotalCount}
                        pageNumber={tableConfig.skip / tableConfig.limit + 1}
                        rowSelection={false}
                        onPaginate={(pageNumber, pageSize) =>
                          this._paginate(pageNumber, pageSize)
                        }
                        onSortChange={(sortName, sortOrder) =>
                          this._onSortChange(sortName, sortOrder)
                        }
                        showTableLoading={loading.showTableLoading}
                      />
                    )}
                  </div>
                </Suspense>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(ScreenWidthHOC(Billing));
