import moment from "moment";
import { RegexConfig } from "../config/RegexConfig";

export const validateSignup = (fieldName, value) => {
  switch (fieldName) {
    case "firstname":
    case "lastname":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 25) {
        return "*Must be 2-25 characters";
      }
      if (
        !RegexConfig.nameWithoutSpecialCharactersAndNumber.test(value?.trim())
      ) {
        return "*No special characters or digits are allowed";
      }
      return "";
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.email.test(value?.trim())) {
        return "*Please enter a valid email address";
      }
      return "";
    case "password":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.password.test(value?.trim())) {
        return "*Should have atleast 8 characters with 1 digit and 1 special character";
      }
      return "";
    case "phone":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.phone.test(value?.trim())) {
        return "*Please enter a valid phone number";
      }
      return "";
    case "income":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "year":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "ssn":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.ssn.test(value?.trim())) {
        return "*Should be exactly 9 digits";
      }
      return "";
    case "address":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 50) {
        return "*Must be 2-50 characters";
      }
      return "";
    case "state":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "city":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "zip":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.zipCode.test(value?.trim())) {
        return "*Invalid zip";
      }
      return "";
    default:
      return "";
  }
};

export const validateLogin = (fieldName, value) => {
  switch (fieldName) {
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.email.test(value?.trim())) {
        return "*Invalid email";
      }
      return "";
    case "otp":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    default:
      return "";
  }
};

export const validateOrderFormValues = (fieldName, value) => {
  switch (fieldName) {
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.email.test(value?.trim())) {
        return "*Invalid email";
      }
      return "";
    case "otp":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "phone":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.phone.test(value?.trim())) {
        return "*Please enter a valid phone number";
      }
      return "";
    case "name":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 20) {
        return "*Must be 2-20 characters";
      }
      if (!RegexConfig.firstLetterAlwaysAlphabet.test(value?.trim())) {
        return "*First Letter must be alphabet";
      }
      return "";
    default:
      return "";
  }
};

export const validateEmail = (fieldName, value) => {
  switch (fieldName) {
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.email.test(value?.trim())) {
        return "*Invalid email";
      }
      return "";
    default:
      return "";
  }
};

export const validateForgotPassword = (fieldName, value) => {
  switch (fieldName) {
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.email.test(value?.trim())) {
        return "*Invalid email";
      }
      return "";
    default:
      return "";
  }
};

export const validateResetPassword = (() => {
  let password;

  return (fieldName, value) => {
    if (fieldName === "password" && value?.trim()?.length) {
      password = value?.trim();
    }

    switch (fieldName) {
      case "password":
        if (!value?.trim()?.length) {
          return "*Required";
        }
        if (!RegexConfig.password.test(value?.trim())) {
          return "*Should have at least 8 characters with 1 digit and 1 special character";
        }
        return "";
      case "confirmPassword":
        if (!value?.trim()?.length) {
          return "*Required";
        }
        if (value?.trim() !== password) {
          return "*Passwords do not match";
        }
        return "";
      default:
        return "";
    }
  };
})();

export const validatePaymentDetails = (fieldName, value) => {
  switch (fieldName) {
    case "cardNumber":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.stripeCard.test(value?.trim())) {
        return "*Invalid card number";
      }
      return "";
    case "expiry":
      if (!value) {
        return "*Required";
      }
      if (moment(value)?.isBefore(moment())) {
        return "*This card is already exprired";
      }
      return "";
    case "cvv":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.cvv.test(value?.trim())) {
        return "*Must be 3 digits";
      }
      return "";
    case "cardCountry":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "cardZip":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.zipCode.test(value?.trim())) {
        return "*Invalid zip";
      }
      return "";
    case "fullname":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 20) {
        return "*Must be 2-20 characters";
      }
      if (
        !RegexConfig.nameWithoutSpecialCharactersAndNumber.test(value?.trim())
      ) {
        return "*No special characters or digits are allowed";
      }
      return "";
    case "address":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 50) {
        return "*Must be 2-50 characters";
      }
      return "";
    case "city":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "state":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "zip":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.zipCode.test(value?.trim())) {
        return "*Invalid zip";
      }
      return "";
    default:
      return "";
  }
};

export const validateProfile = (fieldName, value) => {
  switch (fieldName) {
    case "name":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 25) {
        return "*Must be 2-25 characters";
      }
      if (
        !RegexConfig.nameWithoutSpecialCharactersAndNumber.test(value?.trim())
      ) {
        return "*No special characters or digits are allowed";
      }
      return "";
    case "email":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.email.test(value?.trim())) {
        return "*Please enter a valid email address";
      }
      return "";
    case "phone":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.phone.test(value?.trim())) {
        return "*Please enter a valid phone number";
      }
      return "";
    case "year":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "ssn":
      if (!RegexConfig.fourDigits.test(value?.trim())) {
        return "*Should be exactly 4 digits";
      }
      return "";
    case "address":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (value?.trim()?.length < 2 || value?.trim()?.length > 50) {
        return "*Must be 2-50 characters";
      }
      return "";
    case "state":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "city":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      return "";
    case "zip":
      if (!value?.trim()?.length) {
        return "*Required";
      }
      if (!RegexConfig.zipCode.test(value?.trim())) {
        return "*Invalid zip";
      }
      return "";
    default:
      return "";
  }
};

export const _validateSignerInfoGroup = (formFields, isForDraftCreate) => {
  try {
    let updatedFormfields = JSON.parse(JSON.stringify([...formFields]));

    let isFormValid = true;
    updatedFormfields?.forEach((current, index) => {
      Object.keys(current).forEach((fieldName) => {
        switch (fieldName) {
          case "email": {
            if (current[fieldName].value?.length) {
              if (
                RegexConfig[fieldName].test(
                  String(current[fieldName].value).toLowerCase().trim()
                )
              ) {
                current.email.isValid = true;
                current.email.error = "";
              } else {
                current.email.isValid = false;
                current.email.error = "*Invalid email";
                isFormValid = false;
              }
            }
            break;
          }
          case "name": {
            if (!isForDraftCreate) {
              // Only validate name if not for draft create
              if (current[fieldName].value?.length) {
                current.name.isValid = true;
                current.name.error = "";
                isFormValid = true;
              } else {
                current.name.isValid = false;
                current.name.error = "*Required";
                isFormValid = false;
              }
              break;
            } else {
              current.name.isValid = true;
              current.name.error = "";
              isFormValid = true;
            }
            break;
          }
          case "phoneHome":
          case "workPhone": {
            if (current[fieldName].value?.length) {
              if (
                RegexConfig.phone.test(
                  String(current[fieldName].value).toLowerCase().trim()
                )
              ) {
                current[fieldName].isValid = true;
                current[fieldName].error = "";
              } else {
                current[fieldName].isValid = false;
                current[fieldName].error = "*Invalid phone";
                isFormValid = false;
              }
            }
            break;
          }
          default: {
          }
        }
      });
    });
    // console.log("updatedFormfields >>", updatedFormfields);
    return { updatedFormfields, isFormValid };
  } catch (error) {
    console.log("eeeee >>", error);
  }
};

export const validateAddress = (formFields, isRequired) => {
  try {
    let updatedFormfields = JSON.parse(JSON.stringify({ ...formFields }));

    let isFormValid = true;
    Object.keys(updatedFormfields).forEach((fieldName) => {
      switch (fieldName) {
        case "sddressLine1":
        case "city":
        case "state":
        case "zip": {
          if (isRequired) {
            if (
              updatedFormfields[fieldName].value &&
              updatedFormfields[fieldName].value.trim().length
            ) {
              updatedFormfields[fieldName].isDirty = false;
              updatedFormfields[fieldName].error = null;
            } else {
              updatedFormfields[fieldName].isDirty = true;
              updatedFormfields[fieldName].error = "*Required";
              isFormValid = false;
            }
          } else {
            updatedFormfields[fieldName].isDirty = false;
            updatedFormfields[fieldName].error = null;
          }

          break;
        }
        default: {
        }
      }
    });
    return { updatedFormfields, isFormValid };
  } catch (error) {
    console.log("eeeee >>", error);
  }
};
