import React, { useRef } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button } from "reactstrap";

function InfiniteScrollDropdown({
  optionsConfig,
  value,
  onChangeFunc,
  onBlur,
  placeholder = "",
  shouldMenuShouldScrollIntoView = false,
  totalDataCount = 0,
  totalDataPages = 0,
  updateOptionsConfig,
  dropdownPayload,
  setDropdownPayload,
  isCreateable = false,
  isClearable = false,
  isDisabled = false,
  isMulti = false,
  isLoading = false,
  onInputChangeFunc,
}) {
  const selectRef = useRef(null);

  const _scrollToBottom = () => {
    const newDropDownPayload = { ...dropdownPayload };
    newDropDownPayload["page"] = newDropDownPayload["page"] + 1;
    // console.log("ert", newDropDownPayload["page"], totalDataPages);

    if (newDropDownPayload["page"] >= totalDataPages) {
      setDropdownPayload(newDropDownPayload);
      //   updateOptionsConfig(newDropDownPayload);
    }
  };

  const SelectMenuWithButton = (props) => {
    const isLoadingIndex = optionsConfig.findIndex(
      (each) => each.value === "loading"
    );
    return (
      <>
        {console.log("optionsConfig", optionsConfig?.length < totalDataCount)}
        <components.MenuList {...props}>
          {props.children}
          {isLoadingIndex === -1 && optionsConfig?.length < totalDataCount ? (
            <div className="text-center">
              <Button
                className="h-auto"
                color="link"
                onClick={_scrollToBottom}
                style={{ textTransform: "capitalize" }}
              >
                Load More
              </Button>
            </div>
          ) : null}
        </components.MenuList>
      </>
    );
  };

  const CustomStyle = {
    menuList: (base) => ({
      ...base,
    }),
    option: (styles) => ({
      ...styles,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <>
      {isCreateable ? (
        <CreatableSelect
          components={{ MenuList: SelectMenuWithButton }}
          placeholder={placeholder || "Enter"}
          classNamePrefix="select"
          isClearable={isClearable}
          isDisabled={isDisabled}
          options={optionsConfig}
          onBlur={onBlur}
          value={value}
          onChange={(e) => onChangeFunc(e)}
        />
      ) : (
        <Select
          id="qwerty"
          components={{ MenuList: SelectMenuWithButton }}
          placeholder={placeholder || "Enter"}
          classNamePrefix="select"
          isMulti={isMulti ? isMulti : false}
          isClearable={isClearable}
          options={optionsConfig}
          onBlur={onBlur}
          value={value}
          isLoading={isLoading}
          onChange={(chips) => onChangeFunc(chips)}
          menuShouldScrollIntoView={
            shouldMenuShouldScrollIntoView
              ? shouldMenuShouldScrollIntoView
              : false
          }
          styles={CustomStyle}
          ref={selectRef}
          onInputChange={(inputValue, { action }) => {
            console.log("Action:", action);

            onInputChangeFunc(inputValue, action);
            // Handle input change event here
          }}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
}
export default InfiniteScrollDropdown;
