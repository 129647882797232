import { io } from "socket.io-client";
import { SOCKET_BASE_URL } from "../config";

export const newSocket = io(SOCKET_BASE_URL, {
  forceNew: true,
  upgrade: false,
  jsonp: false,
  reconnection: true,
  reconnectionDelay: 100,
  reconnectionAttempts: 100000,
  transports: ["websocket"],
});

// newSocket.on("connect_error", (err) => {
//   console.log(`connect_error due to ${err.message}`);
// });

// export const connectToSocket = () => {
//   return new Promise((resolve, reject) => {
//     try {
//       newSocket.connect();
//       // console.log("connected>>", newSocket.connected); // true

//       var tryReconnect = function () {
//         if (newSocket.connected === false) {
//           //     // use a connect() here if you want
//           newSocket.connect();
//         }
//       };

//         var intervalID = setInterval(tryReconnect, 4000);

//       // newSocket.on("connection", function () {
//       console.log("connected>>", newSocket.connected); // true
//       // once client connects, clear the reconnection interval function
//       clearInterval(intervalID);
//       //... do other stuff
//       resolve(newSocket);
//       // });
//     } catch (error) {
//       console.log("error>>", error);
//       reject(error);
//     }
//   });
// };

export const connectToSocket = () => {
  return new Promise((resolve, reject) => {
    try {
      var tryReconnect = function () {
        // console.log("inside try connect");
        if (newSocket.connected === false) {
          // console.log("connecting...");
          // use a connect() here if you want
          newSocket.connect();
        }
      };
      // console.log("socket", newSocket);
      var intervalID = setInterval(tryReconnect, 1000);
      
      if (newSocket.connected) {
        clearInterval(intervalID);
        resolve(newSocket);
      }

      newSocket.on("connection", function () {
        console.log("connected>>", newSocket.connected); // true
        console.log("connected>>", newSocket.id); // true
        // once client connects, clear the reconnection interval function
        clearInterval(intervalID);
        //... do other stuff
        resolve(newSocket);
      });
    } catch (error) {
      console.log("error>>", error);
      reject(error);
    }
  });
};

export const disconnectToSocket = () => {
  return new Promise((resolve, reject) => {
    try {
      newSocket.on("disconnect", () => {
        console.log("connect>>", newSocket.connected); // false
      });
      newSocket.close();
      resolve(true);
    } catch (error) {
      console.log("error>>", error);
      reject(error);
    }
  });
};
