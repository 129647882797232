import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import SvgIcons from "../../components/SvgIcons";
import AddCreditCard from "../../../public/components/AddCreditCard";
import { useSelector } from "react-redux";
import {
  deleteAgentCreditCard,
  deleteCompanyCreditCard,
  fetchSigningAgentCreditCard,
  fetchSigningCompanyCreditCard,
  makeDefaultAgentCreditCard,
  makeDefaultCompanyCreditCard,
} from "../../../../http/http-calls";
import { deepClone, errorHandler, showToast } from "../../../../helper-methods";
import { HeaderEventEmitter } from "../../../../helper-methods/HeaderEvents";
import Swal from "sweetalert2";
import SkeletonLoading from "../../components/Skeletons/SkeletonLoading";
import useScreenWidth from "../../components/HelpModule/useScreenWidth";

const AgentCompanyCreditCard = () => {
  const userData = useSelector((state) => state.userData);
  const { isForMobile } = useScreenWidth();

  const [creditCardModal, setCreditCardModal] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggle = () => setCreditCardModal(!creditCardModal);

  useEffect(() => {
    _getAllCreditCards();
    if (userData?.user?.type === "SigningCompany") {
      HeaderEventEmitter.subscribe("companyCreditCard", () => {
        toggle();
      });
    } else {
      HeaderEventEmitter.subscribe("agentCreditCard", () => {
        toggle();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getAllCreditCards = () => {
    const userType = userData?.user?.type;

    switch (userType) {
      case "SigningCompany":
        _fetchSigningCompanyCreditCard();
        break;
      case "Agent":
        _fetchSigningAgentCreditCard();
        break;
      //   case "Client":
      // _clientFindCustomEmailTemplates();
      // break;
      default:
        break;
    }
  };

  const _fetchSigningCompanyCreditCard = async () => {
    setLoading(true);
    try {
      let response = await fetchSigningCompanyCreditCard();
      setCreditCards(response?.cards);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const _fetchSigningAgentCreditCard = async () => {
    setLoading(true);
    try {
      let response = await fetchSigningAgentCreditCard();
      setCreditCards(response?.cards);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };
  //   const _makeCardAsDefault = () => {
  //     const { selectedAccount } = this.state;

  //     const { type: userType } = this.props.userData;

  //     return new Promise((resolve, reject) => {
  //       makeCardDefault(selectedAccount.id, userType)
  //         .then((res) => {
  //           if (!res.error) {
  //             resolve();
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           reject();
  //         });
  //     });
  //   };

  const deleteCard = (id) => {
    const initialState = deepClone(creditCards);
    const updatedCards = initialState.filter((card) => card.id !== id);

    setCreditCards(updatedCards);
  };

  const _makeCardDefault = async (id) => {
    const userType = userData?.user?.type;
    try {
      setLoading(true);

      if (userType === "SigningCompany") {
        await makeDefaultCompanyCreditCard(id);
      } else {
        await makeDefaultAgentCreditCard(id);
      }
      _getAllCreditCards();
      showToast("Card Default Done", "success");
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _deleteCard = async (id) => {
    const userType = userData?.user?.type;

    if (!id) {
      showToast("Card not found", "error");
      return;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this card.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        if (userType === "SigningCompany") {
          await deleteCompanyCreditCard(id);
        } else {
          await deleteAgentCreditCard(id);
        }

        deleteCard(id);

        showToast("Card Deleted", "success");
      } catch (error) {
        errorHandler(error);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>Credit Card</h2>

          <div className="rightSide">
            <Button className="floatingButton" color="primary" onClick={toggle}>
              Add Card
            </Button>
          </div>
        </div>

        {/* remove this button when you done your functionality */}
        {/* <Button
          className="floatingButton"
          color="primary"
          onClick={toggle}
        >
          Add Card
        </Button> */}
        {loading ? (
          isForMobile ? (
            <SkeletonLoading type="card" count={5} height={160} width={350} />
          ) : (
            <SkeletonLoading
              type="SkeletonLoadingSubscription"
              count={12}
              width="100%"
            />
          )
        ) : (
          <Row>
            {creditCards.length > 0 &&
              creditCards.map((item) => (
                <Col xl={4} lg={6} md={6} sm={12}>
                  <div
                    className={`creditCardWrapper  ${
                      item.isDefault ? "selected" : ""
                    }`}
                  >
                    <div className="icon">
                      <SvgIcons type={"creditCard"} />
                    </div>
                    <div className="creditNumber">
                      <h3>
                        <span>
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                        </span>
                        <span>
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                        </span>
                        <span>
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                          <SvgIcons type={"dot"} />
                        </span>
                        <span>{item.last4}</span>
                      </h3>
                      <div className="cardVaild">
                        <span>
                          {item?.exp_month}/
                          {item?.exp_year?.toString().slice(-2)}
                        </span>
                        <span>{item?.brand}</span>
                      </div>
                    </div>
                    <div className="action">
                      {item.isDefault ? (
                        <h6>DEFAULT</h6>
                      ) : (
                        <>
                          <Button
                            color="link"
                            onClick={() => _makeCardDefault(item?.id)}
                            disabled={loading}
                          >
                            Make Default
                          </Button>
                          <Button
                            color="link"
                            className="remove"
                            onClick={() => _deleteCard(item?.id)}
                            disabled={loading}
                          >
                            <SvgIcons type={"remove"} />
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        )}
      </div>
      <AddCreditCard
        isOpen={creditCardModal}
        toggle={toggle}
        userData={userData}
        getAllCreditCards={_getAllCreditCards}
      />
    </>
  );
};

export default AgentCompanyCreditCard;
