export const billingDataHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "createdAt", label: "Billing Date", noSort: true },
  { id: "totalBillingAmount", label: "Amount", noSort: true },
  { id: "plan", label: "Plan", noSort: true },
  { id: "currentSubscription", label: "Description", noSort: true },
  { id: "downloadAction", label: " ", noSort: true },
];

export const manadatoryHeadersFields = {
  agentClient: ["clientName", ["clientId", "email1"]],
  paidIncome: ["fileNo", "amount"],
  pendingIncome: ["fileNo", "date", "signingFee1", ["clientId", "clientEmail"]],
  closing: [
    "fileNo",
    "date",
    "signerFirstName",
    "signerLastName",
    "signerPhoneNo",
    "signerEmail",
    "amount",
    "client",
    "notes",
  ],
  notarialLog: ["date", "purpose", ["clientId", "clientEmail"], "agentFee"],
  milageLog: [
    "date",
    "purpose",
    "endLocationLine1",
    "endLocationCity",
    "endLocationState",
    "endLocationZip",
    "actualMiles",
  ],
  expenses: ["expenseDate", "item", "amount"],
};

export const DropdownHeadersFields = {
  agentClient: [
    "clientName",
    "clientId",
    "pocFirstName",
    "pocLastName",
    "phone1",
    "phone2",
    "email1",
    "email2",
    "address1",
    "address2",
    "city",
    "state",
    "zip",
  ],
  paidIncome: ["fileNo", "checkDate", "checkNo", "amount"],
  pendingIncome: [
    "fileNo",
    "date",
    "clientId",
    "signerFirstName",
    "signerLastName",
    "signerPhone",
    "signerEmail",
    "signingFee1",
    "signingFee2",
    "signingFee3",
    "clientEmail",
    "notes",
  ],
  notarialLog: [
    "date",
    "purpose",
    "clientId",
    "clientEmail",
    "signerFirstName",
    "signerLastName",
    "notarialAct",
    "amountPerAct",
    "notarialFees",
    "agentFee",
  ],
  milageLog: [
    "date",
    "purpose",
    "startLocation",
    "stopLocation",
    "endLocationLine1",
    "endLocationCity",
    "endLocationState",
    "endLocationZip",
    "endLocationCountry",

    "actualMiles",
    "estimatedMiles",
    "comment",
  ],
  expenses: ["transactionId", "expenseDate", "item", "amount", "note"],
};

export const dataHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "fileNumber", label: "File Number", noSort: true },
  { id: "appointmentDate", label: "completion Date", noSort: true },
  { id: "payPeriodPaymentNotesClient", label: "Notes", noSort: true },
  { id: "loanType", label: "Product Type", noSort: true },
  { id: "_signingCompany", label: "Company", noSort: true },
  { id: "payStatusClient", label: "Status", noSort: true },
  { id: "signingCompanyFee", label: "Balance", noSort: true },
];

export const clientsHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "name", label: "Name", noSort: true },
  { id: "email", label: "Email", noSort: true },
  { id: "clientId", label: "Client Id", noSort: true },
  { id: "phone", label: "Phone", noSort: true },
  { id: "address", label: "Location", noSort: true },
  { id: "tags", label: "Tags", noSort: true },
  { id: "pointOfContact", label: "Point Of Contact", noSort: true },
  { id: "isActive", label: "Status", noSort: true },
  {
    id: "action",
    label: "Action",
    noSort: true,
    style: { width: 50, textAlign: "center" },
  },
];
export const clientPageHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "name", label: "Client Name", noSort: true },
  { id: "clientmembers", label: "Clients Member", noSort: true },
  { id: "clientteams", label: "Client Teams", noSort: true },
  { id: "address", label: "Location", noSort: true },
  { id: "action", label: "Action", noSort: true },
];

export const usersHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "name", label: "Name", noSort: true },
  { id: "email", label: "Email", noSort: true },
  { id: "phone", label: "Phone", noSort: true },
  { id: "permissionSettings", label: "Permission", noSort: true },
  { id: "added", label: "Added By", noSort: true },
  { id: "lastLogin", label: "Last Login", noSort: true },
  { id: "isActive", label: "Status", noSort: true },
  {
    id: "action",
    label: "Action",
    noSort: true,
    style: { width: 100, textAlign: "center" },
  },
];

export const usersCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "name", label: "Name" },
];

export const signingCompanyClosingHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "appointmentDate", label: "Signer", noSort: true },
  { id: "fileNumber", label: "Order Details", noSort: true },
  { id: "_agent", label: "Agent", noSort: true },
  { id: "_client", label: "Client", noSort: true },
  { id: "docs", label: "Docs", noSort: true },
  { id: "closingAddress", label: "Location", noSort: true },
  { id: "status", label: "Status", noSort: true },
];

export const expensesHeaderKeys = [
  { id: "_id", label: "id" },
  { id: "item", label: "Item" },
  { id: "amount", label: "Amount" },
  { id: "receipt", label: "receipt" },
  { id: "action", label: "Action", noSort: true },
];

export const datasets = [
  {
    label: "Count",
    backgroundColor: "rgba(30, 41, 92, 0.3)",
    borderColor: "rgba(30, 41, 92, 1)",
    borderWidth: 1,
    hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
    hoverBorderColor: "rgba(30, 41, 92, 1)",
    data: [],
  },
  {
    label: "Amount",
    backgroundColor: "rgba(7, 104, 157, 0.3)",
    borderColor: "rgba(7, 104, 157, 1)",
    borderWidth: 1,
    hoverBackgroundColor: "rgba(7, 104, 157, 0.5)",
    hoverBorderColor: "rgba(7, 104, 157, 1)",
    data: [],
  },
];

export const expenseSummariesHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "createdAt", label: "Date", noSort: true },
  { id: "amount", label: "Amount", noSort: true },
  { id: "item", label: "Type", noSort: true },
  { id: "receipt", label: "Receipt", noSort: true },
  { id: "client", label: "Client", noSort: true },
  { id: "closing#", label: "Closing#", noSort: true },
];

export const accountHeader = [
  { id: "id", label: "id", noSort: true },
  { id: "date", label: "Date", noSort: true },
  { id: "amount", label: "Amount", noSort: true },
  { id: "item", label: "Type", noSort: true },
  { id: "notes", label: "Notes", noSort: true },
  { id: "receipt", label: "Receipt", noSort: true },
  { id: "action", label: "Action", noSort: true },
];

export const accountCompanyHeader = [
  { id: "id", label: "id", noSort: true },
  { id: "date", label: "Date", noSort: true },
  { id: "amount", label: "Amount", noSort: true },
  { id: "item", label: "Type", noSort: true },
  { id: "notes", label: "Notes", noSort: true },
  { id: "receipt", label: "Receipt", noSort: true },
  { id: "action", label: "Action", noSort: true },
];

export const incomeListAccountAgentDatasets = [
  {
    label: "Income",
    backgroundColor: "rgba(30, 41, 92, 0.3)",
    borderColor: "rgba(30, 41, 92, 1)",
    borderWidth: 1,
    hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
    hoverBorderColor: "rgba(30, 41, 92, 1)",
    data: [],
  },
];

export const incomeHeaderAgentKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "appointmentDate", label: "Date", noSort: true },
  { id: "fileNumber", label: "File Number", noSort: true },
  { id: "_borrower", label: "Signer", noSort: true },
  { id: "client", label: "Client", noSort: true },
  { id: "amount", label: "Fee", noSort: true },
  { id: "status", label: "Order Status", noSort: true },
  { id: "notes", label: "Notes", noSort: true },
  { id: "duePayment", label: "Balance Due", noSort: true },
  { id: "action", label: "Action", noSort: true },
  { id: "receipt", label: "Receipt", noSort: true },
];

export const incomeListAccountingCompanyDatasets = [
  {
    label: "Income",
    backgroundColor: "rgba(30, 41, 92, 0.3)",
    borderColor: "rgba(30, 41, 92, 1)",
    borderWidth: 1,
    hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
    hoverBorderColor: "rgba(30, 41, 92, 1)",
    data: [],
  },
];

export const incomeListAccountingCompanyIncomeHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "appointmentDate", label: "Date", noSort: true },
  { id: "fileNumber", label: "File Number", noSort: true },
  { id: "_borrower", label: "Signer", noSort: true },
  { id: "_client", label: "Client", noSort: true },
  { id: "amount", label: "Fee", noSort: true },
  { id: "status", label: "Status", noSort: true },
  { id: "notes", label: "Notes", noSort: true },
  { id: "duePayment", label: "Balance Due", noSort: true },
  { id: "action", label: "Action", noSort: true },
  { id: "delete", label: "", noSort: true },
];

export const agentInvoiceHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "client", label: "Client", noSort: true },
  { id: "createdAt", label: "Date Created", noSort: true },
  { id: "paymentDate", label: "payment Date", noSort: true },
  { id: "closings", label: "Jobs", noSort: true },
  {
    id: "amount",
    label: "Amount",
    noSort: true,
    tooltip:
      "The amount displayed is the pending amount at the time of invoice creation.",
  },
  { id: "status", label: "Status", noSort: true },
  {
    id: "action",
    label: "Action",
    noSort: true,
    tooltip: "The amounts displayed in the PDF reflect real-time data.",
  },
];

export const filterDropdownValuesForInvoice = {
  amount: [
    { name: "0 - $50", value: { min: 0, max: 50 } },
    { name: "$50 - $75", value: { min: 50, max: 75 } },
    { name: "$75 - $100", value: { min: 75, max: 100 } },
    { name: "$100+", value: { min: 100 } },
  ],
  signingCompany: [],
  signingCompanyForDashboard: [],
};

export const companyInvoiceHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "client", label: "Client", noSort: true },
  { id: "createdAt", label: "Date Created", noSort: true },
  { id: "paymentDate", label: "payment Date", noSort: true },
  { id: "_closing", label: "Jobs", noSort: true },
  {
    id: "amount",
    label: "Amount",
    noSort: true,
    tooltip:
      "The amount displayed is the pending amount at the time of invoice creation.",
  },
  { id: "status", label: "Status", noSort: true },
  {
    id: "action",
    label: "Action",
    noSort: true,
    tooltip: "The amounts displayed in the PDF reflect real-time data.",
  },
];

export const mileageHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "travelDate", label: "Date", noSort: true },
  { id: "purpose", label: "File Number", noSort: true },
  { id: "startAddress", label: "Start Location", noSort: true },
  { id: "stopAddress", label: "Stop Location", noSort: true },
  { id: "endAddress", label: "End Location", noSort: true },
  { id: "distance", label: "Total Miles", noSort: true },
  { id: "amount", label: "Deduction", noSort: true },
  { id: "comments", label: "Comments", noSort: true },
  { id: "action", label: "Action", noSort: true, className: "action" },
];

export const notarialLogHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "_borrower", label: "NAME", noSort: true },
  { id: "fileNumber", label: "FILE NUMBER", noSort: true },
  { id: "_client", label: "CLIENT", noSort: true },
  { id: "closingAppointmentDate", label: "DATE", noSort: true },
  { id: "notarialActs", label: "NOTARIAL ACTS", noSort: true },
  { id: "amountPerAct", label: "AMOUNT PER ACT", noSort: true },
  { id: "totalPotential", label: "TOTAL POTENTIAL", noSort: true },
  {
    id: "plTotal",
    label: `TOTAL (NOT TO EXCEED ORDER TOTAL)`,
    noSort: true,
  },
  { id: "action", label: "Action", noSort: true },
];

export const orderClientDataHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "name", label: "Name", noSort: true },
  { id: "email", label: "Email", noSort: true },
  { id: "phone", label: "Phone", noSort: true },
  { id: "pointOfContact", label: "Point Of Contact", noSort: true },
  { id: "status", label: "Status", noSort: true },
  {
    id: "action",
    label: "Action",
    noSort: true,
    style: { textAlign: "center", width: 100 },
  },
];

export const clientOrderHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "appointmentDate", label: "Signer", noSort: true },
  { id: "fileNumber", label: "File#", noSort: true },
  { id: "loanType", label: "Product Type", noSort: true },
  { id: "status", label: "Status", noSort: true },
  // { id: "_signingCompany", label: "Assigned Company", noSort: true },
  // { id: "lender", label: "Client", noSort: true },
  { id: "docs", label: "Docs", noSort: true },
  { id: "closingAddress", label: "Location", noSort: true },
  // { id: "createdBy", label: "Created On Behalf Of", noSort: true },
  { id: "trackOrder", label: "Track Shipment", noSort: true },
];

export const profitLossReportingCompanyDatasets = [
  {
    label: "A",
    backgroundColor: "rgba(30, 41, 92, 0.3)",
    borderColor: "rgba(30, 41, 92, 1)",
    borderWidth: 1,
    hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
    hoverBorderColor: "rgba(30, 41, 92, 1)",
    data: [2, 3, 4, 6, 8, 2, 1, 6, 3, 4, 2, 6],
  },
  {
    label: "B",
    backgroundColor: "rgba(7, 104, 157, 0.3)",
    borderColor: "rgba(7, 104, 157, 1)",
    borderWidth: 1,
    hoverBackgroundColor: "rgba(7, 104, 157, 0.5)",
    hoverBorderColor: "rgba(7, 104, 157, 1)",
    data: [6, 2, 5, 3, 6, 3, 10, 2, 8, 6, 4, 3],
  },
];

export const receiptHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "createdAt", label: "Date", noSort: true },
  { id: "item", label: "Expense Type", noSort: true },
  { id: "amount", label: "Amount", noSort: true },
  { id: "closing#", label: "Closing#", noSort: true },
  { id: "client", label: "Client", noSort: true },
];
