import React from "react";
import { Badge } from "reactstrap";
import {
  getAllNotifications,
  markAllNotificationRead,
} from "../../../http/http-calls";
import {
  errorHandler,
  formatDateAndTimeForNotificationPage,
  isDateTimeToday,
} from "../../../helper-methods";
import { connect } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import SkeletonLoading from "../components/Skeletons/SkeletonLoading";
import ScreenWidthHOC from "./ScreenWidthHOC";

class Notifications extends React.Component {
  state = {
    notificationsList: [],
    loading: false,
  };

  //This function used for mark as read notification
  _markAllNotificationRead = async () => {
    try {
      await markAllNotificationRead();
    } catch (error) {
      errorHandler(error);
    }
  };

  //Get all read notifications
  _getAllNotifications = async () => {
    try {
      this.setState({ loading: true });

      const { userData } = this.props;

      const response = await getAllNotifications();

      if (response) {
        let unreadNotifications = response?.notifications.filter(
          (e) => !e.isRead
        );

        userData.user.unreadnotificationsCount = unreadNotifications.length;

        this.props.updateUserObj(userData.user);

        this.setState({ notificationsList: response?.notifications });
      }

      this.setState({ loading: false });
    } catch (error) {
      errorHandler(error);
    }
  };

  componentDidMount() {
    this._getAllNotifications();
    this._markAllNotificationRead();
  }

  //This function handle click on a particular notification then go to this page handler
  _goToPage = async (data) => {
    const { userData } = this.props;

    if (
      this.props.userData?.type === "signingcompany" &&
      this.props.userData?.user?.isAssistant
    ) {
      // console.log("assistant can't click on notifictions");
      return;
    }

    if (userData.type === "signingcompany") {
      if (data._closing) {
        if (data.title === "New Bid Received") {
          this.props.history.push(
            `/signingcompany/closing-dashboard?_closingid/${data._closing}`
          );
        } else {
          this.props.history.push(
            `/signingcompany/closing-details/${data._closing}`
          );
        }
      } else if (data.title === "CloseWise Connect Invitation") {
        this.props.history.push(
          `/signingcompany/client-details/${data._fromClient}`
        );
      } else {
        this.props.history.push(`/signingcompany/dashboard`);
      }
    }
    if (userData.type === "agent") {
      if (data._closing) {
        if (data.title === "New Closing Available") {
          this.props.history.push(`/agent/new-closing/${data._closing}`);
        } else {
          this.props.history.push(
            `/agent/closing-details-for-agents/${data._closing}`
          );
        }
      } else {
        this.props.history.push(`/agent/dashboard`);
      }
    }
    if (userData.type === "client") {
      if (data._from) {
        if (data.title === "CloseWise Connect Invitation") {
          this.props.history.push(
            `/client/signing-company-details/${data._from._id}`
          );
        } else {
          this.props.history.push(`/client/order-details/${data._closing}`);
        }
      }
    }
  };

  render() {
    let newNotification = this.state.notificationsList.filter((item) =>
      isDateTimeToday(item.createdAt)
    );
    let oldNotification = this.state.notificationsList.filter(
      (item) => !isDateTimeToday(item.createdAt)
    );
    const { isForMobile } = this.props;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Notifications</h2>
          </div>

          <>
            <div className="notificationWrapper">
              <h6>
                New <Badge pill>{newNotification.length}</Badge>
              </h6>

              <ul>
                {this.state.loading ? (
                  <SkeletonLoading
                    type="card"
                    count={5}
                    height={isForMobile ? 100 : 76}
                    width={isForMobile ? 350 : 1550}
                  />
                ) : this.state.notificationsList.length > 0 ? (
                  newNotification.length > 0 ? (
                    newNotification.map((each) => {
                      return (
                        <li
                          onClick={() => {
                            this._goToPage(each);
                          }}
                        >
                          {/* this dot div will show when user not readed notification once read dot div will not show */}
                          {!each.isRead && <div className="dot"></div>}
                          <div className="notificationHeader">
                            <h3>{each.title}</h3>
                            <span>
                              {formatDateAndTimeForNotificationPage(
                                each.createdAt
                              )}
                            </span>
                          </div>
                          <div className="notificationContent">
                            <p>{each.text}</p>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <>
                      <div className="nodata">
                        <img
                          src={
                            require("../../../assets/img/noData.svg").default
                          }
                          alt="no data"
                        />
                        <p>No New Notification !</p>
                      </div>
                    </>
                  )
                ) : null}
              </ul>
            </div>
            <div className="notificationWrapper">
              <h6>
                Earlier{" "}
                <Badge color="success " pill>
                  {oldNotification.length}
                </Badge>
              </h6>
              <ul>
                {this.state.loading ? (
                  <SkeletonLoading
                    type="card"
                    count={5}
                    height={isForMobile ? 100 : 76}
                    width={isForMobile ? 350 : 1550}
                  />
                ) : this.state.notificationsList.length > 0 ? (
                  oldNotification.map((each) => {
                    return (
                      <li
                        onClick={() => {
                          this._goToPage(each);
                        }}
                      >
                        {/* this dot div will show when user not readed notification once read dot div will not show */}
                        {!each.isRead && <div className="dot"></div>}
                        <div className="notificationHeader">
                          <h3>{each.title}</h3>
                          <span>
                            {formatDateAndTimeForNotificationPage(
                              each.createdAt
                            )}
                          </span>
                        </div>
                        <div className="notificationContent">
                          <p>{each.text}</p>
                        </div>
                      </li>
                    );
                  })
                ) : null}
              </ul>
            </div>
          </>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (userData) => dispatch(updateUserObj(userData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(Notifications));
