import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledCollapse,
  CardHeader,
} from "reactstrap";
import { connect } from "react-redux";

class FAQCOMPANY extends React.Component {
  _redirectToContact = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/contact`);
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <div className="pgTitleWrap">
                {/* <h4>FAQ</h4> */}
              </div>
              <div className="faqSection">
                <h2 className="faqSectionHeader">About CloseWise</h2>

                <Card>
                  <CardHeader id="toggler">
                    I currently use another method for managing my closings, why
                    should I use CloseWise?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler">
                    <CardBody>
                      <p>
                        {" "}
                        CloseWise is the best and most affordable all in one
                        business solution for Loan Closing Software on the web.
                        We take a contemporary approach to creating software for
                        signing services, title companies, and notaries to
                        manage and attract more business. Our cloud-based
                        pay-as-you go software has been designed by industry
                        veterans and made to be intuitive enough for new comers
                        to the industry. Closwise continues to improve its
                        workflow automation with input from our users on a
                        regular basis. This helps us to help you deliver the
                        best signing experience possible to your client and
                        saves you time and money.
                      </p>
                      <h6 className="font-weight-bold mt-3">
                        Safeguard Your Clients’ Sensitive Data
                      </h6>
                      <p>
                        Transfer sensitive documents securely and have access to
                        a log of all interactions with an order you can share to
                        reduce your risk in the case of an audit or a dispute.
                      </p>
                      <h6 className="font-weight-bold mt-3">
                        Automate Your Workflow
                      </h6>
                      <p>
                        Save time and money by managing all signings and
                        communications from the CloseWise dashboard. Here
                        signing services, notaries, and escrow agents can
                        cooperate to smoothly view orders, assign notaries, send
                        documents securely, update order status, send invoices,
                        and receive payments.
                      </p>
                      <h6 className="font-weight-bold mt-3">
                        Accounting and Reporting
                      </h6>
                      <p>
                        Not only do we make your workflow more efficient, we
                        automate reporting for mileage and sending invoices.
                        This way you don’t have to spend valuable time
                        organizing, inputting data on extra platforms, or
                        sending emails. Save hours and reduce costs – spend more
                        time growing revenue and less time on daily business
                        maintenance.
                      </p>
                      <p>
                        We have a dedicated engineering team that is constantly
                        improving our product based on your feedback. Developing
                        the best all in one business management software for the
                        signing industry is our mission. Special inquiries can
                        be sent support@closewise.com.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler1">
                    How will CloseWise help me find the best notaries?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler1">
                    <CardBody>
                      <p>
                        {" "}
                        CloseWise has a continuously growing database of
                        notaries in the United States, with comprehensive
                        information about each notary. For each notary our
                        search results present full profiles with photos, work
                        history, number of signings completed, and base fees for
                        various signing types. Once you’ve begun working with a
                        notary you can access their notary documents any time,
                        including commission, W-9, certifications and more.
                      </p>
                      <p>
                        When searching for the right closing agent, CloseWise
                        notary bidding system will suggest the closest notaries
                        first. You can automatically text and email your
                        top-picks through the CloseWise platform to determine
                        availability and assign a notary. Save hours you used to
                        spend on the phone calling notaries. Scheduling is all
                        done with a few clicks with our software – all you have
                        to do is click and wait for your notary responses to
                        avoid hours of work.
                      </p>
                      <p>
                        CloseWise also offers incentives to companies who invite
                        new agents onto the platform using the Invite Agent
                        Feature!{" "}
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler2">
                    What do you mean by loan closing automation?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler2">
                    <CardBody>
                      <p>
                        {" "}
                        There are so many repetitive tasks performed throughout
                        the loan closing process that require hours of time.
                        CloseWise software can take over the heavy lifting and
                        accomplish these tasks in milliseconds so that you can
                        be freed up to focus on initiatives that will grow your
                        business. We automate notary search, email and text
                        notifications, business performance data analysis, and
                        much more. CloseWise will do the business maintenance
                        for you.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler3">
                    Security is critical, how do I know our customers’ and
                    notaries' personal information will be safe?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler3">
                    <CardBody>
                      <h6 className="font-weight-bold mt-3">
                        Secure Document Transfer
                      </h6>
                      <p>
                        CloseWise worries about security so you don’t have to.
                        Especially now with the CFPB’s new mortgage rule
                        changes, CloseWise sees information security as
                        paramount. CloseWise uses SSL (secure sockets layer)
                        security technology to send all emails and documents so
                        you never have to worry again about whether your
                        signers’ personal identity and financial information is
                        protected.
                      </p>
                      <h6 className="font-weight-bold mt-3">What is SSL? </h6>
                      <p>
                        It is a security protocol for technology that secures
                        the communication between Internet users and their
                        service providers. SSL is what online banks use to make
                        sure your financial transactions are secure. In short,
                        it’s what’s happening in the browser whenever you see
                        the little lock icon in your web browser’s address bar.
                        It is required that as a company transferring secure
                        data you also have an SSL certificate for your website.
                        If you do not have this yet, we will help guide you
                        through the process, so you can make sure you are
                        adhering to all security and compliance requirements.{" "}
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler4">
                    Customer service is important to us, how will CloseWise help
                    us ensure the best signing experience for our customers?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler4">
                    <CardBody>
                      <p>
                        We know you aim to deliver the best, hassle-free
                        customer service to your loan signers, and we love that
                        we can help you do that. Signing on the dotted line for
                        a mortgage can be a stressful and confusing experience
                        for consumers, but CloseWise helps you communicate more
                        often and easier with all parties in the process to help
                        avoid errors and build confidence for signers. You can
                        set your account to send automatic reminders to notaries
                        about downloading signing documents and upcoming
                        signings to make sure they are on time and prepared for
                        each loan signing. You can automatically send
                        information to your clients, including notary profiles
                        and documents, to help them feel prepared and
                        comfortable with the closing process.
                      </p>{" "}
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler5">
                    What is your white label branding?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler5">
                    <CardBody>
                      <p>
                        {" "}
                        With CloseWise’ white label branding option you
                        experience the benefits of CloseWise, but all under the
                        umbrella of your own company brand. Some of our
                        customers opt to keep the CloseWise branding visible
                        under their own company brand to demonstrate their
                        alignment with a strong mortgage technology and security
                        company. Alternatively, some customers opt to take part
                        in our white label program which allows them to treat
                        CloseWise as an invisible technology and security layer.
                        You will choose the subdomain of your choice. You can
                        choose from just your company name (ie:
                        orders.yourcompany.com) or your company name combined
                        with the CloseWise name (ie: yourcompany.CloseWise.com).
                        Additionally, you can set up your email notifications to
                        be branded with your own or CloseWise’ branding.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler7">
                    Does CloseWise allow me to analyze the performance of my
                    business?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler7">
                    <CardBody>
                      <p>
                        Yes! CloseWise has an analytics dashboard that helps you
                        see how your business is performing. Set the dates for
                        the time period you want to analyze and your company
                        data for that period will be visually represented on
                        your analytics dashboard. You will see a summary of the
                        number of different types of orders scheduled,
                        completed, canceled, on-hold, and
                        not-signed-at-the-table, along with a bar graph showing
                        your orders per month. You can see
                        performance-by-employee and orders-per-client, along
                        with your fees, notary fees, average margin, and net per
                        client. See our
                        <a href="https://blog.snapdocs.com/2013/03/10/mortgage-analytics/">
                          {" "}
                          blog post
                        </a>{" "}
                        about how our mortgage analytics page can help you.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler8">
                    How do I sign up to utilize CloseWise for my business?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler8">
                    <CardBody>
                      Two ways:
                      <ol>
                        <li>
                          {" "}
                          Go to www.CloseWise.com/???? and request a demo or
                        </li>
                        <li>
                          {" "}
                          Go to app.closewise.com and register a company
                          account. Once this is done a closewise representative
                          will contact you about pricing and account activation!{" "}
                        </li>
                      </ol>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler9">
                    Does Closewise provide training tutorials?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler9">
                    <CardBody>
                      <p>
                        Yes! We built CloseWise to be as intuitive as possible,
                        but if you need help getting the most out of closewise
                        you can create a ticket at the link below and our team
                        will reach out immediately.
                        <a
                          href="http://help.closewise.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Help.closewise.com
                        </a>
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler10">
                    Can I adjust my notification settings?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler10">
                    <CardBody>
                      <p>
                        Only company Admins can adjust notification settings. To
                        update these in the admin account:
                      </p>
                      <p>
                        Check notification settings by clicking the settings
                        icon at the top right of your screen when logged into
                        your account. On this page you can select or unselect
                        the types of notifications assistance you would like to
                        receive.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler11">
                    Why won’t my confirmations upload automatically?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler11">
                    <CardBody>
                      <p>
                        <li>
                          {" "}
                          First, make sure you have a CloseWise Pro account{" "}
                        </li>
                        <li>Make sure your internet connection is working </li>
                        <li>
                          Confirmations that will upload currently include:
                        </li>
                        -Snapdocs email, Signing Order, ServiceLink, Timios,
                        Mortgage Connect. <br />
                        -If these don't upload you can submit a ticket @ <br />
                        To request a new auto-confirmation upload type submit
                        your request @{" "}
                        <a
                          href="http://help.closewise.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Help.closewise.com
                        </a>{" "}
                        and a member of our team will reach out to you
                        immediately.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler12">
                    {/* Why won’t my confirmations upload automatically? */}
                    Can I invite notary or attorney signing agents onto the
                    platform?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler12">
                    <CardBody>
                      <p>
                        Yes - All agents can be invited onto the platform from
                        your agents page / Invite button in the top right
                        corner.
                      </p>{" "}
                      <p>
                        Closewise offers a referral program for all new agents
                        brought onto the platform by your company. In some cases
                        this allows some of our users to pay for their user
                        subscription or even make an additional income on top of
                        paying for their subscription.
                      </p>{" "}
                      <p>
                        To register to the agent referral program contact
                        <a
                          href="http://help.closewise.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Help.closewise.com
                        </a>{" "}
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler13">
                    {/* Why won’t my confirmations upload automatically? */}
                    Does Closewise have title software integrations?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler13">
                    <CardBody>
                      <p>
                        CloseWise handles client integrations on a case by case
                        basis. This allows us to keep the cost to all users as
                        low as possible while still providing a premium service.
                      </p>
                      <p>
                        To request integrations with title softwares please
                        submit a ticket at the link below, and a member of the
                        support team will contact you as soon as possible.
                      </p>
                      <a
                        href="http://help.closewise.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Help.closewise.com
                      </a>{" "}
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler14">
                    {/* Why won’t my confirmations upload automatically? */}
                    Why don’t I see attorneys in my available agent list?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler14">
                    <CardBody>
                      <p>
                        While all notaries are listed publicly - all closing
                        attorneys have to be directly invited by companies who
                        wish to use them. If an attorney is already registered
                        on the platform you can send an agent invite to their
                        email and they will immediately populate in your agent
                        list.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler15">
                    My question is not included in the FAQ’s - can I create a
                    general support ticket?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler15">
                    <CardBody>
                      <p>
                        <a
                          href="http://closewise.freshdesk.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Help.closewise.com
                        </a>{" "}
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(FAQCOMPANY);
