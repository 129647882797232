import React from "react";
import { Button, Input, Label } from "reactstrap";
import {
  capitalize,
  checkPermission,
  formatAddressInSingleText,
  formatDateAsPerTimeZOne,
  getDropdownColor,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
} from "../../../helper-methods";
import { agentClosingStatus } from "../../../config";
import { openTrackingDetails } from "../../../config/trackingConfig";

const ClientOrderDashboardCardDataFormat = ({
  row,
  _redirectToClosingDetails,
  _redirectToOrderDetails,
  loading,
  _onChangeOrderStatus,
}) => {
  return (
    <ul className="cardInfo">
      <li>
        <div className="dataWrap">
          <Label>File </Label>
          <div
            className="text-primary cursorPointer"
            style={{ fontWeight: 600 }}
          >
            {row?.fileNumber ? (
              <div
                className="tableLink"
                style={{
                  whiteSpace: "normal",
                  textDecoration: "underline",
                }}
                onClick={() => _redirectToClosingDetails(row)}
              >
                {row?.fileNumber || "N/A"}
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </div>

        <div className="dataWrap">
          <Label>Product Type </Label>
          <div>
            {
              <>
                <span
                  style={{
                    marginRight: 5,
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  {row.signingType}
                </span>

                {row?.loanType && row?.loanType.length > 0 ? (
                  row?.loanType.indexOf("Other") > -1 ? (
                    <span
                      style={{
                        marginRight: 5,
                        fontWeight: 600,
                      }}
                    >
                      {row?.loanTypeOther}
                    </span>
                  ) : null
                ) : null}
                {row?.loanType
                  .filter((each) => each !== "Other")
                  .map((item) => (
                    <>
                      <span
                        style={{
                          marginRight: 5,
                          fontWeight: 600,
                        }}
                      >
                        {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </span>
                    </>
                  ))}
              </>
            }
          </div>
        </div>
      </li>

      <li>
        <div className="dataWrap  d-flex align-items-center justify-content-between">
          <Label>Status: </Label>

          {row?.status ? (
            <div
              className="customSelectWrap"
              style={{ width: 120, marginRight: "0" }}
            >
              <Input
                type="select"
                name="status"
                className={`status ${getDropdownColor(row?.status)}`}
                disabled={
                  loading.statusChangeLoading ||
                  !(
                    checkPermission(
                      "dashboard",
                      "canUpdateClosingStatusOrScheduling"
                    ) || checkPermission("dashboard", "canEditOrderEntry")
                  )
                }
                value={row?.status}
                onChange={(event) =>
                  _onChangeOrderStatus(row, event.target.value)
                }
              >
                {agentClosingStatus.map((obj) => (
                  <option
                    key={obj.value}
                    value={obj.value}
                    disabled={
                      obj.value === "cca" ||
                      obj.value === "arrived" ||
                      obj.value === "closed" ||
                      obj.value === "dnc" ||
                      row?.status === "closed" ||
                      row?._agent
                    }
                  >
                    {obj.label}
                  </option>
                ))}
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          ) : (
            "N/A"
          )}
        </div>
      </li>

      {/* <li>
        <div className="dataWrap">
          <Label>Assigned Company </Label>
          <div
            style={{
              fontWeight: 600,
            }}
          >
            {
              <>
                {(row?._signingCompany?.companyName &&
                  capitalize(row?._signingCompany?.companyName)) || (
                  <>
                    N/A <span className="mr-1">{row?._noOfBidding}</span>{" "}
                  </>
                )}

                {row?.appointmentDate &&
                !row?._agent &&
                (row?.status === "Pending" || row?.status === "Cancelled") ? (
                  <Button
                    color="link"
                    onClick={() => _onToggleClientAssignOrder(true, row)}
                    disabled={
                      !checkPermission("dashboard", "canEditOrderEntry")
                    }
                    className="px-1"
                  >
                    <img
                      src={require("../../../assets/img/pencil.svg").default}
                      style={{ height: 12 }}
                      alt="edit"
                      loading="lazy"
                    />
                  </Button>
                ) : null}
              </>
            }
          </div>
        </div>
      </li> */}

      <li>
        <div className="dataWrap">
          <Label>Docs</Label>

          <div className="docStatus">
            {row ? (
              <>
                {/* Last Uploaded by Company: $DateTime */}
                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    row?.companyLastDocumentUploadedAt &&
                    `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                      row?.companyLastDocumentUploadedAt,
                      row?.closingAddress?.timeZone
                    )}`
                  }
                  onClick={() =>
                    row.isDocumentCompleted ? _redirectToOrderDetails(row) : {}
                  }
                >
                  {row?.documents?.length && !row.documents[0].isPrivate ? (
                    row.isDocumentCompleted ? (
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    ) : row?.isDocumentUploadedByCompanyOrClient ? (
                      <span
                        className="fa fa-circle text-warning"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    ) : (
                      <span
                        className="fa fa-circle text-primary"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    )
                  ) : (
                    <span
                      className="fa fa-circle text-primary"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )}
                  <span className="value">
                    {row.isDocumentCompleted
                      ? "Docs Ready"
                      : row?.isDocumentUploadedByCompanyOrClient
                      ? "Pending"
                      : "Not Ready"}
                  </span>
                </Button>

                {/* First downloaded by Agent: DateTime */}
                {/* turns green only when agent downloaded all documents  */}
                {/* isAgentDocumentDownloaded */}
                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    row?.isAgentViewDocumentTime &&
                    `First downloaded by Agent: ${formatDateAsPerTimeZOne(
                      row?.isAgentViewDocumentTime,
                      row?.closingAddress?.timeZone
                    )}`
                  }
                >
                  {row.isAllDocumentsDownloaded ? (
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  ) : (
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )}
                  <span className="value">
                    {row?.isAllDocumentsDownloaded
                      ? "Docs Downloaded"
                      : "Pending"}
                  </span>
                </Button>

                {/* Last Uploaded by Agent: DateTime */}

                {/* once a company create a closing and Selects the "scan back is required" check box and assigns the closing, 
            1. The third button will turn red(the rest two will remain gray), 
            2. When the agent uploads any doc and selects "scan back upload completed" it will turn yellow then 
            3. When the company selects "verified agent doc" it will turn green. */}

                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    row?.agentLastDocumentUploadedAt &&
                    `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                      row?.agentLastDocumentUploadedAt,
                      row?.closingAddress?.timeZone
                    )}`
                  }
                >
                  {row.isScanBackNeeded ? (
                    row.isDocumentVerified ? (
                      <>
                        <span
                          className="fa fa-circle text-success"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span className="value">Shipping Approved</span>
                      </>
                    ) : row.isDocumentCompletedByAgent ? (
                      <>
                        <span
                          className="fa fa-circle text-primary"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span
                          className="value"
                          onClick={() => _redirectToOrderDetails(row)}
                        >
                          Scan Upload
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className="fa fa-circle text-danger"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span className="value">Scan Needed</span>
                      </>
                    )
                  ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                    row.isDocumentCompletedByAgent ? (
                      <>
                        <span
                          className="fa fa-circle text-success"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span className="value">Verified</span>
                      </>
                    ) : (
                      <span
                        className="fa fa-circle"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    )
                  ) : (
                    <span
                      className="fa fa-circle"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )}
                </Button>
              </>
            ) : (
              "N/A"
            )}
          </div>
        </div>

        <div className="dataWrap">
          <Label>Created on Behalf Of</Label>
          <div
            style={{
              fontWeight: 600,
            }}
          >
            {row?._createdBy && row?._createdBy?.companyName
              ? capitalize(row?._createdBy?.companyName)
              : row?._createdByClient && row?._createdByClient?.companyName
              ? capitalize(row?._createdByClient?.companyName)
              : "N/A"}
          </div>
        </div>
      </li>

      <li>
        <div className="dataWrap">
          <div className="d-flex justify-content-between">
            <Label>Location </Label>
            <div>
              <Button
                color="link"
                className="p-0"
                title={formatAddressInSingleText(row?.closingAddress)}
                onClick={() => openGoogleMapOnNewTab(row?.closingAddress)}
              >
                <img
                  src={
                    require("../../../assets/img/Google_Maps_Logo_2020.svg")
                      .default
                  }
                  alt="map icon"
                  className="mr-1"
                  loading="lazy"
                />
                {/* Google Map */}
              </Button>
              <Button
                color="link"
                className="p-0"
                title={formatAddressInSingleText(row?.closingAddress)}
                onClick={() => openAppleMapOnNewTab(row.closingAddress)}
              >
                <img
                  src={
                    require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                      .default
                  }
                  alt="map icon"
                  className="mr-1"
                  loading="lazy"
                />
                {/* Apple Map */}
              </Button>
            </div>
          </div>
          <div style={{ fontWeight: 600, flex: 1, marginRight: 10 }}>
            {formatAddressInSingleText(row?.closingAddress) || "N/A"}
          </div>
        </div>
      </li>

      <li>
        <div className="dataWrap  d-flex align-items-center justify-content-between">
          <Label>Track: </Label>

          <Button
            color="primary"
            onClick={() =>
              openTrackingDetails(row?.shippingCompany, row?.trackingInfo)
            }
            disabled={!row?.shippingCompany?.trim()}
          >
            Track Shipment
          </Button>
        </div>
      </li>
    </ul>
  );
};

export default ClientOrderDashboardCardDataFormat;
