import { 
    ADD_LIST_DATA, 
    CLEAR_LIST_DATA,
} from "../actions";

const initialState = {
    list:null
}

export const listDataReducer = (state = initialState, action) => {
    let newState = { ...state };
    switch (action.type) {
        case ADD_LIST_DATA: {
            newState = action.payload.listData;
            break;
        }
        case CLEAR_LIST_DATA: {
            newState = {};
            break;
        }
        default: {
            
        }
    }
    return newState;
}
