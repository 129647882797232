import React, { useState, useEffect } from "react";
import { Button, Input, Label, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import {
  deepClone,
  errorHandler,
  separateCamelCase,
  setThemeColor,
  updateCompanyName,
  updateFavIcon,
} from "../../../helper-methods";
import { customWebsiteDetail } from "../../../http/http-calls";
import { getPhoneNumberFromBrackets } from "../../../helper-methods";
import jwt_decode from "jwt-decode";
import PublicSideCarousel from "../components/PublicCarousel/PublicSideCarousel";
import TermsAndConditionModal from "../components/termsAndConditionModal";
import Gleap from "gleap";
import SignupForm from "../components/SignupForm";
import SvgIcons from "../../private/components/SvgIcons";

const createFormField = (value = "", isRequired = false) => ({
  value,
  error: null,
  isDirty: false,
  isRequired,
});

// This is for card view
// For Scheduling & Business Management
// OR
// For Notaries, Attorney, & Signing Agents
const UserTypeOption = ({
  id,
  userType,
  image,
  whiteImage,
  label,
  formFields,
  changeView,
  viewFor,
}) => {
  const isChecked = formFields.userType.value === userType;

  console.log("isChecked >>", isChecked);
  console.log("userType >>", userType);

  const onChange = () => {
    return changeView(viewFor, userType);
  };

  return (
    <Label for={id}>
      <Input
        hidden
        name="business management"
        type="radio"
        id={id}
        checked={isChecked}
        onChange={onChange}
      />
      <SvgIcons type={image} className="blueIcon" />
      <SvgIcons type={whiteImage} className="whiteIcon" />
      <span>{label}</span>
    </Label>
  );
};

const SignUp = (props) => {
  const [formFields, setFormFields] = useState({
    userType: createFormField("", true),
    viewFor: createFormField("", false),
    isTermsAndConditionsAccepted: createFormField(),
  });
  const [formHearder, setFormHeader] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [termsAndConditionModal, setTermsAndConditionModal] = useState({
    isOpen: false,
    data: null,
  });
  const [companyDetail, setCompanyDetail] = useState({});
  const [decodedData, setDecodedData] = useState(null);

  // for open form view based on user type
  const changeView = (viewFor, userType) => {
    let newFormFields = deepClone(formFields);
    newFormFields.userType.value = userType;
    newFormFields.viewFor.value = viewFor;

    setShowMenu(!showMenu);
    setFormFields(newFormFields);
    setFormHeader(userType);

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("userType", userType);
    const newUrl = window.location.pathname + "?" + urlParams.toString();
    window.history.pushState(null, "", newUrl);
  };

  const handleBackToCreateAccoutOption = () => {
    setShowMenu(!showMenu);
    const newUrl = window.location.pathname;
    window.history.pushState(null, "", newUrl);
    let newFormFields = deepClone(formFields);
    newFormFields.userType.value = "";
    newFormFields.viewFor.value = "";
    setFormFields(newFormFields);
  };

  const extractUserType = (url) => {
    const queryString = url.split("?")[1];
    const params = new URLSearchParams(queryString);
    return params.get("userType");
  };

  // this function return the user type by decoded token
  const getUserTypeValue = (invitedUserType, decoded) => {
    if (invitedUserType === "Agent") {
      return decoded.workType;
    }

    return invitedUserType === "Client"
      ? "ClientTitle&Escrow"
      : "SigningCompanyTitle&Escrow";
  };

  const getFormFieldValue = (field, value) => {
    if (field === "name") {
      return `${value?.first || ""} ${value?.last || ""}`;
    }

    if (field === "phone") {
      return getPhoneNumberFromBrackets(value);
    }

    return value;
  };

  const termsAndConditionModalToggle = (isOpen = false, data = {}) => {
    setTermsAndConditionModal({
      isOpen,
      data,
    });
  };

  // check the userType and return a Boolean value that indicates
  // whether form fields should show or not
  const shouldShowForm = (userType) => {
    const hostnames = [
      "app.closewise.com",
      "localhost",
      "devapp.closewise.com",
      "beta.closewise.com",
    ];
    const pathSegment = props.history.location.pathname.split("/")[2];

    if (userType === "SigningCompany") {
      return (
        showMenu &&
        (decodedData === null ||
          decodedData?.invitedUserType === "SigningCompany") &&
        !props?.history?.location?.search.includes("code") &&
        hostnames.includes(window.location.hostname)
      );
    } else if (userType === "Client") {
      return (
        showMenu &&
        pathSegment !== undefined &&
        (decodedData === null || decodedData?.invitedUserType === "Client")
      );
    } else if (userType === "Agent") {
      return (
        showMenu &&
        (decodedData === null || decodedData?.invitedUserType === "Agent")
      );
    }
  };

  useEffect(() => {
    // Hide Gleap in this page
    Gleap.showFeedbackButton(false);

    const pathSegments = props.history.location.pathname.split("/");
    const token = pathSegments[2];

    if (token) {
      // if user comes from invitation link
      const decoded = jwt_decode(token);
      const newFormFields = { ...formFields };

      Object.entries(decoded).forEach(([decodedKey, decodedValue]) => {
        if (decodedKey === "invitedUserType") {
          newFormFields.userType.value = getUserTypeValue(
            decodedValue,
            decoded
          );
          newFormFields.viewFor.value =
            decoded.workType || decoded.invitedUserType;
        }

        if (newFormFields[decodedKey]) {
          newFormFields[decodedKey].value = getFormFieldValue(
            decodedKey,
            decodedValue
          );
        }
      });

      setFormHeader(decoded.workType);
      setFormFields(newFormFields);
      setShowMenu(decoded.invitedUserType === "Agent" && !decoded.workType);
      setDecodedData(decoded);
    } else {
      setShowMenu(true);
    }

    // let userType = extractUserType(this.props.history.location.search);
    let userType = extractUserType(props.history.location.search);

    if (userType) {
      formFields["userType"].value = userType;
      setShowMenu(false);
      // this.setState({
      //   showMenu: false,
      //   formFields,
      // });
    }

    let payload = {
      site:
        window.location.hostname !== "localhost"
          ? window.location.host
          : "customizedwl.logic-square.com",
    };

    // this function fetch website data like: Theame
    customWebsiteDetail(payload)
      .then((res) => {
        setCompanyDetail(res.detail);
        if (!res.detail?.whiteLabelSetting?.isDefaultUiSelected) {
          let whiteLabelSetting =
            res.detail?.whiteLabelSetting?.customizedUiSetting;

          setThemeColor(whiteLabelSetting);
        } else {
          let whiteLabelSetting =
            res.detail?.whiteLabelSetting?.defaultUiSetting;

          setThemeColor(whiteLabelSetting);
        }
        if (res.detail) {
          updateCompanyName(res.detail);
          updateFavIcon(res.detail?.whiteLabelSetting);
        }
      })
      .catch((error) => {
        errorHandler(error);
      });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="loginWrapper">
        <div
          className={`loginLeft ${
            window.location.host !== "app.closewise.com" ? "whiteLabel" : ""
          }`}
        >
          {window.location.host === "app.closewise.com" ? (
            <PublicSideCarousel companyDetail={companyDetail} />
          ) : window.location.host === "app.closewise.com" ? (
            <img
              src={require("../../../assets/img/logBigIcon.png")}
              alt="logo big"
              className="bigLogo"
            />
          ) : companyDetail.logo ? (
            <img src={companyDetail.logo} alt="logo big" className="bigLogo" />
          ) : (
            <Spinner />
          )}
        </div>
        <div className="loginRight signUpPage">
          <img
            src={require("../../../assets/img/logBigIcon.png")}
            alt="logo big"
            className="bigLogo"
          />
          <div className="FormWrap">
            <div className="accountType">
              <h1>Create Account</h1>

              {!showMenu && formFields.userType.value?.length ? (
                <div className="currentType">
                  <Button color="link" onClick={handleBackToCreateAccoutOption}>
                    <img
                      src={require("../../../assets/img/arrowLeftBlack.png")}
                      alt="arrow"
                    />
                  </Button>{" "}
                  <span>
                    {formHearder
                      ? separateCamelCase(formHearder)
                      : separateCamelCase(formFields.userType.value)}
                  </span>
                </div>
              ) : null}

              {shouldShowForm("SigningCompany") ? (
                <div className="registrationWrap">
                  <h2>For Scheduling &amp; Business Management</h2>
                  <div className="registrationItem">
                    <UserTypeOption
                      formFields={formFields}
                      id="titleEscrow"
                      userType="SigningCompanyTitle&Escrow"
                      image="titlEscrow"
                      whiteImage="titlEscrowWhite"
                      label="Title &amp; Escrow"
                      changeView={changeView}
                      viewFor="SigningCompany"
                    />
                    <UserTypeOption
                      formFields={formFields}
                      id="signingService"
                      userType="SigningCompanySigningService"
                      image="signingservice"
                      whiteImage="SigningService_white"
                      label="Signing Service"
                      changeView={changeView}
                      viewFor="SigningCompany"
                    />
                    <UserTypeOption
                      formFields={formFields}
                      id="lawfirms"
                      userType="SigningCompanyLawFirms"
                      image="lawfirmsOthers"
                      whiteImage="LawFirms_white"
                      label="Law Firms &amp; Others"
                      changeView={changeView}
                      viewFor="SigningCompany"
                    />
                  </div>
                </div>
              ) : shouldShowForm("Client") ? (
                <div className="registrationWrap">
                  <h2>For CloseWise Connect Order Placement</h2>
                  <div className="registrationItem company">
                    <UserTypeOption
                      formFields={formFields}
                      id="CloseWiseTitle"
                      userType="ClientTitle&Escrow"
                      image="title&escrow.svg"
                      whiteImage="Title&Escrow_white.svg"
                      label="Title &amp; Escrow"
                      changeView={changeView}
                      viewFor="Client"
                    />
                    <UserTypeOption
                      formFields={formFields}
                      id="CloseWiselenders"
                      userType="ClientLender"
                      image="lenders"
                      whiteImage="lenders_white"
                      label="Lenders"
                      changeView={changeView}
                      viewFor="Client"
                    />
                    <UserTypeOption
                      formFields={formFields}
                      id="CloseWiseLaw"
                      userType="ClientLaw"
                      image="lawfirms&others.svg"
                      whiteImage="LawFirms_white.svg"
                      label="Law Firms &amp; Others"
                      changeView={changeView}
                      viewFor="Client"
                    />
                  </div>
                </div>
              ) : null}
              {/* Show Only for Agent */}
              {shouldShowForm("Agent") ? (
                <div className="registrationWrap">
                  <h2>For Notaries, Attorney, & Signing Agents</h2>
                  <div className="registrationItem notaries">
                    <UserTypeOption
                      formFields={formFields}
                      id="Notaries"
                      userType="Notary"
                      image="notaries"
                      whiteImage="Notaries_white"
                      label="Notaries"
                      changeView={changeView}
                      viewFor="Notary"
                    />
                    <UserTypeOption
                      formFields={formFields}
                      id="Attorney"
                      userType="Attorney"
                      image="attorney"
                      whiteImage="Attorney_white"
                      label="Attorney"
                      changeView={changeView}
                      viewFor="Notary"
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {!showMenu && formFields.userType.value?.length ? (
              <SignupForm
                {...props}
                token={props.history.location.pathname.split("/")[2]}
                userType={formFields.userType.value}
                viewFor={formFields.viewFor.value}
              />
            ) : null}

            <p className="font-weight-Normal text-center mb-0 mt-3">
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
      <TermsAndConditionModal
        isOpen={termsAndConditionModal?.isOpen}
        data={termsAndConditionModal?.data}
        toggle={(isOpen, data) => termsAndConditionModalToggle(isOpen, data)}
        hideAcceptButton={true}
      />
    </>
  );
};

export default SignUp;
