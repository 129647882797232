import React, { useState } from "react";
import {
  Button,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const TermsAndConditionModal = ({
  isOpen,
  data,
  toggle,
  termLogin,
  hideAcceptButton,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const _handleSubmit = async () => {
    termLogin({ isTermsAndConditionsAccepted: true });
    toggle();
  };

  return (
    <Modal isOpen={isOpen} centered scrollable>
      <ModalHeader toggle={() => toggle()}>Terms And Condition</ModalHeader>
      <ModalBody className="termCondition">
        <FormGroup className="d-flex">
          <CustomInput
            type="checkbox"
            id="termsAndCondition"
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <Label>
            I have read and agree to the
            <Button
              color="link"
              onClick={() =>
                window.open("https://www.closewise.com/terms-and-conditions/")
              }
            >
              terms and Condition{" "}
            </Button>
            <Button
              color="link"
              onClick={() =>
                window.open("https://www.closewise.com/privacy-policy/")
              }
            >
              And Privacy policy{" "}
            </Button>
          </Label>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" outline onClick={() => toggle()}>
          Close
        </Button>
        {!hideAcceptButton && (
          <Button color="primary" disabled={!isChecked} onClick={() => _handleSubmit()}>
            Accept
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default TermsAndConditionModal;
