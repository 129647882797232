import React from "react";
import { CustomInput } from "reactstrap";
import { clientTeamMembersConfig } from "../../../config";

const TeamMemberPermissions = ({
  headingText,
  each,
  index,
  subIndex = undefined,
  onChangeTeamMemberOrCCMember,
  onBlurFormFields = null,
}) => {
  return (
    <div className="PermissionsList">
      <h2>{headingText}</h2>

      {console.log("qwerty ", { subIndex }, { each })}
      {clientTeamMembersConfig?.map((permissionKey) => (
        <CustomInput
          key={permissionKey?.value + index}
          type="checkbox"
          id={`memberPermissionCheckbox_${permissionKey?.value}_${index}${
            subIndex ? "_" + subIndex : ""
          }`}
          label={permissionKey?.label}
          inline
          checked={each?.permissions?.[permissionKey?.value]}
          onChange={(e) =>
            onChangeTeamMemberOrCCMember(
              permissionKey?.value,
              e.target.checked,
              // each?.type,
              index,
              "permissions",
              subIndex
            )
          }
          onBlur={() =>
            onBlurFormFields
              ? onBlurFormFields("permissions", "members", index)
              : null
          }
          disabled={
            (each?.permissions?.noEmails || each?.permissions?.allEmails) &&
            !each?.permissions[permissionKey?.value]
          }
        />
      ))}
      {each?.errors?.permissions ? (
        <p className="validation-error">{each?.errors?.permissions}</p>
      ) : null}
    </div>
  );
};

export default TeamMemberPermissions;
