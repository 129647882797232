import React, { useEffect, useState } from "react";
import {
  capitalize,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatOnlyDateMoment,
  getDateRangeValueSummarie,
  isRegularUser,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  agentGetAllUnregisteredClient,
  agentGetReportingSummaries,
  companyGetListTeamsOfClient,
  getAllListClients,
  signingCompanySummary,
} from "../../../http/http-calls";
import { monthShortNames } from "../../../config";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import AgentCompanySummariesFilter from "../components/AgentCompanySummariesFilter";
import AgentCompanySummariesCard from "../components/AgentCompanySummariesCard";
import { useDispatch, useSelector } from "react-redux";
import AgentCompanySummariesGraph from "../components/AgentCompanySummariesGraph";
import { addListData } from "../../../redux/actions/list";
import SvgIcons from "../components/SvgIcons";

const AgentCompanySummaries = () => {
  const userData = useSelector((state) => state?.userData);
  const dispatch = useDispatch();
  const history = useHistory();
  const [filters, setFilters] = useState({
    dateRangeValue: null,
    customDateRangeValue: "month",
    client: "",
    clientTeam: "",
  });
  const [filterDropdownValues, setFilterDropdownValues] = useState({
    signingCompany: [],
    clients: [],
    clientTeamsList: [],
  });
  const [cardList, setCardList] = useState({
    incomes: 0,
    closings: 0,
    expenses: 0,
    net: 0,
  });

  const [orderGraphData, setOrderGraphData] = useState({
    colors: ["#07B8E7"],
    tooltipName1: "Order #",
    series1: [],
  });

  const [incomeGraphData, setIncomeGraphData] = useState({
    colors: ["#4C96CE"],
    tooltipName1: "Income",
    series1: [],
    formatter1: (value) => formatCurrencyValue(value || "0"),
  });

  const [expenseAndNetGraphData, setExpenseAndNetGraphData] = useState({
    colors: ["#07B8E7", "#F0B634"],
    tooltipName1: "Expenses",
    series1: [],
    formatter1: (value) => formatCurrencyValue(value || "0"),
    tooltipName2: "Net",
    series2: [],
    formatter2: (value) => formatCurrencyValue(value || "0"),
  });

  const [agentPayPeriodGraphData, setAgentPayPeriodGraphData] = useState({
    colors: ["#4C96CE"],
    tooltipName1: "Agent Pay Period",
    series1: [],
    formatter1: (value) => formatCurrencyValue(value || "0"),
  });

  const [expenseGraphData, setexpenseGraphData] = useState({
    colors: ["#4C96CE"],
    tooltipName1: "Expense",
    series1: [],
    formatter1: (value) => formatCurrencyValue(value || "0"),
  });

  const [loading, setLoading] = useState({
    showTableLoading: false,
  });
  const [isClassAdded, setIsClassAdded] = useState(false);

  const _manageLoading = (loaderName, value) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [loaderName]: value,
    }));
  };

  const filterShowMobile = () => {
    setIsClassAdded((prevIsClassAdded) => !prevIsClassAdded);
  };

  const _persistFilter = () => {
    const updateLocalStorage = (filters, localStorageKey) => {
      if (
        filters &&
        (filters.client ||
          (filters.customDateRangeValue &&
            filters.customDateRangeValue !== "month"))
      ) {
        localStorage.setItem(localStorageKey, JSON.stringify(filters));
      } else {
        localStorage.removeItem(localStorageKey);
      }
    };

    if (userData?.type === "agent") {
      updateLocalStorage(filters, "agentexpensesummary");
    } else {
      updateLocalStorage(filters, "companyexpensesummary");
    }
  };

  //This is for graph calculation company and agent
  const _setGraphData = (
    closingsGraph = [],
    incomeGraph = [],
    expenseGraph = [],
    agentPayPeriodGraph = [],
    filterState
  ) => {
    try {
      const processGraph = (graphData, valueKey) => {
        return graphData.map((each) => {
          let label = "";
          if (
            filterState.customDateRangeValue === "month" ||
            filterState.customDateRangeValue === "day" ||
            filterState.customDateRangeValue === "week"
          ) {
            label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
          } else if (filterState.customDateRangeValue === "allTime") {
            label = `${each._id.year}`;
          } else {
            label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
          }
          return { label, value: each[valueKey] };
        });
      };

      const processGraphData = (graphData, negativeValue) => {
        return graphData.map((each, index) => {
          let label = "";

          if (
            filterState.customDateRangeValue === "month" ||
            filterState.customDateRangeValue === "day" ||
            filterState.customDateRangeValue === "week"
          ) {
            label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
          } else if (filterState.customDateRangeValue === "allTime") {
            label = `${each._id.year}`;
          } else {
            label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
          }

          const findInExpense = expenseAndNetGraphData.series1.find(
            (item) => item.label === label
          );
          if (!findInExpense) {
            expenseAndNetGraphData.series1.splice(index, 0, {
              label,
              value: 0,
            });
          }

          return { label, value: negativeValue ? -each.amount : each.amount };
        });
      };
      // closingsGraph
      orderGraphData.series1 = processGraph(closingsGraph, "count");

      // incomeGraph
      incomeGraphData.series1 = processGraph(incomeGraph, "amount");

      // expenseNetGraph
      expenseAndNetGraphData.series1 = processGraph(expenseGraph, "amount");

      if (userData?.type === "signingcompany") {
        //agentPayPeriodGraph
        agentPayPeriodGraphData.series1 = processGraph(
          agentPayPeriodGraph,
          "amount"
        );

        // expenseGraph
        expenseGraphData.series1 = processGraph(expenseGraph, "amount");
      }
      // netGraphConfig = (incomeGraph - expenseGraph)
      if (incomeGraph?.length && expenseGraph?.length) {
        let netPofiteGraph = [];
        let incomeArray = incomeGraph;
        let expenseArray = expenseGraph;
        let agentPayPeriodArray = agentPayPeriodGraph;

        if (userData?.type === "agent") {
          netPofiteGraph = incomeArray.map((income) => {
            const incomeDateObjString = JSON.stringify(income._id);
            const expenseIndexOfSameDate = expenseArray.findIndex(
              (exp) => JSON.stringify(exp._id) === incomeDateObjString
            );
            if (expenseIndexOfSameDate > -1) {
              let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
              return {
                amount: income.amount - expenseObj[0].amount,
                _id: income._id,
              };
            }
            return income;
          });

          if (expenseArray?.length) {
            expenseArray = expenseArray.map((expense) => {
              return {
                amount: -expense.amount,
                _id: expense._id,
              };
            });
            netPofiteGraph = [...netPofiteGraph, ...expenseArray];
            netPofiteGraph.sort((a, b) => {
              if (a._id?.day > b._id?.day) return 1;
              if (a._id?.day < b._id?.day) return -1;
              if (a._id?.month > b._id?.month) return 1;
              if (a._id?.month < b._id?.month) return -1;
              return 0;
            });
          }

          expenseAndNetGraphData.series2 = processGraphData(netPofiteGraph);
        } else {
          netPofiteGraph = incomeArray.map((income) => {
            const incomeDateObjString = JSON.stringify(income._id);
            const expenseIndexOfSameDate = expenseArray.findIndex(
              (exp) => JSON.stringify(exp._id) === incomeDateObjString
            );
            const agentPayPeriodIndexOfSameDate = agentPayPeriodArray.findIndex(
              (agentPayPeriod) =>
                JSON.stringify(agentPayPeriod._id) === incomeDateObjString
            );
            if (
              agentPayPeriodIndexOfSameDate > -1 &&
              expenseIndexOfSameDate === -1
            ) {
              let agentPayPeriodObj = agentPayPeriodArray.splice(
                agentPayPeriodIndexOfSameDate,
                1
              );

              return {
                amount: income.amount - agentPayPeriodObj[0].amount,
                _id: income._id,
              };
            }

            if (
              agentPayPeriodIndexOfSameDate === -1 &&
              expenseIndexOfSameDate > -1
            ) {
              let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
              return {
                amount: income.amount - expenseObj[0].amount,
                _id: income._id,
              };
            }

            if (
              agentPayPeriodIndexOfSameDate > -1 &&
              expenseIndexOfSameDate > -1
            ) {
              let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
              let agentPayPeriodObj = agentPayPeriodArray.splice(
                agentPayPeriodIndexOfSameDate,
                1
              );

              return {
                amount:
                  income.amount -
                  (expenseObj[0].amount + agentPayPeriodObj[0].amount),
                _id: income._id,
              };
            }
            return income;
          });

          if (expenseArray?.length) {
            expenseArray = expenseArray.map((expense) => {
              return {
                amount: -expense.amount,
                _id: expense._id,
              };
            });
            netPofiteGraph = [...netPofiteGraph, ...expenseArray];
            netPofiteGraph.sort((a, b) => {
              if (a._id?.day > b._id?.day) return 1;
              if (a._id?.day < b._id?.day) return -1;
              if (a._id?.month > b._id?.month) return 1;
              if (a._id?.month < b._id?.month) return -1;
              return 0;
            });
          }

          expenseAndNetGraphData.series2 = processGraphData(netPofiteGraph);
        }
      } else if (incomeGraph?.length && !expenseGraph?.length) {
        expenseAndNetGraphData.series2 = processGraphData(incomeGraph);
      } else if (!incomeGraph?.length && expenseGraph?.length) {
        expenseAndNetGraphData.series2 = processGraphData(expenseGraph, true);
      } else {
        expenseAndNetGraphData.series2 = [];
      }

      setOrderGraphData(orderGraphData);
      setIncomeGraphData(incomeGraphData);
      setExpenseAndNetGraphData(expenseAndNetGraphData);
      if (userData?.type === "signingcompany") {
        setexpenseGraphData(expenseGraphData);
        setAgentPayPeriodGraphData(agentPayPeriodGraphData);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _agentGetReportingSummaries = async (dateValue) => {
    const payload = {
      filters: {},
    };

    if (dateValue.client && dateValue.client.length > 0) {
      filterDropdownValues.clients.find((each) => {
        if (each._id === dateValue.client) {
          // console.log("each >>", each);
          if (each.hasOwnProperty("_client") && each._client !== "") {
            payload.filters["clientId"] = each._client || dateValue.client;
            payload.filters["companyId"] = "unregisteredClient";
          } else if (
            each.hasOwnProperty("_signingCompany") &&
            each._signingCompany !== ""
          ) {
            payload.filters["companyId"] = each._signingCompany;
          } else {
            payload.filters["assistantId"] = each._assistant;
          }
        }
        return null;
      });
    }

    if (dateValue.dateRangeValue) {
      payload.filters["startDate"] = formatOnlyDateMoment(
        dateValue.dateRangeValue[0]
      );
      payload.filters["endDate"] = formatOnlyDateMoment(
        dateValue.dateRangeValue[1]
      );
    }
    if (dateValue.customDateRangeValue) {
      if (
        dateValue.customDateRangeValue === "month" ||
        dateValue.customDateRangeValue === "day" ||
        dateValue.customDateRangeValue === "week"
      ) {
        payload.filters["groupByUnit"] = "day";
      } else if (dateValue.customDateRangeValue === "allTime") {
        payload.filters["groupByUnit"] = "year";
      }
    }
    try {
      if (
        dateValue.customDateRangeValue === "customDateRange" &&
        dateValue.dateRangeValue === null
      ) {
      } else {
        // Manage loading state
        _manageLoading("showTableLoading", true);
        const res = await agentGetReportingSummaries(payload);
        _setGraphData(
          res?.closingsGraph,
          res?.incomeGraph,
          res?.expenseGraph,
          undefined,
          dateValue
        );

        setCardList({
          incomes: res?.incomes,
          closings: res?.closings,
          expenses: res?.expenses,
          net: res?.net,
        });
        _manageLoading("showTableLoading", false);
      }
    } catch (error) {
      errorHandler(error);
      _manageLoading("showTableLoading", false);
    }
  };

  const _signingComapanyGetReportingSummaries = async (dateValue) => {
    const payload = {
      filters: {},
    };
    console.log("dateValue", dateValue);

    if (dateValue.client && dateValue.client.length)
      payload.filters["clientId"] = dateValue.client;

    if (
      dateValue?.client &&
      dateValue?.clientTeam &&
      dateValue?.clientTeam?.length
    )
      payload.filters["clientAssistantTeam"] = dateValue?.clientTeam;

    if (dateValue.dateRangeValue) {
      payload.filters["startDate"] = formatOnlyDateMoment(
        dateValue.dateRangeValue[0]
      );
      payload.filters["endDate"] = formatOnlyDateMoment(
        dateValue.dateRangeValue[1]
      );
    }
    if (dateValue.customDateRangeValue) {
      // AgentExpensesSummaries -
      // {String} [groupByUnit] `enum = ["month", "day", "year"]
      if (
        dateValue.customDateRangeValue === "month" ||
        dateValue.customDateRangeValue === "day" ||
        dateValue.customDateRangeValue === "week"
      ) {
        payload.filters["groupByUnit"] = "day";
      } else if (dateValue.customDateRangeValue === "allTime") {
        payload.filters["groupByUnit"] = "year";
      }
    }

    try {
      if (
        dateValue.customDateRangeValue === "customDateRange" &&
        dateValue.dateRangeValue === null
      ) {
      } else {
        _manageLoading("showTableLoading", true);
        const res = await signingCompanySummary(payload);
        _setGraphData(
          res?.closingsGraph,
          res?.incomeGraph,
          res?.expenseGraph,
          res?.agentPayPeriodGraph,
          dateValue
        );

        setCardList({
          incomes: res?.incomes,
          closings: res?.closings,
          expenses: res?.expenses,
          net: res?.net,
        });
        _manageLoading("showTableLoading", false);
      }
    } catch (error) {
      errorHandler(error);
      _manageLoading("showTableLoading", false);
    }
  };

  const _setDateRangeValue = (value = "month") => {
    switch (value) {
      case "day":
      case "week":
      case "month":
      case "year": {
        setFilters((prevFilters) => ({
          ...prevFilters,
          customDateRangeValue: value,
          dateRangeValue: getDateRangeValueSummarie(value),
        }));
        if (userData?.type === "agent") {
          _agentGetReportingSummaries({
            ...filters,
            customDateRangeValue: value,
            dateRangeValue: getDateRangeValueSummarie(value),
          });
        } else {
          _signingComapanyGetReportingSummaries({
            ...filters,
            customDateRangeValue: value,
            dateRangeValue: getDateRangeValueSummarie(value),
          });
        }
        break;
      }
      case "allTime": {
        setFilters((prevFilters) => ({
          ...prevFilters,
          customDateRangeValue: value,
          dateRangeValue: null,
        }));
        if (userData?.type === "agent") {
          _agentGetReportingSummaries({
            ...filters,
            customDateRangeValue: value,
            dateRangeValue: null,
          });
        } else {
          _signingComapanyGetReportingSummaries({
            ...filters,
            customDateRangeValue: value,
            dateRangeValue: null,
          });
        }
        break;
      }
      case "customDateRange": {
        setFilters((prevFilters) => ({
          ...prevFilters,
          dateRangeValue: null,
          customDateRangeValue: value,
        }));
        if (userData?.type === "agent") {
          _agentGetReportingSummaries({
            ...filters,
            dateRangeValue: null,
            customDateRangeValue: value,
          });
        } else {
          _signingComapanyGetReportingSummaries({
            ...filters,
            dateRangeValue: null,
            customDateRangeValue: value,
          });
        }
        return;
      }
      default: {
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }
    }

    _persistFilter();
  };

  const _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      setFilterDropdownValues((prevValues) => ({
        ...prevValues,
        clientTeamsList: res?.teams || [],
      }));
    } catch (err) {
      errorHandler(err);
    }
  };
  console.log("giltrs", filters);

  const _filterOnChange = (type, value) => {
    if (type === "dateRangeValue") {
      if (value) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          customDateRangeValue: "customDateRange",
          [type]: value,
        }));
        if (userData?.type === "agent") {
          _agentGetReportingSummaries({
            ...filters,
            customDateRangeValue: "customDateRange",
            [type]: value,
          });
        } else {
          _signingComapanyGetReportingSummaries({
            ...filters,
            customDateRangeValue: "customDateRange",
            [type]: value,
          });
        }
        _persistFilter();
      } else {
        _setDateRangeValue();
      }
    } else {
      if (userData?.type === "agent") {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [type]: value,
        }));
        _agentGetReportingSummaries({
          ...filters,
          [type]: value,
        });
      } else {
        if (type === "client") {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [type]: value,
            clientTeam: "",
            dateRangeValue: filters?.dateRangeValue
              ? filters?.dateRangeValue
              : getDateRangeValueSummarie(filters?.groupByUnit),
          }));
          _signingComapanyGetReportingSummaries({
            ...filters,
            [type]: value,
            dateRangeValue: filters?.dateRangeValue
              ? filters?.dateRangeValue
              : getDateRangeValueSummarie(filters?.groupByUnit),
            clientTeam: "",
          });

          if (value?.length) {
            _getCompanyListTeamsOfClient(value);
          } else {
            setFilterDropdownValues((prevValues) => ({
              ...prevValues,
              clientTeamsList: [],
            }));
          }
        } else {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [type]: value,
          }));
          _signingComapanyGetReportingSummaries({
            ...filters,
            [type]: value,
          });
        }
      }
      _persistFilter();
    }
  };

  //mange filters from local localstorage for company and agent
  const _setFilterDataFromLocalStorage = () => {
    if (userData?.type === "agent") {
      if (localStorage && localStorage.agentexpensesummary) {
        try {
          const filtersFromLocalStorage = JSON.parse(
            localStorage.agentexpensesummary
          );
          setFilters(filtersFromLocalStorage);
          _setDateRangeValue();
        } catch (e) {
          _setDateRangeValue();
        }
      } else {
        _setDateRangeValue();
      }
    } else {
      if (localStorage && localStorage.companyexpensesummary) {
        try {
          const savedFilters = JSON.parse(localStorage.companyexpensesummary);
          setFilters(savedFilters);
          _setDateRangeValue();
          if (savedFilters?.client) {
            _getCompanyListTeamsOfClient(savedFilters?.client);
          }
        } catch (e) {
          _setDateRangeValue();
        }
      } else {
        _setDateRangeValue();
      }
    }
  };

  //for reset filter
  const _resetFilter = () => {
    const defaultFilters = {
      dateRangeValue: null,
      customDateRangeValue: "month",
      client: "",
    };

    setFilters(defaultFilters);
    _setDateRangeValue();
    const refreshKey =
      userData?.type === "agent"
        ? "agent-reporting-summaries-responsive"
        : "company-reporting-expense-summaries-responsive";
    refreshFunc(refreshKey);
  };

  const _agentGetAllUnregisteredClient = async (data) => {
    let payload = {};
    if (data) {
      payload = { search: data };
    }

    try {
      const res = await agentGetAllUnregisteredClient(payload);
      dispatch(addListData({ clients: res?.clients }));
      let options =
        res?.clients?.map((item) => ({
          ...item,
          value: item?._id,
          label: capitalize(item?.companyName || item?.name?.full || "N/A"),
        })) || [];
      setFilterDropdownValues((prevValues) => ({
        ...prevValues,
        clients: options,
      }));
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAllListClients = async (data) => {
    let payload = {};
    if (data) {
      payload = { search: data };
    }
    try {
      const res = await getAllListClients(payload);
      dispatch(addListData({ clients: res?.clients }));
      const clonedFilterDropdownValues = deepClone(filterDropdownValues);
      let options =
        res?.clients?.map((item) => ({
          ...item,
          value: item._id,
          label: capitalize(item?.companyName || item?.name?.full || "N/A"),
        })) || [];
      clonedFilterDropdownValues["clients"] = options;

      console.log("clonedFilterDropdownValues >>", clonedFilterDropdownValues);

      setFilterDropdownValues(clonedFilterDropdownValues);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (userData?.type === "agent") {
      if (isRegularUser()) {
        _agentGetAllUnregisteredClient(); //client list fetch for agent using userType
        _setFilterDataFromLocalStorage();
        HeaderEventEmitter.subscribe("reset-filter", () => {
          console.log("filter reset hit");
          _resetFilter();
          refreshFunc("agent-reporting-summaries");
        });
      } else {
        history.goBack();
      }
    } else {
      _getAllListClients(); //client list fetch for company using userType
      _setFilterDataFromLocalStorage();

      HeaderEventEmitter.subscribe("reset-filter", () => {
        console.log("filter reset hit");
        _resetFilter();
        refreshFunc("company-reporting-expense-summaries");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <div className="responsiveTitle">
        <h2>Summaries</h2>

        <div className="rightSide">
          <Button id="agent-reporting-summaries-responsive" color="link" onClick={_resetFilter}>
            <SvgIcons type={"refresh"} />
          </Button>
          <Button
            className="filterBarIcon"
            color="link"
            onClick={filterShowMobile}
          >
            <SvgIcons type={"filterIcon"} />
          </Button>
        </div>
      </div>
      <Row>
        <Col md="12">
          {/* filter for tablet and web */}
          <div
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer responsiveFilter ${isClassAdded ? "show" : ""
              }`}
          >
            <div className="filterIcon">
              <SvgIcons type={"filterIcon"} />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <AgentCompanySummariesFilter
              filters={filters}
              _setDateRangeValue={_setDateRangeValue}
              _filterOnChange={_filterOnChange}
              filterDropdownValues={filterDropdownValues}
              getAllListClientsforAgent={(search) =>
                userData.type === "signingcompany"
                  ? _getAllListClients(search)
                  : _agentGetAllUnregisteredClient(search)
              }
            />

            <div className="clearButton">
              <Button size="md" color="primary" onClick={filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <AgentCompanySummariesCard
            cardList={cardList}
            loading={loading.showTableLoading}
          />

          {/* {!loading.showTableLoading && ( */}
          <AgentCompanySummariesGraph
            orderGraphData={orderGraphData}
            incomeGraphData={incomeGraphData}
            agentPayPeriodGraphData={agentPayPeriodGraphData}
            expenseAndNetGraphData={expenseAndNetGraphData}
            userData={userData}
            loading={loading.showTableLoading}
          />
          {/* )} */}

          {userData?.type === "signingcompany" ? (
            <p className="mt-3">
              Note: *income reflects total amount paid and pending
            </p>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default AgentCompanySummaries;
