import cuid from "cuid";
import React, { useState, useEffect } from "react";
import { Card, CardBody, FormGroup, Input, Label, Button } from "reactstrap";
import { updateAgentDetailVisibility } from "../../../http/http-calls";
import { errorHandler, showToast } from "../../../helper-methods";

const CustomAgentDetailsforClient = ({ client, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    client?.agentVisibilityForClient
  );
  console.log("client  details  ", client);

  const handleOptionChange = (event) => {
    console.log("Event", event.target);
    setSelectedOption(event.target.name);
  };
  useEffect(() => {
    setSelectedOption(client?.agentVisibilityForClient);
  }, [client]);

  const onSaveHandler = async () => {
    try {
      setLoading(true);
      const res = await updateAgentDetailVisibility(client._id, {
        agentVisibilityForClient: selectedOption,
      });
      setLoading(false);
      showToast("Successfully Updated", "success");
    } catch (error) {
      console.log("Error:", error);
      errorHandler(error);
      setLoading(false);
    }
  };
  return (
    <Card>
      <CardBody>
        <legend className="col-form-label">
          Select Agent details visibility option
        </legend>
        <FormGroup check>
          <Label for="no" check>
            <Input
              id="no"
              type="radio"
              name="no"
              value={selectedOption}
              onChange={handleOptionChange}
              checked={selectedOption === "no"}
            />{" "}
            No
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label for="yes" check>
            <Input
              id="yes"
              type="radio"
              name="yes"
              value={selectedOption}
              onChange={handleOptionChange}
              checked={selectedOption === "yes"}
            />{" "}
            Yes
          </Label>
        </FormGroup>

        <div className="text-center">
          <Button
            color="primary"
            className="pl-3 mt-3"
            onClick={() => onSaveHandler()}
            disabled={loading ? true : false}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default CustomAgentDetailsforClient;
