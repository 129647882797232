import React, { Suspense, lazy } from "react";
import { Button } from "reactstrap";
import { DateTimeShowWithCellRow } from "./Date-time-show";

const SignerPopover = lazy(() => import("./common/popover"));

const AgentDashboardHeader = ({
  header,
  row,
  cell,
  _onToggleSignerAvailabilityModal,
}) => {
  switch (header) {
    case "appointmentDate": {
      return row ? (
        <>
          <div className="tableUserInfo">
            <div className="userImage">
              <img
                className="clientImg"
                src={
                  row?._signingCompany?.logo
                    ? row?._signingCompany?.logo
                    : row?._client?.logo
                    ? row?._client?.logo
                    : require("../../../assets/img/default_user_icon.svg")
                        .default
                }
                loading="lazy"
                alt="avatar"
              />
            </div>
            <div className="userContent flex-0">
              {row?._borrower?.length ? (
                <Suspense fallback={<></>}>
                  <SignerPopover
                    data={row?._borrower}
                    targetId={row._id}
                    screenType="mobile"
                    displayType="signerDetails"
                  />
                </Suspense>
              ) : null}
              {/* <span className="dateTimeFormat">
                {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)}{" "}
                <span>
                  {getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}
                </span>
              </span> */}
              <DateTimeShowWithCellRow cell={row?.appointmentDate} row={row} />
            </div>
          </div>
          <Button
            color={`${
              row?.appointmentStatus === "Confirmed" && !row.leftMessage
                ? "success "
                : row?.appointmentStatus === "Rescheduled" && !row.leftMessage
                ? "danger "
                : row.leftMessage
                ? "warning"
                : "danger "
            }`}
            className={`confirmButton btnConfirm`}
            onClick={() => _onToggleSignerAvailabilityModal(true, row)}
          >
            {row?.appointmentStatus === "Confirmed" ? "Confirmed" : "Confirm"}
          </Button>
        </>
      ) : (
        "N/A"
      );
    }
    default: {
      return cell;
    }
  }
};

export default AgentDashboardHeader;
