import { capitalize } from "@material-ui/core";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, FormGroup, Label } from "reactstrap";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../config";
import {
  deepClone,
  errorHandler,
  formatOnlyDateMoment,
  getLoggedInUserId,
  openUrlOnNewTab,
  refreshFunc,
  showToast,
  structureQueryParams,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  agentGetAllUnregisteredClient,
  deleteAgentNotarialLog,
  fetchAllNotarialLog,
} from "../../../http/http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import CustomCard from "../components/CustomCard";
import CustomTable from "../components/CustomTable";
import AddNotarialModalAgent from "../components/add-notarial";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { notarialLogHeaderKeys } from "../../../config/stateConfig";
import AgentNotarialLogCard from "../components/AgentNotarialLogCard";
import AgentNotarialLogDataFormat from "../components/AgentNotarialLogDataFormat";
import AgentNotarialLogCardDataFormat from "../components/AgentNotarialLogCardDataFormat";

class NotarialLog extends Component {
  state = {
    notarialLog: [],
    notarialLogTotalCount: 0,
    totalAgentFee: 0,
    totalAmount: 0,
    totalNotarialActs: 0,
    notarialLogHeaderKeys: notarialLogHeaderKeys,
    notarialHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    dateRangeValue: null,
    filters: {
      status: "",
    },
    addNotarialModalAgent: {
      isOpen: false,
      data: null,
    },
    isClassAdded: false,
    unregisterClientList: [],
  };
  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
    });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      } else {
        this._paginate();
      }
    });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._fetchAllNotarialLog();
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount = async () => {
    try {
      this.props.showLoader("Fetching Notarial Log...");
      this._setFilterDataFromLocalStorage();
      // await this._fetchAllNotarialLog();
      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
    HeaderEventEmitter.subscribe("add-notarial", () => {
      console.log("add notarial");
      this._onToggleAddNotarialModalAgent(true);
    });

    this._agentGetAllUnregisteredClient();
  };

  _agentGetAllUnregisteredClient = () => {
    agentGetAllUnregisteredClient()
      .then((res) => {
        console.log("res: ", res);
        this.setState({ unregisterClientList: res?.clients });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  //Fetch all notarialLog data
  _fetchAllNotarialLog = () => {
    this._manageLoading("showTableLoading", true);
    const { tableConfig, filters, dateRangeValue } = deepClone(this.state);

    return new Promise((resolve, reject) => {
      let payload = {
        ...tableConfig,
        filters: {},
      };
      if (dateRangeValue && dateRangeValue.length) {
        payload.filters["startDate"] = dateRangeValue[0];
        payload.filters["endDate"] = dateRangeValue[1];
      }

      if (filters.status)
        payload.filters["status"] =
          filters.status !== "" ? filters.status : undefined;

      fetchAllNotarialLog(payload)
        .then((res) => {
          this.setState(
            {
              notarialLog: res.notarialLogs,
              notarialLogTotalCount: res.totalCount,
              totalAgentFee: res.totalAgentFee,
              totalAmount: res.totalAmount,
              totalNotarialActs: res.totalNotarialActs,
            },
            () => {
              this._manageLoading("showTableLoading", false);
            }
          );
          resolve(res);
        })
        .catch((error) => {
          this._manageLoading("showTableLoading", false);
          reject(error);
        });
    });
  };

  multiLineText = (str) => {
    return str.split(" ").join("\n");
  };

  //This is the component to show data
  _dataFormat = (cell, row, header) => {
    return (
      <AgentNotarialLogDataFormat
        header={header}
        row={row}
        cell={cell}
        _clientName={this._clientName}
        _onToggleAddNotarialModalAgent={this._onToggleAddNotarialModalAgent}
        _deleteNotarialLog={this._deleteNotarialLog}
        loading={this.state.loading}
      />
    );
  };
  //For reset filter
  _resetFilter = () => {
    this.setState(
      {
        dateRangeValue: null,
        filters: {
          status: "",
        },
      },
      () => {
        this._paginate();
        refreshFunc("agent-notarial-log-responsive");
      }
    );
  };

  _deleteNotarialLog = (id) => {
    if (!id) {
      showToast("Notarial not found", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._deleteAgentNotarialLog(id);
      }
    });
  };

  //For delete notarialLog
  _deleteAgentNotarialLog = (id) => {
    this._manageLoading("showTableLoading", true);

    deleteAgentNotarialLog(id)
      .then(async (res) => {
        Swal.fire("Deleted!", "Entry has been deleted.", "success");

        this._manageLoading("showTableLoading", false);
        this._paginate();
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  //For download pdf
  _downloadData = (action = "download") => {
    const { notarialLog, filters, dateRangeValue } = this.state;

    if (!notarialLog.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (dateRangeValue) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    if (filters.status) payload["status"] = filters.status;

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(`${BASE_URL}/agent/download/notarial-logs${queryParams}`);
  };

  //This is show data in mobile view
  _cardDataFormat = (row) => {
    return (
      <AgentNotarialLogCardDataFormat
        row={row}
        _clientName={this._clientName}
        _onToggleAddNotarialModalAgent={this._onToggleAddNotarialModalAgent}
        _deleteNotarialLog={this._deleteNotarialLog}
        loading={this.state.loading}
      />
    );
  };

  _onToggleAddNotarialModalAgent = (isOpen = false, data = null) => {
    this.setState({
      addNotarialModalAgent: {
        isOpen,
        data,
      },
    });
  };

  _clientName = (row) => {
    if (row && row?.isManuallyCreatedByAgent) {
      if (row?._signingCompany) {
        return capitalize(row?._signingCompany?.companyName);
      } else if (row?._client && row?._client !== "") {
        return row?._client?.name
          ? capitalize(row?._client?.name?.full)
          : "N/A";
      } else {
        return row?._assistant?.name?.full
          ? capitalize(row?._assistant?.name?.full)
          : "N/A";
      }
    } else {
      if (row?._closing) {
        if (
          row?._closing?.isCreatedForAgent !== undefined &&
          row?._closing?.isCreatedForAgent === true &&
          row?._closing?._client?.name?.full
        ) {
          return capitalize(row?._closing?._client?.name?.full);
        } else if (row?._closing?._signingCompany) {
          return capitalize(row?._closing?._signingCompany?.companyName);
        } else {
          return row?._closing?._assistant?.name?.full
            ? capitalize(row?._closing?._assistant?.name?.full)
            : "N/A";
        }
      }
    }
    return "N/A";
  };

  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.agentNotarialLogPage = JSON.stringify(data);
    } else {
      delete localStorage.agentNotarialLogPage;
    }
  };

  //Handle filtes from local storage
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentNotarialLogPage) {
      try {
        const filters = JSON.parse(localStorage.agentNotarialLogPage);

        this.setState({ tableConfig: filters.tableConfig }, () => {
          this._fetchAllNotarialLog();
        });
      } catch (e) {
        console.log("error", e);
        this._fetchAllNotarialLog();
      }
    } else {
      this._fetchAllNotarialLog();
    }
  };

  render() {
    const {
      notarialLogHeaderKeys,
      tableConfig,
      notarialLog,
      notarialLogTotalCount,
      loading,
      dateRangeValue,
      totalAgentFee,
      totalAmount,
      totalNotarialActs,
      isClassAdded,
      addNotarialModalAgent,
    } = deepClone(this.state);
    const { isForMobile } = this.props;

    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Notarial Log</h2>

          <div className="rightSide">
            <Button color="link" onClick={this._resetFilter}>
              <img
                id="agent-notarial-log-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>
            <Link to={`/agent/bulk-upload?notarialLog`} className="ml-2">
              Bulk upload
            </Link>
            <Button className="floatingButton" color="primary">
              Add Notarial
            </Button>
            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={this.handleButtonClick}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>

        <div
          className={`filterContainer responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup className="dateRange">
              <Label>Date Range</Label>
              <DateRangePicker
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                onChange={(dateRangeValue) =>
                  this._onChangeDatePicker(dateRangeValue)
                }
                value={dateRangeValue}
              />
            </FormGroup>

            <Button
              color="link"
              outline
              className="downloadButton ml-auto"
              onClick={() => this._downloadData()}
            >
              Download
              <img
                src={require(`../../../assets/img/download_blue.png`)}
                alt="download"
              />
            </Button>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={this.filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        <div className="downloadButtonPos">
          <Button color="link" outline onClick={() => this._downloadData()}>
            Download
            <img
              src={require(`../../../assets/img/download_blue.png`)}
              alt="download"
            />
          </Button>
        </div>

        <AgentNotarialLogCard
          totalNotarialActs={totalNotarialActs}
          totalAmount={totalAmount}
          totalAgentFee={totalAgentFee}
          loading={loading.showTableLoading}
          isForMobile={isForMobile}
        />

        {isForMobile ? (
          <Suspense fallback={<></>}>
            <div className="hideDesktop">
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={notarialLog}
                dataFormat={this._dataFormat}
                totalCount={notarialLogTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                rowSelection={false}
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={this._cardHeaderFormat}
                cardDataFormat={this._cardDataFormat}
              />
            </div>
          </Suspense>
        ) : (
          <Suspense fallback={<></>}>
            <div className="hideMobile">
              {notarialLogHeaderKeys && notarialLogHeaderKeys.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={notarialLog}
                  headerKeys={notarialLogHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={notarialLogTotalCount}
                  rowSelection={false}
                  pageSize={tableConfig.limit}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) =>
                    this._onSortChange(sortName, sortOrder)
                  }
                  showTableLoading={loading.showTableLoading}
                />
              )}
            </div>
          </Suspense>
        )}

        {/* agent side craete notarial Log */}
        <AddNotarialModalAgent
          isOpen={addNotarialModalAgent.isOpen}
          data={addNotarialModalAgent.data}
          toggle={this._onToggleAddNotarialModalAgent}
          resetDetails={() => this._paginate()}
          unregisterClientList={this.state.unregisterClientList}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(NotarialLog));
