import React from "react";
import { TailSpin } from "react-loader-spinner";

const CustomLoader = ({ className = "", width = 26, height = 26 }) => {
  return (
    <TailSpin
      color="#3a9bc2"
      height={height}
      width={width}
      className={className}
    />
  );
};

export default CustomLoader;
