import { loadStripe } from "@stripe/stripe-js";
import toast from "react-hot-toast";
import { STRIPE_API_KEY } from "../../../config";

export const SecurePayment = async (clientSecret) => {
  return new Promise(async (resolve, reject) => {
    // const stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
    const stripe = await loadStripe(STRIPE_API_KEY);

    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(clientSecret);

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      console.log("confirmError -->> ", confirmError);
      toast.error(confirmError?.message || "Server error");
      reject(confirmError);
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.

      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow.
      if (paymentIntent?.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.log("error :>> ", error);
          // The payment failed -- ask your customer for a new payment method.
          reject(error);
        } else {
          resolve(paymentIntent);
          // The payment has succeeded.
        }
      } else {
        resolve(paymentIntent);
        // The payment has succeeded.
      }
    }
  });
};
