import React, { PureComponent, Suspense, lazy, createRef } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import {
  addUnixTimeToString,
  capitalize,
  debounce,
  deepClone,
  errorHandler,
  extractQueryParams,
  extractValidFields,
  generateUniqueFileNo,
  getPhoneNumberFromBrackets,
  isObjectPresent,
  isValidEmail,
  showToast,
  sleepTime,
  sortText,
  // structureQueryParams,
} from "../../../helper-methods";
import {
  companyGetAllProductTypes,
  createNewDraft,
  createNewSigningCompanyClosing,
  editDraft,
  getAgentWithZipcode,
  getAllCompanySettings,
  getAllFiltersData,
  getClosingDetailsById,
  getRonPlatformTypes,
  startAutomationForClosing,
  updateCompanyDetails,
  uploadClosingPdf,
  companyGetListTeamsOfClient,
  createChatLog,
  FileNumberDuplicateMatchCheck,
} from "../../../http/http-calls";
import { RegexConfig } from "../../../config/RegexConfig";
import { PostManager } from "../../../post-manager";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { UploadQueueManager } from "../../../upload-queue-manager";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import { addSettings } from "../../../redux/actions/settings";
import FileNumberDuplicateModal from "../components/FileNumberDuplicateModal";
import AppointmentScheduler from "../components/AppointmentScheduler";
import CreateClosingAddressComponent from "../components/common/createClosingAddressComponent";

const InviteClient = lazy(() => import("../components/InviteClient"));
const SignerInfoGroup = lazy(() => import("../components/SignerInfoGroup"));
const SignerClosingInfo = lazy(() => import("../components/SignerClosingInfo"));
const Instructions = lazy(() => import("../components/instructions"));
const FileChecked = lazy(() => import("../components/FileChecked"));

class CreateClosing extends PureComponent {
  constructor(props) {
    super(props);
    this.signerInfoGroupRef = createRef();
    this.addressRef = createRef();
    this.appointmentSchedulerRef = createRef();
  }
  state = {
    formFields: {
      closingSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      fileNumber: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      titleCompany: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lender: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactEmail: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      ronPlatformType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      closingAddressTimeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      loanTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      signingCompanyFee: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      client: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      clientTeams: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      agent: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      agentFee: {
        value: "",
        error: null,
        isValidate: true,
        isDirty: false,
      },
      // appointmentDate: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      //   isFieldDirty: false,
      // },
      files: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      notes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionId: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionSignerData: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructions: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      paymentInstructions: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      internalNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
        isFieldDirty: false,
      },
      isScanBackNeeded: {
        value: false,
        isDirty: false,
        isValidate: false,
      },
    },
    borrowerData: [],
    closingAddress: null,
    borrowerAddress: null,
    // closingGoogleAddress: null,
    // borrowerGoogleAddress: null,
    uploadFiles: [],
    clients: [],
    clientTeamsList: [],
    agents: [],
    addClientModalData: {
      isOpen: false,
      data: null,
    },
    fileChecked: {
      isOpen: false,
      data: null,
    },
    FileNumberUniqueChecked: {
      isOpen: false,
    },
    pdfFile: null,
    loading: {
      fileUploadLoading: false,
      agentSearchLoading: false,
      submitLoading: false,
      draftLoading: false,
    },
    show: null,
    searchResult: [],
    loanType: {},
    isBorrowerAddressSame: false,
    isBorrowerSearch: true,
    isAppointmentSearch: true,
    inputLoanType: false,
    dropdownAgentList: [],
    signingInstruction: [],
    draftClosing: null,
    companySetting: {},
    closingDetails: {},
    createDraft: false,
    createClosing: false,
    textInstruction: "",
    ronPlatformTypeOptions: [],
    isChecked: false,
    agentsZipCodePage: {
      page: 1,
      limit: 30,
    },
    agentSuggestedTotalCount: 0,
    appointmentSchedulerData: {
      appointmentDate: null,
      endAppointmentDate: null,
      tBD: false,
      isRangeDated: false,
    },
    isFocusTextEditor: null,
    existingFileNos: null,
    keepFileNo: false,
  };

  _resetForm = () => {
    const { formFields } = deepClone(this.state);
    Object.keys(formFields).forEach((field) => {
      if (
        field !== "personOfContactEmail" &&
        field !== "isScanBackNeeded" &&
        field !== "loanType"
      ) {
        formFields[field].value = "";
      } else if (field === "isScanBackNeeded") {
        formFields[field].value = false;
      } else {
        formFields[field].value = [];
      }
      formFields[field].isDirty = false;
      formFields[field].isValidate = true;
      if (formFields[field].placeId) {
        formFields[field].placeId = null;
      }
    });
    this.setState({ formFields });
  };

  componentDidMount = async () => {
    const { location } = this.props;

    // Concurrent asynchronous operations using Promise.all
    await Promise.all([
      this._getAllFiltersData(),
      isObjectPresent("settings") === false
        ? this._getAllCompanySettings()
        : this._setFormFields(this.props.settings),
      // this._getAllCompanySettings(),
      this._getRonPlatformType(),
      this._companyGetAllProductTypes(),
    ]);

    // Process location search params
    if (location.search) {
      const id = location.search.split("&")[0].replace(/[^\w\s]/gi, "");
      this._getSigningClosingById(id);
    }

    // Event subscriptions
    HeaderEventEmitter.subscribe("back-button", () => {
      this._redirectToClosingDashboard();
    });

    HeaderEventEmitter.subscribe("closingFile", (event) => {
      this.uploadClosingPdfFile(event);
    });
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   return nextState?.borrowerAddress !== this.state?.borrowerAddress;
  // }

  _companyGetAllProductTypes = async () => {
    try {
      const res = await companyGetAllProductTypes();
      console.log({ res });
      if (res?.customProductTypes) {
        this._setProductType(res?.customProductTypes?.products);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  _setProductType = (products) => {
    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});
    this.setState({ loanType: groupedProducts });
  };

  _getRonPlatformType = async () => {
    try {
      const response = await getRonPlatformTypes();

      if (!response.error) {
        const convertedArray =
          response?.ronPlatformTypes &&
          Object.entries(response?.ronPlatformTypes).map(([label, value]) => ({
            label,
            value,
          }));
        this.setState({ ronPlatformTypeOptions: convertedArray });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  _getSigningClosingById = (id) => {
    return new Promise((resolve, reject) => {
      getClosingDetailsById(id)
        .then((res) => {
          this.setState({
            closingDetails: res.closing,
            draftClosing: res.closing.isSavedAsDraft,
          });
          HeaderEventEmitter.dispatch("closing-details", {
            closingDetails: res.closing,
          });
          console.log("call _getSigningClosingById");
          this._setForm(res.closing);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _setForm = (closingDetail, isFromUpload = false) => {
    let {
      formFields,
      ronPlatformTypeOptions,
      closingAddress,
      borrowerAddress,
      // closingGoogleAddress,
      // borrowerGoogleAddress,
      appointmentSchedulerData,
    } = deepClone(this.state);

    console.log("closingDetail >>", closingDetail);

    // Helper function to map borrower details
    const mapBorrower = (borrower) => ({
      _id: borrower._id || "",
      name: borrower.name.full || "",
      email: borrower.email || "",
      workPhone: getPhoneNumberFromBrackets(borrower?.phone?.work) || "",
      alternatePhone:
        getPhoneNumberFromBrackets(borrower?.phone?.alternate) || "",
      isForeignNational: borrower.isForeignNational,
      language: borrower.language,
      languageTypeOther: borrower?.languageTypeOther || "",
      timeZone: borrower.timeZone || "",
    });

    // Helper function to map borrower details for file
    const mapBorrowerForFile = (each) => ({
      name: each.borrowerName || "",
      email: each.borrowerEmail || "",
      workPhone: each?.borrowerWorkPhone
        ? getPhoneNumberFromBrackets(each?.borrowerWorkPhone)
        : each?.borrowerHomePhone
        ? getPhoneNumberFromBrackets(each?.borrowerHomePhone)
        : each?.borrowerCellPhone
        ? getPhoneNumberFromBrackets(each?.borrowerCellPhone)
        : "",
      phoneHome: each?.borrowerWorkPhone //earlier alternate  but changed to phoneHome
        ? getPhoneNumberFromBrackets(each?.borrowerWorkPhone)
        : each?.borrowerHomePhone
        ? getPhoneNumberFromBrackets(each?.borrowerHomePhone)
        : each?.borrowerCellPhone
        ? getPhoneNumberFromBrackets(each?.borrowerCellPhone)
        : "",
      isForeignNational: false,
      language: "",
      languageTypeOther: "",
      timeZone: "",
    });
    // Mapping borrower data based on the presence of _borrower in closingDetail

    let borrower = closingDetail?._borrower
      ? closingDetail?._borrower?.map(mapBorrower)
      : closingDetail?.borrowers?.map(mapBorrowerForFile);

    formFields.fileNumber.value = closingDetail?.fileNumber;
    formFields.lender.value = closingDetail?.lender || "";

    formFields.personOfContactName.value = closingDetail._id
      ? closingDetail?.personOfContact?.name
      : closingDetail.pocName || "";

    // console.log(
    //   " closingDetail?.personOfContact?.email",
    //   closingDetail?.personOfContact
    // );
    formFields.personOfContactEmail.value =
      closingDetail?.personOfContact?.email[0]?.trim()?.length
        ? closingDetail?.personOfContact?.email
        : [];

    formFields.signingType.value =
      closingDetail?.signingType &&
      (closingDetail?.signingType === "Mobile" ||
        closingDetail?.signingType === "RON")
        ? closingDetail?.signingType
        : "Mobile";

    formFields.loanTypeOther.value = closingDetail?.loanTypeOther || "";
    formFields.internalNotes.value = closingDetail?.internalNotes || "";
    formFields.signingCompanyFee.value = closingDetail._id
      ? closingDetail?.signingCompanyFee?.toString()
      : closingDetail?.companyFee?.replace(/[$]/g, "");

    if (closingDetail?.internalNotes) {
      formFields.internalNotes.isFieldDirty = true;
    }

    if (closingDetail?.instructions) {
      formFields.instructions.value = closingDetail?.instructions || "";
    }

    if (closingDetail?.paymentInstructions) {
      formFields.paymentInstructions.value =
        closingDetail?.paymentInstructions || "";
    }

    formFields.closingAddressTimeZone.value =
      closingDetail?.closingAddress?.timeZone || "";

    if (ronPlatformTypeOptions?.length > 0) {
      const selectedRonPlatformTypes = ronPlatformTypeOptions.filter((option) =>
        closingDetail?.ronPlatformTypes?.includes(option?.value)
      );

      formFields.ronPlatformType.value = selectedRonPlatformTypes || [];
    }

    formFields.client.value = closingDetail?._client?.id || "";

    formFields.clientTeams.value =
      closingDetail?._clientAssistantTeam?._id || "";

    const firstBorrowerAddress = isFromUpload
      ? closingDetail?.propertyAddress
      : closingDetail?._borrower !== undefined
      ? closingDetail?._borrower[0]?.address || ""
      : {};

    console.log("firstBorrowerAddress", firstBorrowerAddress, borrowerAddress);

    // borrowerGoogleAddress = Object.values({
    //   line1:
    //     firstBorrowerAddress?.line1 || firstBorrowerAddress?.addressLine || "",
    //   line2: firstBorrowerAddress?.line2 || "",
    //   city: firstBorrowerAddress?.city || "",
    //   state: firstBorrowerAddress?.state || "",
    //   zip: firstBorrowerAddress?.zip || "",
    // })
    //   .filter(Boolean)
    //   .join(",");

    const closingAddressData = closingDetail?.closingAddress || {};

    closingAddress = {
      addressLine1:
        closingAddressData?.line1 || closingAddressData?.addressLine || "",
      addressLine2: closingAddressData?.line2 || "",
      city: closingAddressData?.city || "",
      state: closingAddressData?.state || "",
      zip: closingAddressData?.zip || "",
      county: closingAddressData?.county || "",
    };

    // closingGoogleAddress = Object.values({
    //   line1: closingAddressData?.line1 || closingAddressData?.addressLine || "",
    //   line2: closingAddressData?.line2 || "",
    //   city: closingAddressData?.city || "",
    //   state: closingAddressData?.state || "",
    //   zip: closingAddressData?.zip || "",
    // })
    //   .filter(Boolean)
    //   .join(",");

    formFields.loanType.value =
      closingDetail?.loanType?.map((each) => ({ label: each, value: each })) ||
      [];

    // console.log("borrowerGoogleAddress >>", borrowerGoogleAddress);

    formFields.ronPlatformType.value =
      (closingDetail?.ronPlatformTypes &&
        closingDetail?.ronPlatformTypes?.map((each) => ({
          label: each,
          value: each,
        }))) ||
      [];

    if (closingDetail.isSavedAsDraft && closingDetail?.appointmentDate) {
      const params = this.props.location.search.split("&");
      const queryString = extractQueryParams();
      if (!queryString?.isClone === "true") {
        const agentId = params[1];
        const counterTime = params[2];
        const counterAmount = params[3];

        appointmentSchedulerData.appointmentDate =
          counterTime && counterTime !== "undefined"
            ? counterTime
            : closingDetail?.appointmentDate ||
              closingDetail?.appointmentDateTime;

        formFields.agentFee.value =
          counterAmount !== "undefined"
            ? counterAmount
            : closingDetail.agentFee || closingDetail.biddingAmount || "";

        formFields.agent.value =
          agentId !== undefined
            ? { label: closingDetail?._agent?.name?.full, value: agentId }
            : "";
      }
    }

    if (closingDetail?.closingAddress?.zip) {
      // this._getAgentWithZipcode(closingDetail);
      appointmentSchedulerData.appointmentDate =
        closingDetail?.appointmentDate || closingDetail?.appointmentDateTime;
    }

    console.log("closingDetail?.appointmentDate >>", appointmentSchedulerData);
    console.log("borrowerAddress >>++", firstBorrowerAddress);
    console.log("closingAddress >>+++", closingAddress);

    this.setState(
      {
        formFields,
        closingAddress,
        borrowerAddress: firstBorrowerAddress,
        isBorrowerSearch: false,
        isAppointmentSearch: false,
        borrowerData: borrower,
        // borrowerGoogleAddress,
        // closingGoogleAddress,
        appointmentSchedulerData,
      },
      () => {
        if (closingDetail._id) {
          // this._findStandardrate(closingAddress);
        } else {
          this._getAgentWithZipcode(closingDetail);
          if (isObjectPresent("settings") === false) {
            this._getAllCompanySettings();
          }
        }
        if (this.state.formFields?.client?.value) {
          this._getCompanyListTeamsOfClient(
            this.state.formFields?.client?.value
          );
        }
      }
    );
  };

  _getAllFiltersData = () => {
    getAllFiltersData({
      signingType: this.state.formFields.signingType.value,
    })
      .then((res) => {
        // console.log(res);
        let options =
          res?.agents?.map((item) => ({
            value: item._id,
            label: item.name.full,
          })) || [];
        options?.splice(0, 0, { value: "", label: "Select" });
        this.setState({ clients: res?.clients, agents: options });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllCompanySettings = async () => {
    try {
      let response = await getAllCompanySettings();

      this._setFormFields(response?.companySetting);
    } catch (error) {
      errorHandler(error);
    }
  };

  _setFormFields = (companySetting) => {
    const { formFields } = this.state;

    if (companySetting?.signingInstruction?.length) {
      const defaultInstruction = companySetting.signingInstruction.find(
        (each) => each.isDefault
      );

      formFields.instructionId.value = defaultInstruction?._id || "";
      formFields.instructions.value = defaultInstruction?.description || "";
      formFields.paymentInstructions.value =
        companySetting?.paymentInstructions[0]?.description || "";
      formFields.instructionSignerData.value =
        defaultInstruction?.description || "";
    }
    if (companySetting) {
      this.props.addSettings(companySetting);
      formFields["isScanBackNeeded"].value =
        companySetting?.scanBackDocument?.isAlwaysRequired;
    }
    this.setState({
      formFields,
      companySetting: companySetting,
      signingInstruction: companySetting?.signingInstruction?.length
        ? companySetting.signingInstruction
        : [],
    });
  };

  _redirectToClosingDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    let restLink = "";
    if (userData.type === "agent") {
      restLink = "dashboard";
    } else if (userData.type === "company") {
      restLink = "closing-dashboard";
    }
    this.props.history.push(`/${userData.type}/${restLink}`);
  };

  _toggleAddClientModal = (isOpen = false, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileCheckedModal = (isOpen = false, data = null) => {
    this.setState({
      fileChecked: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileNumberUniqueCheckedModal = (isOpen = false) => {
    this.setState({
      FileNumberUniqueChecked: {
        isOpen,
      },
    });
  };

  _validateForm = () => {
    return new Promise((resolve) => {
      const { formFields, pdfFile } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            case "closingAddressTimeZone": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "fileNumber": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "personOfContactName": {
              if (
                formFields[key].value &&
                formFields[key]?.value?.trim().length
              ) {
                if (
                  formFields[key]?.value?.trim().length > 1 &&
                  /^[0-9!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(
                    String(formFields[key]?.value).toLowerCase()
                  )
                ) {
                  formFields[key].error =
                    "*Name containing only number and special character are not allowed";
                  isFormValid = false;
                  formFields[key].isDirty = true;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            // case "personOfContactEmail":
            case "personOfContactEmail": {
              if (formFields[key].value && formFields[key].value.length) {
                formFields.personOfContactEmail.value.forEach((each, index) => {
                  if (RegexConfig.email.test(String(each).toLowerCase())) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Email";
                    isFormValid = false;
                  }
                });
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "client":
            case "loanType": {
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "loanTypeOther": {
              if (formFields.loanType.value === "Other") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "pointOfContact": {
              if (pdfFile === null) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Special Character is Not Allowed";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            // case "lender":
            case "location": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "signingCompanyFee": {
              if (!this.props.userData?.user?.isTitleCompany) {
                if (formFields[key].value && formFields[key].value.length) {
                  if (
                    isNaN(formFields[key].value) ||
                    Number(formFields[key].value) < 0
                  ) {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Fee must be a positive number";
                    isFormValid = false;
                  } else {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "agentFee": {
              if (
                formFields["agent"].value?.value &&
                formFields["agent"].value?.value?.trim().length
              ) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Fee must be a positive number";
                  isFormValid = false;
                } else if (
                  !formFields[key].value &&
                  !formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                // formFields[key].isDirty = true;
                // formFields[key].error = "*Required";
                // isFormValid = false;
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "clientTeams": {
              if (this.state?.clientTeamsList?.length) {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _validateDraftForm = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;
      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            // case "closingAddressTimeZone": {
            //   console.log("qwera", formFields.signingType.value === "RON");
            //   if (
            //     formFields.signingType.value === "RON" ||
            //     (formFields?.loanType?.value?.length === 1 &&
            //       formFields?.loanType?.value[0]?.value === "AttorneyPhone")
            //   ) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }

            case "clientTeams": {
              if (this.state?.clientTeamsList?.length) {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            default: {
            }
          }
        }
      });
      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields, signingInstruction, textInstruction } = deepClone(
      this.state
    );
    console.log("ioioi", fieldName, value);
    if (fieldName === "signingCompanyFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "instructionId") {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );
      formFields.instructionId.value = findInstruction?._id || "";
      formFields.instructions.value = findInstruction?.description || "";
      formFields.instructionSignerData.value =
        findInstruction?.description || "";
    }

    if (fieldName === "appointmentDate") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
      formFields.appointmentDate.isFieldDirty = true;
    } else formFields[fieldName].value = value;

    if (fieldName === "internalNotes") {
      formFields.internalNotes.isFieldDirty = true;
    }

    if (
      fieldName === "homePhone" ||
      fieldName === "phone" ||
      fieldName === "workPhone" ||
      fieldName === "alternatePhone"
    )
      formFields[fieldName].value = value.replace(
        /\([^]*\)|[^a-z0-9\s]|\D|\s/gi,
        ""
      );

    if (fieldName === "agent") {
      formFields[fieldName].value = value;
      formFields["agentFee"].isDirty = true;
    }

    if (fieldName === "witnessCount") {
      let text =
        value !== ""
          ? !textInstruction.includes("witness")
            ? `<p>#${value} witness required.</p>`.concat(textInstruction)
            : textInstruction.replace(/\d+(?= witness required)/, value)
          : textInstruction.replace(/<p>#.*?<\/p>/, "");

      this.setState({
        textInstruction: text,
      });
    }

    if (fieldName === "ronPlatformType") {
      let text = value?.length
        ? !formFields.instructions.value.includes("MUST BE A RON APPROVED")
          ? `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
              (each) => each?.value
            )}`.concat(formFields.instructions.value)
          : formFields.instructions.value.replace(
              /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
              `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
                (each) => each?.value
              )}`
            )
        : formFields.instructions.value.replace(
            /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
            ""
          );
      formFields["instructions"].value = text;
    }

    if (fieldName === "client") {
      formFields["clientTeams"].value = "";
      if (value?.length) {
        this._getCompanyListTeamsOfClient(value);
      } else {
        this.setState({ clientTeamsList: [] });
      }
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      // this._validateForm();
      if (
        fieldName === "loanType" ||
        // fieldName === "closingAddressState" ||
        // fieldName === "closingAddressCity" ||
        // fieldName === "closingSearch" ||
        fieldName === "signingType" ||
        fieldName === "client"
      ) {
        // console.log("standardRate");
        this._findStandardrate();
      }
      if (fieldName === "client" || fieldName === "loanType") {
        // console.log("scanBack");
        this._findScanbackDocument();
      }
      if (fieldName === "signingType" && value) {
        this.setState(
          {
            isBorrowerSearch: false,
            isAppointmentSearch: false,
          },
          () => {
            this._validateForm();
          }
        );
      }
      if (fieldName === "witnessCount" || fieldName === "instructionId") {
        this._signerDataInstruction();
      }
    });
  };

  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);

      this.setState({ clientTeamsList: res?.teams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  _signerDataInstruction = () => {
    const { formFields, textInstruction } = deepClone(this.state);
    let finalText = textInstruction.concat(
      formFields.instructionSignerData.value
    );
    formFields["instructions"].value = sortText(finalText);

    this.setState({ formFields });
  };

  _findStandardrate = async (closingAddressResponse) => {
    console.log("Call _findStandardrate");
    try {
      const { formFields } = deepClone(this.state);

      let closingAddress;
      const data = await this.addressRef?.current?._getAddressData();
      if (closingAddressResponse === undefined) {
        closingAddress = data;
      } else {
        closingAddress = closingAddressResponse;
      }

      let companySettingStandardFee = this._filterObjectForAgent(
        closingAddress?.state?.value || "",
        closingAddress?.city?.value || "",
        formFields?.loanType?.value,
        formFields?.signingType?.value,
        formFields?.client?.value
      );

      let companySettingStandardFeeClient = this._filterObjectForCompany(
        closingAddress?.state?.value || "",
        closingAddress?.city?.value || "",
        formFields?.loanType?.value,
        formFields?.signingType?.value,
        formFields?.client?.value
      );
      console.log("comp", companySettingStandardFee);
      const agentFeesObj =
        companySettingStandardFee &&
        companySettingStandardFee?.find((each) => !each?.isForClient);
      const companyFeesObj = companySettingStandardFeeClient?.find(
        (each) => each?.isForClient
      );

      formFields["agentFee"].value =
        agentFeesObj?.offerRate !== undefined
          ? agentFeesObj?.offerRate?.toString()
          : formFields?.agentFee?.value;

      formFields["signingCompanyFee"].value =
        companyFeesObj?.offerRate !== undefined
          ? companyFeesObj?.offerRate?.toString()
          : formFields?.signingCompanyFee?.value;

      if (
        agentFeesObj?.offerRate !== undefined ||
        companyFeesObj?.offerRate !== undefined
      ) {
        this.setState({ formFields });
      }
    } catch (error) {
      console.log("errr _findStandardrate >>", error);
    }
  };

  _filterObjectForAgent = (state, city, productType, signingType) => {
    const { companySetting, formFields } = deepClone(this.state);
    console.log("fil", state, city, productType, signingType);
    let loanTypes = productType.map((item) => item.value);

    let filteredData =
      companySetting &&
      Object.keys(companySetting).length > 0 &&
      companySetting?.standardFeeRates.filter(
        (item) =>
          !item.isForClient &&
          item.signingType === signingType &&
          loanTypes.includes(item.productType)
      );
    console.log("agentfilteredData1", filteredData);

    if (state || state === "") {
      filteredData =
        Array.isArray(filteredData) &&
        filteredData?.filter((item) =>
          item.state.trim().length > 0 ? item.state === state : true
        );
    }
    console.log("agentfilteredData2", filteredData);

    if (city) {
      filteredData =
        Array.isArray(filteredData) &&
        filteredData?.filter((item) =>
          item.county.trim().length > 0
            ? item.county
                .toLowerCase()
                .includes(formFields?.closingAddressCity?.value.toLowerCase())
            : true
        );
    }
    console.log("agentfilteredData3", filteredData);
    return filteredData;
  };

  _filterObjectForCompany = (
    state,
    city,
    productType,
    signingType,
    clientId
  ) => {
    try {
      console.log("qwas", state, city, productType, signingType, clientId);
      const { companySetting } = deepClone(this.state);

      let loanTypes = productType.map((item) => item.value);

      let filteredData =
        // companySetting &&
        Object.keys(companySetting).length > 0 &&
        companySetting?.standardFeeRates?.filter(
          (item) =>
            item.isForClient &&
            item.signingType === signingType &&
            loanTypes.includes(item.productType)
        );
      console.log("filteredData1", filteredData);

      if (state) {
        const matched = filteredData?.filter(
          (item) => item?.isForClient && item?.state === state
        );
        filteredData =
          matched.length > 0
            ? matched
            : Array.isArray(filteredData) &&
              filteredData?.filter((item) => item?.state === "");
        console.log("matchstate", matched);
      } else {
        filteredData = filteredData?.filter((item) => item?.state === "");
      }
      console.log("filteredData2", filteredData);

      if (city) {
        const matched = filteredData?.filter((item) =>
          item?.county?.toLowerCase().includes(city.toLowerCase())
        );
        console.log("matchcity", matched, filteredData);

        filteredData =
          matched.length > 0
            ? matched
            : filteredData?.filter(
                (item) =>
                  item?.county === undefined ||
                  item?.county?.trim().length === 0
              );
        console.log("filterdatty", filteredData);
      } else {
        filteredData =
          filteredData && filteredData?.filter((item) => !item?.county);
      }

      console.log("filteredData2", filteredData);

      if (clientId) {
        const matched = filteredData?.find(
          (item) => item?.isForClient && item?._client?.id === clientId
        );
        filteredData = matched
          ? [matched]
          : filteredData?.filter(
              (item) => item?.isForClient && item?._client === undefined
            );
      } else {
        filteredData = filteredData?.filter(
          (item) => item?.isForClient && item?._client === undefined
        );
      }
      console.log("filteredData3", filteredData);
      return filteredData;
    } catch (error) {
      console.log("Error >>", error);
    }
  };

  _findScanbackDocument = () => {
    const { companySetting, formFields } = deepClone(this.state);

    if (
      formFields?.loanType?.value !== "" &&
      formFields?.client?.value !== ""
    ) {
      let companySettingScanBackDocument =
        companySetting &&
        companySetting?.scanBackDocument?.scanBackDocumentDetail?.some(
          (item) =>
            item?._client?._id === formFields.client.value &&
            item?.productType === formFields.loanType.value
        );
      formFields["isScanBackNeeded"].value = companySetting?.scanBackDocument
        ?.isAlwaysRequired
        ? companySetting?.scanBackDocument?.isAlwaysRequired
        : companySettingScanBackDocument;

      formFields["agentFee"].value = formFields.agentFee.value
        ? formFields.agentFee.value
        : "";
      this.setState({ formFields }, () => {
        this._findStandardrate();
      });
    }
  };

  _updateFile = (event) => {
    const { uploadFiles } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      Object.keys(event.target.files).forEach((index) => {
        let objFile = event.target.files[index];
        if (objFile.type.includes("pdf") || objFile.type.includes("word")) {
          uploadFiles.push({
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            category: "documents",
          });
        } else {
          showToast("Only Doc, Docx or PDF file is allowed", "error");
        }
      });
    }
    this.setState({ uploadFiles });
  };

  _deleteContent = (index) => {
    let { uploadFiles } = deepClone(this.state);
    uploadFiles.splice(index, 1);
    this.setState({ uploadFiles });
  };

  _structureData = (updatedFormfields) => {
    const data = updatedFormfields?.map(
      ({
        _id,
        name,
        email,
        workPhone,
        phoneHome,
        isForeignNational,
        language,
        languageTypeOther,
        timeZone,
      }) => {
        const splitBorrowerName = name.value.trim().split(" ");

        return {
          _id: _id?.value?.trim().length ? _id.value : undefined,
          name: {
            first: splitBorrowerName.slice(0, 1).join(" "),
            last: splitBorrowerName[1]
              ? splitBorrowerName.slice(1).join(" ")
              : "",
          },
          email: email.value ? email.value : undefined,
          phone: {
            work: workPhone.value ? workPhone.value : undefined,
            alternate: phoneHome?.value ? phoneHome?.value : undefined,
          },
          isForeignNational: isForeignNational.value,
          language: language.value ? language.value : undefined,
          languageTypeOther: languageTypeOther.value
            ? languageTypeOther.value
            : undefined,
          timeZone: timeZone.value ? timeZone.value : undefined,
          address: {},
        };
      }
    );
    return data;
  };

  _submitCreateSigningClosing = async (e) => {
    if (e) e.preventDefault();

    if (this.state.createClosing) {
      this._manageLoading("submitLoading", true);
      await this._markAllFieldDirty();
    } else {
      this._manageLoading("draftLoading", true);
      await this._markDraftFieldDirty();
    }

    const isFormValid = this.state.createClosing
      ? await this._validateForm()
      : await this._validateDraftForm();

    const res = await this.signerInfoGroupRef?.current?._handleSubmit(
      this.state.createDraft
    );

    const addressResponse = await this.addressRef?.current?._handleSubmit();
    console.log("addressResponse >>", addressResponse);

    const {
      isClosingAddressValid,
      closingAddressResponse,
      borrowerAddressResponse,
    } = addressResponse;

    console.log("!isFormValid ", isFormValid);

    console.log("|| !res?.isFormValid >> >>", !res?.isFormValid);

    const isAppointmentSchedulerResponse =
      await this.appointmentSchedulerRef.current.getAppointmentSchedulerData();
    // console.log("isAppointmentSchedulerResponse >>", {
    //   ...isAppointmentSchedulerResponse.appointmentSchedulerData,
    // });

    if (!isFormValid) {
      // showToast("Please Fill The Required Fields", "error");
      Object.keys(this.state.formFields)
        .filter((item) => item !== "closingSearch")
        .forEach((key) => {
          if (this.state.formFields[key].error) {
            const result = key.replace(/([A-Z])/g, " $1");
            const finalResult =
              result.charAt(0).toUpperCase() + result.slice(1);
            console.log("finalResult >>", finalResult);
            const finalResult2 =
              finalResult === "Loan Type"
                ? "Product Type"
                : finalResult === "Closing Address Time Zone"
                ? "Closing Time Zone"
                : finalResult;
            // console.log(key)
            showToast(`Please provide ${finalResult2} `, "error");
          }
        });
      if (
        !this.state.createDraft &&
        (!this.state.formFields.fileNumber.value ||
          !this.state.formFields.fileNumber.value.trim().length > 0)
      ) {
        this._toggleFileCheckedModal(true);
      }

      this._manageLoading("draftLoading", false);
      this._manageLoading("submitLoading", false);
    }

    if (!isClosingAddressValid || !isAppointmentSchedulerResponse?.isValid) {
      if (!isAppointmentSchedulerResponse?.isValid) {
        showToast(`Please provide Appointment Date `, "error");
      }
      if (!isClosingAddressValid) {
        showToast(`Please provide valid Closing Address `, "error");
      }
      this._manageLoading("draftLoading", false);
      this._manageLoading("submitLoading", false);
    }

    try {
      console.log(
        " QWERTY >> ",
        isFormValid &&
          res?.isFormValid &&
          closingAddressResponse?.isFormValid &&
          isAppointmentSchedulerResponse?.isValid
      );
      if (
        isFormValid &&
        res?.isFormValid &&
        isClosingAddressValid &&
        isAppointmentSchedulerResponse?.isValid
      ) {
        this.props.showLoader("Create Closing...");
        const { formFields, uploadFiles } = deepClone(this.state);

        let borrower = await this._structureData(res?.updatedFormfields);
        // console.log(updatedClosingAddress?.closingAddress);

        const payload = {
          ...isAppointmentSchedulerResponse.appointmentSchedulerData,
          signingCompanyFee: this.props.userData?.user?.isTitleCompany
            ? "1000"
            : formFields.signingCompanyFee.value
            ? formFields.signingCompanyFee.value
            : undefined,
          fileNumber: formFields.fileNumber.value
            ? formFields.fileNumber.value
            : undefined,
          lender: formFields.lender.value ? formFields.lender.value : undefined,

          personOfContact: {
            name: formFields.personOfContactName.value,
            email:
              formFields.personOfContactEmail.value.length > 0
                ? formFields.personOfContactEmail.value
                : [],
          },

          loanTypeOther:
            formFields.loanType.value.filter((item) => item.value === "Other")
              .length > 0
              ? formFields.loanTypeOther.value
              : undefined,
          // appointmentDate: formatDateMoment(formFields.appointmentDate.value),

          closingAddress: {
            line1: closingAddressResponse?.addressLine1?.value?.trim().length
              ? closingAddressResponse?.addressLine1?.value
              : undefined,
            line2: closingAddressResponse?.addressLine2?.value?.trim().length
              ? closingAddressResponse?.addressLine2?.value
              : undefined,
            city: closingAddressResponse?.city?.value?.trim().length
              ? closingAddressResponse?.city?.value
              : undefined,
            state: closingAddressResponse?.state?.value?.trim().length
              ? closingAddressResponse?.state?.value
              : undefined,
            zip: closingAddressResponse?.zip?.value?.trim().length
              ? closingAddressResponse?.zip?.value
              : undefined,
            timeZone: formFields?.closingAddressTimeZone.value
              ? formFields?.closingAddressTimeZone?.value
              : undefined,
            county: closingAddressResponse?.county?.value?.trim().length
              ? closingAddressResponse?.county?.value
              : undefined,
          },

          timeZone: formFields.closingAddressTimeZone.value
            ? formFields.closingAddressTimeZone.value
            : undefined,

          instructions: formFields.instructions.value
            ? formFields.instructions.value
            : undefined,
          isScanBackNeeded: formFields.isScanBackNeeded.value,
          clientId: formFields.client.value
            ? formFields.client.value
            : undefined,
          agentId:
            formFields.agent.value !== ""
              ? formFields.agent.value.value
              : undefined,
          agentFee:
            formFields.agentFee.value !== ""
              ? formFields.agentFee.value
              : undefined,
        };

        payload["borrower"] = borrower;

        if (formFields?.additionalSigner?.value) {
          payload["additionalSigner"] = formFields.additionalSigner.value;
        }

        if (formFields?.signingType?.value === "RON")
          payload.ronPlatformTypes = formFields?.ronPlatformType?.value?.length
            ? formFields?.ronPlatformType?.value?.map((each) => each?.value)
            : undefined;

        if (
          formFields?.internalNotes?.isFieldDirty &&
          formFields?.internalNotes?.value &&
          formFields?.internalNotes?.value?.trim()?.length
        ) {
          payload["internalNotes"] = formFields.internalNotes.value.trim();
        }

        if (
          formFields?.companyNotes?.value &&
          formFields?.companyNotes?.value.trim().length
        ) {
          payload["companyNotes"] = formFields.companyNotes.value.trim();
        }
        if (
          formFields?.signingType?.value &&
          formFields?.signingType?.value.trim().length
        ) {
          payload["signingType"] = formFields.signingType.value.trim();
        }

        if (
          formFields?.witnessCount?.value &&
          formFields?.witnessCount?.value.trim().length
        ) {
          payload["witnessCount"] = Number(
            formFields.witnessCount.value.trim()
          );
        }

        if (
          formFields?.clientTeams?.value &&
          formFields?.clientTeams?.value?.trim()?.length
        ) {
          payload["_clientAssistantTeam"] =
            formFields?.clientTeams?.value?.trim();
        }

        if (formFields?.loanType?.value) {
          payload["loanType"] = await formFields.loanType.value.map(
            (item) => item.value
          );
        }
        if (formFields?.loanCategories?.value) {
          payload["loanCategories"] = await formFields?.loanCategories?.value;
        }

        if (formFields?.ronPlatformType?.value) {
          payload["ronPlatformTypes"] =
            await formFields.ronPlatformType.value.map((item) => item.value);
        }

        if (
          formFields?.loanOfficer?.value &&
          formFields?.loanOfficer?.value?.trim()?.length
        ) {
          payload["loanOfficer"] = formFields?.loanOfficer?.value.trim();
        }

        if (borrowerAddressResponse?.zip?.value) {
          const address = extractValidFields(borrowerAddressResponse);
          const modifiedAddress = {
            ...address,
            line1: address.addressLine1,
            line2: address.addressLine2,
          };

          delete modifiedAddress.addressLine1;
          delete modifiedAddress.addressLine2;

          const newArray = borrower?.map((obj) => ({
            ...obj,
            address: modifiedAddress,
          }));
          payload["borrower"] = newArray;
        }
        console.log("payload >>>>>", payload);

        // this for checking undefined and blank values and remove that keys
        const filteredPayload = Object.keys(payload).reduce((acc, key) => {
          if (
            payload[key] !== undefined &&
            payload[key] !== "" &&
            (!Array.isArray(payload[key]) || payload[key].length > 0)
          ) {
            acc[key] = payload[key];
          }
          return acc;
        }, {});

        if (uploadFiles && uploadFiles.length) {
          try {
            filteredPayload["documents"] = [];

            let postID = PostManager.addMediaFilesCount(uploadFiles.length);

            PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
              if (id.postID === postID) {
                console.log("payload :>> ", id);
                await sleepTime(500);

                // console.log("filteredPayload >>", filteredPayload);
                this.apiCallingDraftOrClosing(filteredPayload);

                PostManager.deletePostID(postID);
              } else {
                console.log("into else");
                return;
              }
            });

            uploadFiles.forEach((uploadFile) => {
              // for logo images
              let mediaData = {
                file: uploadFile.uploadData,
                blobObject: uploadFile.previewBlob,
                isPrivate: uploadFile.isPrivate
                  ? uploadFile.isPrivate
                  : undefined,
              };
              const uploadId = UploadQueueManager.addMediaToQueue(
                mediaData,
                "pdf"
              );

              // Listen for upload complete
              UploadQueueManager.onUploadComplete(
                uploadId,
                async (mediaResponse) => {
                  PostManager.onSingleMediaFileUploadCompleted(postID);
                  // Upload complete
                  // Get content id from backend
                  filteredPayload["documents"].push({
                    title: addUnixTimeToString(
                      mediaResponse.data.media.file.name
                    ),
                    url: mediaResponse.fileUrl,
                    docType: mediaResponse.data.mediaType,
                    category: uploadFile?.category,
                    isPrivate: uploadFile.isPrivate
                      ? uploadFile.isPrivate
                      : undefined,
                  });
                }
              );

              UploadQueueManager.onUploadFailed(uploadId, async (error) => {
                console.log("response", error);
                this.props.hideLoader();
                showToast(
                  error && error.reason && error.reason.length
                    ? error.reason
                    : "Cannot Upload File to AWS",
                  "error"
                );
              });
            });
          } catch (error) {
            this.props.hideLoader();
            showToast(
              error && error.reason && error.reason.length
                ? error.reason
                : "Server error. Try again after sometime.",
              "error"
            );
          }
        } else {
          this.apiCallingDraftOrClosing(filteredPayload);
          // console.log("filteredPayload >>", filteredPayload);
        }
      } else {
        console.log("scscsacascasc");
        if (this.state.createClosing) {
          this._manageLoading("submitLoading", false);
        } else {
          this._manageLoading("draftLoading", false);
        }
        return;
      }
    } catch (error) {
      console.log({ error });
      this._manageLoading("draftLoading", false);
      this._manageLoading("submitLoading", false);
      this.props.hideLoader();
    }
  };

  apiCallingDraftOrClosing = async (payload) => {
    const { closingDetails, createDraft, createClosing } = this.state;
    let params = this.props.location?.search?.split("&");
    let id = params[0].replace(/[^\w\s]/gi, "");
    const queryString = extractQueryParams();
    // console.log("queryString >>", queryString?.isClone === "true");
    // this.props.hideLoader();
    // return;
    // console.log("egtyhv5", this.props.location?.search?.trim().length > 0);

    const shouldCreateClosing =
      this.state.createClosing && !this.state.createDraft;
    const isSearchEmpty = this.props.location?.search?.trim().length === 0;
    const isCloneQuery = queryString?.isClone === "true";

    //for create Closing
    if (
      (shouldCreateClosing && isSearchEmpty) ||
      (shouldCreateClosing && isCloneQuery)
    ) {
      this._createSigningCompanyClosing(payload);
      return;
    }

    // for new create Draft
    if (
      (this.state.createDraft && isSearchEmpty) ||
      (this.state.createDraft && isCloneQuery)
    ) {
      this._draftSigningCompanyClosing(payload);
      return;
    }

    // for converting draft into closing
    if (
      !isSearchEmpty &&
      !isCloneQuery
      // formFields.agent?.value?.trim().length
    ) {
      try {
        console.log(
          "closingDetails isSavedAsDraft>>",
          closingDetails.isSavedAsDraft
        );
        console.log(
          "closingDetails isImportedFile>>",
          closingDetails.isImportedFile
        );
        console.log("closingDetails createClosing>>", createClosing);
        console.log("closingDetails draftClosing>>", createDraft);

        // this section for closing from imported file
        // And Click on the create button
        if (
          closingDetails.isImportedFile &&
          closingDetails.isSavedAsDraft &&
          this.state.createClosing &&
          !this.state.createDraft
        ) {
          this._updateCompanyDetails(id, payload);
        } else if (
          !closingDetails.isImportedFile &&
          closingDetails.isSavedAsDraft &&
          this.state.createClosing &&
          !this.state.createDraft
        ) {
          // this section for closing from draft
          // Not imported file
          this._updateCompanyDetails(id, payload);
        } else if (
          !closingDetails.isImportedFile &&
          !closingDetails.isSavedAsDraft
        ) {
          this._updateCompanyDetails(id, payload);
        } else {
          let response = await this._updateSigningCompanyDraftClosing(
            id,
            payload
          );
          if (response?.closing?.internalNotes) {
            const payload = {
              text: response?.closing?.internalNotes,
              closingId: response?.closing?.id,
              isVisibleClient: false,
              isVisibleAgent: false,
              isInternal: true,
            };
            await createChatLog(payload);
          }
          showToast("Updated Successfully", "success");
          this.props.hideLoader();
          this.props.history.push("/signingcompany/dashboard");
        }
      } catch (error) {
        this.props.hideLoader();
        errorHandler(error);
      }
    }
  };

  _updateCompanyDetails = (id, payload) => {
    payload.isSavedAsDraft = false;
    updateCompanyDetails(id, payload)
      .then((res) => {
        showToast("Updated Successfully", "success");
        this.props.hideLoader();
        this.props.history.push("/signingcompany/dashboard");
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _updateSigningCompanyDraftClosing = async (id, payload) => {
    try {
      const res = await editDraft(id, payload);
      return res;
    } catch (error) {
      throw error;
    }
  };

  _draftSigningCompanyClosing = (data) => {
    // console.log("dahyah", data);
    const { formFields, companySetting } = deepClone(this.state);
    createNewDraft(data)
      .then((res) => {
        if (
          companySetting.bidAutomationSetting.autoSendBidRequest &&
          formFields?.agentFee?.value?.trim()?.length
        ) {
          this._startAutomation(res?.closing);
        }

        if (res?.closing?.internalNotes) {
          const payload = {
            text: res?.closing?.internalNotes,
            closingId: res?.closing?.id,
            isVisibleClient: false,
            isVisibleAgent: false,
            isInternal: true,
          };
          createChatLog(payload);
        }

        showToast("Closing Draft Successfully", "success");
        this.props.hideLoader();
        this._manageLoading("draftLoading", false);
        this.props.history.push("/signingcompany/dashboard");
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
        this._manageLoading("draftLoading", false);
      });
  };

  _createSigningCompanyClosing = (data) => {
    const { formFields, companySetting } = deepClone(this.state);
    createNewSigningCompanyClosing(data)
      .then((res) => {
        if (
          companySetting.bidAutomationSetting.autoSendBidRequest &&
          formFields.agentFee?.value?.trim().length &&
          !formFields.agent.value?.value?.trim().length > 0
        ) {
          this._startAutomation(res.closing);
        }

        if (res?.closing?.internalNotes) {
          const payload = {
            text: res?.closing?.internalNotes,
            closingId: res?.closing?.id,
            isVisibleClient: false,
            isVisibleAgent: false,
            isInternal: true,
          };
          createChatLog(payload);
        }

        showToast("Closing Created Successfully", "success");
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
        this.props.history.push("/signingcompany/dashboard");
      })
      .catch((error) => {
        console.log("errore", error);
        errorHandler(error);
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
      });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _markDraftFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      formFields["closingAddressTimeZone"].isDirty = true;
      formFields["clientTeams"].isDirty = true;

      this.setState({ formFields }, () => resolve(true));
    });
  };

  uploadClosingPdfFile = async (event) => {
    try {
      const pdfFile = event.target.files[0];

      if (!pdfFile || !pdfFile.type.includes("pdf")) {
        showToast("Only PDF files are allowed", "error");
        return;
      }

      this.setState((prevState) => ({
        loading: {
          ...prevState.loading,
          fileUploadLoading: true,
        },
      }));

      this.setState({ pdfFile, uploadFiles: [] }, async () => {
        HeaderEventEmitter.dispatch("confirmationFileLoading", true);

        try {
          const pdf = new FormData();
          pdf.append("file", pdfFile);

          const response = await uploadClosingPdf(pdf);

          if (response.data?.borrowers?.length) {
            this._resetForm();
            this._setForm(response.data, true);
            this._addingInUpload();
            showToast("Order Created", "success");
          } else {
            this.setState({ pdfFile: null });
            showToast("Something went wrong!", "error");
          }

          this.setState((prevState) => ({
            loading: {
              ...prevState.loading,
              fileUploadLoading: false,
            },
          }));
        } catch (error) {
          console.log("error>>", error);
          this.setState((prevState) => ({
            loading: {
              ...prevState.loading,
              fileUploadLoading: false,
            },
          }));
          showToast(error.reason || "Something went wrong!", "error");
        } finally {
          HeaderEventEmitter.dispatch("confirmationFileLoading", false);
        }
      });
    } catch (error) {
      console.error("Error handling file upload:", error);
      this.setState({ loading: { fileUploadLoading: false } });
    }
  };

  _addingInUpload = () => {
    const { uploadFiles } = deepClone(this.state);

    let objFile = this.state.pdfFile;
    uploadFiles.push({
      previewBlob: URL.createObjectURL(objFile),
      uploadData: objFile,
      isPrivate: true,
      category: "confirmation",
    });

    this.setState({ uploadFiles });
  };

  checkNotMetioned = (text) => {
    let toAvoid = [
      "Not mentioned",
      "N/A (not specified)",
      "Not Specified",
      "Not mentioned in the text",
      "N/A (not mentioned in the text)",
    ];
    let lowerCaseText = text.toLowerCase();

    for (let phrase of toAvoid) {
      if (lowerCaseText.includes(phrase.toLowerCase())) {
        return false;
      }
    }

    return true;
  };

  _manageLoading = (loaderName, value) => {
    console.log("_manageLoading >>", loaderName, value);
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading }, () => {
      console.log("loading?.submitLoading >>", loaderName, loading);
    });
  };

  _getAgentWithZipcode = (data) => {
    const { agentsZipCodePage, formFields } = deepClone(this.state);
    let payload = {
      zipCode: formFields?.closingAddressZip?.value
        ? formFields?.closingAddressZip?.value
        : undefined,
      state:
        this.state?.closingDetails?.signingType === "RON"
          ? data?.closingAddress?.state
          : undefined,
      signingType: formFields?.signingType?.value,
      skip: 0,
      limit: agentsZipCodePage?.page * agentsZipCodePage?.limit,
      search: data?.search,
    };

    if (
      data ||
      // data?.closingAddress?.zip?.length === 5 ||
      // data?.closingAddress?.state
      // payload ||
      payload?.zipCode === 5 ||
      payload?.state
    ) {
      this._manageLoading("agentSearchLoading", true);

      getAgentWithZipcode(payload)
        .then((res) => {
          let options =
            res?.agents?.map((item) => ({
              value: item?._id,
              label: capitalize(item?.name?.first + " " + item?.name?.last),
            })) || [];
          this.setState({
            dropdownAgentList: options,
            agentSuggestedTotalCount: res?.totalCount || 0,
          });
          this._manageLoading("agentSearchLoading", false);
          return options;
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("agentSearchLoading", false);
          // this._manageLoading("showTableLoading", false);
        });
    }
  };

  _onChipSelect = (chips) => {
    let isValid = chips.length ? isValidEmail(chips[chips?.length - 1]) : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          // chips: chips,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _startAutomation = (data) => {
    const { formFields } = deepClone(this.state);
    const { bidAutomationSetting } = deepClone(this.state.companySetting);
    if (formFields?.agentFee?.value?.toString()?.trim()) {
      let payload = {
        automationType: bidAutomationSetting.automationType,
        isCounter: bidAutomationSetting.autoSendBidRequest,
        biddingAmount: Number(formFields?.agentFee?.value),
        autoAssignCounterResponse:
          bidAutomationSetting.autoAssignCounterResponse,
        bidInstructions: data?.instructions,
      };
      console.log(bidAutomationSetting);
      // this._connectToSocket();
      startAutomationForClosing(data._id, payload)
        .then((res) => {
          showToast("Automation started Successfully", "success");
        })
        .catch((error) => {
          errorHandler(error);
        });
    } else {
      showToast("No Agent is in the list", "error");
    }
  };

  _approvedFileNumber = (value) => {
    const { formFields } = deepClone(this.state);
    formFields["fileNumber"].value = "CW-" + value?.FileNumber;
    this.setState({ formFields }, () => {
      this._toggleFileCheckedModal();
      this._validateForm();
    });
  };

  _addTextInInstruction = (subField, value, index) => {
    const { textInstruction } = deepClone(this.state);

    if (subField === "isForeignNational") {
      let text = value
        ? `<p>Signer ${index + 1} is a foreign National.</p>`.concat(
            textInstruction
          )
        : textInstruction.replace(
            `<p>Signer ${index + 1} is a foreign National.</p>`,
            ""
          );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "timeZone") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} is in ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
                `<p>Signer ${index + 1} is in ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
              ""
            );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "language") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} speaks ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
                `<p>Signer ${index + 1} speaks ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
              ""
            );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
  };

  _sortText = (input) => {
    console.log("text", input);
    const signerRegex = /<p>.*?\bSigner\s+\d+\b.*?<\/p>/g;
    const signerMatches = input.match(signerRegex);
    console.log("signerMatches", signerMatches);
    // sort the signer strings in ascending order
    if (signerMatches !== null) {
      signerMatches.sort();

      // replace the original signer strings with the sorted ones
      let output = input.replace(signerRegex, () => signerMatches.shift());

      // console.log(output);
      return output;
    } else {
      return input;
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  searchOnChange = debounce((value) => {
    // console.log(value);
    if (value.length > 0) {
      this._getAgentWithZipcode({ search: value });
    }
  }, 1000);

  _duplicateFileNumberCheck = async (e, type = "submitLoading") => {
    if (e) e.preventDefault();

    console.log("type >>>", type);
    let { fileNumber } = deepClone(this.state.formFields);
    const { formFields } = deepClone(this.state);
    try {
      this._manageLoading(type, true);
      const res = await FileNumberDuplicateMatchCheck({
        fileNumber: fileNumber.value,
      });

      if (res?.existingFileNos.length > 0) {
        // this._toggleFileNumberUniqueCheckedModal(true);
        this.setState({ existingFileNos: res.existingFileNos });
        const uniqueFileNo = generateUniqueFileNo(
          res.existingFileNos,
          fileNumber.value
        );

        formFields.fileNumber.value = uniqueFileNo;
        this.setState({ formFields }, async () => {
          await this._submitCreateSigningClosing(e);
        });
        this._manageLoading(type, false);
      } else {
        await this._submitCreateSigningClosing(e);
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading(type, false);
    }
  };

  getIsClosingAddressRequired = () => {
    const { formFields } = this.state;
    return (
      formFields.signingType.value !== "RON" &&
      !(
        formFields?.loanType?.value?.length === 1 &&
        formFields?.loanType?.value[0]?.value === "AttorneyPhone"
      )
    );
  };

  _keepFileNo = (e) => {
    this.setState({ keepFileNo: true }, () => {
      this._toggleFileNumberUniqueCheckedModal();
      this._submitCreateSigningClosing(e);
    });
  };

  render() {
    const {
      formFields,
      uploadFiles,
      clients,
      loanType,
      addClientModalData,
      // eslint-disable-next-line no-unused-vars
      loading,
      fileChecked,
      dropdownAgentList,
      signingInstruction,
      confirmationFileLoading,
      closingDetails,
      ronPlatformTypeOptions,
      agentSuggestedTotalCount,
      agentsZipCodePage,
      clientTeamsList,
      FileNumberUniqueChecked,
      existingFileNos,
      keepFileNo,
    } = deepClone(this.state);
    // console.log("loading?.submitLoading", loading?.submitLoading);
    // console.log("closing Address >>", closingAddress);
    // console.log("brrower Address >>", borrowerAddress);

    // console.log(
    //   "{this.state.appointmentSchedulerData >>",
    //   this.state.appointmentSchedulerData

    // );

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                // onClick={() =>
                //   !isRegularUser() && this._toggleUpgradeAccountModal(true)
                // }
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>Create Order</h2>
              {console.log("HSPPPPPPP")}
            </div>

            <div className="rightSide">
              {(!Object.keys(closingDetails).length > 0 ||
                !closingDetails?.isSavedAsDraft) && (
                <>
                  <Label
                    className="uploadBtn floatingButton"
                    for="uploadAutomation"
                    // onClick={() =>
                    //   !isRegularUser() && this._toggleUpgradeAccountModal(true)
                    // }
                  >
                    {loading.fileUploadLoading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      <>
                        <img
                          src={
                            require("../../../assets/img/uploadIcon.svg")
                              .default
                          }
                          alt="upload automation"
                        />{" "}
                        UPLOAD
                      </>
                    )}

                    <Input
                      type="file"
                      id="uploadAutomation"
                      accept=".pdf"
                      disabled={confirmationFileLoading}
                      title="Upload Confirmation"
                      value=""
                      onChange={(event) => this.uploadClosingPdfFile(event)}
                    />
                  </Label>
                </>
              )}
            </div>
          </div>

          <Row>
            <Col>
              <Suspense fallback={<></>}>
                <SignerInfoGroup
                  ref={this.signerInfoGroupRef}
                  _onChangeFormFieldBorrower={(fieldName, value, index) =>
                    this._addTextInInstruction(fieldName, value, index)
                  }
                  data={this.state.borrowerData}
                  isNeedToShownTimeZone={
                    formFields?.signingType?.value === "RON" ||
                    (formFields?.loanType?.value?.length === 1 &&
                      formFields?.loanType?.value[0]?.value === "AttorneyPhone")
                  }
                />
              </Suspense>
              <hr />

              <Row className="ClosingWrapper">
                <CreateClosingAddressComponent
                  xl={4}
                  lg={6}
                  md={6}
                  xm={12}
                  ref={this.addressRef}
                  borrowerAddress={this.state.borrowerAddress}
                  closingAddress={this.state.closingAddress}
                  // closingGoogleAddress={this.state.closingGoogleAddress}
                  // borrowerGoogleAddress={this.state.borrowerGoogleAddress}
                  isClosingAddressRequired={
                    formFields.signingType.value !== "RON" &&
                    !(
                      formFields?.loanType?.value?.length === 1 &&
                      formFields?.loanType?.value[0]?.value === "AttorneyPhone"
                    )
                  }
                  passData={(data) => this._findStandardrate(data)}
                />

                <Col xl={4} md={6}>
                  <AppointmentScheduler
                    ref={this.appointmentSchedulerRef}
                    data={this.state.appointmentSchedulerData}
                    timeZone={closingDetails?.timeZone}
                  />
                </Col>
              </Row>

              <hr />

              <Suspense fallback={<></>}>
                <SignerClosingInfo
                  ref={this.signerClosingInfo}
                  type="signingcompany"
                  // lenderClients={lenderClients}
                  dropdownAgentList={dropdownAgentList}
                  lenderClients={clients}
                  formFields={formFields}
                  loanType={loanType}
                  ronPlatformTypeOptions={ronPlatformTypeOptions}
                  onChangeFormField={(fieldName, value) =>
                    this._onChangeFormField(fieldName, value)
                  }
                  validate={this._validateForm}
                  onChipSelect={(arr, fieldName) =>
                    this._onChipSelect(arr, fieldName)
                  }
                  onSucessFullyClientAdd={this._getAllFiltersData}
                  _getAgentWithZipcode={this._getAgentWithZipcode}
                  agentSuggestedTotalCount={agentSuggestedTotalCount}
                  agentsZipCodePage={agentsZipCodePage}
                  setPayloadAndCallApi={(newDropDownPayload) => {
                    this.setState(
                      {
                        agentsZipCodePage: newDropDownPayload,
                      },
                      () => this._getAgentWithZipcode(newDropDownPayload)
                    );
                  }}
                  xl={4}
                  lg={6}
                  md={6}
                  sm={12}
                  clientTeamsList={clientTeamsList}
                />
              </Suspense>

              <div className="uploadDocumentInline">
                {/* files upload can be seen by signing company only */}
                <Label className="uploadLink">
                  <Input
                    type="file"
                    style={{ display: "none" }}
                    accept=".pdf,.doc,.docx"
                    multiple
                    value=""
                    onChange={this._updateFile}
                  />
                  <img
                    src={require("../../../assets/img/uploadIcon.png")}
                    height={40}
                    className="mr-1"
                    alt="upload files"
                  />{" "}
                  Upload Signing Documents
                </Label>
                <div className="uploadList">
                  {uploadFiles && uploadFiles.length
                    ? uploadFiles
                        .filter((each) => each?.category === "documents")
                        .map((media, index) => (
                          <div className="uploadItem" key={index}>
                            {/* please image according to  upload files  */}
                            <img
                              key={index}
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              height={18}
                              alt="file Icon"
                            />

                            {/* file name */}
                            <span>
                              {media.uploadData && media.uploadData.name
                                ? media.uploadData.name
                                : null}
                            </span>
                            {!media?.isPrivate ? (
                              <Button
                                color="link"
                                onClick={() => this._deleteContent(index)}
                                className="deletePreview"
                              >
                                <img
                                  src={
                                    require("../../../assets/img/close_grey.svg")
                                      .default
                                  }
                                  height={15}
                                  alt="delete"
                                />
                              </Button>
                            ) : null}
                          </div>
                        ))
                    : null}
                </div>
              </div>

              <FormGroup className="floatingLabel">
                {/* only signing company gets to see it.  */}
                <Input
                  type="textarea"
                  rows="3"
                  placeholder=" "
                  value={formFields.internalNotes.value}
                  name="internalNotes"
                  onChange={(event) =>
                    this._onChangeFormField("internalNotes", event.target.value)
                  }
                />
                <Label>Internal Notes</Label>
              </FormGroup>

              <Suspense fallback={<></>}>
                <Instructions
                  instructionData={formFields.instructions.value}
                  paymentInstructionsData={formFields.paymentInstructions.value}
                  onChangeFormField={this._onChangeFormField}
                  signingInstruction={signingInstruction}
                  instructionIdData={formFields.instructionId.value}
                  isScanBackNeededData={formFields.isScanBackNeeded.value}
                  userType="signingcompany"
                />
              </Suspense>

              <div className="text-center mt-4">
                <Button
                  color="primary"
                  outline
                  size="lg"
                  onClick={(e) =>
                    this.setState(
                      {
                        draftClosing:
                          this.state.draftClosing !== null
                            ? this.state.draftClosing
                            : false,
                        createClosing: true,
                        createDraft: false,
                      },
                      () =>
                        // this.state.formFields?.fileNumber?.value?.length && !keepFileNo
                        this.state.formFields?.fileNumber?.value?.length &&
                        !this.state.draftClosing
                          ? this._duplicateFileNumberCheck(e, "submitLoading")
                          : this._submitCreateSigningClosing(e)
                    )
                  }
                  disabled={loading?.draftLoading || loading?.submitLoading}
                >
                  {loading?.submitLoading ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : null}{" "}
                  Create
                </Button>
                {formFields.agent.value === "" && (
                  <Button
                    className="ml-3"
                    size="lg"
                    color="primary"
                    onClick={(e) =>
                      this.setState(
                        { createDraft: true, createClosing: false },
                        () =>
                          this.state.formFields?.fileNumber?.value?.length
                            ? this._duplicateFileNumberCheck(e, "draftLoading")
                            : this._submitCreateSigningClosing(e)
                      )
                    }
                    disabled={loading?.draftLoading || loading?.submitLoading}
                  >
                    {loading?.draftLoading ? (
                      <i className="fa fa-spinner fa-spin mr-2" />
                    ) : null}{" "}
                    Draft
                  </Button>
                )}
              </div>
            </Col>
            {uploadFiles.length > 0 && (
              <Col xl={4} lg={4}>
                <div className="pdfView">
                  {/* <iframe
                    title="pdf"
                    // src={uploadFiles[0]?.previewBlob}
                    src={`https://docs.google.com/gview?url=${uploadFiles[0]?.previewBlob}&embedded=true`}
                  ></iframe> */}
                  <object
                    data={uploadFiles[0]?.previewBlob}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  >
                    <p>
                      Your browser does not support PDFs.{" "}
                      <a href={uploadFiles[0]?.previewBlob}>Download the PDF</a>
                      .
                    </p>
                  </object>
                </div>
              </Col>
            )}
          </Row>

          <Suspense fallback={<></>}>
            <InviteClient
              isOpen={addClientModalData.isOpen}
              data={addClientModalData.data}
              type={"add"}
              onSuccess={() => this._getAllFiltersData()}
              toggle={() => this._toggleAddClientModal()}
            />
          </Suspense>

          <Suspense fallback={<></>}>
            <FileChecked
              isOpen={fileChecked.isOpen}
              approved={this._approvedFileNumber}
              toggle={() => this._toggleFileCheckedModal()}
            />
          </Suspense>

          <FileNumberDuplicateModal
            isOpen={FileNumberUniqueChecked.isOpen}
            fileNumber={formFields.fileNumber.value}
            toggle={() => this._toggleFileNumberUniqueCheckedModal()}
            existingFileNos={existingFileNos}
            keep={(e) => this._keepFileNo(e)}
            FileNumberSubmitHandler={
              (value) => this._onChangeFormField("fileNumber", value)
              // this._submitCreateSigningClosing(e) // should comment out
            }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
    settings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    addSettings: (data) => dispatch(addSettings(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClosing);
