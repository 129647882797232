import React, { Component } from "react";
import { Input, Table, Button } from "reactstrap";
import CSVReader from "react-csv-reader";
import {
  convertToTitleCase,
  deepClone,
  errorHandler,
  showToast,
  splitCamelCase,
} from "../../../helper-methods";
import {
  agentbulkUploadexpenses,
  agentbulkUploadMilageLog,
  agentbulkUploadNotarialLog,
  agentbulkUploadPaidIncome,
  agentbulkUploadPendingIncome,
  bulkUploadClient,
  signingCompanybulkUploadAgents,
  signingCompanybulkUploadClients,
} from "../../../http/http-calls";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import BulkUploadInfo from "../components/BulkUploadInfo";
import {
  DropdownHeadersFields,
  manadatoryHeadersFields,
} from "../../../config/stateConfig";

const parseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

class BulkUpload extends Component {
  state = {
    csvParsedData: [],
    csvParsedheader: [],
    csvFile: null,
    headers: [],
    bulkUploadResponse: {
      isOpen: false,
      data: null,
      success: null,
    },
    loading: false,
  };

  componentDidMount() {
    // console.log("we", this.props.location.search);
    if (this.props.location.search.trim().length === 0) {
      this._goBack();
    }
    HeaderEventEmitter.subscribe("bulkUpload", this._handleForce);
    HeaderEventEmitter.subscribe("back-button", this._goBack);
  }

  _handleForce = ({ data, fileInfo, originalFile }) => {
    let headerArr = data[0].map((item, index) => ({
      csvHeaderValue: item,
      position: index,
      value: "",
    }));

    this.setState({
      csvParsedData: data,
      csvParsedheader: data.splice(0, 1),
      csvFile: originalFile,
      headers: headerArr,
    });
  };

  _checkForFolderLink = () => {
    if (this.state.folderLink.value.trim().length) {
      this.setState(
        {
          headers: [...this.state.headers, this.state.folderLink],
        },
        () => {
          this._handleSubmit();
        }
      );
    } else {
      this._handleSubmit();
    }
  };

  _handleSubmit = async () => {
    let isValid = this._validateFields();

    if (isValid) {
      this.setState({ loading: true });
      try {
        const formData = new FormData();
        console.log("formData", this.state);

        formData.append("file", this.state.csvFile);
        this.state.headers.sort((a, b) => a.position - b.position);
        this.state.headers.forEach((item, index) => {
          if (index !== item.position) {
            this.state.headers.splice(index, 0, {
              csvHeaderValue: "ignore",
              position: index,
              value: "ignore",
            });
          }
        });

        this.state.headers.map((each) => {
          formData.append(
            each.csvHeaderValue,
            each.value !== "" ? each.value : "ignore"
          );
          return formData;
        });

        let response = await this._bulkUploadApiCall(formData);

        this.setState({
          bulkUploadResponse: {
            isOpen: true,
            data: response?.data,
            success: response?.dataUploadedCount,
          },
        });
        this.setState({ loading: false });
        showToast("Uploaded Successfully", "success", 3000);
        console.log("response", response);

        if (response.error) {
          this.setState({
            bulkUploadResponse: {
              isOpen: true,
              // data: response?.data || response?.reason,
              data: response?.data,
              success: response?.dataUploadedCount,
            },
          });
        }
      } catch (error) {
        this.setState({ loading: false });
        errorHandler(error);
      }
    }
  };

  _bulkUploadApiCall = async (data) => {
    switch (this.props.location.search.replace("?", "")) {
      case "agentClient":
        return await bulkUploadClient(data);

      //signing company agents
      // case "companyAgent":
      case "agents":
        return await signingCompanybulkUploadAgents(data);

      //signing company clients
      // case "companyClient":
      case "clients":
        return await signingCompanybulkUploadClients(data);

      // agent pending income
      case "pendingIncome":
        return await agentbulkUploadPendingIncome(data);

      // agent paid income
      case "paidIncome":
        return await agentbulkUploadPaidIncome(data);

      //Notarial Log
      case "notarialLog":
        return await agentbulkUploadNotarialLog(data);

      //Milage Log
      case "milageLog":
        return await agentbulkUploadMilageLog(data);

      case "expenses":
        return await agentbulkUploadexpenses(data);

      default:
        break;
    }
  };

  _uploadCandidateCVLink = (data) => {
    this.setState(
      {
        folderLink: data,
      },
      () => {
        this._closeCandidateCvFolderLinkModal();
      }
    );
  };

  _checkArrayAndGetMissing = (firstArray, secondArray) => {
    console.log(">>>>>>>>>>>>>", firstArray, secondArray);

    return firstArray.reduce((result, element) => {
      if (!secondArray.includes(element)) {
        if (Array.isArray(element)) {
          let hasCommon = secondArray.some((item) => element.includes(item));
          if (!hasCommon) {
            result.push(element);
          }
        } else {
          result.push(element);
        }
      }
      return result;
    }, []);
  };

  _validateFields = () => {
    const { headers } = deepClone(this.state);

    let headersArray = headers.map((item) => item.value);

    let result = this._checkArrayAndGetMissing(
      manadatoryHeadersFields[this.props.location.search.replace("?", "")],
      headersArray
    );
    let normalCase =
      result.length > 0 &&
      result.flat().map((item) => convertToTitleCase(item));
    // console.log("result", normalCase);
    normalCase.length &&
      showToast(
        `Mandatory Field ${normalCase.join(", ")} are Missing`,
        "error",
        3000
      );
    return !result.length > 0;
  };

  _handleHeaderChange = (index, value, csvHeaderValue) => {
    let obj = {};
    let arr = this.state.headers;

    obj["position"] = index;
    obj["value"] = value;
    obj["csvHeaderValue"] = csvHeaderValue;

    const i = arr.findIndex((element) => element.position === index);
    if (i > -1) arr[i] = obj; // (2)
    else arr.push(obj);
    this.setState({ headers: arr });
  };

  disableOptionHeader = (value) => {
    return this.state.headers.some((item) => item.value === value);
  };

  _closeModal = () => {
    this.setState({
      bulkUploadResponse: {
        isOpen: false,
        data: null,
        success: null,
      },
    });
  };

  _handleOnChange = (e, fieldName) => {
    this.setState({
      folderLink: { ...this.state.folderLink, value: e.target.value },
    });
  };

  _goBack = () => {
    this.props.history.goBack();
  };
  render() {
    const { loading, bulkUploadResponse } = this.state;

    return (
      <div className="content">
        <div className="responsiveTitle">
          <div className="d-flex align-items-center">
            <Button
              color="link"
              className="backBtn"
              onClick={() => this._goBack()}
            >
              <img
                src={require("../../../assets/img/arrowLeft.svg").default}
                alt="backbutton"
                height={14}
              />
            </Button>
            <h2>
              Bulk Upload
              <span>{this.props?.userData?.user?.companyName}</span>
            </h2>
          </div>

          <div className="rightSide">
            <CSVReader
              cssClass="uploadBtn ml-auto floatingButton"
              onFileLoaded={this._handleForce}
              parserOptions={parseOptions}
              inputStyle={{ display: "none" }}
              label={
                <>
                  <i className="fa fa-upload mr-1" /> Bulk Upload
                </>
              }
            />
            {this.state.csvFile !== null && (
              <Button
                color="primary"
                size="md"
                onClick={this._handleSubmit}
                disabled={this.state.loading}
              >
                {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
                <img
                  src={require("../../../assets/img/import_white.svg").default}
                  className="mr-1"
                  alt="import"
                />{" "}
                Import
              </Button>
            )}
          </div>
        </div>
        <div className="text-right mb-3 hideMobile">
          {this.state.csvFile !== null && (
            <Button
              color="primary"
              size="md"
              onClick={this._handleSubmit}
              disabled={this.state.loading}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              <img
                src={require("../../../assets/img/import_white.svg").default}
                className="mr-1"
                alt="import"
              />{" "}
              Import
            </Button>
          )}
        </div>

        {this.state?.csvParsedData.length > 0 ? (
          <div className="CustomTableWrapper bulkUploadTable">
            <Table striped responsive>
              <thead>
                <tr>
                  {this.state?.csvParsedData.length > 0 &&
                    this.state?.csvParsedheader[0].map((each, index) => {
                      return (
                        <th key={index}>
                          <div
                            className="custom-select-wrapper "
                            style={{ width: 130 }}
                          >
                            <Input
                              type="select"
                              name="Status"
                              onChange={(e) => {
                                this._handleHeaderChange(
                                  index,
                                  e.target.value,
                                  each
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {DropdownHeadersFields[
                                this.props.location.search.replace("?", "")
                              ]?.map((item) => (
                                // console.log("first",item)
                                <option
                                  value={item}
                                  disabled={
                                    item !== "ignore"
                                      ? this.disableOptionHeader(item)
                                      : false
                                  }
                                >
                                  {splitCamelCase(item)}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </th>
                      );
                    })}
                </tr>
                <tr>
                  {this.state?.csvParsedData.length > 0 &&
                    this.state?.csvParsedheader[0].map((item, ind) => {
                      return <th key={ind}>{item}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {this.state.csvParsedData.map((childArray, childIndex) => {
                  return (
                    <tr key={childIndex}>
                      {childArray.map((item, ind) => {
                        // console.log(item);
                        return <td key={ind}>{item || "N/A"}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="nodata">
            <img
              src={require("../../../assets/img/noData.svg").default}
              alt="no data"
            />
            <p>There is no data to display</p>
          </div>
        )}
        <BulkUploadInfo
          isOpen={bulkUploadResponse.isOpen}
          toggle={() => {
            this._closeModal();
          }}
          data={bulkUploadResponse.data}
          success={bulkUploadResponse.success}
        />
      </div>
    );
  }
}

export default BulkUpload;
