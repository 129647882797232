import useScreenWidth from "../components/HelpModule/useScreenWidth";

const ScreenWidthHOC = (Component) => {
  return function WrappedComponent(props) {
    const { screenWidth, isForMobile } = useScreenWidth();
    return (
      <Component
        {...props}
        screenWidth={screenWidth}
        isForMobile={isForMobile}
      />
    );
  };
};

export default ScreenWidthHOC;
