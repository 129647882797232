import React, { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";

const LineGraph = ({ data, className = "", height }) => {
  const [options, setOptions] = useState({
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    legend:{
      position: 'top',
    },
    yaxis: [
      {
        labels: {
          formatter: (value) => value.toFixed(0),
        },
      },
    ],
    xaxis: {
      categories: [],
    },
    // colors: [],
    tooltip: {
      // shared: false,
      x: {
        show: false,
      },
    },
  });

  const [series, setSeries] = useState([]);

  const _setChartState = (data) => {
    const newOptions = { ...options };
    const newSeries = [...series];

    if (data?.series1?.length) {
      newOptions.xaxis.categories = data.series1.map((each) => each.label);

      newSeries[0] = {};
      newSeries[0].name = data.tooltipName1;
      newSeries[0].data = data.series1.map((each) => each.value);
    }
    if (data?.formatter1) {
      newOptions.yaxis[0].labels.formatter = data.formatter1;
    }
    if (data?.series2?.length) {
      newSeries[1] = {};
      newSeries[1].name = data.tooltipName2;
      newSeries[1].data = data.series2.map((each) => each.value);
    }
    if (data?.colors?.length) {
      newOptions.colors = data.colors;
    }

    setOptions(newOptions);
    setSeries(newSeries);
  };

  useEffect(() => {
    if (data?.series1?.length) {
      _setChartState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return series?.[0]?.data?.length ? (
    <Chart
      options={options}
      series={series}
      type="area"
      className={className}
      height={height}
    />
  ) : (
    <></>
  );
};

export default memo(LineGraph);
