import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";

class StripeElement extends Component {
  state = {
    errorMessage: "",
  };

  componentDidMount() {
    this.props.addCard(this._addCard);
  }

  _addCard = (ev = null) => {
    if (ev) {
      ev.preventDefault();
    }
    if (this.props.stripe) {
      this.props.showLoader();
      this.props.stripe
        .createToken()
        .then((payload) => {
          if (payload.error && payload.error.message) {
            this.props.hideLoader();
            this.props.onStripeError(payload.error);
            this.setState({ errorMessage: payload.error.message });
          } else {
            this.props.onCardAdded(payload);
          }
        })
        .catch((err) => {
          console.log("errrr", err);
          this.props.hideLoader();
          this.props.onStripeError(err);
        });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  _updateErrorMessage = (e) => {
    if (e && e.error && e.error.message) {
      this.setState({ errorMessage: e.error.message });
    } else {
      this.setState({ errorMessage: "" });
    }
  };

  render() {
    const { errorMessage } = this.state;

    return (
      <div className="w-100">
        <CardElement onChange={this._updateErrorMessage} />
        {errorMessage ? <p>{errorMessage}</p> : null}
      </div>
    );
  }
}

export default injectStripe(StripeElement);
