import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import {
  capitalize,
  errorHandler,
  formatDate,
  formatPhoneNumber,
  getDropdownColor,
  isRegularUser,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  agentUpdateEnquiryForm,
  fetchAllAgentEnquiryForms,
} from "../../../http/http-calls";
import useScreenWidth from "../components/HelpModule/useScreenWidth";

const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));
const { ShowTextModal } = lazy(() =>
  import("../components/Modals/Agents/ShowTextModal")
);

const initialFilters = {
  appointmentDate: null,
  status: "",
  services: [],
  search: "",
};

const enquiryFormsHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "appointmentDate", label: "Enquiry Date", noSort: true },
  { id: "name", label: "User Name", noSort: true },
  { id: "email", label: "User Email", noSort: true },
  { id: "phone", label: "User Phone", noSort: true },
  { id: "description", label: "Description", noSort: true },
  { id: "status", label: "Status", noSort: true },
];

const enquiryFormsCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "name", label: "Name" },
];

const AgentQueryCenter = () => {
  const searchTimer = useRef();
  const history = useHistory();
  const { isForMobile } = useScreenWidth();

  const userData = useSelector((state) => state?.userData);

  const [isClassAdded, setIsClassAdded] = useState(false);

  const [enquiryForms, setEnquiryForms] = useState({
    data: [],
    totalCount: 0,
  });

  const [filters, setFilters] = useState(initialFilters);
  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
  });
  const [loading, setLoading] = useState({
    showTableLoading: false,
    statusLoading: null,
  });

  const [showTextModal, setShowTextModal] = useState({
    isOpen: false,
    data: null,
  });

  const _manageLoading = (key = "", value = false) => {
    setLoading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _toggleShowTextModal = (isOpen = false, data = null) => {
    setShowTextModal({ isOpen, data });
  };

  const _filterShowMobile = () => {
    setIsClassAdded((prev) => !prev);
  };

  const _resetFilter = () => {
    setFilters(initialFilters);

    const newTableConfig = {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    };
    setTableConfig(newTableConfig);

    _getAllAgentQueries(newTableConfig, initialFilters);
    refreshFunc("agent-query-center-responsive");
  };

  useEffect(() => {
    try {
      // only let pro-plus users access this page
      if (!isRegularUser()) {
        history.goBack();
        return;
      }

      HeaderEventEmitter.subscribe("reset-filter", () => {
        _resetFilter();
        refreshFunc("agent-query-center");
      });

      _setFilterDataFromLocalStorage();
      document.querySelector("#scroll").scrollTo(0, 0);
    } catch (err) {
      errorHandler(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getAllAgentQueries = async (newTableConfig, newFilters) => {
    try {
      if (!userData?.user?.id) {
        errorHandler({ reason: "Something went wrong, Try again later." });
        return;
      }

      const updatedFilters = newFilters ? newFilters : filters;
      const updatedTableConfig = newTableConfig ? newTableConfig : tableConfig;

      _manageLoading("showTableLoading", true);

      const payload = {
        skip: updatedTableConfig?.skip,
        limit: updatedTableConfig?.limit,
        agentId: userData?.user?.id,
      };

      if (updatedFilters?.services?.length) {
        payload["services"] = updatedFilters?.services?.map(
          (each) => each?.value
        );
      }
      if (updatedFilters?.status) {
        payload["status"] = updatedFilters?.status;
      }
      if (updatedFilters?.search) {
        payload["search"] = updatedFilters?.search;
      }
      if (updatedFilters?.appointmentDate) {
        payload["startDate"] = moment(
          updatedFilters?.appointmentDate[0]
        ).format("DD/MM/YYYY");
        payload["endDate"] = moment(updatedFilters?.appointmentDate[1]).format(
          "DD/MM/YYYY"
        );
      }

      const res = await fetchAllAgentEnquiryForms(payload);

      setEnquiryForms({
        data: res?.enquiryForms,
        totalCount: res?.totalCount,
      });
      _manageLoading("showTableLoading", false);
    } catch (err) {
      errorHandler(err);
      _manageLoading("showTableLoading", false);
    }
  };

  const _filterOnChange = (type, value) => {
    const newFilters = { ...filters };

    newFilters[type] = value;

    setFilters(newFilters);

    if (type === "search") {
      if (searchTimer?.current) clearTimeout(searchTimer?.current);

      searchTimer.current = setTimeout(() => {
        _paginate(1, 10, newFilters);
      }, 1000);
    } else {
      _paginate(1, 10, newFilters);
    }
  };
  const _paginate = (pageNumber = 1, pageSize = 10, newFilters = filters) => {
    const newTableConfig = { ...tableConfig };

    newTableConfig.skip = (pageNumber - 1) * pageSize;
    newTableConfig.limit = pageSize;
    newTableConfig["pageNumber"] = pageNumber;

    setTableConfig(newTableConfig);
    _persistFilter(newTableConfig, newFilters);

    _getAllAgentQueries(newTableConfig, newFilters);
  };

  const _persistFilter = (tableConfig, filters) => {
    try {
      if (
        (filters &&
          (filters?.status ||
            filters?.services ||
            filters?.appointmentDate ||
            (filters?.search && filters?.search?.length))) ||
        tableConfig?.pageNumber !== 1
      ) {
        let data = { tableConfig, filters };
        localStorage.agentQueryCenterPage = JSON.stringify(data);
      } else {
        delete localStorage.agentQueryCenterPage;
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentQueryCenterPage) {
      try {
        const filters = JSON.parse(localStorage.agentQueryCenterPage);

        setFilters(filters?.filters);
        setTableConfig(filters?.tableConfig);

        _getAllAgentQueries(filters?.tableConfig, filters?.filters);
      } catch (e) {
        _getAllAgentQueries(tableConfig, filters);
      }
    } else {
      _getAllAgentQueries(tableConfig, filters);
    }
  };

  // update agent query status and oprimistically update ui
  const _agentQueryStatusUpdate = async (row, value) => {
    try {
      _manageLoading("statusLoading", row?.id);
      const payload = {
        status: value,
      };

      await agentUpdateEnquiryForm(row?.id, payload);

      const newEnquiryForms = [...enquiryForms?.data];
      const enquiry = newEnquiryForms?.find(
        (enquiry) => enquiry?.id === row?.id
      );

      enquiry["status"] = value || enquiry?.status;

      setEnquiryForms((prev) => ({
        ...prev,
        enquiryForms: newEnquiryForms,
      }));

      showToast("Status Updated", "success");
      _manageLoading("statusLoading", null);
    } catch (err) {
      _getAllAgentQueries(tableConfig, filters);
      _manageLoading("statusLoading", null);
      errorHandler(err);
    }
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <div style={{ width: 90 }}>
            {row?.name?.full ? capitalize(row?.name?.full) : "N/A"}
          </div>
        );
      }

      case "email": {
        return <div>{row?.email ? row?.email : "N/A"}</div>;
      }

      case "phone": {
        return (
          <div style={{ width: 90 }}>
            {row?.phone ? formatPhoneNumber(row?.phone) : "N/A"}
          </div>
        );
      }

      case "appointmentDate": {
        return (
          <div style={{ width: 80 }}>
            {row?.createdAt ? formatDate(row?.createdAt) : "N/A"}
          </div>
        );
      }

      case "description": {
        return (
          <div style={{ width: 150 }}>
            {row?.description ? (
              row?.description?.length > 50 ? (
                <>
                  {row?.description?.slice(0, 50)}...{" "}
                  <Button
                    color="link"
                    className="readMore"
                    onClick={() => _toggleShowTextModal(true, row?.description)}
                  >
                    Show More
                  </Button>
                </>
              ) : (
                row?.description
              )
            ) : (
              "N/A"
            )}
          </div>
        );
      }

      case "status": {
        return row ? (
          <div className="d-flex align-items-center">
            <div className="customSelectWrap mr-2" style={{ width: 120 }}>
              <Input
                type="select"
                name="status"
                className={`status ${getDropdownColor(cell)}`}
                value={row?.status}
                onChange={(event) =>
                  _agentQueryStatusUpdate(row, event.target.value)
                }
                disabled={loading?.statusLoading}
              >
                <option value="Pending">Pending</option>
                <option value="In-Progress">In-Progress</option>
                <option value="Answered">Answered</option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
            {loading?.statusLoading === row?.id ? (
              <i className="fa fa-spinner fa-spin mr-2" />
            ) : null}
          </div>
        ) : (
          "N/A"
        );
      }

      default: {
        return cell;
      }
    }
  };

  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userContent">
                <span className="signerName text-black">
                  {row?.name?.full ? capitalize(row?.name?.full) : "N/A"}
                </span>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.email ? row?.email : "N/A"}
              </span>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Phone</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.phone ? formatPhoneNumber(row?.phone) : "N/A"}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Enquiry Date</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.createdAt ? formatDate(row?.createdAt) : "N/A"}
              </span>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Description</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.description ? (
                  row?.description?.length > 50 ? (
                    <>
                      {row?.description?.slice(0, 50)}...{" "}
                      <Button
                        color="link"
                        className="readMore"
                        onClick={() =>
                          _toggleShowTextModal(true, row?.description)
                        }
                      >
                        Show More
                      </Button>
                    </>
                  ) : (
                    row?.description
                  )
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap  d-flex align-items-center justify-content-between ">
            <Label>Status</Label>

            {row ? (
              <>
                <div className="customSelectWrap" style={{ width: 100 }}>
                  <Input
                    type="select"
                    className={`status ${getDropdownColor(row?.status)}`}
                    name="status"
                    value={row?.status}
                    onChange={(event) =>
                      _agentQueryStatusUpdate(row, event.target.value)
                    }
                    disabled={loading?.statusLoading}
                  >
                    <option value="Pending">Pending</option>
                    <option value="In-Progress">In-Progress</option>
                    <option value="Answered">Answered</option>
                  </Input>

                  <div className="downArrow">
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </div>

                {loading?.statusLoading === row?.id ? (
                  <i className="fa fa-spinner fa-spin mr-2" />
                ) : null}
              </>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };

  return (
    <div id="scroll">
      <div className="content">
        <div className="responsiveTitle">
          <h2>Agent Query Center</h2>

          <div className="rightSide">
            <Button color="link">
              <img
                id="agent-query-center-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
                onClick={_resetFilter}
              />
            </Button>
            <Button
              className="filterBarIcon"
              color="link"
              onClick={_filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
          </div>
        </div>

        {/* search filed for responsive will show below 767px */}
        <div className="mobileSearchFiled">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img
                  src={require("../../../assets/img/searchIcon.svg").default}
                  alt="searchIcon"
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              name="search"
              placeholder="Search by name, email or phone"
              value={filters?.search}
              onChange={(e) => _filterOnChange("search", e.target.value)}
            />
          </InputGroup>
        </div>

        <div
          onClick={_filterShowMobile}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        />
        <div
          className={`filterContainer  responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={_filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup className={`searchTable`}>
              <Label>Search</Label>
              <InputGroup>
                <InputGroupText addonType="prepend">
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
                <Input
                  type="text"
                  placeholder="Search by name, email or phone"
                  value={filters?.search}
                  onChange={(e) => _filterOnChange("search", e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="dateRange">
              <Label>Date Range</Label>
              <DateRangePicker
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                onChange={(dateRangeValue) =>
                  _filterOnChange("appointmentDate", dateRangeValue)
                }
                value={filters?.appointmentDate}
              />
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  name="status"
                  value={filters?.status}
                  onChange={(e) => _filterOnChange("status", e.target.value)}
                >
                  <option value="">All</option>
                  <option value={"Pending"}>Pending</option>
                  <option value={"In-Progress"}>In-Progress</option>
                  <option value={"Answered"}>Answered</option>
                </Input>
              </div>
            </FormGroup>
          </div>

          <div className="clearButton">
            <Button size="md" color="primary" onClick={_filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        {isForMobile ? (
          <Suspense fallback={<></>}>
            <div className="hideDesktop">
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={enquiryForms?.data}
                headerKeys={enquiryFormsCardHeaderKeys}
                dataFormat={_dataFormat}
                totalCount={enquiryForms?.totalCount}
                onPaginate={(pageNumber, pageSize) =>
                  _paginate(pageNumber, pageSize)
                }
                showTableLoading={loading?.showTableLoading}
                cardHeaderFormat={_cardHeaderFormat}
                cardDataFormat={_cardDataFormat}
              />
            </div>
          </Suspense>
        ) : (
          <Suspense fallback={<></>}>
            <div className="hideMobile">
              {enquiryFormsHeaderKeys && enquiryFormsHeaderKeys?.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig?.pageNumber}
                  tableData={enquiryForms?.data}
                  headerKeys={enquiryFormsHeaderKeys}
                  dataFormat={_dataFormat}
                  totalCount={enquiryForms?.totalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    _paginate(pageNumber, pageSize)
                  }
                  showTableLoading={loading?.showTableLoading}
                  isHideForLessData
                />
              )}
            </div>
          </Suspense>
        )}
      </div>

      {showTextModal?.isOpen && (
        <ShowTextModal
          isOpen={showTextModal?.isOpen}
          data={showTextModal?.data}
          toggle={_toggleShowTextModal}
        />
      )}
    </div>
  );
};

export default AgentQueryCenter;
