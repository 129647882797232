import React, { Suspense, lazy } from "react";
import {
  capitalize,
  formatCurrencyValue,
  formatDateOnlyAsPerTimeZone,
} from "../../../helper-methods";
import { Button } from "reactstrap";
const SignerPopover = lazy(() => import("./common/popover"));

const AgentNotarialLogDataFormat = ({
  header,
  row,
  cell,
  _clientName,
  _onToggleAddNotarialModalAgent,
  _deleteNotarialLog,
  loading,
}) => {
  switch (header) {
    case "_borrower": {
      return (
        <>
          {/* show the below span only when there are more than 1 signers  */}
          <div style={{ width: 120 }}>
            {row?.isManuallyCreatedByAgent
              ? (row?._borrower &&
                  row?._borrower.length > 0 &&
                  capitalize(row?._borrower[0]?.name?.full)) ||
                "N/A"
              : (row?._closing?._borrower &&
                  capitalize(row?._closing?._borrower[0]?.name?.full)) ||
                "N/A"}{" "}
            {row?.isManuallyCreatedByAgent
              ? row?._borrower &&
                row?._borrower?.length > 1 && (
                  <>
                    <Suspense fallback={<></>}>
                      <SignerPopover
                        data={row?._borrower}
                        targetId={row?._id}
                        displayType="onlySignerNames"
                      />
                    </Suspense>
                  </>
                )
              : row?._closing?._borrower &&
                row?._closing?._borrower?.length > 1 && (
                  <>
                    <Suspense fallback={<></>}>
                      <SignerPopover
                        data={row?._closing?._borrower}
                        targetId={row?._id}
                        displayType="onlySignerNames"
                      />
                    </Suspense>
                  </>
                )}
          </div>
        </>
      );
    }
    case "fileNumber": {
      return (
        <>
          <div style={{ width: 120 }}>
            {/* {row ? <>{row?._closing?.fileNumber || "N/A"}</> : "N/A"} */}
            {row && row?.isManuallyCreatedByAgent ? (
              row?.purpose
            ) : (
              <>{row?._closing?.fileNumber || "N/A"}</>
            )}
          </div>
        </>
      );
    }

    case "_client": {
      return (
        <>
          <div style={{ width: 150 }}>{_clientName(row)}</div>
        </>
      );
    }
    case "loanType": {
      return cell
        ? (cell && cell.length > 0
            ? cell.indexOf("Other") > -1
              ? row?.loanTypeOther
              : null
            : null,
          cell
            .filter((each) => each !== "Other")
            .map((item) => (
              <>
                {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                <br />
              </>
            )))
        : "N/A";
    }
    case "lender": {
      return cell ? capitalize(cell) : "N/A";
    }

    case "closingAppointmentDate": {
      return row ? (
        <>
          {row ? (
            <>
              <div
                className="d-flex align-items-center"
                style={{ whiteSpace: "nowrap", fontSize: 13 }}
              >
                {formatDateOnlyAsPerTimeZone(
                  cell,
                  row?._closing?.closingAddress?.timeZone
                )}
              </div>{" "}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        "N/A"
      );
    }
    case "totalPotential": {
      return row
        ? row?.isManuallyCreatedByAgent
          ? row?.agentFee
            ? row?.notarialFees
              ? row?.notarialFees > row?.agentFee
                ? formatCurrencyValue(row?.notarialFees)
                : formatCurrencyValue(row?.agentFee)
              : row?.agentFee > row?.plTotal
              ? formatCurrencyValue(row?.agentFee)
              : formatCurrencyValue(row?.plTotal)
            : formatCurrencyValue(row?.plTotal)
          : row?._closing?.agentFee > row?.plTotal
          ? formatCurrencyValue(row?._closing?.agentFee)
          : formatCurrencyValue(row?.plTotal)
        : "N/A";
    }
    case "plTotal": {
      return row
        ? row?.isManuallyCreatedByAgent
          ? formatCurrencyValue(row?.plTotal)
          : row?._closing?.agentFee > row?.plTotal
          ? formatCurrencyValue(row?.plTotal)
          : formatCurrencyValue(row?._closing?.agentFee)
        : "N/A";
    }
    case "amountPerAct": {
      return cell ? formatCurrencyValue(cell) : "N/A";
    }
    case "notarialActs": {
      return row?.notarialActs ? row?.notarialActs : "N/A";
    }

    case "action": {
      return (
        <>
          {row?.isManuallyCreatedByAgent ? (
            <div style={{ width: 60 }}>
              <Button
                color="link"
                className="actionBtn"
                onClick={() => _onToggleAddNotarialModalAgent(true, row)}
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>

              <Button
                color="link"
                className="remove"
                onClick={() => _deleteNotarialLog(row.id)}
                disabled={loading.statusChangeLoading ? true : false}
              >
                <img
                  src={require("../../../assets/img/deleteIcon.svg").default}
                  alt="delete"
                />
              </Button>
            </div>
          ) : (
            !row?.isManuallyCreatedByAgent &&
            row?._closing?.isCreatedForAgent && (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => _onToggleAddNotarialModalAgent(true, row)}
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>
            )
          )}
          {!row?.isManuallyCreatedByAgent &&
            !row?._closing?.isCreatedForAgent && <span>-</span>}
        </>
      );
    }

    default: {
      return cell;
    }
  }
};

export default AgentNotarialLogDataFormat;
