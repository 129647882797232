// import { Button } from "reactstrap";
// import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
// import { CLIENT_ID } from "../../../config";

// const GoogleLoginComponent = ({ btnText, className, onSuccess }) => {

//   return (
//     <GoogleOAuthProvider clientId={CLIENT_ID} >
//       <MyCustomButton {...{ btnText, className, onSuccess }} />
//     </GoogleOAuthProvider>
//   );
// };

// const MyCustomButton = ({ btnText, className, onSuccess }) => {
//   /**
//    *
//    * @param {Object} response - google res object
//    * @param {Boolean} failure - failure -> true or false
//    */
//   const _responseGoogle = (response, failure) => {
//     try {
//       if (failure) {
//         console.log(`google failure:${failure} res: `, response);
//         return;
//       }

//       console.log(`google failure:${failure} res: `, response);

//       if (response && !failure) {
//         const payload = {
//           ...response,
//           accessToken: response.access_token,
//         };
//         console.log("Response form Google", payload);
//         onSuccess(payload);
//       }
//     } catch (error) {
//       console.log("error", error);
//     }
//   };

//   const onClickGoogle = useGoogleLogin({
//     onSuccess: (response) => _responseGoogle(response, false), // failure -> true or false
//     onError: () => _responseGoogle(null, true), // failure -> true or false
//     redirect_uri: "https%3A%2F%2Fapi-dev.closewise.com%2Fapi%2Fv1%2Fsocial-login-auth%3Fredirectpage%3Dhttps%3A%2F%2Fapp.carolinaattorneynetwork.com"
//   });

//   return (
//     <Button onClick={() => onClickGoogle()}>
//       {btnText ? (
//         btnText
//       ) : (
//         <>
//           <img src={require("../../../assets/img/google.png")} alt="google" />
//         </>
//       )}
//     </Button>
//   );
// };

// export default GoogleLoginComponent;

import { Button } from "reactstrap";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BASE_URL, CLIENT_ID } from "../../../config";
import { useEffect } from "react";

const GoogleLoginComponent = ({ btnText, className, onSuccess }) => {
  // This is how to send all parameter send send from frontend and hit a backend server after authorization.
  // For Many whitelabel we add one by one in developer console => for this problem we write it here to know BE from which url user hit and remaing flow same

  const buildGoogleOAuthURL = () => {
    const params = new URLSearchParams({
      client_id: CLIENT_ID,
      redirect_uri: `${BASE_URL}/social-login-auth/?`,
      response_type: "token",
      scope: "openid profile email",
      include_granted_scopes: "true",
      prompt: "select_account",
      state: window.location.href,
    });

    return `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
  };

  const handleOAuthResponse = () => {
    const hash = window?.location?.hash;
    // http://localhost:3000/login/#state=http://localhost:3000/login&access_token=ya29.a0AcM612wgowjyXCJGdG4YatTYXsXu5I1Oi1Na4RlnJOoSrlwzpp4bfudZ0dxKYxsShxT_hEXdwWBdjkYKvhFcQC4JXk52bz0DzxjLDzezQKTwziz6Q5qySGjdvJi-c7mYv_39yhCFMLuvScGnuA5B49_ZCPEC8eOJkbcaCgYKASkSARESFQHGX2Mia5bgDoOVumm_08dBUtCB5g0170&token_type=Bearer&expires_in=3599&scope=email%20profile%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile%20openid&authuser=0&hd=logic-square.com&prompt=none
    // This type url come i has we extract token from that and send it to parent

    if (hash && hash !== "") {
      const params = new URLSearchParams(hash.substring(1)); // Skip the '#' character
      const accessToken = params.get("access_token");

      if (accessToken?.length) {
        onSuccess({ accessToken });
      } else {
        throw new Error("No access token returned");
      }
    } else {
      throw new Error("No hash found in URL");
    }
  };

  const onClickGoogle = () => {
    const googleOAuthURL = buildGoogleOAuthURL();

    // Redirect the user to the Google OAuth consent screen
    window.location.href = googleOAuthURL;
  };

  useEffect(() => {
    const hash = window?.location?.hash;

    if (hash && hash !== "") {
      handleOAuthResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <MyCustomButton
        btnText={btnText}
        className={className}
        onClick={onClickGoogle}
      />
    </GoogleOAuthProvider>
  );
};

const MyCustomButton = ({ btnText, className, onClick }) => {
  return (
    <Button onClick={onClick} className={className}>
      {btnText ? (
        btnText
      ) : (
        <>
          <img src={require("../../../assets/img/google.png")} alt="google" />
        </>
      )}
    </Button>
  );
};

export default GoogleLoginComponent;
