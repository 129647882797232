import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { getFullName } from "../../../helper-methods";
import Select from "react-select";

const TeamOrCCMembers = ({
  type,
  each,
  index,
  clientsList,
  onChangeTeamMemberOrCCMember,
  onBlurFormFields,
  members,
}) => {
  return type === "members" ? (
    <FormGroup className={`z-index-${members?.length - index}`}>
      <Select
        options={clientsList?.map((each) => ({
          label: getFullName(each?.name),
          value: each,
        }))}
        value={each?.memberObj}
        onChange={(event) =>
          onChangeTeamMemberOrCCMember(
            "_user",
            event,
            // each?.type,
            index
          )
        }
        onBlur={() => onBlurFormFields("_user", "members", index)}
        className="customMultiSelect"
        placeholder="Select"
        closeMenuOnSelect={true}
        isClearable
      />

      {each?.errors?._user ? (
        <p className="validation-error">{each?.errors?._user}</p>
      ) : null}
    </FormGroup>
  ) : (
    <>
      <FormGroup className="floatingLabel">
        <Input
          placeholder=" "
          value={each?.ccName}
          onChange={(e) =>
            onChangeTeamMemberOrCCMember(
              "ccName",
              e.target.value,
              // each?.type,
              index
            )
          }
          onBlur={() => onBlurFormFields("ccName", "members", index)}
        />
        <Label>Name</Label>
        {each?.errors?.ccName ? (
          <p className="validation-error">{each?.errors?.ccName}</p>
        ) : null}
      </FormGroup>
      <FormGroup className="floatingLabel">
        <Input
          placeholder=" "
          value={each?.ccEmail}
          onChange={(e) =>
            onChangeTeamMemberOrCCMember(
              "ccEmail",
              e.target.value,
              // each?.type,
              index
            )
          }
          onBlur={() => onBlurFormFields("ccEmail", "members", index)}
        />
        <Label>Email</Label>
        {each?.errors?.ccEmail ? (
          <p className="validation-error">{each?.errors?.ccEmail}</p>
        ) : null}
      </FormGroup>
    </>
  );
};

export default TeamOrCCMembers;
