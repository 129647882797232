import Skeleton from "react-loading-skeleton";

const SkeletonLoadingSubscription = ({
  count,
  col = 3,
  borderRadius = 8,
  height,
  width,
}) => {
  const skeletonArray = Array(count).fill(0); // Create an array based on the count

  return (
    <div
      className="skeleton-grid-wrapper"
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${col}, 1fr)`,
        gap: "10px",
      }}
    >
      {skeletonArray.map((_, index) => (
        <div key={`SkeletonBox_${index}`} className="skeleton-box">
          <Skeleton height={150} width={width} borderRadius={borderRadius} />
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoadingSubscription;
