import React from "react";
import { Button, FormGroup, Form, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  deepClone,
  errorHandler,
  setThemeColor,
  updateCompanyName,
  updateFavIcon,
} from "../../../helper-methods";
import { customWebsiteDetail, forgetPassword } from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import PublicSideCarousel from "../components/PublicCarousel/PublicSideCarousel";
import Swal from "sweetalert2";
import Gleap from "gleap";

class ForgotPassword extends React.Component {
  state = {
    formFields: {
      email: {
        value: "",
        error: null,
        isDirty: false,
      },
    },
    companyDetail: {},
  };
  componentDidMount() {
    // Hide Gleap in this page
    Gleap.showFeedbackButton(false);

    this._customWebsiteDetail();
  }
  _customWebsiteDetail = () => {
    let payload = {
      site:
        window.location.hostname !== "localhost"
          ? window.location.host
          : "customizedwl.logic-square.com",
    };

    customWebsiteDetail(payload)
      .then((res) => {
        this.setState({ companyDetail: res?.detail }, () => {
          if (
            !this.state.companyDetail?.whiteLabelSetting?.isDefaultUiSelected
          ) {
            let whiteLabelSetting =
              this.state.companyDetail?.whiteLabelSetting?.customizedUiSetting;

            setThemeColor(whiteLabelSetting);
          } else {
            let whiteLabelSetting =
              this.state.companyDetail?.whiteLabelSetting?.defaultUiSetting;

            // let matchingColorScheme = _findMatchingColorScheme(
            //   whiteLabelSetting,
            //   colorsUI
            // );
            setThemeColor(whiteLabelSetting);
          }
          if (this.state?.companyDetail) {
            updateCompanyName(this.state.companyDetail);
            updateFavIcon(this.state.companyDetail?.whiteLabelSetting);
          }
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _resetFormField = () => {
    this.setState({
      formFields: {
        email: {
          value: "",
          error: null,
          isDirty: false,
        },
      },
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty) {
          switch (e) {
            case "email": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (type, value) => {
    const { formFields } = this.state;
    formFields[type].value = value;
    formFields[type].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _onForgotPassword = async (e) => {
    if (e) e.preventDefault();

    await this._markAllFieldDirty();
    const isFormValid = await this._validateForm();

    if (isFormValid) {
      this.props.showLoader("Loading...");

      const { formFields } = deepClone(this.state);

      let data = {
        handle: formFields.email.value,
        site:
          window.location.hostname !== "localhost"
            ? window.location.host
            : "customizedwl.logic-square.com",
      };

      forgetPassword(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
          });
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              popup: "alert-swal-modal",
            },
          });

          swalWithBootstrapButtons.fire({
            imageUrl: require("../../../assets/img/mail.svg").default,
            imageAlt: "mail image",
            text: `Your reset password link has been emailed, please use it to reset your password.`,

            confirmButtonText: "OKAY",
          });

          this.props.hideLoader();
          this._resetFormField();
          this.props.history.push(`/login`);
        })
        .catch((error) => {
          errorHandler(error);
          this.props.hideLoader();
        });
    }
  };

  render() {
    const { formFields, companyDetail } = this.state;

    return (
      <>
        <div className="loginWrapper">
          <div
            className={`loginLeft ${
              window.location.host !== "app.closewise.com" ? "whiteLabel" : ""
            }`}
          >
            {/* <PublicSideCarousel companyDetail={companyDetail}/> */}
            {window.location.host === "app.closewise.com" ? (
              <PublicSideCarousel companyDetail={companyDetail} />
            ) : (
              <img
                src={
                  companyDetail.logo
                    ? companyDetail.logo
                    : require("../../../assets/img/logBigIcon.png")
                }
                alt="logo big"
                className="bigLogo"
              />
            )}
          </div>
          <div className="loginRight">
            <img
              src={require("../../../assets/img/logBigIcon.png")}
              alt="logo big"
              className="bigLogo"
            />
            <div className="FormWrap">
              <h1>Forgot Password?</h1>
              <p className="mb-4">
                No worries, we will send you instructions to reset your password
              </p>
              <Form onSubmit={this._onForgotPassword} spellCheck="false">
                <FormGroup className="floatingLabel">
                  <Input
                    type="email"
                    value={formFields.email.value}
                    onChange={(e) =>
                      this._onChangeFormField("email", e.target.value)
                    }
                    placeholder=" "
                    autoComplete="username"
                  />
                  <Label>Email Address</Label>
                  {formFields.email.error && (
                    <div className="validation-error">
                      {formFields.email.error}
                    </div>
                  )}
                </FormGroup>

                <Button
                  color="primary"
                  className="w-100 d-block my-4"
                  type="submit"
                >
                  Reset Password
                </Button>
              </Form>
              <div className="goBackWrap">
                Back to
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
