import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  CustomInput,
  Label,
  Row,
} from "reactstrap";
import WhiteLabelUIColor from "../../components/Modals/Company/WhiteLabelUIColor";
import { updateSigningCompanyProfile } from "../../../../http/http-calls";
import {
  _nameOfDefaultUI,
  colorsUI,
  errorHandler,
  setThemeColor,
  showToast,
} from "../../../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { updateUserObj } from "../../../../redux/actions/user-data";

const WhiteLabelUI = (props) => {
  const reduxState = useSelector((state) => state?.userData);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isDefaultUiSelected, setIsDefaultUiSelected] = useState(true);
  const [currentUI, setCurrentUI] = useState("demoUI1");
  const [currentColorUI, setCurrentColorUI] = useState({
    primary: "#4c96ce", // default color
    secondary: "#16346c",
    secondaryText: "#ffffff",
    tertiary: "#0f2550",
    activeText: "#fff",
  });
  const [whiteLabelUIColor, setWhiteLabelUIColor] = useState({ isOpen: false });

  const _toggleWhiteLabelUIColor = (isOpen = false) => {
    setWhiteLabelUIColor({ isOpen });
  };

  const _toggleThemeName = (themeName = "demoUI1") => {
    try {
      setCurrentUI(themeName);

      setThemeColor(colorsUI[themeName]);
      setCurrentColorUI(colorsUI[themeName]);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onSaveWhiteLabelUIColor = (data) => {
    // console.log({ data });
    setIsDefaultUiSelected(false);
    setThemeColor(data);
    setCurrentColorUI(data);
  };

  // For submit Changes
  const _onSave = async (data) => {
    try {
      let demopayload = {
        whiteLabelSetting: {
          isDefaultUiSelected,
        },
      };

      if (!isDefaultUiSelected) {
        // Use customizedUiSetting
        demopayload.whiteLabelSetting.customizedUiSetting = {
          primary: currentColorUI.primary,
          secondary: currentColorUI.secondary,
          tertiary: currentColorUI.tertiary,
          secondaryText: currentColorUI.secondaryText,
        };
      } else {
        // Use defaultUiSetting
        demopayload.whiteLabelSetting.defaultUiSetting = {
          primary: currentColorUI.primary,
          secondary: currentColorUI.secondary,
          tertiary: currentColorUI.tertiary,
          secondaryText: currentColorUI.secondaryText,
          activeText: currentColorUI.activeText,
        };
      }

      setLoading(true);

      let payload = data.whiteLabelSetting ? data : demopayload;

      await updateSigningCompanyProfile(reduxState.user.id, payload);

      showToast("WhiteLabel has been updated", "success");

      console.log("aqw", isDefaultUiSelected);
      if (!isDefaultUiSelected) {
        // Use customizedUiSetting

        const updatedObject = {
          // siteOwnerDetail: {
          // ...reduxState.user,
          whiteLabelSetting: {
            ...reduxState.user.whiteLabelSetting,
            isDefaultUiSelected,
            customizedUiSetting: {
              primary: currentColorUI.primary,
              secondary: currentColorUI.secondary,
              tertiary: currentColorUI.tertiary,
              secondaryText: currentColorUI.secondaryText,
            },
            // },
          },
        };
        dispatch(updateUserObj(updatedObject));
      } else {
        // Use defaultUiSetting

        const updatedObject = {
          whiteLabelSetting: {
            ...reduxState.user.whiteLabelSetting,
            isDefaultUiSelected,
            defaultUiSetting: {
              primary: currentColorUI.primary,
              secondary: currentColorUI.secondary,
              tertiary: currentColorUI.tertiary,
              secondaryText: currentColorUI.secondaryText,
              activeText: currentColorUI.activeText,
            },
            // },
          },
        };

        dispatch(updateUserObj(updatedObject));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    console.log("cui", reduxState.user?.whiteLabelSetting);
    if (!reduxState.user?.whiteLabelSetting?.isDefaultUiSelected) {
      let whiteLabelSetting =
        reduxState.user?.whiteLabelSetting?.customizedUiSetting;
      console.log("qwed", whiteLabelSetting);
      setCurrentUI("");
      setCurrentColorUI(whiteLabelSetting);
      setThemeColor(whiteLabelSetting);
    } else {
      let whiteLabelSetting =
        reduxState.user?.whiteLabelSetting?.defaultUiSetting;

      let matchingColorScheme = findMatchingColorScheme(
        whiteLabelSetting,
        colorsUI
      );
      console.log("qasw", matchingColorScheme);
      setCurrentUI(matchingColorScheme);
      setCurrentColorUI(whiteLabelSetting);
      _toggleThemeName(matchingColorScheme);
    }

    return () => {
      // if (!isDefaultUiSelected) {
      if (!reduxState.user?.whiteLabelSetting?.isDefaultUiSelected) {
        let whiteLabelSetting =
          reduxState.user?.whiteLabelSetting?.customizedUiSetting;

        setThemeColor(whiteLabelSetting);
      } else {
        let whiteLabelSetting =
          reduxState.user?.whiteLabelSetting?.defaultUiSetting;
        console.log("ftn", reduxState.user);
        let matchingColorScheme = findMatchingColorScheme(
          whiteLabelSetting,
          colorsUI
        );

        _toggleThemeName(matchingColorScheme);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState]);

  //This function used to get initial ui colour type
  const findMatchingColorScheme = (targetObject, colorsUI) => {
    console.log("wer", targetObject, colorsUI);

    let foundKey = null;

    for (const key in colorsUI) {
      if (JSON.stringify(colorsUI[key]) === JSON.stringify(targetObject)) {
        foundKey = key;
        break;
      }
    }
    return foundKey;
  };

  return (
    <>
      <div className="CardWrapper">
        {console.log("qwer", isDefaultUiSelected, currentUI)}
        <Card>
          <CardHeader>
            <CardTitle>UI Settings</CardTitle>
          </CardHeader>
          <CardBody>
            {/* <p>Customize your UI</p> */}
            {
              <p>
                UI Color Type :{" "}
                {reduxState.user?.whiteLabelSetting?.isDefaultUiSelected
                  ? _nameOfDefaultUI(
                      findMatchingColorScheme(
                        reduxState.user?.whiteLabelSetting?.defaultUiSetting,
                        colorsUI
                      )
                    )
                  : "Custom UI Colour"}
              </p>
            }
            <Row>
              <Col xl={6} lg={12} className="mb-4">
                <div className="uiDesign">
                  <h1>
                    <CustomInput
                      type="radio"
                      id="demo1"
                      name="customRadio"
                      checked={currentUI === "demoUI1"}
                      onClick={() => _toggleThemeName("demoUI1")}
                    />
                    Default
                  </h1>
                  <Label for="demo1">
                    <img
                      src={require("../../../../assets/img/OrderDashboard1.png")}
                      alt="background"
                    />
                  </Label>
                </div>
              </Col>
              <Col xl={6} lg={12} className="mb-4">
                <div className="uiDesign">
                  <h1>
                    <CustomInput
                      type="radio"
                      id="demo2"
                      name="customRadio"
                      checked={currentUI === "demoUI2"}
                      onClick={() => _toggleThemeName("demoUI2")}
                    />
                    Charcoal gray
                  </h1>
                  <Label for="demo2">
                    <img
                      src={require("../../../../assets/img/OrderDashboard2.png")}
                      alt="background"
                    />
                  </Label>
                </div>
              </Col>
              <Col xl={6} lg={12} className="mb-4">
                <div className="uiDesign">
                  <h1>
                    <CustomInput
                      type="radio"
                      id="demo3"
                      name="customRadio"
                      checked={currentUI === "demoUI3"}
                      onClick={() => _toggleThemeName("demoUI3")}
                    />
                    Dark blue
                  </h1>
                  <Label for="demo3">
                    <img
                      src={require("../../../../assets/img/OrderDashboard3.png")}
                      alt="background"
                    />
                  </Label>
                </div>
              </Col>
              <Col xl={6} lg={12} className="mb-4">
                <div className="uiDesign">
                  <h1>
                    <CustomInput
                      type="radio"
                      id="demo4"
                      name="customRadio"
                      checked={currentUI === "demoUI4"}
                      onClick={() => _toggleThemeName("demoUI4")}
                    />
                    Olive green
                  </h1>
                  <Label for="demo4">
                    <img
                      src={require("../../../../assets/img/OrderDashboard4.png")}
                      alt="background"
                    />
                  </Label>
                </div>
              </Col>
              <Col
                md={12}
                className="d-flex align-items-center justify-content-center"
              >
                <Button
                  color="primary"
                  siz="md"
                  className="mr-4"
                  onClick={() => _toggleWhiteLabelUIColor(true)}
                >
                  Customize
                </Button>
                <Button color="primary" disabled={loading} onClick={_onSave}>
                  {loading && <i className="fa fa-spinner fa-spin" />} Save
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>

      {/* when need to add customization then use this modal */}
      <WhiteLabelUIColor
        currentColorUI={currentColorUI}
        isOpen={whiteLabelUIColor.isOpen}
        toggle={() => _toggleWhiteLabelUIColor()}
        onSave={_onSaveWhiteLabelUIColor}
        customizedUIData={
          reduxState.user?.whiteLabelSetting?.customizedUiSetting
        }
      />
    </>
  );
};

export default WhiteLabelUI;
