import React, { useState } from "react";
import { Button } from "reactstrap";

const ReadMore = ({ text, maxLength = 250 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDescription = () => {
    return isExpanded
      ? text
      : text?.length
      ? text?.slice(0, maxLength) + "..."
      : "";
  };

  return (
    <div className="read-more-less">
      <p
        className="mb-0"
        dangerouslySetInnerHTML={{ __html: renderDescription() }}
      ></p>
      {text?.length >= maxLength && (
        <Button color="link" onClick={toggleReadMore} className="readMore">
          {isExpanded ? "read Less" : "read More"}
        </Button>
      )}
    </div>
  );
};

export default ReadMore;
