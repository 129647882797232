import React from "react";
import ReactPaginate from "react-paginate";

const CustomPagination = ({
  data,
  dataCount,
  pageNumber,
  pageSize,
  onPageChange,
  isPageStartFromOne = false,
  isHideForLessData = false,
}) => {
  if (!data?.length || !dataCount) {
    return <></>;
  }

  const from = Math.min((pageNumber - 1) * pageSize + 1, dataCount);
  // const from = Math.max((pageNumber - 1) * pageSize + 1, 0);
  const to = Math.min(from + pageSize - 1, dataCount);

  if (isHideForLessData && dataCount <= data?.length) {
    return (
      <div className="paginationWrap">
        <span className="showng_entries">
          Showing {from} to {to}, out of {dataCount}
        </span>
      </div>
    );
  }

  return (
    <div className="paginationWrap">
      <span className="showng_entries">
        {/* {
          console.log("wer",pageNumber,pageSize)
        } */}
        {/* Showing {data?.length} entries out of {dataCount} */}
        Showing {from} to {to}, out of {dataCount}
      </span>
      <ReactPaginate
        forcePage={isPageStartFromOne ? pageNumber - 1 : pageNumber}
        nextLabel={<i className="fa fa-chevron-right" />}
        onPageChange={({ selected }) =>
          onPageChange(isPageStartFromOne ? selected + 1 : selected)
        }
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(dataCount / pageSize)}
        previousLabel={<i className="fa fa-chevron-left" />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default CustomPagination;
