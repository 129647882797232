import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Spinner,
} from "reactstrap";
import TeamNewMember from "./company-clients/client-teamNewMember";
import NewClient from "./company-clients/new-client";
import AddToOtherTeam from "./company-clients/addToOtherTeam";
import { capitalize, errorHandler } from "../../../helper-methods";

const InviteClient = ({ isOpen, toggle, data, type, onSuccess }) => {
  const newClient = useRef();
  const teamMember = useRef();
  const addToOtherTeam = useRef();

  const [loading, setLoading] = useState(false);
  const [newClientData, setNewClientData] = useState(null);
  const [step, setStep] = useState(1);

  const stepTitles = {
    1: "New Client",
    2: "Team Member",
    3: "to Team",
  };

  const _addClient = async () => {
    if (step === 1) {
      try {
        setLoading(true);
        const res = await newClient.current._onSubmit();

        if (!res) {
          setLoading(false);
          return;
        }
        onSuccess();
        setLoading(false);
        closeModal();
      } catch (err) {
        errorHandler(err);
        setLoading(false);
      }
    } else {
      nextStep();
    }
  };

  const nextStep = async () => {
    if (step === 1) {
      const res = await newClient.current._onSubmit();
      console.log("call",{ res });
      if (type === "add") {
        if (res) {
          setStep((prevStep) => prevStep + 1);
        }
      } else {
        closeModal();
      }
    }
    if (step === 2) {
      const res = await teamMember.current._onSubmit();
      // console.log( res );

      if (res) {
        setStep((prevStep) => prevStep + 1);
      }
    }
    if (step === 3) {
      console.log("qas", addToOtherTeam);

      const res = await addToOtherTeam.current._onSubmit();
      console.log(res);

      if (res) {
        setStep(1);
        closeModal();
      }
    }
    // console.log("res", res);
  };

  const closeModal = () => {
    setStep(1);
    toggle();
    if (step === 2) {
      onSuccess();
    }
  };

  const manageLoading = (loading = false) => {
    setLoading(loading);
  };
  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="md" scrollable>
      <ModalHeader toggle={closeModal}>
        {capitalize(type)} {stepTitles[step]}
      </ModalHeader>
      <ModalBody>
        {step === 1 && (
          <NewClient
            ref={newClient}
            manageLoading={manageLoading}
            data={data}
            type={type}
            createdClientData={(data) => setNewClientData(data)}
            onSuccess={() => onSuccess()}
          />
        )}
        {step === 2 && (
          <TeamNewMember
            ref={teamMember}
            manageLoading={manageLoading}
            data={data}
            type={type}
            newClientData={newClientData}
            onSuccess={() => onSuccess()}
          />
        )}
        {step === 3 && (
          <AddToOtherTeam
            ref={addToOtherTeam}
            manageLoading={manageLoading}
            data={data}
            type={type}
            newClientData={newClientData}
            onSuccess={() => onSuccess()}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {(step === 3 || step === 1) && type === "add" && (
          <Button
            color="primary"
            outline={step === 1}
            size="lg"
            disabled={loading}
            onClick={() => _addClient()}
            // onClick={nextStep}
          >
            {loading ? <Spinner size="sm" /> : null}{" "}
            {type === "edit" ? "Update" : "Add"}
          </Button>
        )}
        {(step === 1 || step === 2) && type === "add" && (
          <Button
            color="primary"
            size="lg"
            onClick={nextStep}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : null} Next
          </Button>
        )}
        {step === 1 && type === "edit" && (
          <Button
            color="primary"
            size="lg"
            onClick={nextStep}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : null} Update
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default InviteClient;
