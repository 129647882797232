import { ADD_SETTINGS, CLEAR_SETTINGS } from "../actions";

const initialState = null;

export const settingsReducer = (state = initialState, action) => {
  let newState =  state ;
  switch (action.type) {
    case ADD_SETTINGS: {
      newState = action.payload.settings||null;
      break;
    }
    case CLEAR_SETTINGS: {
      newState = null;
      break;
    }
    default: {
    }
  }
  return newState;
};
