import React, { Suspense, lazy } from "react";
import {
  capitalize,
  checkPermission,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  getDropdownColor,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
} from "../../../helper-methods";
import { Button, Input } from "reactstrap";
import { agentClosingStatus } from "../../../config";
import { DateTimeShowWithCellRow } from "./Date-time-show";
import { openTrackingDetails } from "../../../config/trackingConfig";
const SignerPopover = lazy(() => import("./common/popover"));

const ClientOrderDashboardDataFormat = ({
  header,
  row,
  cell,
  _onToggleSignerAvailabilityModal,
  _redirectToOrderDetails,
  _redirectToClosingDetails,
  loading,
  _onChangeOrderStatus,
}) => {
  switch (header) {
    case "appointmentDate": {
      return row ? (
        <>
          <div className="tableUserInfo">
            {/* <div className="userImage">
              <img
                className="clientImg"
                src={
                  row?._lenderClient?.picUrl
                    ? row?._lenderClient?.picUrl
                    : require("../../../assets/img/default_user_icon.svg")
                        .default
                }
                loading="lazy"
                alt="avatar"
              />
            </div> */}
            <div className="userContent">
              {row?._borrower?.length ? (
                <>
                  <Suspense fallback={<></>}>
                    <SignerPopover
                      data={row?._borrower}
                      targetId={row?._id}
                      screenType="table"
                      displayType="signerDetails"
                    />
                  </Suspense>
                </>
              ) : null}
              {/* <span className="dateTimeFormat">
                {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)},{" "}
                <span>
                  {getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}
                </span>
              </span> */}
              <DateTimeShowWithCellRow cell={cell} row={row} />

              <Button
                color={`${
                  row?.appointmentStatus === "Confirmed" && !row.leftMessage
                    ? "success "
                    : row?.appointmentStatus === "Rescheduled" &&
                      !row.leftMessage
                    ? "danger "
                    : row.leftMessage
                    ? "warning"
                    : "danger "
                }`}
                className={`confirmButton `}
                onClick={() => _onToggleSignerAvailabilityModal(true, row)}
              >
                {row?.appointmentStatus === "Confirmed"
                  ? "Confirmed"
                  : "Confirm"}
              </Button>
            </div>
          </div>
        </>
      ) : (
        "N/A"
      );
    }
    case "docs": {
      return row ? (
        <div className="docStatus">
          {/* Last Uploaded by Company: $DateTime */}
          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.companyLastDocumentUploadedAt &&
              `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                row?.companyLastDocumentUploadedAt,
                row?.closingAddress?.timeZone
              )}`
            }
            onClick={() =>
              row.isDocumentCompleted ? _redirectToOrderDetails(row) : {}
            }
          >
            {row?.documents?.length && !row.documents[0].isPrivate ? (
              row.isDocumentCompleted ? (
                <span
                  className="fa fa-circle text-success"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : row?.isDocumentUploadedByCompanyOrClient ? (
                <span
                  className="fa fa-circle text-warning"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : (
                <span
                  className="fa fa-circle text-primary"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )
            ) : (
              <span
                className="fa fa-circle text-primary"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
            <span className="value">
              {row.isDocumentCompleted
                ? "Docs Ready"
                : row?.isDocumentUploadedByCompanyOrClient
                ? "Pending"
                : "Not Ready"}
            </span>
          </Button>

          {/* First downloaded by Agent: DateTime */}
          {/* turns green only when agent downloaded all documents  */}
          {/* isAgentDocumentDownloaded */}
          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.isAgentViewDocumentTime &&
              `First downloaded by Agent: ${formatDateAsPerTimeZOne(
                row?.isAgentViewDocumentTime,
                row?.closingAddress?.timeZone
              )}`
            }
          >
            {row.isAllDocumentsDownloaded ? (
              <span
                className="fa fa-circle text-success"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            ) : (
              <span
                className="fa fa-circle text-warning"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
            <span className="value">
              {row?.isAllDocumentsDownloaded ? "Docs Downloaded" : "Pending"}
            </span>
          </Button>

          {/* Last Uploaded by Agent: DateTime */}

          {/* once a company create a closing and Selects the "scan back is required" check box and assigns the closing, 
              1. The third button will turn red(the rest two will remain gray), 
              2. When the agent uploads any doc and selects "scan back upload completed" it will turn yellow then 
              3. When the company selects "verified agent doc" it will turn green. */}

          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.agentLastDocumentUploadedAt &&
              `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                row?.agentLastDocumentUploadedAt,
                row?.closingAddress?.timeZone
              )}`
            }
          >
            {row.isScanBackNeeded ? (
              row.isDocumentVerified ? (
                <>
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span className="value">Shipping Approved</span>
                </>
              ) : row.isDocumentCompletedByAgent ? (
                <>
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span
                    className="value"
                    onClick={() => _redirectToOrderDetails(row)}
                  >
                    Scan Upload
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="fa fa-circle text-danger"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span className="value">Scan Needed</span>
                </>
              )
            ) : row?.documents?.length && !row.documents[0].isPrivate ? (
              row.isDocumentCompletedByAgent ? (
                row.isDocumentVerified ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Shipping Approved</span>
                  </>
                ) : (
                  <>
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Scan Upload</span>
                  </>
                )
              ) : (
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )
            ) : (
              <span
                className="fa fa-circle"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
          </Button>
        </div>
      ) : (
        "N/A"
      );
    }
    case "fileNumber": {
      return row ? (
        <>
          <div
            className="tableLink"
            style={{
              minWidth: 75,
              whiteSpace: "normal",
            }}
            onClick={() => _redirectToClosingDetails(row)}
          >
            {row.fileNumber || "N/A"}
          </div>
        </>
      ) : (
        "N/A"
      );
    }
    case "_signingCompany": {
      return (
        <>
          {cell?.companyName ? (
            <span style={{ fontWeight: 600 }}>
              {cell?.companyName && capitalize(cell.companyName)}
            </span>
          ) : (
            "N/A"
          )}

          {/* {row.appointmentDate &&
          !row?._agent && (
            <span>
              {row?.status === "Pending" || row?.status === "Cancelled"}
            </span>
          ) ? (
            <Button
              color="link"
              onClick={() => _onToggleClientAssignOrder(true, row)}
              disabled={!checkPermission("dashboard", "canEditOrderEntry")}
              className="px-1"
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="pencil"
                loading="lazy"
              />
            </Button>
          ) : null} */}
        </>
      );
    }
    case "loanType": {
      return (
        <>
          <div style={{ width: 100, fontWeight: 600 }}>
            <span style={{ marginRight: 6, display: "inline-block" }}>
              {" "}
              {row.signingType}
            </span>

            {cell && cell.length > 0 ? (
              cell.indexOf("Other") > -1 ? (
                <span style={{ marginRight: 6, display: "inline-block" }}>
                  {row?.loanTypeOther}
                </span>
              ) : null
            ) : null}
            {cell
              .filter((each) => each !== "Other")
              .map((item) => (
                <>
                  <span style={{ marginRight: 6, display: "inline-block" }}>
                    {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </span>
                </>
              ))}
          </div>
        </>
      );
    }
    case "lender": {
      return (
        <>
          <div style={{ width: 80, fontWeight: 600 }}>
            {cell ? capitalize(cell) : "N/A"}
          </div>
        </>
      );
    }
    case "createdBy": {
      return (
        <>
          <span style={{ fontWeight: 600 }}>
            {/* companyName for userType signing company */}
            {row?._createdBy && row?._createdBy?.companyName
              ? capitalize(row?._createdBy?.companyName)
              : row?._createdByClient && row?._createdByClient?.companyName
              ? capitalize(row?._createdByClient?.companyName)
              : "N/A"}
          </span>
        </>
      );
    }
    case "agentFee": {
      return cell ? formatCurrencyValue(cell) : "N/A";
    }
    case "closingAddress": {
      return (
        <>
          <span
            style={{
              fontWeight: 600,
              width: 140,
              display: "block",
              marginBottom: 5,
            }}
          >
            {formatAddressInSingleText(cell) || "N/A"}
          </span>
          <Button
            color="link"
            className="p-0"
            title={formatAddressInSingleText(cell)}
            onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
          >
            <img
              src={
                require("../../../assets/img/Google_Maps_Logo_2020.svg").default
              }
              alt="map icon"
              className="mr-1"
              loading="lazy"
            />
            Google Map
          </Button>
          <Button
            color="link"
            className="p-0"
            title={formatAddressInSingleText(cell)}
            onClick={() => openAppleMapOnNewTab(row.closingAddress)}
          >
            <img
              src={
                require("../../../assets/img/Apple_Maps_(WatchOS).svg").default
              }
              alt="map icon"
              className="mr-1"
              loading="lazy"
            />
            Apple Map
          </Button>
        </>
      );
    }
    case "status": {
      return cell ? (
        <div className="customSelectWrap" style={{ width: 100 }}>
          <Input
            type="select"
            name="status"
            className={`status ${getDropdownColor(cell)}`}
            disabled={
              loading.statusChangeLoading ||
              !(
                checkPermission(
                  "dashboard",
                  "canUpdateClosingStatusOrScheduling"
                ) || checkPermission("dashboard", "canEditOrderEntry")
              )
            }
            value={cell}
            onChange={(event) => _onChangeOrderStatus(row, event.target.value)}
          >
            {agentClosingStatus.map((obj) => (
              <option
                key={obj.value}
                value={obj.value}
                disabled={
                  obj.value === "CCA" ||
                  obj.value === "Arrived" ||
                  obj.value === "Closed" ||
                  obj.value === "DNC" ||
                  cell === "Closed" ||
                  row?._agent
                }
              >
                {obj.label}
              </option>
            ))}
          </Input>
          <div className="downArrow">
            <i className="fa fa-chevron-down"></i>
          </div>
        </div>
      ) : (
        "N/A"
      );
    }
    case "loanOfficer": {
      return cell ? capitalize(cell) : "N/A";
    }

    case "trackOrder": {
      return (
        <Button
          color="primary"
          onClick={() =>
            openTrackingDetails(row?.shippingCompany, row?.trackingInfo)
          }
          disabled={!row?.shippingCompany?.trim()}
        >
          Track Shipment
        </Button>
      );
    }

    default: {
      return cell;
    }
  }
};

export default ClientOrderDashboardDataFormat;
