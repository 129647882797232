export const ADD_USER_DATA = "ADD_USER_DATA";
export const UPDATE_USER_OBJ = "UPDATE_USER_OBJ";
export const UPDATE_ASSISTANT_OBJ = "UPDATE_ASSISTANT_OBJ";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const UPLOAD_FILE_PERCENTAGE = "UPLOAD_FILE_PERCENTAGE";

export const ADD_LIST_DATA = "ADD_LIST_DATA";
export const CLEAR_LIST_DATA = "CLEAR_LIST_DATA";

export const ADD_SETTINGS = "ADD_SETTINGS";
export const CLEAR_SETTINGS = "CLEAR_SETTINGS";
