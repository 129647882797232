import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

class ContactUs extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <div className="pgTitleWrap">
                <h4>Contact Us</h4>
              </div>

              <Card className="contactWrap">
                <CardBody>
                  <p>For any questions, queries or concerns please email us.</p>
                  <Form>
                    <FormGroup className="custom-select-wrapper">
                      <Label for="exampleSelect">Please Select An Issue</Label>
                      <Input type="select" name="select">
                        <option>Select</option>
                        <option>Option 1</option>
                        <option>Option 2</option>
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleText">Details</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        rows="3"
                      />
                    </FormGroup>

                    <Button className="themeBtn">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ContactUs;
