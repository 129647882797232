import { 
    ADD_LIST_DATA,
    CLEAR_LIST_DATA
} from "./action-types";

export const addListData = listData => {
    return {
        type: ADD_LIST_DATA,
        payload: {
            listData
        }
    }
}


export const clearListData = () => {
    return {
        type: CLEAR_LIST_DATA
    }
}