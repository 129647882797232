import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  UncontrolledButtonDropdown,
} from "reactstrap";
import CreatableSelect from "react-select";

const ClientAgentFilters = ({
  filters,
  tagsList,
  _filterOnChange,
  _validateForm,
  _searchOnChange,
  _checkProPlusUser,
}) => {
  return (
    <div className="filterWrapper">
      <FormGroup>
        <Label>Status</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            name="status"
            value={filters?.isActive}
            onChange={(e) => _filterOnChange("isActive", e.target.value)}
          >
            <option value="">All</option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </Input>
        </div>
      </FormGroup>
      <FormGroup className={`withChips`}>
        <Label>Tags</Label>
        <CreatableSelect
          isClearable
          placeholder="select"
          className="customMultiSelect"
          isMulti
          value={filters?.tags}
          options={tagsList}
          onChange={(value) => _filterOnChange("tags", value)}
          blur={() => {
            _validateForm();
          }}
        />
      </FormGroup>
      <FormGroup className={`searchTable d-block`}>
        <Label>Search</Label>
        <InputGroup>
          <InputGroupText addonType="prepend">
            <img
              src={require("../../../assets/img/searchIcon.svg").default}
              alt="searchIcon"
            />
          </InputGroupText>
          <Input
            type="text"
            placeholder="Client"
            value={filters?.search}
            onChange={(e) => _searchOnChange(e.target.value)}
          />
        </InputGroup>
      </FormGroup>

      <UncontrolledButtonDropdown className="dropdownList hideMobile">
        <DropdownToggle caret>Bulk Activity</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => _checkProPlusUser("Email")}>
            Email Template
          </DropdownItem>

          <DropdownItem onClick={() => _checkProPlusUser("Message")}>
            Message Template
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

export default ClientAgentFilters;
