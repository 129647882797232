import React, { Suspense, lazy } from "react";
import {
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import InviteClient from "../components/InviteClient";
import {
  deleteCompanyClientById,
  getAllClients,
  updateClient,
  uploadClientCSV,
} from "../../../http/http-calls";
import {
  deepClone,
  showToast,
  checkPermission,
  errorHandler,
} from "../../../helper-methods";
import { FormGroup, capitalize } from "@material-ui/core";
import { DEFAULT_COVER_PICTURE } from "../../../config";
import { connect } from "react-redux";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ScreenWidthHOC from "./ScreenWidthHOC";
import Swal from "sweetalert2";
import { clientPageHeaderKeys } from "../../../config/stateConfig";
import { ClientMembers } from "../components/Modals/Company/ClientMembers";
import { ClientTeams } from "../components/Modals/Company/ClientTeams";
import SvgIcons from "../components/SvgIcons";

const CustomTable = lazy(() => import("../components/CustomTable"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const InviteClientNewModal = lazy(() =>
  import("../components/InviteClientNewModal")
);
const BulkUploadInfo = lazy(() => import("../components/BulkUploadInfo"));
const AgentClientCardDataFormat = lazy(() =>
  import("../components/AgentClientCardDataFormat")
);
const AgentClientCardHeaderFormat = lazy(() =>
  import("../components/AgentClientCardHeaderFormat")
);

class Clients extends React.Component {
  state = {
    clientsList: [],
    clientsTotalCount: 0,
    clientsHeaderKeys: clientPageHeaderKeys,
    clientsCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    addClientModalData: {
      isOpen: false,
      data: null,
      type: null,
    },
    bulkUploadResponse: {
      isOpen: false,
      data: null,
      success: null,
    },
    clientMemberModal: {
      isOpen: false,
      data: null,
      success: null,
    },
    clientTeamsModal: {
      isOpen: false,
      data: null,
      success: null,
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      bulkFile: false,
      clientDeleteLoading: false,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    filters: {
      search: "",
    },
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _toggleAddClientModal = (isOpen = false, type = null, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
        type,
      },
    });
  };

  _onToggleBulkInfoModal = () => {
    let { bulkUploadResponse } = deepClone(this.state);

    bulkUploadResponse.isOpen = !bulkUploadResponse.isOpen;
    bulkUploadResponse.data = null;
    bulkUploadResponse.success = null;

    this.setState({
      bulkUploadResponse,
      selectedFile: {},
    });
  };

  _onToggleClientMembers = (isOpen = false, data = null) => {
    this.setState({
      clientMemberModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleClientTeams = (isOpen = false, data = null) => {
    this.setState({
      clientTeamsModal: {
        isOpen,
        data,
      },
    });
  };

  // get list of all clients from backend
  _getAllClients = async (isShowLoader = true) => {
    try {
      if (isShowLoader) {
        this._manageLoading("showTableLoading", true);
      }

      const { tableConfig, filters } = this.state;

      let data = {
        skip: tableConfig.skip,
        limit: tableConfig.limit,
      };

      if (filters?.search && filters?.search?.trim()?.length)
        Object.assign(data, { search: filters?.search?.trim() });

      const res = await getAllClients(data);

      this.setState({
        clientsList: res?.clients || [],
        clientsTotalCount: res?.totalCount || 0,
      });
      if (isShowLoader) {
        this._manageLoading("showTableLoading", false);
      }
    } catch (error) {
      errorHandler(error);
      if (isShowLoader) {
        this._manageLoading("showTableLoading", false);
      }
    }
  };

  componentDidMount = () => {
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("add-client", () => {
      this._toggleAddClientModal(true, "add");
    });

    document.querySelector("#scroll").scrollTo(0, 0);
  };

  // status update api call
  _clientStatusUpdate = async (data, value) => {
    this._manageLoading("statusChangeLoading", true);

    const payload = {
      isActive: value === "active" ? true : false,
    };

    try {
      const res = await updateClient(data.id, payload);
      showToast("Status Updated Successfully", "success");

      const { clientsList } = deepClone(this.state);
      const client = clientsList?.find((client) => client?.id === data?.id);
      client["isActive"] = res?.client?.isActive;

      this.setState({ clientsList });
      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after some time.",
        "error"
      );

      this._manageLoading("statusChangeLoading", false);
    }
  };

  _handleOnDeleteClientAlert = (clientId, index) => {
    if (!clientId) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this client.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._handleOnDeleteClient(clientId, index);
      }
    });
  };

  _handleOnDeleteClient = async (clientId, index) => {
    try {
      this._manageLoading("clientDeleteLoading", true);

      const res = await deleteCompanyClientById(clientId);
      if (!res.error) {
        showToast("Client deleted successfully", "success");
      }

      await this._getAllClients(false);
      this._manageLoading("clientDeleteLoading", false);
    } catch (err) {
      errorHandler(err);
      this._manageLoading("clientDeleteLoading", false);
    }
  };

  _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <AgentClientCardHeaderFormat
          header={header}
          row={row}
          cell={cell}
          loading={this.state.loading}
          _toggleAddClientModal={this._toggleAddClientModal}
          _handleOnDeleteClientAlert={this._handleOnDeleteClientAlert}
          history={this.props.history}
          userData={this.props.userData}
          _onToggleClientMembers={this._onToggleClientMembers}
          _onToggleClientTeams={this._onToggleClientTeams}
        />
      </Suspense>
    );
  };

  _paginate = (pageNumber, pageSize) => {
    const { tableConfig } = this.state;

    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;

    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllClients();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate(1, 10);
  };

  _filterOnChange = (type, value, isDelay = false) => {
    try {
      if (isDelay) clearTimeout(this.searchTimer);

      const { filters } = this.state;
      filters[type] = value;

      this.setState({ filters }, () => {
        if (isDelay) {
          this.searchTimer = setTimeout(() => {
            this._paginate(1, 10);
          }, 1000);
        } else {
          this._paginate(1, 10);
        }
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  _onFileSelect = (fieldName, e) => {
    if (e.target.files?.[0]) {
      this.setState(
        {
          isFormDirty: true,
          [fieldName]: {
            previewBlob: URL.createObjectURL(e.target.files[0]),
            uploadData: e.target.files[0],
          },
        },
        () => this._handleSubmit()
      );
    }
  };

  _handleSubmit = async () => {
    this._manageLoading("bulkFile", true);

    try {
      // creating payload
      const formData = new FormData();
      formData.append("file", this.state.selectedFile?.uploadData);
      formData.append("companyName", "companyName");
      formData.append("phone", "phone");
      formData.append("generalEmail", "generalEmail");
      formData.append("schedulingEmail", "schedulingEmail");
      formData.append("accountingEmail", "accountingEmail");
      formData.append("personOfContact", "personOfContact");

      let response = await uploadClientCSV(formData);
      this._manageLoading("bulkFile", false);
      if (typeof response.error === "string") {
        errorHandler(response.error);
      } else {
        !response.error && showToast("Uploaded Successfully", "success");
        this.setState({
          bulkUploadResponse: {
            isOpen: true,
            data: response?.reason,
            success: response?.dataUploadedCount,
          },
        });
      }
    } catch (error) {
      this._manageLoading("bulkFile", false);
      this.setState({
        bulkUploadResponse: {
          isOpen: true,
          data: error.reason,
          success: error.dataUploadedCount,
        },
      });
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            {row?.companyName ? (
              <>
                <div
                  className={`tableUserInfo ${!row?._signingCompany ? "cursorPointer" : ""
                    }`}
                  onClick={() =>
                    this.props.history.push(
                      `/signingcompany/client-details/${row._id}`
                    )
                  }
                >
                  <div className="userImage">
                    <img
                      className="clientImg"
                      src={row?.logo || DEFAULT_COVER_PICTURE}
                      loading="lazy"
                      alt="avatar"
                    />
                  </div>
                  <div className="userContent">
                    {row?.companyName && (
                      <span className="signerName">
                        {capitalize(row?.companyName)}
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  // setting filters from local storage
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.companyClientsPage) {
      try {
        const filters = JSON.parse(localStorage.companyClientsPage);
        this.setState(
          { tableConfig: filters.tableConfig, filters: filters.filters },
          () => {
            this._getAllClients();
          }
        );
      } catch (e) {
        this._getAllClients();
      }
    } else {
      this._getAllClients();
    }
  };

  // persist data in local storage
  _persistFilter = () => {
    try {
      const { tableConfig, filters } = this.state;

      if (tableConfig.pageNumber !== 1) {
        let data = { tableConfig, filters };
        localStorage.companyClientsPage = JSON.stringify(data);
      } else {
        delete localStorage.companyClientsPage;
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <AgentClientCardDataFormat
          row={row}
          userData={this.props.userData}
          loading={this.state.loading}
          _toggleAddClientModal={this._toggleAddClientModal}
          _handleOnDeleteClientAlert={this._handleOnDeleteClientAlert}
          _onToggleClientMembers={this._onToggleClientMembers}
          _onToggleClientTeams={this._onToggleClientTeams}
        />
      </Suspense>
    );
  };

  render() {
    const {
      addClientModalData,
      clientsList,
      clientsHeaderKeys,
      loading,
      clientsTotalCount,
      tableConfig,
      bulkUploadResponse,
      clientsCardHeaderKeys,
      filters,
      clientMemberModal,
      clientTeamsModal,
    } = this.state;
    const { isForMobile } = this.props;

    return (
      <>
        <div id="scroll">
          <div className="content">
            <div className="responsiveTitle">
              <h2>Clients</h2>

              <div className="rightSide">
                {checkPermission("clients", "canAdd") && (
                  <>
                    <Label className="uploadLink">
                      <Input
                        type="file"
                        hidden
                        accept=".csv"
                        value=""
                        disabled={loading.bulkFile}
                        onChange={(e) => this._onFileSelect("selectedFile", e)}
                      />
                      {loading.bulkFile ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : (
                        <>
                          <SvgIcons type="upload" />
                          Upload
                        </>
                      )}
                    </Label>

                    {/* <Link
                      to={`/signingcompany/bulk-upload?companyClient`}
                      className="mr-3"
                    >
                      Bulk upload
                    </Link> */}

                    <Button
                      className="floatingButton"
                      color="primary"
                      onClick={() => this._toggleAddClientModal(true, "add")}
                    >
                      Add Client
                    </Button>
                  </>
                )}
              </div>
            </div>

            {/* search filed for responsive will show below 767px */}
            <div className="mobileSearchFiled">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      src={
                        require("../../../assets/img/searchIcon.svg").default
                      }
                      alt="searchIcon"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="search"
                  value={filters?.search}
                  onChange={(e) =>
                    this._filterOnChange("search", e.target.value, true)
                  }
                  placeholder="Company Name"
                />
              </InputGroup>
            </div>

            <div className="filterContainer responsiveFilter">
              <div className="filterIcon">
                <SvgIcons type="filterIcon" />
                Filter by
              </div>
              <div className="filterWrapper">
                <FormGroup className="searchTable">
                  <Label>Search</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <img
                          src={
                            require("../../../assets/img/searchIcon.svg")
                              .default
                          }
                          alt="searchIcon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Company Name"
                      value={filters?.search}
                      onChange={(e) =>
                        this._filterOnChange("search", e.target.value, true)
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </div>

            {isForMobile ? (
              <Suspense fallback={<></>}>
                <div className="hideDesktop">
                  <CustomCard
                    isPageStartFromOne={true}
                    pageNumber={tableConfig.pageNumber}
                    tableData={clientsList}
                    headerKeys={clientsCardHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={clientsTotalCount}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    rowSelection={false}
                    showTableLoading={loading.showTableLoading}
                    cardHeaderFormat={this._cardHeaderFormat}
                    cardDataFormat={this._cardDataFormat}
                  />
                </div>
              </Suspense>
            ) : (
              <Suspense fallback={<></>}>
                <div className="hideMobile">
                  {clientsHeaderKeys && clientsHeaderKeys.length && (
                    <CustomTable
                      striped
                      isPageStartFromOne={true}
                      pageNumber={tableConfig.pageNumber}
                      tableData={clientsList}
                      headerKeys={clientsHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={clientsTotalCount}
                      rowSelection={false}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      onSortChange={(sortName, sortOrder) =>
                        this._onSortChange(sortName, sortOrder)
                      }
                      showTableLoading={loading.showTableLoading}
                    />
                  )}
                </div>
              </Suspense>
            )}

            {addClientModalData?.data?.isSignedUp &&
              addClientModalData?.data?.isEditable ? (
              <Suspense fallback={<></>}>
                <InviteClientNewModal
                  isOpen={addClientModalData.isOpen}
                  data={addClientModalData.data}
                  onSuccess={() => this._getAllClients()}
                  toggle={this._toggleAddClientModal}
                />
              </Suspense>
            ) : (
              <Suspense fallback={<></>}>
                <InviteClient
                  isOpen={addClientModalData.isOpen}
                  data={addClientModalData.data}
                  type={addClientModalData.type}
                  onSuccess={() => this._getAllClients()}
                  toggle={this._toggleAddClientModal}
                />
              </Suspense>
            )}

            <Suspense fallback={<></>}>
              <BulkUploadInfo
                isOpen={bulkUploadResponse.isOpen}
                toggle={() => this._onToggleBulkInfoModal()}
                data={bulkUploadResponse.data}
                success={bulkUploadResponse.success}
                type={"Clients"}
              />
            </Suspense>

            {clientMemberModal?.isOpen ? (
              <Suspense fallback={<></>}>
                <ClientMembers
                  isOpen={clientMemberModal?.isOpen}
                  data={clientMemberModal?.data}
                  toggle={() => this._onToggleClientMembers()}
                />
              </Suspense>
            ) : null}

            {clientTeamsModal?.isOpen ? (
              <Suspense fallback={<></>}>
                <ClientTeams
                  isOpen={clientTeamsModal?.isOpen}
                  data={clientTeamsModal?.data}
                  toggle={() => this._onToggleClientTeams()}
                />
              </Suspense>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(ScreenWidthHOC(Clients));
