import { API_KEY, CLIENT_ID, SCOPES } from "../config";

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */
export const initializeClient = () => {
  return new Promise((resolve, reject) => {
    try {
      window.gapi.load("client:auth2", () => {
        window.gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            // discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
            access_type: "offline",
            response_type: "code",
            approval_prompt: "force",
          })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } catch (error) {
      reject(error);
    }
  });
};

/**
 *  Sign in the user
 */
export const handleAuthClick = (event) => {
  return new Promise((resolve, reject) => {
    try {
      // window.gapi.auth2
      //   .getAuthInstance()
      //   .signIn()
      //   .then((res) => {
      //     console.log(window.gapi.auth2.getAuthInstance().isSignedIn.get());
      //     console.log("gapi.client", window.gapi.client.getToken());
      //     resolve(true);
      //   })
      //   .catch((error) => {
      //     console.log("google calendar error >> ", error);
      //     reject(error);
      //   });
      const authInstance = window.gapi.auth2.getAuthInstance();
      authInstance.grantOfflineAccess().then((res) => {
        console.log("code", res.code);

        resolve(res.code);
      });
    } catch (error) {
      console.log("google calendar error >> ", error);
      reject(error);
    }
  });
};

export const getGoogleCalendarAccessToken = () => {
  return new Promise(async (resolve, reject) => {
    try {
      await handleAuthClick();
      const { access_token } = window.gapi.client.getToken();
      if (access_token) {
        resolve({ access_token });
      } else {
        reject({ reason: "Token not found, Try again after sometime." });
      }
    } catch (error) {
      console.log("google calendar error >> ", error);
      reject(error);
    }
  });
};

/**
 *  Sign out the user
 */
export const handleSignoutClick = () => {
  return new Promise((resolve, reject) => {
    try {
      window.gapi.auth2
        .getAuthInstance()
        .signOut()
        .then((res) => {
          // console.log(window.gapi.auth2.getAuthInstance().isSignedIn.get());
          // console.log("gapi.auth2", window.gapi.auth2);
          resolve(true);
        })
        .catch((error) => {
          console.log("google calendar error >> ", error);
          reject(error);
        });
    } catch (error) {
      console.log("google calendar error >> ", error);
      reject(error);
    }
  });
};
