import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import CreateInvoiceCompanyModal from "../components/create-invoice-company-modal";
import CreateInvoiceAgentModal from "../components/create-invoice-agent-modal";
import {
  agentInvoiceHeaderKeys,
  companyInvoiceHeaderKeys,
  filterDropdownValuesForInvoice,
} from "../../../config/stateConfig";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  convertIsoToUtc,
  deleteLocalStorageData,
  errorHandler,
  formatOnlyDateMoment,
  getLoggedInUserId,
  openUrlOnNewTab,
  refreshFunc,
  showToast,
  structureQueryParams,
} from "../../../helper-methods";
import {
  agentGetAllRegisterClients,
  companyGetListTeamsOfClient,
  companyListDownloadInvoiceClient,
  getAllAgentInvoices,
  getClientListForCompanyInvoice,
  getClientListForInvoice,
  signingCompanyFetchAllInvoices,
} from "../../../http/http-calls";
import { BASE_URL } from "../../../config";
import useScreenWidth from "../components/HelpModule/useScreenWidth";
import SvgIcons from "../components/SvgIcons";
const InvoiceTableDataManagementforCompanyAndAgent = lazy(() =>
  import("../components/InvoiceTableDataManagementforCompanyAndAgent")
);
const JobsIncludedInvoicingAgentModal = lazy(() =>
  import("../components/jobs-included-invoicing-agent-modal")
);
const AgentCompanyFilterComponent = lazy(() =>
  import("../components/Agent-Company-FilterComponent")
);
const PaidAndPendingAgentandCompany = lazy(() =>
  import("../components/PaidAndPendingAgentandCompany")
);
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));
const UpgradeAccountModal = lazy(() =>
  import("../components/Modals/upgradeAccountModal")
);
const DataFormatInvoicing = lazy(() =>
  import("../components/invoicingAgentCompanydataFormat")
);

const DEFAULT_KEY_FOR_COMPANY = "signingcompany";

const AgentCompanyInvoicing = () => {
  const userCred = useSelector((state) => state?.userData?.type);
  const userData = useSelector((state) => state?.userData);
  const { isForMobile } = useScreenWidth();

  const [dateRangeValue, setDateRangeValue] = useState(null);

  const [createInvoiceModal, setCreateInvoiceModal] = useState({
    isOpen: false,
    data: null,
  });

  const [jobsIncludedModal, setJobsIncludedModal] = useState({
    isOpen: false,
    data: null,
  });

  const [upgradeAccountModal, setUpgradeAccountModal] = useState({
    isOpen: false,
    data: null,
  });

  const [isClassAdded, setIsClassAdded] = useState(false);
  const [filters, setFilters] = useState({
    // client: "",
    // clientTeam: "",
    // status: "",
    // search: "",
    // companyId: "",
    // amount: "",
  });

  const [filterDropdownValues, setFilterDropdownValues] = useState(
    userCred === DEFAULT_KEY_FOR_COMPANY
      ? {
        clients: [],
        downloadClients: [],
        clientTeamsList: [],
      }
      : filterDropdownValuesForInvoice
  );
  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
  });

  const [loading, setLoading] = useState({
    showDataLoading: false,
    markPaidLoading: false,
    showTableLoading: false,
  });

  const [paidAmount, setPaidAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [companyInvoiceList, setCompanyInvoiceList] = useState([]);
  const [companyInvoiceTotalCount, setCompanyInvoiceTotalCount] = useState(0);
  const tableRef = useRef(null);

  const [agentInvoiceList, setAgentInvoiceList] = useState([]);
  const [agentInvoiceTotalCount, setAgentInvoiceTotalCount] = useState(0);
  const _onToggleCreateInvoiceModal = (isOpen = false, data = null) => {
    setCreateInvoiceModal({
      isOpen: isOpen,
      data: data,
    });
  };

  const _onToggleJobsIncludedModal = (isOpen = false, data = null) => {
    setJobsIncludedModal({
      isOpen,
      data,
    });
  };

  const _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    setUpgradeAccountModal({ isOpen, data });
  };

  const _paginate = (pageNumber = 1, pageSize = 10) => {
    const updatedTableConfig = {
      ...tableConfig,
      skip: (pageNumber - 1) * pageSize,
      limit: pageSize,
      pageNumber: pageNumber,
    };
    setTableConfig(updatedTableConfig);
  };

  const _onSortChange = (sortName, sortOrder) => {
    _paginate();
  };

  // This function work for both agent and company handle localstorage
  const _persistFilter = () => {
    if (
      (filters &&
        ((filters.search && filters.search.length) ||
          filters.client ||
          filters.status ||
          filters.companyId ||
          filters.amount)) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      if (userCred === DEFAULT_KEY_FOR_COMPANY) {
        localStorage.companyinvoice = JSON.stringify(data);
      } else {
        localStorage.agentinvoice = JSON.stringify(data);
      }
    } else {
      if (userCred === DEFAULT_KEY_FOR_COMPANY) {
        delete localStorage.companyinvoice;
      } else {
        delete localStorage.agentinvoice;
      }
    }
  };

  const _onChangeDatePicker = (dateRangeValue) => {
    setDateRangeValue(dateRangeValue);
    _getAllInvoice(dateRangeValue, filters, {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    });

    _paginate();
    // _persistFilter();
  };

  const _filterOnChange = (type, value) => {
    const newFilters = { ...filters };
    newFilters[type] = value;
    if (userCred === DEFAULT_KEY_FOR_COMPANY) {
      if (type === "client") {
        newFilters["clientTeam"] = "";
        if (value?.length) {
          _getCompanyListTeamsOfClient(value);
        } else {
          setFilterDropdownValues({
            ...filterDropdownValues,
            clientTeamsList: [],
          });
        }
      }
    }
    setFilters(newFilters);
    _getAllInvoice(dateRangeValue, newFilters, {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    });
    _paginate();
    // _persistFilter();
  };

  //filter reset for agent and company
  const _resetFilter = () => {
    if (userCred === DEFAULT_KEY_FOR_COMPANY) {
      setFilters({
        // client: "",
        // clientTeam: "",
        // status: "",
        // search: "",
      });
      setDateRangeValue(null);
      _paginate();
      _getAllInvoice(null, {}, { skip: 0, limit: 10, pageNumber: 1 });
      refreshFunc("company-accounting-invoicing-responsive");
      deleteLocalStorageData("companyinvoice");
    } else {
      setFilters({
        // status: "",
        // search: "",
        // companyId: "",
        // amount: "",
      });
      setDateRangeValue(null);
      _paginate();
      _getAllInvoice(null, {}, { skip: 0, limit: 10, pageNumber: 1 });
      refreshFunc("agent-accounting-invoicing-responsive");
    }
  };

  const _getClientListForCompanyInvoice = async () => {
    try {
      const res = await getClientListForCompanyInvoice();
      setFilterDropdownValues((prevState) => ({
        ...prevState,
        clients: res.list || [],
      }));
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };
  const _manageLoading = (loaderName, value) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [loaderName]: value,
    }));
  };

  //This is for fetch invoice data for agent and company both
  const _getAllInvoice = async (
    updatedDateRange,
    updatedFilters,
    newTableConfig
  ) => {
    const payload = {
      ...(newTableConfig ? newTableConfig : tableConfig),

      filters: {},
    };

    if (updatedDateRange && updatedDateRange.length) {
      payload.filters["startDate"] = updatedDateRange[0];
      payload.filters["endDate"] = updatedDateRange[1];
    }

    if (updatedFilters.client)
      payload.filters["clientId"] = updatedFilters.client;
    if (updatedFilters?.client && updatedFilters?.clientTeam)
      payload.filters["clientAssistantTeam"] = updatedFilters?.clientTeam;

    if (updatedFilters.status)
      payload.filters["status"] =
        updatedFilters.status !== "" ? updatedFilters.status : undefined;

    if (updatedFilters.companyId && updatedFilters.companyId.length > 0) {
      filterDropdownValues.signingCompanyForDashboard.find((each) => {
        if (each._id === updatedFilters.companyId) {
          if (each.hasOwnProperty("_client")) {
            payload.filters["companyId"] = "unregisteredClient";
            payload.filters["clientId"] = each._client;
          } else if (each.hasOwnProperty("_signingCompany")) {
            payload.filters["companyId"] = each._signingCompany;
          } else {
            payload.filters["assistantId"] = each._assistant;
          }
        }
        return null;
      });
    }

    if (updatedFilters.amount) {
      try {
        payload.filters["amount"] = JSON.parse(updatedFilters.amount);
      } catch (error) {
        updatedFilters.amount = "";
        delete payload.filters["amount"];
      }
    }
    if (userCred === DEFAULT_KEY_FOR_COMPANY) {
      _manageLoading("showDataLoading", true);
      try {
        const res = await signingCompanyFetchAllInvoices(payload);
        setPaidAmount(res.paidAmount);
        setPendingAmount(res.pendingAmount);
        setCompanyInvoiceList(res.invoices);
        setCompanyInvoiceTotalCount(res.totalCount);
        _manageLoading("showDataLoading", false);
      } catch (error) {
        showToast(
          error && error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
        _manageLoading("showDataLoading", false);
      }
    } else {
      _manageLoading("showTableLoading", true);
      try {
        const res = await getAllAgentInvoices(payload);
        setPaidAmount(res.paidAmount);
        setPendingAmount(res.pendingAmount);
        setAgentInvoiceList(res?.invoices);
        setAgentInvoiceTotalCount(res.totalCount);
        _manageLoading("showTableLoading", false);
      } catch (error) {
        showToast(
          error && error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
        _manageLoading("showTableLoading", false);
      }
    }
  };

  const _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      setFilterDropdownValues((prevFilterDropdownValues) => ({
        ...prevFilterDropdownValues,
        clientTeamsList: res?.teams,
      }));
    } catch (err) {
      errorHandler(err);
    }
  };

  // this function use for both agent and company for filter set from local storage
  const _setFilterDataFromLocalStorage = () => {
    if (userCred === DEFAULT_KEY_FOR_COMPANY) {
      const localStorageData = localStorage && localStorage.companyinvoice;
      if (localStorageData) {
        try {
          const parsedData = JSON.parse(localStorageData);
          let dateRange = null;
          if (parsedData.dateRangeValue !== null) {
            dateRange = [
              new Date(convertIsoToUtc(parsedData.dateRangeValue[0])),
              new Date(convertIsoToUtc(parsedData.dateRangeValue[1])),
            ];
          }
          setFilters(parsedData.filters || {});
          setDateRangeValue(dateRange);
          setTableConfig(parsedData.tableConfig || null);
          if (parsedData.filters?.client) {
            _getCompanyListTeamsOfClient(parsedData.filters.client);
          }
        } catch (e) {
          _getAllInvoice(); // fetch all invoices for company
        }
      } else {
        _getAllInvoice();
      }
    } else {
      const localStorageData = localStorage && localStorage.agentinvoice;
      if (localStorageData) {
        try {
          const filters = JSON.parse(localStorage.agentinvoice);
          setFilters(filters?.filters);
          setTableConfig(filters?.tableConfig);
        } catch (e) {
          _getAllInvoice(); // fetch all invoices for agent
        }
      } else {
        _getAllInvoice();
      }
    }
  };

  const _companyListDownloadInvoiceClient = async () => {
    try {
      const res = await companyListDownloadInvoiceClient();
      setFilterDropdownValues((prevFilterDropdownValues) => ({
        ...prevFilterDropdownValues,
        downloadClients: res.list || [],
      }));
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  // For manage table data agent and company
  const _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <DataFormatInvoicing
          cell={cell}
          row={row}
          header={header}
          userCred={userCred}
          _onToggleJobsIncludedModal={_onToggleJobsIncludedModal}
          _downloadInvoiceData={_downloadInvoiceData}
        />
      </Suspense>
    );
  };

  //For invoice download
  const _downloadInvoiceData = (invoiceId) => {
    _manageLoading("showTableLoading", true);
    openUrlOnNewTab(
      `${BASE_URL}/${userCred}/download/invoice/${invoiceId}?userId=${getLoggedInUserId()}`
    );
    _manageLoading("showTableLoading", false);
  };

  //For company side download All
  const _downloadData = (action = "download") => {
    if (!companyInvoiceList.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
      action,
    };

    if (filters.client) payload["clientId"] = filters.client;
    else {
      showToast("Please select client from filter", "error");
      return;
    }
    if (filters.status) payload["status"] = filters.status;

    if (dateRangeValue && dateRangeValue.length) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    const apiUrl = `${BASE_URL}/signingcompany/mail/invoices${queryParams}`;

    if (action === "download") {
      openUrlOnNewTab(apiUrl);
    }
  };

  //For mobile view cardData mange from this component
  const _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <InvoiceTableDataManagementforCompanyAndAgent
          row={row}
          onToggleJobsIncludedModal={_onToggleJobsIncludedModal}
          downloadInvoiceData={_downloadInvoiceData}
          siteName={userCred === DEFAULT_KEY_FOR_COMPANY ? "company" : "agent"}
        />
      </Suspense>
    );
  };

  const _getClientListForInvoice = async () => {
    try {
      const res = await getClientListForInvoice();
      setFilterDropdownValues((prevState) => ({
        ...prevState,
        signingCompany: res.list || [],
      }));
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  const _agentGetAllRegisterClients = async () => {
    try {
      const res = await agentGetAllRegisterClients();
      setFilterDropdownValues((prevState) => ({
        ...prevState,
        signingCompanyForDashboard: res.listAll || [],
      }));
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (userCred === DEFAULT_KEY_FOR_COMPANY) {
      _getClientListForCompanyInvoice(); // for show client in creare invoice
      _setFilterDataFromLocalStorage();
      _companyListDownloadInvoiceClient(); // for filter show client list

      const resetFilterSubscription = HeaderEventEmitter?.subscribe(
        "reset-filter",
        () => {
          _resetFilter();
          refreshFunc("company-accounting-invoicing");
        }
      );

      const addInvoiceSubscription = HeaderEventEmitter?.subscribe(
        "add-invoice",
        () => {
          _onToggleCreateInvoiceModal(true);
        }
      );

      return () => {
        resetFilterSubscription?.unsubscribe();
        addInvoiceSubscription?.unsubscribe();
      };
    } else {
      _getClientListForInvoice(); // For new invoice modal client show in dropdown
      _agentGetAllRegisterClients(); // For filter client show
      _setFilterDataFromLocalStorage();
      HeaderEventEmitter.subscribe("reset-filter", () => {
        _resetFilter();
        refreshFunc("agent-accounting-invoicing");
      });
      HeaderEventEmitter?.subscribe("add-invoice", () => {
        _onToggleCreateInvoiceModal(true);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for initial oage render
  useEffect(() => {
    // if (
    //   // Object.values(filters).some((value) => value !== "") ||
    //   Object.keys(filters).length ||
    //   // dateRangeValue !== null ||
    //   tableConfig.skip !== 0 ||
    //   tableConfig.limit !== 10 ||
    //   tableConfig.pageNumber !== 1
    // ) {
    _getAllInvoice(dateRangeValue, filters);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for page change
  useEffect(() => {
    _getAllInvoice(dateRangeValue, filters, tableConfig);
    _persistFilter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dateRangeValue, tableConfig]);

  return (
    <div className="content">
      <div className="responsiveTitle">
        <h2>Invoicing</h2>
        <div className="rightSide">
          <Button
            className="floatingButton"
            color="primary"
            onClick={() => _onToggleCreateInvoiceModal(true)}
          >
            New Invoice
          </Button>
          <Button color="link" id="company-accounting-invoicing-responsive" onClick={_resetFilter}>
            {/* <img
              
              src={require("../../../assets/img/refresh.svg").default}
              alt="refresh"
              height={14}
            /> */}
            <SvgIcons type="refresh" />
          </Button>
          <Button
            className="filterBarIcon"
            color="link"
            onClick={() => setIsClassAdded(true)}
          >
            <SvgIcons type="filterIcon" />
            {/* <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="Filter"
              height={14}
            /> */}
          </Button>
        </div>
      </div>
      {/* filter for tablet and web */}

      <div className={`mobileFilterView ${isClassAdded ? "show" : ""}`}></div>

      <div
        className={`filterContainer  responsiveFilter ${isClassAdded ? "show" : ""
          }`}
      >
        {!isForMobile ? (
          <div className="filterIcon">
            <SvgIcons type="filterIcon" />
            Filter by
          </div>
        ) : (
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={() => setIsClassAdded(false)}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
        )}
        <div className="filterWrapper">
          <Suspense fallback={<></>}>
            <AgentCompanyFilterComponent
              dateRangeValue={dateRangeValue}
              filters={filters}
              onChangeDatePicker={_onChangeDatePicker}
              filterOnChange={_filterOnChange}
              filterDropdownValues={filterDropdownValues}
              siteName={
                userCred === DEFAULT_KEY_FOR_COMPANY ? "company" : "agent"
              }
            />
          </Suspense>
          {userCred === DEFAULT_KEY_FOR_COMPANY ? (
            <Button
              color="link"
              outline
              className="downloadButton ml-lg-auto mt-3"
              onClick={() => _downloadData()}
            >
              Download All
              {/* <img
                src={require("../../../assets/img/download_blue.png")}
                alt="download"
              /> */}
              <SvgIcons type={"download"} />
            </Button>
          ) : null}
        </div>
        <div className="clearButton">
          <Button
            size="md"
            color="primary"
            onClick={() => setIsClassAdded(false)}
          >
            Close
          </Button>
        </div>
      </div>

      <>
        <Suspense fallback={<></>}>
          <PaidAndPendingAgentandCompany
            paidAmount={paidAmount}
            pendingAmount={pendingAmount}
            siteName={
              userCred === DEFAULT_KEY_FOR_COMPANY ? "company" : "agent"
            }
            loading={
              userCred === DEFAULT_KEY_FOR_COMPANY
                ? loading.showDataLoading
                : loading.showTableLoading
            }
            isForMobile={isForMobile}
          />
        </Suspense>
      </>

      {isForMobile ? (
        <Suspense fallback={<></>}>
          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={
                userCred === DEFAULT_KEY_FOR_COMPANY
                  ? companyInvoiceList
                  : agentInvoiceList
              }
              dataFormat={_dataFormat}
              totalCount={
                userCred === DEFAULT_KEY_FOR_COMPANY
                  ? companyInvoiceTotalCount
                  : agentInvoiceTotalCount
              }
              onPaginate={(pageNumber, pageSize) =>
                _paginate(pageNumber, pageSize)
              }
              rowSelection={false}
              showTableLoading={
                userCred === DEFAULT_KEY_FOR_COMPANY
                  ? loading.showDataLoading
                  : loading.showTableLoading
              }
              cardDataFormat={_cardDataFormat}
            />
          </div>
        </Suspense>
      ) : (
        <Suspense fallback={<></>}>
          <div className="hideMobile">
            {companyInvoiceHeaderKeys &&
              companyInvoiceHeaderKeys.length &&
              userCred === DEFAULT_KEY_FOR_COMPANY && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={companyInvoiceList}
                  headerKeys={companyInvoiceHeaderKeys}
                  dataFormat={_dataFormat}
                  totalCount={companyInvoiceTotalCount}
                  rowSelection={false}
                  onPaginate={(pageNumber, pageSize) =>
                    _paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) =>
                    _onSortChange(sortName, sortOrder)
                  }
                  showTableLoading={
                    userCred === DEFAULT_KEY_FOR_COMPANY
                      ? loading.showDataLoading
                      : loading.showTableLoading
                  }
                  ref={tableRef}
                />
              )}
            {agentInvoiceHeaderKeys &&
              agentInvoiceHeaderKeys.length &&
              userCred === "agent" && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={agentInvoiceList}
                  headerKeys={agentInvoiceHeaderKeys}
                  dataFormat={_dataFormat}
                  totalCount={agentInvoiceTotalCount}
                  rowSelection={false}
                  onPaginate={(pageNumber, pageSize) =>
                    _paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) =>
                    // sort function is disabled
                    _paginate()
                  }
                  showTableLoading={loading.showTableLoading}
                />
              )}
          </div>
        </Suspense>
      )}

      {/* for create invoicing company or agent  */}
      {userCred === DEFAULT_KEY_FOR_COMPANY ? (
        <CreateInvoiceCompanyModal
          isOpen={createInvoiceModal.isOpen}
          creatorData={createInvoiceModal.data}
          clients={filterDropdownValues.clients}
          toggle={() => _onToggleCreateInvoiceModal()}
          resetDetails={() => {
            _paginate();
            _getAllInvoice();
          }}
        />
      ) : (
        <CreateInvoiceAgentModal
          isOpen={createInvoiceModal.isOpen}
          data={createInvoiceModal.data}
          signingCompany={filterDropdownValues.signingCompany}
          toggle={() => _onToggleCreateInvoiceModal()}
          resetDetails={() => {
            _paginate();
            _getAllInvoice();
          }}
        />
      )}

      {jobsIncludedModal.isOpen && (
        <Suspense fallback={<></>}>
          <JobsIncludedInvoicingAgentModal
            isOpen={jobsIncludedModal.isOpen}
            data={jobsIncludedModal.data}
            toggle={() => _onToggleJobsIncludedModal()}
            userData={userData}
          />
        </Suspense>
      )}

      {upgradeAccountModal.isOpen && (
        <Suspense fallback={<></>}>
          <UpgradeAccountModal
            isOpen={upgradeAccountModal.isOpen}
            toggle={_toggleUpgradeAccountModal}
          />
        </Suspense>
      )}
    </div>
  );
};

export default AgentCompanyInvoicing;
