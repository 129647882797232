import React, { Suspense } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Label,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Collapse,
  Table,
  FormGroup,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  deepClone,
  formatCurrencyValue,
  // formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  formatTime,
  openGoogleMapOnNewTab,
  showToast,
  formatDateMoment,
  formatDateAsPerTimeZoneWithDateAndTime,
  errorHandler,
} from "../../../helper-methods";
import {
  getAgentClosingBidDetail,
  agentAcceptOrRejectBidding,
  agentCounterBidding,
  agentAcceptOrRejectBiddingNew,
  agentCounterBiddingNew,
} from "../../../http/http-calls";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { DateTimeShowWithClosingDetails } from "../components/Date-time-show";
import ReadMore from "../components/readMore";

class NewClosingAgent extends React.Component {
  state = {
    closingDetails: null,
    loading: false,
    isOpenCounterForm: false,
    counterForm: {
      counterAmount: {
        value: "",
        isDirty: false,
        error: null,
      },
      appointmentTime: {
        value: "",
        isDirty: false,
        error: null,
      },
      paymentInstructions: {
        value: "",
        isDirty: false,
        error: null,
      },
    },
  };

  _redirectToDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/dashboard`);
  };

  componentDidMount = async () => {
    // console.log("serfgfds", this.props.match.params);
    try {
      const id = this.props.match.params.id.replace("&guestuser", "");
      this.props.showLoader("Fetching Closing Details...");

      await this._getAgentClosingBidDetail(id);

      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
      this._redirectToDashboard();
    }
  };

  _getAgentClosingBidDetail = (id = null) => {
    const { userData } = this.props;

    if (!id && this.state.closingDetails && this.state.closingDetails.id) {
      id = this.state.closingDetails.id;
    }
    const parts = this.props.match.params.id.split("&");

    const payload = {
      closingId: parts[0],
      agentId: this.props.userData.user.id,
    };

    return new Promise((resolve, reject) => {
      getAgentClosingBidDetail(payload)
        .then((res) => {
          this.setState(
            {
              closingDetails: res.closing,
            },
            () => {
              HeaderEventEmitter.dispatch("closing-details", {
                closingDetails: res.closing,
              });
            }
          );
          if (res?.closing?._agent !== undefined) {
            showToast("Job no longer available", "error");
            this.props.history.push(`/${userData.type}/dashboard`);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _agentAcceptOrRejectBidding = async (status) => {
    try {
      this.setState({ loading: true });

      const payload = {
        status,
        closingId: this.state.closingDetails?.id,
        agentId: this.props.userData.user.id,
      };

      if (this.state.closingDetails?.isAgentWebsiteClosing) {
        await agentAcceptOrRejectBiddingNew(payload);
      } else {
        await agentAcceptOrRejectBidding(payload);
      }

      this.setState({ loading: false });
      showToast(`Order ${status}`, "success");
      this._redirectToDashboard();
    } catch (err) {
      this.setState({ loading: false });
      errorHandler(err);
    }
  };

  _validatecounterFormField = () => {
    return new Promise((resolve, reject) => {
      const { counterForm } = this.state;

      let isFormValid = true;

      Object.keys(counterForm).forEach((key) => {
        if (counterForm[key].isDirty) {
          switch (key) {
            case "appointmentTime": {
              if (
                counterForm[key].value &&
                counterForm[key].value.trim().length
              ) {
                counterForm[key].isDirty = false;
                counterForm[key].error = null;
              } else {
                counterForm[key].isDirty = true;
                counterForm[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "counterAmount": {
              if (
                counterForm[key].value &&
                counterForm[key].value.trim().length
              ) {
                if (
                  isNaN(counterForm[key].value) ||
                  Number(counterForm[key].value) < 1
                ) {
                  counterForm[key].isDirty = true;
                  counterForm[key].error = "*Invalid Fee";
                  isFormValid = false;
                } else {
                  counterForm[key].isDirty = false;
                  counterForm[key].error = null;
                }
              } else {
                counterForm[key].isDirty = true;
                counterForm[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ counterForm }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeCounterFormField = (fieldName, value) => {
    const { counterForm } = this.state;
    if (fieldName === "counterAmount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      )
        return;
    }
    if (fieldName === "appointmentTime") {
      counterForm[fieldName].value = value ? new Date(value).toISOString() : "";
    } else {
      counterForm[fieldName].value = value;
    }
    counterForm[fieldName].isDirty = true;
    this.setState({ counterForm }, () => {
      // Validation
      this._validatecounterFormField();
    });
  };

  _markAllCounterFormFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { counterForm } = this.state;
      Object.keys(counterForm).forEach((key) => {
        counterForm[key].isDirty = true;
      });
      this.setState({ counterForm }, () => resolve(true));
    });
  };

  _onSubmitCounterOffer = async (e) => {
    try {
      if (e) e.preventDefault();

      await this._markAllCounterFormFieldDirty();

      const isFormValid = await this._validatecounterFormField();

      if (isFormValid) {
        this.setState({ loading: true });

        const { counterForm, closingDetails } = deepClone(this.state);

        let payload = {
          closingId: closingDetails?.id,
          agentId: this.props.userData.user.id,
          counterAmount: Number(counterForm.counterAmount.value.trim()),
          appointmentTime: formatDateMoment(counterForm.appointmentTime.value),
        };

        if (closingDetails?.isAgentWebsiteClosing) {
          await agentCounterBiddingNew(payload);
        } else {
          await agentCounterBidding(payload);
        }
        this.setState({ loading: false });
        showToast(`Order counter offer has been sent.`, "success");
        this._redirectToDashboard();
      }
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _setCounterFormField = () => {
    const { counterForm, closingDetails } = deepClone(this.state);

    counterForm.counterAmount.value =
      closingDetails?.biddingAmount?.toString() || "";
    // counterForm.appointmentTime.value = closingDetails?.appointmentDate || "";
    counterForm.appointmentTime.value = closingDetails?.appointmentDate
      ? formatDateAsPerTimeZoneWithDateAndTime(
          closingDetails.appointmentDate,
          closingDetails.closingAddress.timeZone
        )
      : "";
    Object.keys(counterForm).forEach((key) => {
      counterForm[key].isDirty = false;
      counterForm[key].error = null;
    });
    this.setState({ counterForm });
  };

  _toggleCounterFormCard = () => {
    if (!this.state.isOpenCounterForm) {
      this._setCounterFormField();
    }
    this.setState({ isOpenCounterForm: !this.state.isOpenCounterForm });
  };

  render() {
    const { closingDetails, loading, counterForm, isOpenCounterForm } =
      this.state;
    // console.log(this.state);
    const { isForMobile } = this.props;
    return (
      <div className="content">
        <div className="responsiveTitle">
          <div className="d-flex align-items-center">
            <h2>
              New Signing Offer
              {closingDetails && (
                <span>Order No #{closingDetails.closingNumber || "N/A"}</span>
              )}
            </h2>
          </div>
        </div>

        {closingDetails && (
          <Row>
            <Col xl={6} lg={12}>
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Order Details</h2>
                </div>
                {!isForMobile ? (
                  <Suspense fallback={<></>}>
                    <div className="CustomTableWrapper hideMobile">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Appointment Date</th>
                            <th>Address</th>
                            <th>Fees Offered</th>
                            <th>Product type</th>
                            <th>Signing type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {closingDetails.appointmentDate ? (
                                <>
                                  <div>
                                    <DateTimeShowWithClosingDetails
                                      closingDetails={closingDetails}
                                    />
                                    <span>
                                      {getTimeZoneAbbr(
                                        closingDetails.appointmentDate,
                                        closingDetails.closingAddress.timeZone
                                      )}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td>
                              <span
                                style={{
                                  fontWeight: 600,
                                  width: 150,
                                  display: "block",
                                }}
                              >
                                {closingDetails?.closingAddress ? (
                                  <>
                                    {closingDetails?.closingAddress?.line1
                                      ? `${closingDetails?.closingAddress?.line1}, `
                                      : ""}
                                    {closingDetails?.closingAddress?.line2
                                      ? `${closingDetails?.closingAddress?.line2}, `
                                      : ""}
                                    {closingDetails?.closingAddress?.city
                                      ? `${closingDetails?.closingAddress?.city}, `
                                      : ""}
                                    {closingDetails?.closingAddress?.state
                                      ? `${closingDetails?.closingAddress?.state}, `
                                      : ""}
                                    {closingDetails?.closingAddress?.zip
                                      ? `${closingDetails?.closingAddress?.zip}`
                                      : ""}
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </span>

                              {closingDetails.closingAddress ? (
                                <Button
                                  color="link"
                                  // className="showMap"
                                  onClick={() =>
                                    openGoogleMapOnNewTab(
                                      closingDetails.closingAddress
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../assets/img/map-marker.svg")
                                        .default
                                    }
                                    alt="final"
                                  />
                                </Button>
                              ) : null}
                            </td>
                            <td>
                              {closingDetails.biddingAmount
                                ? formatCurrencyValue(
                                    closingDetails.biddingAmount
                                  )
                                : "N/A"}
                            </td>
                            <td>
                              {closingDetails.loanType &&
                              closingDetails.loanType.length > 0 ? (
                                closingDetails.loanType.indexOf("Other") >
                                -1 ? (
                                  <span style={{ marginRight: 6 }}>
                                    {closingDetails?.loanTypeOther}
                                  </span>
                                ) : null
                              ) : null}
                              {closingDetails.loanType
                                .filter((each) => each !== "Other")
                                .map((item) => (
                                  <>
                                    <span style={{ marginRight: 6 }}>
                                      {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                    </span>
                                  </>
                                ))}
                            </td>
                            <td>
                              <span style={{ marginRight: 6 }}>
                                {closingDetails?.signingType}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Suspense>
                ) : (
                  <Suspense fallback={<></>}>
                    <Card className="responsiveTableCard hideDesktop">
                      <ul className="cardInfo">
                        <li>
                          <div className="dataWrap">
                            <Label>Appointment Date: </Label>
                            <div style={{ fontWeight: 600 }}>
                              {closingDetails?.appointmentDate ? (
                                <>
                                  <DateTimeShowWithClosingDetails
                                    closingDetails={closingDetails}
                                  />
                                  {" | "}
                                  <span>
                                    {getTimeZoneAbbr(
                                      closingDetails?.appointmentDate,
                                      closingDetails?.closingAddress?.timeZone
                                    )}
                                  </span>
                                </>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </div>
                          <div className="dataWrap">
                            <Label>Address: </Label>
                            <div style={{ fontWeight: 600 }}>
                              {closingDetails?.closingAddress ? (
                                <>
                                  {closingDetails?.closingAddress?.line1
                                    ? `${closingDetails?.closingAddress?.line1}, `
                                    : ""}
                                  {closingDetails?.closingAddress?.line2
                                    ? `${closingDetails?.closingAddress?.line2}, `
                                    : ""}
                                  {closingDetails?.closingAddress?.city
                                    ? `${closingDetails?.closingAddress?.city}, `
                                    : ""}
                                  {closingDetails?.closingAddress?.state
                                    ? `${closingDetails?.closingAddress?.state}, `
                                    : ""}
                                  {closingDetails?.closingAddress?.zip
                                    ? `${closingDetails?.closingAddress?.zip}`
                                    : ""}
                                </>
                              ) : (
                                "N/A"
                              )}

                              {closingDetails?.closingAddress ? (
                                <Button
                                  color="link"
                                  // className="showMap"
                                  onClick={() =>
                                    openGoogleMapOnNewTab(
                                      closingDetails?.closingAddress
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../assets/img/map-marker.svg")
                                        .default
                                    }
                                    alt="final"
                                  />
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="dataWrap">
                            <Label>Fees Offered: </Label>
                            <div style={{ fontWeight: 600 }}>
                              {closingDetails?.biddingAmount
                                ? formatCurrencyValue(
                                    closingDetails?.biddingAmount
                                  )
                                : "N/A"}
                            </div>
                          </div>
                          <div className="dataWrap">
                            <Label>Product Type: </Label>
                            <div style={{ fontWeight: 600 }}>
                              {closingDetails?.loanType &&
                              closingDetails?.loanType.length > 0 ? (
                                closingDetails?.loanType.indexOf("Other") >
                                -1 ? (
                                  <span style={{ marginRight: 6 }}>
                                    {closingDetails?.loanTypeOther}
                                  </span>
                                ) : null
                              ) : null}
                              {closingDetails?.loanType
                                ?.filter((each) => each !== "Other")
                                ?.map((item) => (
                                  <>
                                    <span style={{ marginRight: 6 }}>
                                      {item?.replace(
                                        /([a-z])([A-Z])/g,
                                        "$1 $2"
                                      )}
                                    </span>
                                  </>
                                ))}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="dataWrap">
                            <Label>Signing Type: </Label>
                            <div style={{ fontWeight: 600 }}>
                              <span style={{ marginRight: 6 }}>
                                {closingDetails?.signingType}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Card>
                  </Suspense>
                )}
              </div>
              <div className="CardWrapper mt-4">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Payment Instructions</h2>
                </div>
                <Card>
                  <CardBody>
                    {closingDetails.paymentInstructions ? (
                      <div className="hideToolbar">
                        <ReadMore text={closingDetails?.paymentInstructions} />
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col xl={6} lg={12}>
              <div className="CardWrapper mt-3 mt-xl-0">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Signing Instructions</h2>
                </div>
                <Card>
                  <CardHeader>
                    <CustomInput
                      type="checkbox"
                      id="isScanBackNeeded"
                      checked={closingDetails.isScanBackNeeded}
                      label="Scan Backs Needed"
                      disabled={true}
                    />
                  </CardHeader>
                  <CardBody>
                    {closingDetails.bidInstructions ? (
                      <div className="hideToolbar">
                        <ReadMore text={closingDetails?.bidInstructions} />

                        {/* <TextEditor
                           disabled
                           readOnly={true}
                           content={closingDetails.bidInstructions}
                           onChange={() => {}} // caution: don't remove
                         /> */}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col md="12">
              {closingDetails?._mainForwardAgent?.toString() !==
                this.props.userData.user.id.toString() && (
                <div className="signingOfferButton">
                  <Button
                    color="primary"
                    outline
                    size="lg"
                    onClick={() => this._agentAcceptOrRejectBidding("Accepted")}
                  >
                    Accept
                  </Button>
                  <Button
                    color="danger"
                    outline
                    size="lg"
                    className="mx-3"
                    onClick={() => this._agentAcceptOrRejectBidding("Rejected")}
                  >
                    Decline
                  </Button>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={this._toggleCounterFormCard}
                  >
                    Counter Offer
                  </Button>
                </div>
              )}

              <Collapse isOpen={isOpenCounterForm}>
                <div className="counterOfferWrap">
                  <Row>
                    <Col xs="6" md="6">
                      <FormGroup
                        className={`floatingLabel ${
                          counterForm.appointmentTime.value ||
                          this.state.isOpenReactDatetime
                            ? "valueAdded"
                            : ""
                        }`}
                      >
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: " ",
                            value: formatTime(
                              counterForm.appointmentTime.value
                            ),
                          }}
                          value={
                            counterForm.appointmentTime.value
                              ? new Date(counterForm.appointmentTime.value)
                              : ""
                          }
                          onChange={(e) =>
                            this._onChangeCounterFormField(
                              "appointmentTime",
                              e._d
                            )
                          }
                          onOpen={() =>
                            this.setState({ isOpenReactDatetime: true })
                          }
                          onClose={() =>
                            this.setState({ isOpenReactDatetime: false })
                          }
                          dateFormat={false}
                          timeFormat={true}
                          timeConstraints={{ minutes: { step: 15 } }}
                        />
                        <Label>Appointment Time</Label>
                      </FormGroup>
                      {counterForm.appointmentTime.error && (
                        <div className="validation-error">
                          {counterForm.appointmentTime.error}
                        </div>
                      )}
                    </Col>

                    <Col md={6}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={
                                  require("../../../assets/img/dollar.svg")
                                    .default
                                }
                                height={12}
                                alt="doller"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder=" "
                            value={counterForm.counterAmount.value}
                            onChange={(e) =>
                              this._onChangeCounterFormField(
                                "counterAmount",
                                e.target.value
                              )
                            }
                          />
                          <Label>Fee</Label>
                        </InputGroup>
                      </FormGroup>
                      {counterForm.counterAmount.error && (
                        <div className="validation-error">
                          {counterForm.counterAmount.error}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="text-center my-3">
                    <Button
                      color="primary"
                      onClick={this._onSubmitCounterOffer}
                    >
                      Submit Offer
                    </Button>
                  </div>
                </div>
              </Collapse>

              {loading && (
                <div className="table-overlay">
                  <div>
                    <i className="fa fa-spinner fa-spin" />
                  </div>
                </div>
              )}
            </Col>
            <Col>
              {closingDetails?._mainForwardAgent?.toString() ===
                this.props.userData.user.id.toString() && (
                <p className="text-center">
                  You have already shared your feedback. Please wait for
                  Client's response.
                </p>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(NewClosingAgent));
