import React from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselCaption,
} from 'reactstrap';

const items = [
    {
        header: 'All of your scheduled appointments in one place',
        caption:'Consolidate orders into one seamless wise solution with orders you receive across platforms.',
        // caption: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
    },
    {
        header: 'Accounting for all of your business needs',
        caption:'From check processing, payroll, expenses, to automated invoicing - We’ve got you covered.',
        // caption: ' The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ,'
    },
    {
        header: 'Auto track your metrics and grow',
        caption:'Let us create your business reports for you. Get data driven metrics to analyze and grow your practice.'
        // caption: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
    }
];

const PublicTextCarousel = ({ activeIndex, next, previous, goToIndex }) => {



    const slides = items.map((item) => {
        return (
            <CarouselItem key={item.src}>
                <CarouselCaption captionText={item.caption} captionHeader={item.header} />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            
        >
            {slides}
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        </Carousel>
    );
}

export default PublicTextCarousel;