import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { formatCurrencyValue } from "../../../helper-methods";
import { useSelector } from "react-redux";
import SkeletonLoading from "./Skeletons/SkeletonLoading";
import useScreenWidth from "./HelpModule/useScreenWidth";
import SvgIcons from "./SvgIcons";

const AgentCompanySummariesCard = ({ cardList, loading }) => {
  const userData = useSelector((state) => state?.userData);
  const { isForMobile } = useScreenWidth();

  return (
    <>
      <Row className={"mt-2"}>
        <Col xl={3} lg={userData?.type === "signingcompany" ? 0 : 6} xs={6}>
          {loading ? (
            <SkeletonLoading type="card" count={1} height={isForMobile ? 130 : 76} width={isForMobile ? 170 : 220} />
          ) : (
            <Card className="infoCard">
              <CardBody>
                <div className="infoContent">
                  <span>
                    {userData?.type === "agent" ? "Closing" : ""} Orders
                  </span>
                  <h4>{cardList.closings || 0}</h4>
                </div>
                <div className="icon">
                  {/* <img
                    src={require("../../../assets/img/TotalOrders.svg").default}
                    alt="notaryActs"
                  /> */}
                  <SvgIcons type={"TotalOrders"} />
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
        {(userData?.type === "agent" ||
          (!userData?.user?.isTitleCompany &&
            userData?.type === "signingcompany")) && (
            <Col xl={3} lg={userData?.type === "signingcompany" ? 0 : 6} xs={6}>
              {loading ? (
                <SkeletonLoading type="card" count={1} height={isForMobile ? 130 : 76} width={isForMobile ? 170 : 220} />
              ) : (
                <Card className="infoCard">
                  <CardBody>
                    <div className="infoContent">
                      <span>
                        {userData?.type === "agent" ? "Total" : ""} Income
                      </span>
                      <h4>
                        {cardList.incomes
                          ? formatCurrencyValue(cardList.incomes)
                          : "$0"}
                      </h4>
                    </div>
                    <div className="icon">
                      <img
                        src={require("../../../assets/img/Income.png")}
                        alt="notaryActs"
                      />
                      {/* <SvgIcons type={"TotalIncome"} /> */}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          )}
        {(userData?.type === "agent" ||
          (!userData?.user?.isTitleCompany &&
            userData?.type === "signingcompany")) && (
            <Col xl={3} lg={userData?.type === "signingcompany" ? 0 : 6} xs={6}>
              {loading ? (
                <SkeletonLoading type="card" count={1} height={isForMobile ? 130 : 76} width={isForMobile ? 170 : 220} />
              ) : (
                <Card className="infoCard">
                  <CardBody>
                    <div className="infoContent">
                      <span>
                        {userData?.type === "agent" ? "Total" : ""} Expenses
                      </span>
                      <h4>
                        {cardList.expenses
                          ? formatCurrencyValue(cardList.expenses)
                          : "$0"}
                      </h4>
                    </div>
                    <div className="icon">
                      {/* <img
                        src={require("../../../assets/img/Expenses.svg").default}
                        alt="notaryActs"
                      /> */}
                      <SvgIcons type={"Expenses"} />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          )}
        <Col xl={3} lg={userData?.type === "signingcompany" ? 0 : 6} xs={6}>
          {loading ? (
            <SkeletonLoading type="card" count={1} height={isForMobile ? 130 : 76} width={isForMobile ? 170 : 220} />
          ) : (
            <Card className="infoCard">
              <CardBody>
                <div className="infoContent">
                  <span>Net {userData?.type === "agent" ? "Profit" : ""}</span>
                  <h4
                    className={
                      cardList.net <= 0 ? "text-danger" : "text-success"
                    }
                  >
                    {cardList.net ? formatCurrencyValue(cardList.net) : "$0"}
                  </h4>
                </div>
                <div className="icon">
                  {/* <img
                    src={require("../../../assets/img/TotalIncome.svg").default}
                    alt="notaryActs"
                  /> */}
                  <SvgIcons type={"TotalIncome"} />
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AgentCompanySummariesCard;
