import React, { lazy, Suspense } from "react";
import {
  Input,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import {
  deepClone,
  showToast,
  enableTimeBetweenStartTimeAndEndTime,
  formatOnlyDateMoment,
  convertIsoToUtc,
  refreshFunc,
  structureQueryParams,
  deleteLocalStorageData,
  errorHandler,
} from "../../../helper-methods";
import { connect } from "react-redux";
import {
  getAllAgentClosing,
  updateClosingDetailsById,
  getAgentDraftClosings,
} from "../../../http/http-calls";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ScreenWidthHOC from "./ScreenWidthHOC";
import SvgIcons from "../components/SvgIcons";
import {
  agentClosingCardBodyKeys,
  agentClosingCardHeaderKeys,
  agentClosingHeaderKeys,
  draftClosingHeaderKeys,
} from "../../../config/agentConfig";
import AgentDashboardFilter from "../components/AgentDashboardFilter";
import AgentDashboardCardDataFormat from "../components/AgentDashboardCardDataFormat";
import AgentDashboardDataFormat from "../components/AgentDashboardDataFormat";
import AgentDashboardHeader from "../components/AgentDashboardHeader";

// Code Spliting imports
const CustomTable = lazy(() => import("../components/CustomTable"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const AgentDashboardimportModal = lazy(() =>
  import("../components/AgentCompanyDashboardimportModal")
);
const SignerAvailabilityAgentModal = lazy(() =>
  import("../components/Modals/signer-availability-for-agents-modal")
);
const SigningStatusModal = lazy(() => import("../components/signing-status"));

class AgentDashboard extends React.Component {
  state = {
    agentClosingList: [],
    draftClosingsList: [],
    draftClosingTotalCount: 0,
    agentClosingTotalCount: 0,
    agentClosingHeaderKeys: agentClosingHeaderKeys,
    agentClosingCardHeaderKeys: agentClosingCardHeaderKeys,
    agentClosingCardBodyKeys: agentClosingCardBodyKeys,
    draftClosingHeaderKeys: draftClosingHeaderKeys,
    signingStatusModal: {
      isOpen: false,
      data: null,
      status: null,
    },
    signerAvailabilityModal: {
      isOpen: false,
      data: null,
    },
    importModal: {
      isOpen: false,
      data: null,
    },
    filters: {
      status: "",
      appointmentDate: "",
      search: "",
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    draftTableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    dateRangeValue: null,
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    loanType: [],

    isClassAdded: false,
    isOpen: false,
    role: "",
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    this.searchTimer = null;
    this._setFilterDataFromLocalStorage();

    let path = this.props.location.search?.split("/")[1];
    if (path) {
      // if coming from onesignal notification or notification open signer availabilty modal for appointment conformation
      this._onToggleSignerAvailabilityModal(true, { id: path });
    }

    // subscribe events
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("agent-dashboard");
    });
    HeaderEventEmitter.subscribe("create-closing", () => {
      this._redirectToCreateClosing();
    });

    HeaderEventEmitter.subscribe("import-modal", () => {
      this._onToggleImportModal(true); // open import modal for draft closings
    });

    document.querySelector("#scroll").scrollTo(0, 0);
  };

  _getAllAgentClosing = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

    if (filters.status && filters.status.length) {
      Object.assign(tableConfig, { status: filters.status });
    } else {
      delete tableConfig.status;
    }

    if (filters.search && filters.search.trim().length) {
      Object.assign(tableConfig, { search: filters.search.trim() });
    } else {
      delete tableConfig.search;
    }

    if (
      dateRangeValue !== null &&
      dateRangeValue[0]?.getDate() === dateRangeValue[1]?.getDate()
    )
      Object.assign(tableConfig, { isReverse: true });

    delete tableConfig.pageNumber;

    let tableConfigData = structureQueryParams(tableConfig, "&");

    let params = `appointmentDate[start]=${
      dateRangeValue !== null
        ? formatOnlyDateMoment(dateRangeValue[0])
        : undefined
    }&appointmentDate[end]=${
      dateRangeValue !== null
        ? formatOnlyDateMoment(dateRangeValue[1])
        : undefined
    }${tableConfigData}`;

    getAllAgentClosing(params)
      .then((res) => {
        this.setState(
          {
            agentClosingList: res?.closings || [],
            agentClosingTotalCount: res?.totalCount || 0,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllAgentClosing();
    });
    // document.querySelector(".content").scrollIntoView();
  };

  // function to open signing status modal for updating closing status
  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  // function for signer availabilty modal for appointment confirmation
  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  // function for opening draft closings
  _onToggleImportModal = (isOpen = false, data = null) => {
    this.setState({
      importModal: {
        isOpen,
        data,
      },
    });
  };

  _filterOnChange = (type, value, isDelay = false) => {
    // throw new Error("Invalid");
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = deepClone(this.state);

    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
          this._persistFilter();
        }, 1000);
      } else {
        this._paginate();
        this._persistFilter();
      }
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          status: "",
          appointmentDate: "",
          search: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._paginate();
      }
    );
    refreshFunc("agent-dashboard-responsive");

    deleteLocalStorageData("agentClosingdashboard");
  };

  _redirectToClosingDetails = (data) => {
    const { userData } = this.props;
    this.props.history.push(
      `/${userData.type}/closing-details-for-agents/${data.id}`
    );
  };

  _redirectToCreateClosing = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/create-closing`);
  };

  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateClosingDetailsByIdStatus = async (data, status) => {
    // Update status closing Status
    try {
      if (
        status === "Closed" ||
        status === "DNC" ||
        status === "CCA" ||
        status === "Cancelled"
      ) {
        //  status is "Closed","DNC","CCA", "Cancelled" Signing Status Modal will open
        this._onToggleSigningStatusModal(true, data, status);
      } else {
        this._manageLoading("statusChangeLoading", true);

        const updateAgentRes = await this._updateClosingDetailsById(data.id, {
          status,
        });

        showToast("Status Updated Successfully", "success");
        const { agentClosingList } = deepClone(this.state);

        if (updateAgentRes.closing.status === "CCA") {
          this._getAllAgentClosing();
        } else {
          const closing = agentClosingList.find(
            (closing) => closing.id === data.id
          );
          closing["status"] = updateAgentRes.closing.status;
          this.setState({ agentClosingList });
        }
        this._manageLoading("statusChangeLoading", false);
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _dataFormat = (cell, row, header) => {
    return (
      <AgentDashboardDataFormat
        header={header}
        row={row}
        cell={cell}
        _onToggleSignerAvailabilityModal={this._onToggleSignerAvailabilityModal}
        _redirectToClosingDetails={this._redirectToClosingDetails}
        loading={this.state.loading}
        _updateClosingDetailsByIdStatus={this._updateClosingDetailsByIdStatus}
        _isDisabledCheckStatusDropdown={this._isDisabledCheckStatusDropdown}
      />
    );
  };

  _isDisabledCheckStatusDropdown = (itemData) => {
    // For arrived Status the Option will enable on same day
    let result = enableTimeBetweenStartTimeAndEndTime(
      itemData.appointmentDate,
      itemData.closingAddress?.timeZone
    );
    return !result;
  };

  _rowClassName = (row, rowIdx) => {
    // "Closed", "DNC", "Cancelled", "CCA" row color changes if this status for closing
    const statusEnum = ["Closed", "DNC", "Cancelled", "CCA"];
    if (row?.status && statusEnum.includes(row.status)) {
      return "rowStatus";
    }
    return "";
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _persistFilter = () => {
    const { filters, dateRangeValue, tableConfig } = this.state;
    if (
      (filters &&
        (filters.status || (filters.search && filters.search.length))) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      localStorage.agentClosingdashboard = JSON.stringify(data);
    } else {
      delete localStorage.agentClosingdashboard;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.agentClosingdashboard) {
      try {
        const filter = JSON.parse(localStorage.agentClosingdashboard);
        if (filter.dateRangeValue !== null) {
          let dateRange = [
            new Date(convertIsoToUtc(filter?.dateRangeValue[0])),
            new Date(convertIsoToUtc(filter?.dateRangeValue[1])),
          ];
          this.setState({ dateRangeValue: dateRange }, () => {
            this._getAllAgentClosing();
          });
        }
        this.setState(
          { filters: filter.filters, tableConfig: filter?.tableConfig },
          () => {
            this._getAllAgentClosing();
          }
        );
      } catch (e) {
        this._getAllAgentClosing();
      }
    } else {
      this._getAllAgentClosing();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.states) delete filters.agent;
    if (!filters.status) delete filters.status;
    // if(dateRangeValue===null) delete dateRangeValue;

    return filters;
  };

  _cardHeaderFormat = (cell, row, header) => {
    return (
      <AgentDashboardHeader
        header={header}
        row={row}
        cell={cell}
        _onToggleSignerAvailabilityModal={this._onToggleSignerAvailabilityModal}
      />
    );
  };
  _cardDataFormat = (row) => {
    return (
      <AgentDashboardCardDataFormat
        row={row}
        _redirectToClosingDetails={this._redirectToClosingDetails}
        loading={this.state.loading}
        _updateClosingDetailsByIdStatus={this._updateClosingDetailsByIdStatus}
        _isDisabledCheckStatusDropdown={this._isDisabledCheckStatusDropdown}
      />
    );
  };
  _getAgentDraftClosings = () => {
    const { draftTableConfig } = this.state;

    let payload = {
      skip: draftTableConfig.skip,
      limit: draftTableConfig.limit,
    };
    getAgentDraftClosings(payload)
      .then((res) => {
        this.setState({ draftClosingsList: res?.closings || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _paginateDraft = (pageNumber = 1, pageSize = 10) => {
    const { draftTableConfig } = this.state;
    draftTableConfig.skip = (pageNumber - 1) * pageSize;
    draftTableConfig.limit = pageSize;
    draftTableConfig["pageNumber"] = pageNumber;
    this.setState({ draftTableConfig }, () => {
      // this._getAllAgentClosing();
      this._getAgentDraftClosings();
    });
    // document.querySelector(".content").scrollIntoView();
  };

  render() {
    const {
      filters,
      agentClosingList,
      agentClosingTotalCount,
      agentClosingHeaderKeys,
      agentClosingCardHeaderKeys,
      loading,
      signerAvailabilityModal,
      signingStatusModal,
      tableConfig,
      dateRangeValue,
      isClassAdded,
      agentClosingCardBodyKeys,
      // isOpen,
      // role,
      importModal,
      draftClosingsList,
      draftTableConfig,
      draftClosingHeaderKeys,
      draftClosingTotalCount,
    } = this.state;

    const { userData, isForMobile } = this.props;

    return (
      <div id="scroll">
        <div className="content">
          <div className="responsiveTitle">
            <h2>Order Dashboard</h2>
            <div className="rightSide">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="agent-dashboard-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => this._onToggleImportModal(true)}
              >
                Import
              </Button>
              <Button
                className="floatingButton"
                color="primary"
                onClick={this._redirectToCreateClosing}
              >
                + Create
              </Button>
            </div>
          </div>
          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="search"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
                placeholder="Search Signer, File"
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <SvgIcons type={"filterIcon"} />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>

            <AgentDashboardFilter
              filters={filters}
              dateRangeValue={dateRangeValue}
              _filterOnChange={this._filterOnChange}
              _onChangeDatePicker={this._onChangeDatePicker}
            />
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>
          <Suspense fallback={<></>}>
            {isForMobile ? (
              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={agentClosingList}
                  headerKeys={agentClosingCardHeaderKeys}
                  bodyKeys={agentClosingCardBodyKeys}
                  dataFormat={this._dataFormat}
                  totalCount={agentClosingTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowClassName={(row, rowIdx) =>
                    this._rowClassName(row, rowIdx)
                  }
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </div>
            ) : (
              <div className="hideMobile">
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={agentClosingList}
                  headerKeys={agentClosingHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={agentClosingTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowClassName={(row, rowIdx) =>
                    this._rowClassName(row, rowIdx)
                  }
                  showTableLoading={loading.showTableLoading}
                />
              </div>
            )}
          </Suspense>

          <Suspense fallback={<></>}>
            {signingStatusModal.isOpen && (
              <SigningStatusModal
                isOpen={signingStatusModal.isOpen}
                data={signingStatusModal.data}
                status={signingStatusModal.status}
                userData={userData}
                updateClosingDetailsById={this._updateClosingDetailsById}
                resetDetails={() => this._getAllAgentClosing()}
                toggle={() => this._onToggleSigningStatusModal()}
              />
            )}

            {signerAvailabilityModal.isOpen && (
              // Signer availability modal for confirm appointment
              <SignerAvailabilityAgentModal
                isOpen={signerAvailabilityModal.isOpen}
                data={signerAvailabilityModal.data}
                updateClosingDetailsById={this._updateClosingDetailsById}
                resetDetails={() => this._getAllAgentClosing()}
                toggle={() => this._onToggleSignerAvailabilityModal()}
              />
            )}

            {/* this modal shows the list of draft closings */}
            <AgentDashboardimportModal
              userType="agent"
              isOpen={importModal.isOpen}
              draftClosingsList={draftClosingsList}
              draftTableConfig={draftTableConfig}
              draftClosingHeaderKeys={draftClosingHeaderKeys}
              draftClosingTotalCount={draftClosingTotalCount}
              toggle={() => this._onToggleImportModal()}
              resetData={() => this._getAgentDraftClosings()}
              paginateDraft={(pageNumber, pageSize) =>
                this._paginateDraft(pageNumber, pageSize)
              }
            />
          </Suspense>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(ScreenWidthHOC(AgentDashboard));
