export const TRACKING_URL = {
  USPS: "https://tools.usps.com/go/TrackConfirmAction_input",
  UPS: "https://www.ups.com/track?loc=en_US&requester=ST/",
  FedEx: "https://www.fedex.com/en-us/tracking.html",
  DHL: "https://www.mydhli.com/global-en/home/tracking.html",
};

export const openTrackingDetails = (shippingCompany, trackingInfo) => {
  console.log("shippingCompany, trackingInfo", shippingCompany, trackingInfo);
    if(!trackingInfo){
      trackingInfo = ""
    }
  
    if (shippingCompany === "FedEx") {
      window.open(`https://www.fedex.com/fedextrack/?trknbr=${trackingInfo}`);
      // redirectUrl = `${process.env.FEDEX_BASE_URL}/fedextrack/?trknbr=${trackingInfo}`
    } else if (shippingCompany === "DHL") {
      window.open(
        `https://www.mydhli.com/global-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingInfo}`
      );
      // redirectUrl = `${process.env.DHL_BASE_URL}/global-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingInfo}`
    } else if (shippingCompany === "UPS") {
      window.open(
        `https://www.ups.com/track?tracknum=${trackingInfo}&loc=en_US&requester=ST/`
      );
      // redirectUrl = `${process.env.UPS_BASE_URL}/track?tracknum=${trackingInfo}&loc=en_US&requester=ST/`
    } else if (shippingCompany === "USPS") {
      window.open(
        `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingInfo}%2C&tABt=true`
      );
      // redirectUrl = `${process.env.USPS_BASE_URL}/go/TrackConfirmAction?tLabels=${trackingInfo}`
    }
  
 
};
