import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo,
} from "react";
import { FormGroup, Label } from "reactstrap";
import ReactDatetime from "react-datetime";
import momenttz from "moment-timezone";
import "react-datetime/css/react-datetime.css";
import {
  formatDate,
  formatDateAndTime,
  // formatDateAndTimeAsPerTimeZone,
  formatDateMoment,
  // formatTimeAsPerTimeZone,
  // isMMDDYYYY,
  isMMDDYYYYhhmmAMPM,
} from "../../../helper-methods";

const DefaultAppointmentSchedulerConfig = {
  regular: { disable: false, show: true },
  dateRange: { disable: false, show: true },
  tbd: { disable: false, show: true },
};

const AppointmentScheduler = forwardRef(
  (
    {
      data,
      AppointmentSchedulerConfig = DefaultAppointmentSchedulerConfig,
      timezone = "America/New_York",
    },
    ref
  ) => {
    const [viewType, setViewType] = useState("regular"); // "regular", "dateRange", "tbd"
    const [isOpenReactDatetime, setIsOpenReactDatetime] = useState(false);
    const [validateTrigger, setValidateTrigger] = useState(false);
    const isMounted = useRef(false); // Ref to track mounting

    const [formFields, setFormFields] = useState({
      appointmentDate: { value: null, error: null },
      endAppointmentDate: { value: null, error: null },
    });

    const handleRadioChange = (event) => {
      setViewType(event?.target?.value);
    };

    const onChangeFormField = (field, value) => {
      setFormFields((prevFields) => ({
        ...prevFields,
        [field]: { ...prevFields[field], value },
      }));
      setValidateTrigger((prev) => !prev);
    };

    /**
     * This function validates the appointment dates based on the current viewType and the form fields.
     * It returns an object with any errors found. If no errors are found, an empty object is returned.
     *
     * @returns {Object} An object with any errors found. If no errors are found, an empty object is returned.
     */
    const validateDates = () => {
      // Initialize an empty object to store any errors found
      let errors = {};

      // If the viewType is "regular", check if the appointment date is valid
      if (viewType === "regular") {
        const appointmentDate = formFields.appointmentDate.value;
        console.log("appointmentDate >>", appointmentDate);
        if (!appointmentDate) {
          // If the appointment date is invalid, add an error to the object
          errors.appointmentDate = "*Required";
        } else {
          // If the appointment date is valid, remove any existing error
          delete errors.appointmentDate;
        }
      }
      // If the viewType is "dateRange", check if the start and end dates are valid
      else if (viewType === "dateRange") {
        const appointmentDate = formFields.appointmentDate.value;
        const endAppointmentDate = formFields.endAppointmentDate.value;

        if (!appointmentDate) {
          // If the start date is invalid, add an error to the object
          errors.appointmentDate = "Start date is required.";
        }
        if (!endAppointmentDate) {
          // If the end date is invalid, add an error to the object
          errors.endAppointmentDate = "End date is required.";
        }
        if (
          appointmentDate &&
          endAppointmentDate &&
          new Date(appointmentDate) > new Date(endAppointmentDate)
        ) {
          // If the start date is after the end date, add errors to the object
          errors.appointmentDate = "Start date must be before end date.";
          errors.endAppointmentDate = "End date must be after start date.";
        }
      }
      // If the viewType is "tbd", check if the appointment date is valid
      else if (viewType === "tbd") {
        const appointmentDate = formFields.appointmentDate.value;
        if (!appointmentDate) {
          // If the appointment date is invalid, add an error to the object
          errors.appointmentDate = "TBT date is required.";
        }
      }

      // Update the form fields with any errors found
      setFormFields((prevFields) => ({
        ...prevFields,
        appointmentDate: {
          ...prevFields.appointmentDate,
          error: errors.appointmentDate || null,
        },
        endAppointmentDate: {
          ...prevFields.endAppointmentDate,
          error: errors.endAppointmentDate || null,
        },
      }));

      // Return the object with any errors found
      return errors;
    };

    /**
     * This function creates a payload object that represents the appointment data
     * based on the current viewType and the form fields. It returns a promise that
     * resolves to the payload object.
     *
     * @returns {Promise<object>} A promise that resolves to the payload object.
     */
    const createPayload = () => {
      return new Promise((resolve) => {
        // Initialize the payload object with default values
        let payload = {
          isRangeDated: false, // Indicates if the appointment is a date range
          tBD: false, // Indicates if the appointment is a TBD appointment
          endAppointmentDate: null, // The end date of the appointment range
          appointmentDate: null, // The start date of the appointment range
        };

        // Determine the appropriate fields to set based on the viewType
        switch (viewType) {
          case "regular": // Regular appointment
            payload.appointmentDate = formatDateMoment(
              formFields.appointmentDate.value
            );
            delete payload.endAppointmentDate; // Remove the end appointment date field
            break;
          case "dateRange": // Appointment with a date range
            payload.isRangeDated = true; // Set the isRangeDated field to true
            payload.appointmentDate = formatDateMoment(
              formFields.appointmentDate.value
            );
            payload.endAppointmentDate = formatDateMoment(
              formFields.endAppointmentDate.value
            );
            break;
          case "tbd": // Appointment with a TBD date
            payload.tBD = true; // Set the tBD field to true
            payload.appointmentDate = formatDateMoment(
              formFields.appointmentDate.value
            );
            delete payload.endAppointmentDate; // Remove the end appointment date field
            break;
          default:
            break;
        }

        // Resolve the promise with the payload object
        resolve(payload);
      });
    };

    /**
     * Asynchronously retrieves the appointment scheduler data.
     * This function first calls the validateDates function to check if the dates are valid.
     * If the dates are not valid, it returns an object with isValid set to false and the appointment scheduler data.
     * If the dates are valid, it calls the createPayload function to create the payload object.
     * It then checks if there are any errors in the payload. If there are errors, it returns an object with isValid set to false and the appointment scheduler data.
     * If there are no errors, it returns an object with isValid set to true and the appointment scheduler data.
     *
     * @return {Promise<Object>} An object with isValid and appointmentSchedulerData properties.
     */
    const getAppointmentSchedulerData = async () => {
      // Check if the dates are valid
      const errors = validateDates();

      // If the dates are valid, create the payload object
      const res = await createPayload();

      // If there are errors, return an object with isValid set to false and the appointment scheduler data
      if (errors && Object.keys(errors).length) {
        return { isValid: false, appointmentSchedulerData: res };
      }

      // Return an object with isValid set to true and the appointment scheduler data
      return { isValid: true, appointmentSchedulerData: res };
    };

    /**
     * This function handles incoming data and sets the form fields
     * based on the type of appointment (TBD, date range, regular).
     *
     * @param {Object} data - The incoming data object. It should contain the following properties:
     *                        - tBD: A boolean indicating whether the appointment is TBD or not.
     *                        - appointmentDate: The date of the appointment. It can be a date string or a moment object.
     * @return {void} This function does not return anything.
     */
    const handleIncomingData = ({ tBD, appointmentDate }) => {
      // console.log(
      //   "isMMDDYYYYhhmmAMPM(appointmentDate) >>",
      //   isMMDDYYYYhhmmAMPM(appointmentDate)
      // );
      if (appointmentDate === null) {
        return;
      }
      // Set the view type based on the appointment type
      // If the appointment is TBD, set the view type to 'tbd'.
      // Otherwise, set the view type to 'regular'.
      setViewType(tBD ? "tbd" : "regular");

      // console.log("isMMDDYYYYhhmmAMPM(appointmentDate) >>", appointmentDate);

      // Update the appointmentDate form field with the new value
      // We use the spread operator to create a new object and keep the existing form fields intact
      // We then update the appointmentDate field with the new value and set the error to null
      setFormFields((prev) => ({
        ...prev,
        // appointmentDate: { value: appointmentDate, error: null },
        appointmentDate: {
          value: isMMDDYYYYhhmmAMPM(appointmentDate.toLowerCase())
            ? appointmentDate
            : momenttz.tz(appointmentDate, timezone),
          error: null,
        },
      }));
    };

    useImperativeHandle(ref, () => ({
      getAppointmentSchedulerData,
    }));

    useEffect(() => {
      if (isMounted?.current) {
        validateDates();
      } else {
        isMounted.current = true;
      }
      // eslint-disable-next-line
    }, [validateTrigger]);

    useEffect(() => {
      handleIncomingData(data);
      // eslint-disable-next-line
    }, [data]);

    console.log("timezone", timezone);

    return (
      <div>
        <div className="radio-group">
          {AppointmentSchedulerConfig.regular.show && (
            <label className="mr-3">
              <input
                type="radio"
                value="regular"
                className="mr-1"
                checked={viewType === "regular"}
                onChange={handleRadioChange}
                disabled={AppointmentSchedulerConfig.regular.disable}
              />
              Regular
            </label>
          )}
          {/* {AppointmentSchedulerConfig.dateRange.show && (
            <label className="mr-3">
              <input
                type="radio"
                value="dateRange"
                className="mr-1"
                checked={viewType === "dateRange"}
                onChange={handleRadioChange}
                disabled={AppointmentSchedulerConfig.dateRange.disable}
              />
              Date Range
            </label>
          )} */}
          {AppointmentSchedulerConfig.tbd.show && (
            <label>
              <input
                type="radio"
                value="tbd"
                className="mr-1"
                checked={viewType === "tbd"}
                onChange={handleRadioChange}
                disabled={AppointmentSchedulerConfig.tbd.disable}
              />
              TBD
            </label>
          )}
        </div>
        {AppointmentSchedulerConfig.regular.show && viewType === "regular" && (
          <FormGroup
            className={`floatingLabel ${
              formFields.appointmentDate.value || isOpenReactDatetime
                ? "valueAdded"
                : ""
            }`}
          >
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: " ",
                value: formatDateAndTime(formFields.appointmentDate.value),
              }}
              // initialValue={formFields.appointmentDate.value}
              value={formFields.appointmentDate.value}
              onChange={(e) => onChangeFormField("appointmentDate", e)}
              onOpen={() => setIsOpenReactDatetime(true)}
              onClose={() => setIsOpenReactDatetime(false)}
              timeFormat={true}
              timeConstraints={{ minutes: { step: 15 } }}
            />
            <Label>Appointment Date & Time</Label>
            {formFields.appointmentDate.error && (
              <div className="validation-error">
                {formFields.appointmentDate.error}
              </div>
            )}
          </FormGroup>
        )}

        {AppointmentSchedulerConfig.tbd.show && viewType === "tbd" && (
          <FormGroup
            className={`floatingLabel ${
              formFields.appointmentDate.value || isOpenReactDatetime
                ? "valueAdded"
                : ""
            }`}
          >
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: " ",
                value: formatDate(formFields.appointmentDate.value),
              }}
              // initialValue={formFields.appointmentDate.value}
              value={formFields.appointmentDate.value}
              onChange={(e) => onChangeFormField("appointmentDate", e)}
              onOpen={() => setIsOpenReactDatetime(true)}
              onClose={() => setIsOpenReactDatetime(false)}
              timeFormat={false}
              timeConstraints={{ minutes: { step: 15 } }}
            />
            <Label>TBD Date</Label>
            {formFields.appointmentDate.error && (
              <div className="validation-error">
                {formFields.appointmentDate.error}
              </div>
            )}
          </FormGroup>
        )}
      </div>
    );
  }
);

export default memo(AppointmentScheduler);
