import React, { useState } from "react";
import { NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";
import { errorHandler } from "../../../helper-methods";
import { useSelector } from "react-redux";

import UserProfile from "./user-profile";
import AchSettings from "./ach-settings";
import CompanyProfileDetail from "../components/company-profile-details";
import WhiteLabelUI from "./Company/WhiteLabelUI";
import { useHistory } from "react-router";
import SvgIcons from "../components/SvgIcons";

export default function CompanyProfile() {
  const userData = useSelector((state) => state?.userData);
  const history = useHistory();

  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);

  const _toggleTab = async (tab) => {
    if (!loading && tab !== activeTab) {
      try {
        setLoading(true);
        setActiveTab(tab);
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
        setActiveTab("");
      }
    }
  };

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>Company Profile</h2>
        </div>

        {userData?.user.isAssistant ? (
          <UserProfile />
        ) : (
          <>
            <div className="verticalTabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      _toggleTab("1");
                    }}
                  >
                    <SvgIcons type={"basicDetails"} />
                    Basic Details
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      _toggleTab("2");
                    }}
                  >
                    <SvgIcons type={"bankInfo"} />
                    Bank Info
                  </NavLink>
                </NavItem>
                {userData.user?.whiteLabelRequestStatus === "Done" && (
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        _toggleTab("3");
                      }}
                    >
                      <SvgIcons type={"setting"} />
                      <div>
                        White Label Setting
                        <small className="d-block">UI Selection</small>
                      </div>
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={activeTab}>
                {/* basic detail page */}
                <TabPane tabId="1">
                  <CompanyProfileDetail activeTab={activeTab} />
                </TabPane>

                <TabPane tabId="2">
                  <AchSettings history={history} activeTab={activeTab} />
                </TabPane>
                <TabPane tabId="3">
                  <WhiteLabelUI />
                </TabPane>
              </TabContent>
            </div>
          </>
        )}
      </div>
    </>
  );
}
