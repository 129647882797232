import {
  ADD_USER_DATA,
  CLEAR_USER_DATA,
  UPDATE_ASSISTANT_OBJ,
  UPDATE_USER_OBJ,
} from "../actions";

const initialState = {
  type: null,
  handle: null,
  token: null,
  user: null,
};

export const userDataReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ADD_USER_DATA: {
      newState = action.payload.userData;
      break;
    }
    case UPDATE_USER_OBJ: {
      newState = {
        ...newState,
        user: {
          ...newState.user,
          ...action.payload.user,
        },
      };
      break;
    }
    case UPDATE_ASSISTANT_OBJ: {
      if (newState?.user?._assistant && action?.payload?.assistant) {
        newState.user = {
          ...newState.user,
          permissionSettings: {
            ...newState.user.permissionSettings,
            ...action.payload.assistant.permissionSettings,
          },
          _assistant: {
            ...newState.user._assistant,
            ...action.payload.assistant,
          },
        };
      }
      break;
    }
    case CLEAR_USER_DATA: {
      newState = {};
      break;
    }
    default: {
    }
  }
  return newState;
};
