import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  Spinner,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { findFaqTopicById } from "../../../../http/http-calls";
import { errorHandler } from "../../../../helper-methods";
import { HeaderEventEmitter } from "../../../../helper-methods/HeaderEvents";

const TopicDetails = () => {
  const history = useHistory();

  const { id } = useParams();

  const [topicData, setTopicData] = useState(null);
  const [loading, setLoading] = useState(false);

  const _findFaqTopicById = async () => {
    try {
      setLoading(true);
      let response = await findFaqTopicById(id);
      setTopicData(response?.faqTopic || {});
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };
  const _goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    _findFaqTopicById();
    HeaderEventEmitter.subscribe("back-button", _goBack);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <div className="d-flex align-items-center">
            <Button
              color="link"
              className="backBtn"
              onClick={() => history.goBack()}
            >
              <img
                src={require("../../../../assets/img/arrowLeft.svg").default}
                alt="backbutton"
                height={14}
              />
            </Button>
            <h2>Topic Details</h2>
          </div>
        </div>

        <Card>
          {topicData ? (
            <>
              <CardHeader>
                <CardTitle>{topicData?.topicName}</CardTitle>
              </CardHeader>
              <CardBody className="topicDetailsWrap">
                {topicData?.imageFiles?.map((each) => (
                  <img
                    key={each.url}
                    src={each.url}
                    loading="lazy"
                    alt="Category Img"
                    className="topicImg"
                  />
                ))}

                {topicData?.videoFiles?.map(
                  (each, index) =>
                    each.url && (
                      <div key={each.url}>
                        <iframe
                          src={each.url}
                          title={`youtube_video_title_${each.title}_${index}`}
                          className="topicVideo"
                          controls
                        />
                      </div>
                    )
                )}

                {topicData?.content && (
                  <div
                    className="customWysiwyg"
                    dangerouslySetInnerHTML={{ __html: topicData?.content }}
                  />
                )}
              </CardBody>
            </>
          ) : (
            <CardBody className="topicDetailsWrap">
              {loading ? (
                <Spinner />
              ) : (
                <div className="nodata">
                  <img
                    src={require("../../../../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              )}
            </CardBody>
          )}
        </Card>
      </div>
    </>
  );
};

export default TopicDetails;
