import React from "react";
import { Button } from "reactstrap";

const RenderInput = ({ label, openCalendar }) => {
  return (
    <Button color="link" onClick={() => openCalendar()}>
      <span>{label}</span> <img src={require('../../../../assets/img/arrowRight.svg').default} alt="arrow right"/>
    </Button>
  );
};

export default RenderInput;
