import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

class FeatureUnavailableModal extends Component {
  _closeModal = () => {
    this.props.toggle();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        centered
        scrollable
      >
        <ModalHeader toggle={() => this._closeModal()}></ModalHeader>
        <ModalBody>
          <p className="text-center mb-0">
            {/* This feature is currently unavailable. Please book with the agent
            directly until your booking portal is complete. Thank you! */}
            Feature not set up by Notary Currently
          </p>
        </ModalBody>
      </Modal>
    );
  }
}

export default FeatureUnavailableModal;
