import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const SystemGeneratedPasswordModal = ({ isOpen, toggle }) => {
  const history = useHistory();

  // get userData from redux
  const userData = useSelector((state) => state.userData);

  const goToProfilePage = () => {
    history.push("/client/profile");
    toggle();
  };

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>Warning</ModalHeader>
      <ModalBody className="modalContent">
        <p className="text-center">
          Hello{" "}
          {userData?.user?.isAssistant
            ? userData?.user?._assistant?.name?.full
            : userData?.user?.name?.full || ""}
          ,
          <br />
          <br />
          You are currently using a system-generated password to log in. You can
          change your password through the profile page or by using the 'Forgot
          Password' option.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={goToProfilePage}>
          Go to Profile Page
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SystemGeneratedPasswordModal;
