import React from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap";
import SvgIcons from "../../private/components/SvgIcons";


const PublicConfirmation = () => {


    return (
        <>
            <Row className="justify-content-center align-items-center p-4" style={{ height: '100vh' }}>
                <Col xl={4} lg={6} md={6}>

                    <Card className="confirmationWrap">
                        <CardBody>
                            <div className="checkIcon">
                                <SvgIcons type={"check"} />
                            </div>
                            <h1>Congratulations!</h1>
                            <p>
                                Your payment has been initiated. Please check your email to receive the payment receipt.
                            </p>
                        </CardBody>
                    </Card>


                </Col>
            </Row>
        </>
    );
};

export default PublicConfirmation;
