import { capitalize } from "@material-ui/core";
import React, { Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import {
  checkPermission,
  deepClone,
  errorHandler,
  isProPlusUser,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  deleteAgentClientById,
  getAgentClientTagsList,
  getAllAgentClients,
  updateAgentClient,
} from "../../../http/http-calls";
import ScreenWidthHOC from "./ScreenWidthHOC";
import Swal from "sweetalert2";
import { clientsHeaderKeys } from "../../../config/stateConfig";
import ClientAgentFilters from "../components/ClientAgentFilters";
import ClientAgentDataFormat from "../components/ClientAgentDataFormat";
import ClientAgentCardDataFormat from "../components/ClientAgentCardDataFormat";
import UpgradeProfessionalAccountModal from "../components/Modals/upgradeProfessionalAccountModal";

const AgentBulkActivityModal = lazy(() =>
  import("../components/agent-bulk-activity-modal")
);
const AddClientAgentModal = lazy(() =>
  import("../components/Modals/add-client-modal-agent")
);
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));

class Clients extends React.Component {
  state = {
    clientsList: [],
    tagsList: [],
    clientsTotalCount: 0,
    clientsHeaderKeys: clientsHeaderKeys,
    clientsCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    addClientModalData: {
      isOpen: false,
      data: null,
    },
    bulkActivityModal: {
      isOpen: false,
      data: null,
      type: "",
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      clientDeleteLoading: false,
    },
    filters: {
      isActive: "",
      tags: [],
      search: "",
    },
    clientsSelectedRows: [],
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    isClassAdded: false,
    upgradeProAccountModal: {
      isOpen: false,
      data: null,
    },
  };

  // manage loading
  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  // selected rows is getting as props to this function
  _setSelectedRows = (clientsSelectedRows = []) => {
    this.setState({
      clientsSelectedRows,
    });
  };

  // filter show on mobile view
  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  // toggle add/edit  client modal
  _toggleAddClientModal = (isOpen = false, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
      },
    });
  };
  // open bulk activity modal after selecting client
  _toggleBulkActivityModal = (isOpen = false, type = "", data = null) => {
    const { clientsSelectedRows } = deepClone(this.state);

    if (!clientsSelectedRows?.length) {
      errorHandler({ reason: "Please select at least one client" });
      return;
    }

    this.setState({
      bulkActivityModal: {
        isOpen,
        type,
        data,
      },
    });
  };

  // handle filter onchange function
  _filterOnChange = (type, value) => {
    const { filters } = this.state;

    filters[type] = value;
    this.setState({ filters }, () => {
      this._paginate();
    });
  };

  // function handles changing on search
  _searchOnChange = (searchTerm) => {
    clearTimeout(this.searchTimer);

    const { filters } = this.state;
    filters["search"] = searchTerm;

    this.setState({ filters });

    this.searchTimer = setTimeout(() => {
      this._paginate();
    }, 1000);
  };

  //reset table
  _resetTable = () => {
    this._getAllAgentClients();
    this._getAllTagsList();
  };
  // get all agent client

  _getAllAgentClients = async () => {
    try {
      this._manageLoading("showTableLoading", true);

      const { tableConfig, filters } = this.state;

      let data = {
        skip: tableConfig.skip,
        limit: tableConfig.limit,
      };

      if (String(filters?.isActive)?.length) {
        data["isActive"] = filters?.isActive;
      }
      if (filters?.tags?.length) {
        data["tags"] = JSON.stringify([
          ...filters?.tags?.map((each) => each?.value),
        ]);
      }
      if (filters?.search?.length) {
        data["search"] = filters?.search || "";
      }

      const res = await getAllAgentClients(data);

      this._manageLoading("showTableLoading", false);
      this.setState({
        clientsList: res.clients,
        clientsTotalCount: res.totalCount,
      });
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  // get all tag list
  _getAllTagsList = async () => {
    try {
      const res = await getAgentClientTagsList();

      const tagsListOptions =
        res?.tags?.map((each) => ({
          label: each,
          value: each,
        })) || [];

      this.setState({ tagsList: tagsListOptions });
    } catch (err) {
      errorHandler(err);
    }
  };

  componentDidMount = () => {
    // this._getAllAgentClients();
    this._setFilterDataFromLocalStorage(); // function to set data from local storage to state
    HeaderEventEmitter.subscribe("add-client", () => {
      this._toggleAddClientModal(true); // open add client modal
    });
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter(); // reset filter
      refreshFunc("agent-clients");
      this._getAllAgentClients(); // fetch all clients for that agent
    });

    this.searchTimer = null;

    // get all tag list
    this._getAllTagsList();
  };

  // reset the filter
  _resetFilter = () => {
    const { filters } = this.state;
    filters["isActive"] = "";
    filters["tags"] = [];
    filters["search"] = "";

    this.setState({ filters }, () => {
      this._paginate();
      refreshFunc("agent-clients-responsive");
    });
  };
  // update client status for that agent
  _clientStatusUpdate = async (data, value) => {
    try {
      this._manageLoading("statusChangeLoading", true);

      const payload = {
        isActive: value === "active" ? true : false,
      };

      const res = await updateAgentClient(data.id, payload);

      showToast("Status Updated Successfully", "success");
      const { clientsList } = deepClone(this.state);
      const client = clientsList.find((client) => client.id === data.id);
      client["isActive"] = res.client.isActive;
      this.setState({ clientsList });
      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);

      this._manageLoading("statusChangeLoading", false);
    }
  };

  // delete client on confirmation of pop-up
  _handleOnDeleteClientAlert = (clientId) => {
    if (!clientId) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this client.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._handleOnDeleteClient(clientId);
      }
    });
  };

  // api call to delete client
  _handleOnDeleteClient = async (clientId) => {
    try {
      this._manageLoading("clientDeleteLoading", true);

      await deleteAgentClientById(clientId);
      showToast("Client Deleted Successfully", "success");
      this._getAllAgentClients();
      this._manageLoading("clientDeleteLoading", false);
    } catch (err) {
      errorHandler(err);
      this._manageLoading("clientDeleteLoading", false);
    }
  };
  // format data for the table
  _dataFormat = (cell, row, header) => {
    return (
      <ClientAgentDataFormat
        header={header}
        row={row}
        cell={cell}
        loading={this.state.loading}
        _clientStatusUpdate={this._clientStatusUpdate}
        _toggleAddClientModal={this._toggleAddClientModal}
        _handleOnDeleteClientAlert={this._handleOnDeleteClientAlert}
      />
    );
  };

  // handle pagination
  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;

    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllAgentClients();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate(1, 10);
  };

  // format data for card header in mobile view
  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row.logo
                      ? row.logo
                      : require("../../../assets/img/default_user_icon.svg")
                        .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="signerName text-black">
                  {capitalize(
                    row?._signingCompany
                      ? row.companyName
                      : row?._client
                        ? row.name?.full
                        : row?._assistant
                          ? row.name?.full
                          : "N/A"
                  )}
                </span>
              </div>
            </div>

            <Button
              title="Edit"
              color="link"
              className="actionBtn"
              onClick={() => this._toggleAddClientModal(true, row)}
              disabled={
                !checkPermission("clients", "canUpdate") || row?._signingCompany
              }
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
            <Button
              color="link"
              onClick={() => this._handleOnDeleteClientAlert(row?._client)}
              disabled={
                row?._signingCompany || this.state.loading?.clientDeleteLoading
              }
            >
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  // format data for card in mobile view
  _cardDataFormat = (row) => {
    return (
      <ClientAgentCardDataFormat
        row={row}
        loading={this.state.loading}
        _clientStatusUpdate={this._clientStatusUpdate}
      />
    );
  };

  // function to save filter data in local storage
  _persistFilter = () => {
    const { filters, tableConfig } = this.state;
    if (
      (filters &&
        (filters?.isActive?.length ||
          filters?.search?.length ||
          filters?.tags?.length)) ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, tableConfig };
      console.log({ data });
      localStorage.agentClientPage = JSON.stringify(data);
    } else {
      delete localStorage.agentClientPage;
    }
  };

  // function to set data from local storage to state
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentClientPage) {
      try {
        const filter = JSON.parse(localStorage.agentClientPage);

        this.setState(
          { tableConfig: filter?.tableConfig, filters: filter?.filters },
          () => {
            this._getAllAgentClients();
          }
        );
      } catch (e) {
        this._getAllAgentClients();
      }
    } else {
      this._getAllAgentClients();
    }
  };

  _toggleProfessionalUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeProAccountModal: {
        isOpen,
        data,
      },
    });
  };

  // check for is logged in user is proplus or not if not then open upgrade account modal
  _checkProPlusUser = (type) => {
    if (!isProPlusUser()) {
      // this._toggleUpgradeAccountModal(true);
      this._toggleProfessionalUpgradeAccountModal(true);
      return;
    }

    if (type === "Email") {
      this._toggleBulkActivityModal(true, "Email");
    } else if (type === "Message") {
      this._toggleBulkActivityModal(true, "Message");
    }
  };

  render() {
    const {
      addClientModalData,
      clientsList,
      clientsCardHeaderKeys,
      clientsHeaderKeys,
      loading,
      clientsTotalCount,
      tableConfig,
      isClassAdded,
      bulkActivityModal,
      clientsSelectedRows,
      tagsList,
      filters,
      upgradeProAccountModal,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <>
        <div className="content">

          <div className="responsiveTitle">
            <h2>Clients</h2>

            <div className="rightSide">
              <Button
                color="primary"
                className="floatingButton"
                onClick={() => this._toggleAddClientModal(true)}
              >
                Add CLIENT
              </Button>
              <Link to={`/agent/bulk-upload?agentClient`} className="mr-3">
                Bulk upload
              </Link>

              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="agent-clients-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>
              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
            </div>
          </div>
          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${isClassAdded ? "show" : ""
              }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>

            <ClientAgentFilters
              filters={filters}
              tagsList={tagsList}
              _filterOnChange={this._filterOnChange}
              _validateForm={this._validateForm}
              _searchOnChange={this._searchOnChange}
              _checkProPlusUser={this._checkProPlusUser}
            />

            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>
          <div className="hideDesktop mb-4 text-right">
            <UncontrolledButtonDropdown className="dropdownList">
              <DropdownToggle caret>Bulk Activity</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this._checkProPlusUser("Email")}>
                  Email Template
                </DropdownItem>

                <DropdownItem onClick={() => this._checkProPlusUser("Message")}>
                  Message Template
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
          {isForMobile ? (
            <Suspense fallback={<></>}>
              <div className="hideDesktop">
                <Suspense fallback={<></>}>
                  <CustomCard
                    isPageStartFromOne={true}
                    pageNumber={tableConfig?.pageNumber}
                    tableData={clientsList}
                    headerKeys={clientsCardHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={clientsTotalCount}
                    rowSelection={true}
                    selectedRows={clientsSelectedRows}
                    setSelectedRows={(selectedRows) =>
                      this._setSelectedRows(selectedRows)
                    }
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    showTableLoading={loading?.showTableLoading}
                    cardHeaderFormat={this._cardHeaderFormat}
                    cardDataFormat={this._cardDataFormat}
                  />
                </Suspense>
              </div>
            </Suspense>
          ) : (
            <Suspense fallback={<></>}>
              <div className="hideMobile">
                <Suspense fallback={<></>}>
                  {clientsHeaderKeys && clientsHeaderKeys?.length && (
                    <CustomTable
                      striped
                      isPageStartFromOne={true}
                      pageNumber={tableConfig?.pageNumber}
                      tableData={clientsList}
                      headerKeys={clientsHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={clientsTotalCount}
                      rowSelection={true}
                      selectedRows={clientsSelectedRows}
                      setSelectedRows={(selectedRows) =>
                        this._setSelectedRows(selectedRows)
                      }
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      onSortChange={(sortName, sortOrder) =>
                        this._onSortChange(sortName, sortOrder)
                      }
                      showTableLoading={loading?.showTableLoading}
                    />
                  )}
                </Suspense>
              </div>
            </Suspense>
          )}
        </div>
        {/* add/edit client agent modal */}
        <Suspense fallback={<></>}>
          {addClientModalData.isOpen ? (
            <AddClientAgentModal
              isOpen={addClientModalData.isOpen}
              data={addClientModalData.data}
              resetTable={this._resetTable}
              toggle={this._toggleAddClientModal}
            />
          ) : null}
        </Suspense>
        {/*  agent bulk activity modal */}
        <Suspense fallback={<></>}>
          <AgentBulkActivityModal
            isOpen={bulkActivityModal.isOpen}
            data={bulkActivityModal.data}
            bulkActivityType={bulkActivityModal.type}
            toggle={this._toggleBulkActivityModal}
            clientsSelectedRows={clientsSelectedRows}
          />
        </Suspense>
        {/* upgrade account modal */}
        <Suspense fallback={<></>}>
          <UpgradeProfessionalAccountModal
            {...this.props}
            isOpen={upgradeProAccountModal.isOpen}
            toggle={this._toggleProfessionalUpgradeAccountModal}
          />
        </Suspense>
      </>
    );
  }
}

export default ScreenWidthHOC(Clients);
