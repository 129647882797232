import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  CustomInput,
  ListGroup,
  ListGroupItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  deepClone,
  formatDate,
  showToast,
  formatAddressInSingleText,
  isRegularUser,
  formatOnlyDateMoment,
  errorHandler,
  debounce,
  // formatDateAsPerTimeZOne,
} from "../../../helper-methods";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../helper-methods/googleService";
import {
  agentCreateMileage,
  agentUpdateClosingMileage,
  getMileageDetail,
} from "../../../http/http-calls";

class AddMileageReportingAgentModal extends Component {
  state = {
    formFields: {
      date: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      purpose: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      startAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      stopAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      endAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      comments: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      estimatedMiles: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      actualMiles: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
    },
    mileageData: {},
    multiStop: false,
    googleAddressResult: [],
    isAddressListShow: null, // startAddress or endAddress
    loading: false,
  };

  _resetStateModal = () => {
    const defaultValue = {
      value: "",
      error: null,
      isDirty: false,
      isValidate: true,
    };

    const defaultField = {
      value: "",
      error: null,
      isDirty: false,
      isValidate: false,
    };

    this.setState({
      formFields: {
        date: {
          ...defaultValue,
        },
        purpose: {
          ...defaultValue,
        },
        startAddress: {
          ...defaultValue,
        },
        stopAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        endAddress: {
          ...defaultValue,
        },
        comments: {
          ...defaultField,
        },
        estimatedMiles: {
          ...defaultField,
        },
        actualMiles: {
          ...defaultValue,
        },
      },
      multiStop: false,
      googleAddressResult: [],
      isAddressListShow: null, // startAddress or endAddress or stopAddress
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen, data } = this.props;
    const { formFields } = this.state;
    if (isOpen && isOpen !== prevProps.isOpen) {
      // this._agentGetAllUnregisteredClient();

      formFields.date.value = data?.travelDate
        ? new Date(data?.travelDate).toISOString()
        : "";
      formFields.purpose.value = data?.purpose ? data?.purpose : "";
      formFields.startAddress.value = formatAddressInSingleText(
        data?.startAddress
      );
      formFields.stopAddress.value = formatAddressInSingleText(
        data?.stopAddress
      );
      formFields.endAddress.value = formatAddressInSingleText(data?.endAddress);
      formFields.comments.value = data?.comments ? data?.comments : "";
      formFields.actualMiles.value = data?.distance ? data?.distance : "";

      this.setState({
        formFields,
        multiStop: data?.stopAddress ? true : false,
      });
      if (data) {
        this._getMileageDetail();
      }
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields, multiStop } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            case "date": {
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "purpose": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "stopAddress": {
              if (multiStop) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (formFields[key].placeId || this.props.data) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "startAddress":
            case "endAddress": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (formFields[key].placeId || this.props.data) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Address";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            // case "estimatedMiles":
            case "actualMiles": {
              if (
                formFields[key]?.value &&
                String(formFields[key]?.value)?.trim()?.length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            default:
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeMultiCity = (multiStop) => {
    this.setState({ multiStop });
  };

  _onSelectAddress = async (fieldName, addr) => {
    let placeDetail = await googlePlaceDetails(addr.place_id);
    const { formFields } = deepClone(this.state);
    if (placeDetail.postal) {
      formFields[fieldName].value = addr.description;
      formFields[fieldName].placeId = addr.place_id;
      formFields[fieldName].isDirty = true;
      this.setState({ formFields, isAddressListShow: null }, () => {
        this._validateForm();
      });
    } else {
      showToast("Invalid Address", "error");
    }
  };

  _googlePlaceSearch = async (searchValue, isAddressListShow) => {
    const googleAddressResult = await googlePlaceSearch(searchValue);
    this.setState({ googleAddressResult, isAddressListShow });
  };

  debouncedGooglePlaceSearch = debounce(async (value, fieldName) => {
    this._googlePlaceSearch(value, fieldName);
  }, 1000);

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    if (fieldName === "date")
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    else formFields[fieldName].value = value;

    if (
      fieldName === "startAddress" ||
      fieldName === "endAddress" ||
      fieldName === "stopAddress"
    ) {
      // this._googlePlaceSearch(value, fieldName);
      this.debouncedGooglePlaceSearch(value, fieldName);
      formFields[fieldName].placeId = null;
      formFields.estimatedMiles.value = "";
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _agentCreateMileage = (payload) => {
    agentCreateMileage(payload)
      .then((res) => {
        showToast("Mileage Added Successfully", "success");
        this.props.resetDetails();
        this._closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _agentUpdateClosingMileage = (payload) => {
    const id = this.props.data.id;
    agentUpdateClosingMileage(id, payload)
      .then((res) => {
        showToast("Mileage Updated Successfully", "success");
        this.props.resetDetails();
        this._closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _onSubmit = async (e) => {
    if (e) e.preventDefault();

    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (isFormValid) {
      this.setState({ loading: true });

      const { formFields } = this.state;
      const { data } = this.props;
      const payload = {
        travelDate: formatOnlyDateMoment(formFields.date.value) + " 00:00",
        purpose:
          data?.purpose?.trim() !== formFields.purpose.value?.trim()
            ? formFields.purpose.value?.trim()
            : undefined,
        startAddress: {},
        endAddress: {},
        stopAddress: {},
        comments:
          data?.comments?.trim() !== formFields.comments.value?.trim()
            ? formFields.comments.value?.trim()
            : undefined,
        // distance: Number(formFields.actualMiles.value),
        distance:
          data?.distance !== formFields.actualMiles.value
            ? formFields.actualMiles.value?.trim()
            : undefined,
      };

      if (formFields.startAddress.placeId) {
        const addressObj = await googlePlaceDetails(
          formFields.startAddress.placeId
        );
        payload.startAddress["street"] = addressObj.address;
        payload.startAddress["city"] = addressObj.city;
        payload.startAddress["state"] = addressObj.state;
        payload.startAddress["zip"] = addressObj.postal;
        payload.startAddress["lat"] = addressObj.lat;
        payload.startAddress["lng"] = addressObj.lng;
      } else {
        delete payload.startAddress;
      }
      if (formFields.stopAddress.placeId) {
        const addressObj = await googlePlaceDetails(
          formFields.stopAddress.placeId
        );
        payload.stopAddress["line1"] = addressObj.address;
        payload.stopAddress["city"] = addressObj.city;
        payload.stopAddress["state"] = addressObj.state;
        payload.stopAddress["zip"] = addressObj.postal;
        payload.stopAddress["lat"] = addressObj.lat;
        payload.stopAddress["lng"] = addressObj.lng;
      } else {
        delete payload.stopAddress;
      }
      if (formFields.endAddress.placeId) {
        const addressObj = await googlePlaceDetails(
          formFields.endAddress.placeId
        );
        payload.endAddress["line1"] = addressObj.address;
        payload.endAddress["city"] = addressObj.city;
        payload.endAddress["state"] = addressObj.state;
        payload.endAddress["zip"] = addressObj.postal;
        payload.endAddress["lat"] = addressObj.lat;
        payload.endAddress["lng"] = addressObj.lng;
      } else {
        delete payload.endAddress;
      }

      // console.log("api hit -->> ", payload, office);

      if (this.props.data) {
        //  console.log(payload)
        this._agentUpdateClosingMileage(payload);
      } else {
        this._agentCreateMileage(payload);
      }
    }
  };

  _getMileageDetail = async () => {
    // const id = this.props.data.id;
    const { formFields } = this.state;
    const { data } = this.props;

    let payload = {
      startAddress: {},
      endAddress: {},
      stopAddress: {},
    };

    if (formFields.startAddress.placeId) {
      const addressObj = await googlePlaceDetails(
        formFields.startAddress.placeId
      );
      payload.startAddress["street"] = addressObj.address;
      payload.startAddress["city"] = addressObj.city;
      payload.startAddress["state"] = addressObj.state;
      payload.startAddress["zip"] = addressObj.postal;
      payload.startAddress["lat"] = addressObj.lat;
      payload.startAddress["lng"] = addressObj.lng;
    } else {
      // this.props.data.startAddress
      payload.startAddress["line1"] = data?.startAddress.address;
      payload.startAddress["city"] = data?.startAddress.city;
      payload.startAddress["state"] = data?.startAddress.state;
      payload.startAddress["zip"] = data?.startAddress.postal;
      payload.startAddress["lat"] = data?.startAddress.lat;
      payload.startAddress["lng"] = data?.startAddress.lng;
    }
    if (formFields.stopAddress.placeId) {
      const addressObj = await googlePlaceDetails(
        formFields.stopAddress.placeId
      );
      payload.stopAddress["line1"] = addressObj.address;
      payload.stopAddress["city"] = addressObj.city;
      payload.stopAddress["state"] = addressObj.state;
      payload.stopAddress["zip"] = addressObj.postal;
      payload.stopAddress["lat"] = addressObj.lat;
      payload.stopAddress["lng"] = addressObj.lng;
    } else {
      // this.props.data.stopAddress
      payload.stopAddress["line1"] = data?.stopAddress
        ? data.stopAddress.street
        : undefined;
      payload.stopAddress["city"] = data?.stopAddress
        ? data.stopAddress.city
        : undefined;
      payload.stopAddress["state"] = data?.stopAddress
        ? data.stopAddress.state
        : undefined;
      payload.stopAddress["zip"] = data?.stopAddress
        ? data.stopAddress.postal
        : undefined;
      payload.stopAddress["lat"] = data?.stopAddress
        ? data.stopAddress.lat
        : undefined;
      payload.stopAddress["lng"] = data?.stopAddress
        ? data.stopAddress.lng
        : undefined;
    }

    if (formFields.endAddress.placeId) {
      const addressObj = await googlePlaceDetails(
        formFields.endAddress.placeId
      );
      payload.endAddress["line1"] = addressObj.address;
      payload.endAddress["city"] = addressObj.city;
      payload.endAddress["state"] = addressObj.state;
      payload.endAddress["zip"] = addressObj.postal;
      payload.endAddress["lat"] = addressObj.lat;
      payload.endAddress["lng"] = addressObj.lng;
    } else {
      payload.endAddress["line1"] = data?.endAddress.line1;
      payload.endAddress["city"] = data?.endAddress.city;
      payload.endAddress["state"] = data?.endAddress.state;
      payload.endAddress["zip"] = data?.endAddress.postal;
      payload.endAddress["lat"] = data?.endAddress.lat;
      payload.endAddress["lng"] = data?.endAddress.lng;
    }

    payload.stopAddress = this.state.multiStop ? payload.stopAddress : {};

    // console.log(payload)

    getMileageDetail(payload)
      .then((res) => {
        // showToast("Mileage Updated Successfully", "success");
        // this.props.resetDetails();
        // this._closeModal();
        formFields.estimatedMiles.value = res?.miles;
        this.setState({ formFields });
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      formFields,
      multiStop,
      loading,
      googleAddressResult,
      isAddressListShow,
    } = this.state;

    const {
      isOpen,
      // data
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        className="modal-dialog-centered"
        toggle={this._closeModal}
        scrollable
      >
        <ModalHeader toggle={this._closeModal}>
          {this.props.data ? "Edit" : "Add"} Mileage
        </ModalHeader>
        <ModalBody>
          <FormGroup
            className={`floatingLabel ${
              formFields.date.value || this.state.isOpenReactDatetime
                ? "valueAdded"
                : ""
            }`}
          >
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: " ",
                value: formatDate(formFields.date.value),
                readOnly: this.props.data,
              }}
              value={
                formFields.date.value ? new Date(formFields.date.value) : ""
              }
              onOpen={() => this.setState({ isOpenReactDatetime: true })}
              onClose={() => this.setState({ isOpenReactDatetime: false })}
              onChange={(e) => this._onChangeFormField("date", e._d)}
              closeOnSelect={true}
              timeFormat={false}
            />
            {formFields.date.error && (
              <div className="validation-error">{formFields.date.error}</div>
            )}
            <Label>Date</Label>
          </FormGroup>
          <FormGroup className="floatingLabel">
            <Input
              placeholder=" "
              value={formFields.purpose.value}
              name="purpose"
              onChange={(event) =>
                this._onChangeFormField("purpose", event.target.value)
              }
              disabled={this.props.data}
            />
            <Label>File Number</Label>
            {formFields.purpose.error && (
              <div className="validation-error">{formFields.purpose.error}</div>
            )}
          </FormGroup>
          <FormGroup>
            <CustomInput
              type="checkbox"
              id="agent__add__mileage__log__office_checkbox"
              label="Multi Stop"
              checked={multiStop}
              onChange={(event) =>
                this._onChangeMultiCity(event.target.checked)
              }
              disabled={!isRegularUser()}
            />
          </FormGroup>

          <FormGroup className="floatingLabel withInputGroup locationDot">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText></InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                value={formFields.startAddress.value}
                placeholder=" "
                name="startAddress"
                onChange={(event) =>
                  this._onChangeFormField("startAddress", event.target.value)
                }
              />
              <Label>Start Location</Label>
            </InputGroup>
            {formFields.startAddress.error && (
              <div className="validation-error">
                {formFields.startAddress.error}
              </div>
            )}
            {/* address suggestion list for input tag - start Address */}
            {googleAddressResult &&
            googleAddressResult.length &&
            isAddressListShow === "startAddress" ? (
              <ListGroup flush className="customSearchOptions">
                {googleAddressResult.map((addr, index) => {
                  return (
                    <ListGroupItem
                      key={addr.place_id || index}
                      className="cursorPointer"
                      onClick={() =>
                        this._onSelectAddress("startAddress", addr)
                      }
                    >
                      {addr.description}
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            ) : null}
          </FormGroup>

          {multiStop && (
            <FormGroup className="floatingLabel withInputGroup locationDot">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="stopLocation"></InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={formFields.stopAddress.value}
                  placeholder=" "
                  name="stopAddress"
                  onChange={(event) =>
                    this._onChangeFormField("stopAddress", event.target.value)
                  }
                />
                <Label>Stop Location</Label>
              </InputGroup>
              {formFields.stopAddress.error && (
                <div className="validation-error">
                  {formFields.stopAddress.error}
                </div>
              )}
              {/* address suggestion list for input tag - start Address */}
              {googleAddressResult &&
              googleAddressResult.length &&
              isAddressListShow === "stopAddress" ? (
                <ListGroup flush className="customSearchOptions">
                  {googleAddressResult.map((addr, index) => {
                    return (
                      <ListGroupItem
                        key={addr.place_id || index}
                        className="cursorPointer"
                        onClick={() =>
                          this._onSelectAddress("stopAddress", addr)
                        }
                      >
                        {addr.description}
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              ) : null}
            </FormGroup>
          )}
          {
            <FormGroup className="floatingLabel withInputGroup locationDot">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="endLocation"></InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={formFields.endAddress.value}
                  placeholder=" "
                  name="endAddress"
                  onChange={(event) =>
                    this._onChangeFormField("endAddress", event.target.value)
                  }
                />
                <Label>End Location</Label>
              </InputGroup>
              {formFields.endAddress.error && (
                <div className="validation-error">
                  {formFields.endAddress.error}
                </div>
              )}
              {/* address suggestion list for input tag - end Address */}
              {googleAddressResult &&
              googleAddressResult.length &&
              isAddressListShow === "endAddress" ? (
                <ListGroup flush className="customSearchOptions">
                  {googleAddressResult.map((addr, index) => {
                    return (
                      <ListGroupItem
                        key={addr.place_id || index}
                        className="cursorPointer"
                        onClick={() =>
                          this._onSelectAddress("endAddress", addr)
                        }
                      >
                        {addr.description}
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              ) : null}
            </FormGroup>
          }
          <Button
            color="primary"
            outline
            className="mx-auto mt-4 d-block"
            onClick={this._getMileageDetail}
          >
            Calculate
          </Button>

          <Row className="mt-4">
            <Col sm="6">
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  value={formFields.estimatedMiles.value}
                  name="estimatedMiles"
                  disabled
                  placeholder=" "
                />
                <Label>Round Estimated Miles</Label>
                {formFields.estimatedMiles.error && (
                  <div className="validation-error">
                    {formFields.estimatedMiles.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="floatingLabel">
                <Input
                  type="number"
                  value={formFields.actualMiles.value}
                  name="actualMiles"
                  placeholder=" "
                  onChange={(event) =>
                    this._onChangeFormField("actualMiles", event.target.value)
                  }
                />
                <Label>Actual Miles</Label>
                {formFields.actualMiles.error && (
                  <div className="validation-error">
                    {formFields.actualMiles.error}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="floatingLabel">
            <Input
              type="textarea"
              rows="3"
              placeholder=" "
              value={formFields.comments.value}
              name="comments"
              onChange={(event) =>
                this._onChangeFormField("comments", event.target.value)
              }
            />
            <Label>Comments</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="lg" outline onClick={this._closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={this._onSubmit}
          >
            {this.props.data ? "Save" : "Add"}
            {loading ? <i className="fa fa-spinner fa-spin ml-2" /> : null}{" "}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddMileageReportingAgentModal;
