import React from "react";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import { agentClosingStatus } from "../../../config";
import { capitalize } from "../../../helper-methods";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import usaStates from "../../../config/usa_states_titlecase";

const ClientOrderDashboardFilters = ({
  filters,
  clientTeamsList,
  // signingCompanies,
  dateRangeValue,
  _filterOnChange,
  _onChangeDatePicker,
}) => {
  return (
    <div className="filterWrapper">
      <FormGroup className="searchTable">
        <Label>Search</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <img
                src={require("../../../assets/img/searchIcon.svg").default}
                alt="searchIcon"
                loading="lazy"
              />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Search Ex. File No. or Signer"
            value={filters?.search}
            onChange={(e) => _filterOnChange("search", e.target.value, true)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <Label>Status</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            name="status"
            value={filters?.status}
            onChange={(e) => _filterOnChange("status", e.target.value)}
          >
            <option value="">All</option>
            {agentClosingStatus?.map((obj) => (
              <option key={obj.value} value={obj.value}>
                {obj.label}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>
      <FormGroup style={{ minWidth: "150px" }}>
        <Label>Teams</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            placeholder=" "
            value={filters?.clientTeam}
            onChange={(event) =>
              _filterOnChange("clientTeam", event.target.value)
            }
            name="clientTeamsId"
          >
            <option value="">All Teams</option>
            {clientTeamsList?.map((team) => (
              <option key={team?._id} value={team?._id}>
                {team?.teamName ? capitalize(team?.teamName) : ""}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>
      <FormGroup className="dateRange">
        <Label>Date Range</Label>
        <DateRangePicker
          className="dateRange"
          format="MM-dd-y"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          onChange={(dateRangeValue) => _onChangeDatePicker(dateRangeValue)}
          value={dateRangeValue}
        />
      </FormGroup>
      {/* <FormGroup>
        <Label>Signing Company</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            name="signingCompanyId"
            value={filters?.signingCompanyId}
            onChange={(e) =>
              _filterOnChange("signingCompanyId", e.target.value)
            }
          >
            <option value="">All</option>
            {signingCompanies.map((each) => (
              <option key={each._id} value={each._id}>
                {capitalize(each.companyName)}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup> */}
      <FormGroup>
        <Label>State</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            name="state"
            value={filters?.state}
            onChange={(e) => _filterOnChange("state", e.target.value)}
          >
            <option value="">All</option>
            {usaStates?.map((state) => (
              <option key={state?.abbreviation} value={state?.abbreviation}>
                {state?.name}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup>
      {/* <FormGroup className="noshadow">
        <CustomInput
          id="unassigned"
          label=" "
          type="checkbox"
          name="unassigned"
          className="mt-4"
          checked={filters?.unassigned}
          onChange={(e) => _filterOnChange("unassigned", e.target.checked)}
        />
      </FormGroup> */}
    </div>
  );
};

export default ClientOrderDashboardFilters;
