export const paths = [
    {
      path: "/agent/create-closing",
      name: "Create Closing",
      headerName: "Create Order",
    },
    {
      path: "/client/create-order",
      name: "Create Closing",
      headerName: "Create Order",
    },
    {
      path: "/signingcompany/create-closing",
      name: "Create Closing",
      headerName: "Create Order",
    },
    {
      path: "/agent/settings",
      name: "Settings",
      headerName: "Settings",
    },
    {
      path: "/agent/notifications",
      name: "Notifications",
      headerName: "Notifications",
    },
    {
      path: "/agent/faq",
      name: "FAQ",
      headerName: "FAQ",
    },
    {
      path: "/signingcompany/settings",
      name: "Settings",
      headerName: "Settings",
    },
    {
      path: "/signingcompany/notifications",
      name: "Notifications",
      headerName: "Notifications",
    },
    {
      path: "/signingcompany/faq",
      name: "FAQ",
      headerName: "FAQ",
    },
    {
      path: "/agent/billing",
      name: "My Billing",
      headerName: "My Billing",
    },
    {
      path: "/signingcompany/billing",
      name: "My Billing",
      headerName: "My Billing",
    },
    {
      path: "/client/settings",
      name: "Settings",
      headerName: "Settings",
    },
    {
      path: "/client/notifications",
      name: "Notifications",
      headerName: "Notifications",
    },
    {
      path: "/client/faq",
      name: "FAQ",
      headerName: "FAQ",
    },
  ];