import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { errorHandler } from "../../../helper-methods";
import { getClientSettings } from "../../../http/http-calls";
import SkeletonLoading from "../components/Skeletons/SkeletonLoading";

const EmailCustomization = lazy(() =>
  import("../components/emailCustomization")
);
const ClientNotificationSettingTab = lazy(() =>
  import("../components/tabs/ClientNotificationSettingTab")
);
const ClientJobSettingTab = lazy(() =>
  import("../components/tabs/ClientJobSettingTab")
);

const ClientSettings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [clientSetting, setClientSetting] = useState(null);

  const [loading, setLoading] = useState(false);

  const _toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const _getClientSettings = async () => {
    try {
      setLoading(true);
      const res = await getClientSettings();

      setClientSetting(res?.clientSetting);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    _getClientSettings();
  }, []);

  return (
    <div className="content">
      <div className="responsiveTitle">
        <h2>Settings {loading ? <Spinner /> : null}</h2>
      </div>

      <div className="verticalTabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => _toggleTab("1")}
            >
              Jobs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => _toggleTab("2")}
            >
              Notifications
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "3" ? "active" : ""}
              onClick={() => _toggleTab("3")}
            >
              Email Customization
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Suspense fallback={<></>}>
              {loading ? (
                <SkeletonLoading type="clientSettings" count={1} />
              ) : (
                <ClientJobSettingTab
                  clientSetting={clientSetting}
                  onSuccess={_getClientSettings}
                />
              )}
            </Suspense>
          </TabPane>
          <TabPane tabId="2">
            <Suspense fallback={<></>}>
              <ClientNotificationSettingTab
                clientSetting={clientSetting}
                activeTab={activeTab}
              />
            </Suspense>
          </TabPane>
          <TabPane tabId="3">
            <Suspense fallback={<></>}>
              <EmailCustomization />
            </Suspense>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default ClientSettings;
