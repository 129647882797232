import { Route, Redirect } from "react-router-dom";
import React from "react";
// import { store } from '../../../redux/store';
import { isCardAdded } from "../../../guards/auth-guard";

const SecuredRoute = ({ component: Component, ...rest }) => {
  // const state = store.getState();
  // console.log(state.userData.type,rest.path);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isCardAdded(rest.path)) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: rest.redirectRoute,
                extras: { ...rest.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default SecuredRoute;
