import React, { Suspense, lazy } from "react";
import {
  Badge,
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import {
  checkPermission,
  deepClone,
  errorHandler,
  formatOnlyDateMoment,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import { Elements } from "react-stripe-elements";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  clientPayCompany,
  getAllClientPayment,
  getAllCompanyForPayment,
  updateClientPayment,
} from "../../../http/http-calls";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { dataHeaderKeys } from "../../../config/stateConfig";
import ClientConnectPayFilters from "../components/ClientConnectPayFilters";
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));
const ClientMakePaymentModal = lazy(() =>
  import("../components/ClientMakePaymentModal")
);
const AddNotesModal = lazy(() =>
  import("../components/add-notes-income-list-modal")
);
const AccountComponentModal = lazy(() =>
  import("../components/AccountComponentModal")
);

const ClientConnectPayCards = lazy(() =>
  import("../components/ClientConnectPayCards")
);

const ClientConnectPayDataFormat = lazy(() =>
  import("../components/ClientConnectPayDataFormat")
);

const ClientConnectPayCardDataFormat = lazy(() =>
  import("../components/ClientConnectPayCardDataFormat")
);
class ClientPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotalCount: 0,
      totalPayment: 0,
      totalPaid: 0,
      totalDue: 0,
      dataHeaderKeys: dataHeaderKeys,
      dataCardHeaderKeys: [
        { id: "id", label: "id" },
        { id: "name", label: "Name" },
      ],
      tableConfig: {
        skip: 0,
        limit: 50,
        pageNumber: 1,
      },
      addNotesModal: {
        isOpen: false,
        data: null,
      },
      signingCompanys: [],
      clientMakePayment: {
        isOpen: false,
        data: null,
      },
      filters: {
        signingCompanyId: "",
        payStatusClient: "",
        search: "",
      },
      dateRangeValue: null,
      loading: {
        showTableLoading: false,
        markPaidLoading: false,
      },
      selectedRows: [],
      selectedArray: [],
      payAchModal: {
        isOpen: false,
        data: null,
      },
      isClassAdded: false,
    };
    this.tableRef = React.createRef();
  }

  //Loading manage
  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _getPaymentAndCompanyData = async () => {
    const companies = await this._getAllCompanyForPayment();

    this.setState(
      {
        filters: {
          signingCompanyId: companies?.[0]?.id,
        },
      },
      () => {
        this._setFilterDataFromLocalStorage();
      }
    );
  };

  componentDidMount = () => {
    this._getPaymentAndCompanyData();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("client-client-payment");
    });
  };

  //Client data get for payment
  _getAllClientPayment = async () => {
    try {
      this._manageLoading("showTableLoading", true);

      const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

      const filterPayload = {};

      if (filters?.signingCompanyId?.length)
        filterPayload["signingCompanyId"] = filters.signingCompanyId;
      if (filters?.payStatusClient?.length)
        filterPayload["payStatusClient"] = filters.payStatusClient;

      if (dateRangeValue && dateRangeValue[0])
        Object.assign(filterPayload, {
          startDate: formatOnlyDateMoment(dateRangeValue[0]),
        });

      if (dateRangeValue && dateRangeValue[1])
        Object.assign(filterPayload, {
          endDate: formatOnlyDateMoment(dateRangeValue[1]),
        });

      if (filters.search?.length) tableConfig["search"] = filters?.search?.trim();

      tableConfig["filters"] = { ...filterPayload };

      const res = await getAllClientPayment(tableConfig);

      this.setState(
        {
          data: res?.closings,
          dataTotalCount: res?.totalCount || 0,
          totalPayment: res?.totalPayment || 0,
          totalDue: res?.totalDue || 0,
          totalPaid: res?.totalPaid || 0,
        },
        () => {
          this._manageLoading("showTableLoading", false);
        }
      );
    } catch (err) {
      errorHandler(err);
      this._manageLoading("showTableLoading", false);
    }
  };

  _getAllCompanyForPayment = () => {
    return new Promise(async (resolve) => {
      try {
        const { filters, dateRangeValue } = deepClone(this.state);

        const filterPayload = {};

        if (filters?.payStatusClient?.length)
          filterPayload["payStatusClient"] = filters.payStatusClient;
        if (dateRangeValue) {
          filterPayload["startDate"] = dateRangeValue[0];
          filterPayload["endDate"] = dateRangeValue[1];
        }
        if (filters.search && filters.search.length)
          filterPayload["search"] = filters.search;

        const res = await getAllCompanyForPayment(filterPayload);

        this.setState(
          {
            signingCompanys: res?.signingCompanys?.length
              ? res.signingCompanys
              : [],
          },
          () => {
            this._setSelectedRows();
            resolve(res?.signingCompanys);
          }
        );
      } catch (err) {
        resolve([]);
        errorHandler(err);
      }
    });
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => this._paginate());
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = this.state;
    filters[type] = value;
    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      } else {
        this._paginate();
      }
    });
  };

  //reset filter function
  _resetFilter = () => {
    this.setState(
      {
        filters: {
          signingCompanyId: "",
          payStatusClient: "",
          search: "",
        },
        dateRangeValue: null,
      },
      () => this._paginate()
    );
  };

  //pagination handle function
  _paginate = (pageNumber = 1, pageSize = 50) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllClientPayment();
      // this._getAllCompanyForPayment();
    });
  };

  _onTogglePayAchModal = (isOpen = false, note = null) => {
    this.setState({
      payAchModal: {
        isOpen,
        note,
      },
    });
  };

  _onToggleClientMakePayment = (isOpen = false, data = null) => {
    this.setState({
      clientMakePayment: {
        isOpen,
        data,
      },
    });
  };

  _onToggleAddNotesModal = (isOpen = false, data = null) => {
    this.setState({
      addNotesModal: {
        isOpen,
        data,
      },
    });
  };

  _recordPayment = async (action, note) => {
    try {
      if (action !== "pay") {
        showToast();
        return;
      }

      this._manageLoading("showTableLoading", true);

      const { filters, selectedRows } = this.state;

      const payload = {
        action,
        payPeriodPaymentNotesClient: note?.trim() ? note.trim() : "",
      };
      const closingId = selectedRows?.map((each) => each?.id);

      if (filters.signingCompanyId)
        payload["signingCompanyId"] = filters.signingCompanyId;
      if (selectedRows?.length) payload["closingId"] = closingId;

      await updateClientPayment(payload);

      showToast("Record has been updated", "success");

      this._getAllClientPayment();
      this._setSelectedRows();
      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  //This function user for save notes
  _onSaveNotes = (id, note) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { filters } = this.state;

        const payload = {
          closingId: [id],
          payPeriodPaymentNotesClient: note?.trim() ? note.trim() : "",
        };

        if (filters.signingCompanyId)
          payload["signingCompanyId"] = filters.signingCompanyId;

        const res = await updateClientPayment(payload);

        this._getAllClientPayment();
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  _setSelectedRows = (selectedRows = []) => {
    const { data } = deepClone(this.state);

    selectedRows = selectedRows?.filter(
      (each) => each?.payStatusClient !== "Paid"
    );

    const results = data.filter((item) =>
      selectedRows.some((each) => each.id === item.id)
    );

    this.setState({
      selectedRows,
      selectedArray: results,
    });
  };

  //This function is calculate total company fee
  _calculateCompanyTotalFee = () => {
    const { selectedArray } = deepClone(this.state);
    let arr = selectedArray.map((item) => item.signingCompanyFee || 0);
    return arr.reduce((a, b) => a + b, 0);
  };

  //Thus function is payement when click on Pay Ach from make payment modal
  _processPayment = async () => {
    try {
      let { filters, selectedRows, payAchModal } = deepClone(this.state);

      if (!selectedRows?.length) {
        showToast("Please Select Closings", "error");
        return;
      }

      this.setState({ loadingModal: true });

      let payload = {
        signingCompanyId: filters.signingCompanyId,
        amount: this._calculateCompanyTotalFee(),
        payPeriodPaymentNotes: payAchModal.note,
        closingId: selectedRows,
      };

      await clientPayCompany(payload);

      this._onTogglePayAchModal();
      this._getAllClientPayment();
      this._setSelectedRows();

      showToast(`Payment has been completed`, "success");

      this.setState({ loadingModal: false });
    } catch (error) {
      errorHandler(error);

      this.setState({ loadingModal: false });
    }
  };

  //For view in table view
  _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <ClientConnectPayDataFormat
          cell={cell}
          row={row}
          header={header}
          _onToggleAddNotesModal={this._onToggleAddNotesModal}
        />
      </Suspense>
    );
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            {row?.payStatusClient === "Pending" ? (
              <Badge color="warning" className="outline" pill>
                Pending
              </Badge>
            ) : row?.payStatusClient === "Paid" ? (
              <Badge color="success" className="outline" pill>
                Paid
              </Badge>
            ) : (
              <Badge color="primary" className="outline" pill>
                {row?.payStatusClient || "-"}
              </Badge>
            )}
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  //For view in mobile view
  _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <ClientConnectPayCardDataFormat
          row={row}
          _onToggleAddNotesModal={this._onToggleAddNotesModal}
        />
      </Suspense>
    );
  };

  //This function used for handle local storage data
  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.clientConnectPayPage = JSON.stringify(data);
    } else {
      delete localStorage.clientConnectPayPage;
    }
  };

  //Data set in state filters from local storage
  _setFilterDataFromLocalStorage = () => {
    try {
      if (localStorage && localStorage.clientConnectPayPage) {
        const filters = JSON.parse(localStorage.clientConnectPayPage);

        this.setState({ tableConfig: filters?.tableConfig }, () => {
          this._getAllClientPayment();
        });
      } else {
        this._getAllClientPayment();
      }
    } catch (e) {
      console.log("error", e);
      this._getAllClientPayment();
    }
  };

  render() {
    const {
      dateRangeValue,
      filters,
      data,
      dataTotalCount,
      dataHeaderKeys,
      tableConfig,
      loading,
      clientMakePayment,
      totalPayment,
      totalPaid,
      totalDue,
      // signingCompanys,
      selectedRows,
      selectedArray,
      addNotesModal,
      loadingModal,
      payAchModal,
      dataCardHeaderKeys,
      isClassAdded,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Connect Pay</h2>

            <div className="rightSide">
              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                  loading="lazy"
                />
              </Button>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                    loading="lazy"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="File Number"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${isClassAdded ? "show" : ""
              }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
                loading="lazy"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                  loading="lazy"
                />
              </Button>
            </div>
            <ClientConnectPayFilters
              filters={filters}
              dateRangeValue={dateRangeValue}
              // signingCompanys={signingCompanys}
              _filterOnChange={this._filterOnChange}
              _onChangeDatePicker={this._onChangeDatePicker}
            />
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <Row>
            <Col md="12">
              <Suspense fallback={<></>}>
                <ClientConnectPayCards
                  dataTotalCount={dataTotalCount}
                  totalPayment={totalPayment}
                  totalPaid={totalPaid}
                  totalDue={totalDue}
                  loading={loading.showTableLoading}
                />
              </Suspense>
              {checkPermission("payment", "canPay") ? (
                selectedRows?.length ? (
                  <div
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                    style={{ marginBottom: 20, marginTop: 15 }}
                  >
                    <div
                      className="fs-14"
                      style={{ color: "#1B2430", fontWeight: 600 }}
                    >
                      <span>{selectedRows.length}</span>{" "}
                      {selectedRows.length > 1 ? "Records are" : "Record is"}{" "}
                      Selected
                    </div>
                    {selectedRows.filter((item) => item?.payStatusClient)
                      ?.length > 0 && (
                        <div>
                          <Button
                            color="primary"
                            size="lg"
                            onClick={() => this._onToggleClientMakePayment(true)}
                          >
                            Make Payment ${this._calculateCompanyTotalFee()}
                          </Button>
                        </div>
                      )}
                  </div>
                ) : null
              ) : null}

              {isForMobile ? (
                <div className="hideDesktop">
                  <Suspense fallback={<></>}>
                    <CustomCard
                      isPageStartFromOne={true}
                      pageNumber={tableConfig.pageNumber}
                      tableData={data}
                      headerKeys={dataCardHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={dataTotalCount}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      rowSelection={true}
                      selectedRows={selectedRows}
                      setSelectedRows={(selectedRows) =>
                        this._setSelectedRows(selectedRows)
                      }
                      showTableLoading={loading.showTableLoading}
                      cardHeaderFormat={this._cardHeaderFormat}
                      cardDataFormat={this._cardDataFormat}
                    />
                  </Suspense>
                </div>
              ) : (
                <div className="hideMobile">
                  <Suspense fallback={<></>}>
                    {dataHeaderKeys && dataHeaderKeys.length && (
                      <CustomTable
                        striped
                        isPageStartFromOne={true}
                        pageNumber={tableConfig.pageNumber}
                        tableData={data}
                        headerKeys={dataHeaderKeys}
                        dataFormat={this._dataFormat}
                        totalCount={dataTotalCount}
                        rowSelection={
                          checkPermission("payment", "canPay") ? true : false
                        }
                        onPaginate={(pageNumber, pageSize) =>
                          this._paginate(pageNumber, pageSize)
                        }
                        setSelectedRows={(selectedRows) =>
                          this._setSelectedRows(selectedRows)
                        }
                        selectedRows={selectedRows}
                        isRowSelectedCondition={true}
                        rowSelectedCondition={(row) => {
                          return row.payStatusClient !== "Paid";
                        }}
                        showTableLoading={loading.showTableLoading}
                        ref={this.tableRef}
                        pageSize={50}
                      />
                    )}
                  </Suspense>
                </div>
              )}
            </Col>
          </Row>

          {/* Add notes modal */}
          <Suspense fallback={<></>}>
            <AddNotesModal
              isOpen={addNotesModal.isOpen}
              data={addNotesModal.data}
              toggle={() => this._onToggleAddNotesModal()}
              onSaveNotes={(id, note) => this._onSaveNotes(id, note)}
            />
          </Suspense>

          {/* payment and record payment modal */}
          <Suspense fallback={<></>}>
            <ClientMakePaymentModal
              isOpen={clientMakePayment.isOpen}
              data={selectedArray}
              toggle={() => this._onToggleClientMakePayment()}
              openAchModal={(note) => this._onTogglePayAchModal(true, note)}
              recordPayment={(note) => this._recordPayment("pay", note)}
            />
          </Suspense>

          {/* This is the payment show modal when click on ach payment then show this modal */}
          <Suspense fallback={<></>}>
            <Elements>
              <AccountComponentModal
                loadingModal={loadingModal}
                isOpen={payAchModal.isOpen}
                toggle={() => this._onTogglePayAchModal()}
                processPayment={() => this._processPayment()}
                manageLoading={(value) =>
                  this.setState({ loadingModal: value })
                }
              />
            </Elements>
          </Suspense>
        </div>
      </>
    );
  }
}

export default ScreenWidthHOC(ClientPayment);
