import React from "react";
import { Button, Input, Label } from "reactstrap";
import {
  agentTodayOrFutureDate,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  getDropdownColor,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
} from "../../../helper-methods";

const AgentDashboardCardDataFormat = ({
  row,
  _redirectToClosingDetails,
  loading,
  _updateClosingDetailsByIdStatus,
  _isDisabledCheckStatusDropdown,
}) => {
  return (
    <>
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>File</Label>
            <div
              className="text-primary cursorPointer"
              style={{ fontWeight: 600 }}
            >
              <div
                className="tableLink"
                style={{
                  minWidth: 80,
                  whiteSpace: "normal",
                  textDecoration: "underline",
                }}
                onClick={() => _redirectToClosingDetails(row)}
              >
                {row.fileNumber || "N/A"}
              </div>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Fee</Label>
            <div style={{ fontWeight: 600 }}>
              {row.agentFee
                ? formatCurrencyValue(row.agentFee)
                : formatCurrencyValue(0)}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Product Type</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                  display: "inline-block",
                }}
              >
                {row.signingType} {","}
              </span>

              {row.loanType && row.loanType.length > 0 ? (
                row.loanType.indexOf("Other") > -1 ? (
                  <span
                    style={{
                      marginRight: 5,
                      fontWeight: 600,
                      display: "inline-block",
                    }}
                  >
                    {row?.loanTypeOther}
                  </span>
                ) : null
              ) : null}

              {React.Children.toArray(
                row.loanType
                  .filter((each) => each !== "Other")
                  .map((item) => (
                    <span
                      key={item._id}
                      style={{
                        marginRight: 5,
                        fontWeight: 600,
                      }}
                    >
                      {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </span>
                  ))
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Location</Label>
            <div className="d-flex justify-content-between">
              <div style={{ fontWeight: 600, flex: 1, marginRight: 10 }}>
                {formatAddressInSingleText(row.closingAddress) || "N/A"}
              </div>
              <div className="d-flex">
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row.closingAddress)}
                  onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Google_Maps_Logo_2020.svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-3"
                  />
                </Button>
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row.closingAddress)}
                  onClick={() => openAppleMapOnNewTab(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                        .default
                    }
                    alt="map icon"
                  />
                </Button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Doc Status</Label>
            <div className="docStatus">
              {/* Last Uploaded by Company: $DateTime */}
              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.companyLastDocumentUploadedAt &&
                  `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                    row?.companyLastDocumentUploadedAt,
                    row?.closingAddress?.timeZone
                  )}`
                }
                onClick={() => _redirectToClosingDetails(row)}
              >
                {row?.documents?.length && !row.documents[0].isPrivate ? (
                  row.isDocumentCompleted ? (
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  ) : row?.isDocumentUploadedByCompanyOrClient ? (
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  ) : (
                    <span
                      className="fa fa-circle text-primary"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )
                ) : (
                  <span
                    className="fa fa-circle text-primary"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
                <span className="value">
                  {row.isDocumentCompleted
                    ? "Docs Ready"
                    : row?.isDocumentUploadedByCompanyOrClient
                    ? "Pending"
                    : "Not Ready"}
                </span>
              </Button>

              {/* First downloaded by Agent: DateTime */}
              {/* turns green only when agent downloaded all documents  */}
              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.isAgentViewDocumentTime &&
                  `First downloaded by Agent:  ${formatDateAsPerTimeZOne(
                    row?.isAgentViewDocumentTime,
                    row?.closingAddress?.timeZone
                  )}`
                }
              >
                {row.isAllDocumentsDownloaded ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
                <span className="value">
                  {row?.isAllDocumentsDownloaded
                    ? "Docs Downloaded"
                    : "Pending"}
                </span>
              </Button>

              {/* Last Uploaded by Agent: DateTime */}
              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.agentLastDocumentUploadedAt &&
                  `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                    row?.agentLastDocumentUploadedAt,
                    row?.closingAddress?.timeZone
                  )}`
                }
              >
                {row.isScanBackNeeded ? (
                  row.isDocumentVerified ? (
                    <>
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Shipping Approved</span>
                    </>
                  ) : row.isDocumentCompletedByAgent ? (
                    <>
                      <span
                        className="fa fa-circle text-primary"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span
                        className="value"
                        onClick={() => _redirectToClosingDetails(row)}
                      >
                        Scan Upload
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fa fa-circle text-danger"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Scan Needed</span>
                    </>
                  )
                ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                  row.isDocumentCompletedByAgent ? (
                    <>
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Verified</span>
                    </>
                  ) : (
                    <span
                      className="fa fa-circle"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
              </Button>
            </div>
          </div>
        </li>
        <li>
          {/* Pending , Cancelled Status can only changed only agent create that closing  */}
          <div className="dataWrap  d-flex align-items-center justify-content-between">
            <Label className="mb-0">Status</Label>
            <div
              className="customSelectWrap"
              style={{ width: 120, marginRight: "0" }}
            >
              <Input
                type="select"
                name="status"
                className={`status ${getDropdownColor(row?.status)}`}
                value={row.status}
                // disabled={loading.statusChangeLoading ? true : false}
                disabled={
                  row?.isAgentWebsiteClosing
                    ? row?.status !== "Pending" && row?.status !== "Arrived"
                      ? true
                      : loading.statusChangeLoading
                      ? true
                      : false
                    : loading.statusChangeLoading
                    ? true
                    : false
                }
                onChange={(event) =>
                  _updateClosingDetailsByIdStatus(row, event.target.value)
                }
              >
                <option key="CCA" value="CCA">
                  Can't Complete
                </option>
                <option
                  selected
                  key="Pending"
                  value="Pending"
                  // disabled={row._signingCompany !== undefined}
                  disabled={!row.isCreatedForAgent}
                >
                  Pending
                </option>
                <option
                  key="Closed"
                  value="Closed"
                  disabled={
                    agentTodayOrFutureDate(row) && row.status !== "Arrived"
                  }
                >
                  Closed
                </option>
                <option
                  key="Cancelled"
                  value="Cancelled"
                  disabled={!row.isCreatedForAgent}
                >
                  Cancelled
                </option>
                <option
                  key="DNC"
                  value="DNC"
                  disabled={
                    agentTodayOrFutureDate(row) && row.status !== "Arrived"
                  }
                >
                  Did not close
                </option>
                <option
                  key="Arrived"
                  value="Arrived"
                  disabled={
                    _isDisabledCheckStatusDropdown(row) ||
                    row.status === "Closed"
                  }
                >
                  Arrived
                </option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default AgentDashboardCardDataFormat;
