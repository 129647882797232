import React, { useEffect, useState } from "react";
import {
  Label,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../../config";
import {
  capitalize,
  checkPermission,
  errorHandler,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatPhoneNumber,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  clientResponseToConnectByCompany,
  getCompanyConnectByClient,
  reRequestCompanyConnectByClient,
  requestCompanyConnectByClient,
} from "../../../http/http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import CompanyDocuments from "../components/CompanyDocuments";
import CompanyNotes from "../components/CompanyNotes";

const SigningCompanyDetails = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState({
    requestLoading: false,
  });

  const _manageLoading = (key = "", value = false) => {
    setLoading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _getCompanyConnectByClient = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = props?.match?.params;

        const res = await getCompanyConnectByClient({ companyId: id });

        setCompany(res?.company || null);

        resolve(res?.company || null);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _onLoad = async () => {
    try {
      dispatch(showLoader("Loading..."));

      await _getCompanyConnectByClient();

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
      history.goBack();
    }

    HeaderEventEmitter.subscribe("back-button", () => {
      history.goBack();
    });
  };

  useEffect(() => {
    _onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _requestCompanyConnectByClient = async (company) => {
    try {
      if (!company?._id) return;

      const companyId = company?._id;

      _manageLoading("requestLoading", true);

      if (company.connectStatus === "rejected") {
        await reRequestCompanyConnectByClient(companyId);
      } else {
        await requestCompanyConnectByClient({ companyId });
      }

      await _getCompanyConnectByClient();

      _manageLoading("requestLoading", false);
    } catch (error) {
      errorHandler(error);
      _manageLoading("requestLoading", false);
    }
  };

  const _clientResponseToConnectByCompany = async ({ id, status }) => {
    try {
      _manageLoading("requestLoading", status);

      await clientResponseToConnectByCompany({ companyId: id, status });

      await _getCompanyConnectByClient();

      _manageLoading("requestLoading", false);
    } catch (error) {
      errorHandler(error);
      _manageLoading("requestLoading", false);
    }
  };

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <div className="d-flex align-items-center">
            <Button
              color="link"
              className="backBtn"
              onClick={() => props.history.goBack()}
            >
              <img
                src={require("../../../assets/img/arrowLeft.svg").default}
                alt="backbutton"
                height={14}
              />
            </Button>
            <h2>Company Details</h2>
          </div>
        </div>
        <Row>
          <Col xl={3} lg={4}>
            <Card className="aboutCompany">
              <CardBody>
                <div className="companyImage">
                  <img
                    src={company?.logo ? company.logo : DEFAULT_PROFILE_PICTURE}
                    alt="company Img"
                  />
                </div>
                <h5>
                  {company?.companyName
                    ? capitalize(company.companyName)
                    : "N/A"}
                </h5>
                <p>{formatAddressInSingleText(company?.address)}</p>
                <div className="action">
                  {company?.connectStatus === "accepted" ? (
                    <Button color="success" outline>
                      Connected{" "}
                    </Button>
                  ) : null}
                  {company?.connectStatus === "pending" ||
                    company?.connectStatus === "rejected"
                    ? //  &&
                    // company?.connectResquestBy?.toLowerCase() === "client")
                    checkPermission("connectCompanies", "canUpdate") && (
                      <Button
                        color="primary"
                        disabled={loading.requestLoading}
                        onClick={() =>
                          _requestCompanyConnectByClient(company)
                        }
                      >
                        {loading.requestLoading ? (
                          <i className="fa fa-spinner fa-spin mr-2" />
                        ) : null}{" "}
                        {company?.connectStatus === "rejected" &&
                          company?.connectResquestBy?.toLowerCase() === "client"
                          ? "Re-"
                          : null}
                        Request Connection
                      </Button>
                    )
                    : null}
                  {company?.connectStatus === "requested" ? (
                    company?.connectResquestBy?.toLowerCase() ===
                      "signingcompany" ? (
                      checkPermission("connectCompanies", "canUpdate") && (
                        <>
                          <Button
                            color="success"
                            outline
                            disabled={loading.requestLoading ? true : false}
                            onClick={() =>
                              this._clientResponseToConnectByCompany({
                                id: company?._id,
                                status: "accepted",
                              })
                            }
                          >
                            {loading.requestLoading === "accepted" ? (
                              <i className="fa fa-spinner fa-spin mr-2" />
                            ) : null}{" "}
                            Accept
                          </Button>
                          <Button
                            color="danger"
                            outline
                            disabled={loading.requestLoading ? true : false}
                            onClick={() =>
                              this._clientResponseToConnectByCompany({
                                id: company?._id,
                                status: "rejected",
                              })
                            }
                          >
                            {loading.requestLoading === "rejected" ? (
                              <i className="fa fa-spinner fa-spin mr-2" />
                            ) : null}{" "}
                            Reject
                          </Button>
                        </>
                      )
                    ) : (
                      <Button color="primary" outline>
                        Request Sent
                      </Button>
                    )
                  ) : null}
                  {company?.connectStatus === "requested" ? (
                    company?.connectResquestBy?.toLowerCase() ===
                      "signingcompany" ? (
                      checkPermission("connectCompanies", "canUpdate") && (
                        <>
                          <Button
                            color="success"
                            outline
                            disabled={loading.requestLoading ? true : false}
                            onClick={() =>
                              _clientResponseToConnectByCompany({
                                id: company?._id,
                                status: "accepted",
                              })
                            }
                          >
                            {loading.requestLoading === "accepted" ? (
                              <i className="fa fa-spinner fa-spin mr-2" />
                            ) : null}{" "}
                            Accept
                          </Button>
                          <Button
                            color="danger"
                            outline
                            disabled={loading.requestLoading ? true : false}
                            onClick={() =>
                              _clientResponseToConnectByCompany({
                                id: company?._id,
                                status: "rejected",
                              })
                            }
                          >
                            {loading.requestLoading === "rejected" ? (
                              <i className="fa fa-spinner fa-spin mr-2" />
                            ) : null}{" "}
                            Reject
                          </Button>
                        </>
                      )
                    ) : (
                      <Button color="primary" outline>
                        Request Sent
                      </Button>
                    )
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={9} lg={8}>
            <Card>
              <CardHeader>
                <CardTitle>Contact</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl={3} sm={6} className="mb-3 mb-xl-0">
                    <div className="clientContactInfo">
                      <h6>Scheduling</h6>
                      <div className="infoWrap">
                        <Label>Email</Label>
                        <p>{company?.schedulingEmail || "N/A"}</p>
                      </div>
                      <div className="infoWrap">
                        <Label>Work Phone</Label>
                        <p>
                          {company?.workPhone
                            ? formatPhoneNumber(company.workPhone)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} sm={6} className="mb-3 mb-xl-0">
                    <div className="clientContactInfo">
                      <h6>Accounting</h6>
                      <div className="infoWrap">
                        <Label>Email</Label>
                        <p>{company?.accountingEmail || "N/A"}</p>
                      </div>
                      <div className="infoWrap">
                        <Label>Home Phone</Label>
                        <p>
                          {company?.homePhone
                            ? formatPhoneNumber(company.homePhone)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} sm={6} className="mb-3 mb-xl-0">
                    <div className="clientContactInfo">
                      <h6>General</h6>
                      <div className="infoWrap">
                        <Label>Email</Label>
                        <p>{company?.email || "N/A"}</p>
                      </div>
                      <div className="infoWrap">
                        <Label>Phone</Label>
                        <p>
                          {company?.phone
                            ? formatPhoneNumber(company.phone)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} sm={6}>
                    <div className="clientContactInfo">
                      <h6>After Hours</h6>
                      <div className="infoWrap">
                        <Label>Name</Label>
                        <p>
                          {company?.emergencyContact?.name
                            ? capitalize(company?.emergencyContact.name)
                            : "N/A"}
                        </p>
                      </div>
                      <div className="infoWrap">
                        <Label>Phone</Label>
                        <p>
                          {company?.emergencyContact?.phone
                            ? formatPhoneNumber(company?.emergencyContact.phone)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Stats</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl={3} xs={6} className="mb-4 mb-xl-0">
                    <div className="CompanyStats">
                      <div>
                        <h6>Jobs Completed</h6>
                        <p>{company?.jobsCompleted}</p>
                      </div>
                      <div className="icon">
                        <img
                          src={require("../../../assets/img/JobCompleted.png")}
                          alt="JobCompleted"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} xs={6} className="mb-4 mb-xl-0">
                    <div className="CompanyStats">
                      <div>
                        <h6>Jobs Scheduled</h6>
                        <p>{company?.jobsScheduled}</p>
                      </div>
                      <div className="icon">
                        <img
                          src={require("../../../assets/img/JobsScheduled.png")}
                          alt="JobCompleted"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} xs={6} className="mb-4 mb-xl-0">
                    <div className="CompanyStats">
                      <div>
                        <h6>Invoices Paid</h6>
                        <p>
                          {company?.invoicesPaid
                            ? formatCurrencyValue(company?.invoicesPaid)
                            : "-"}
                        </p>
                      </div>

                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/TotalIncome.svg")
                              .default
                          }
                          alt="JobCompleted"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} xs={6} className="mb-4 mb-xl-0">
                    <div className="CompanyStats">
                      <div>
                        <h6>Invoices Pending</h6>
                        <p>
                          {company?.invoicesPending
                            ? formatCurrencyValue(company?.invoicesPending)
                            : "-"}
                        </p>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/invoicepending.svg")
                              .default
                          }
                          alt="JobCompleted"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <CompanyDocuments data={company} />
            <CompanyNotes data={company} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SigningCompanyDetails;
