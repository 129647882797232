import React from "react";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
// import { capitalize } from "../../../helper-methods";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const ClientConnectPayFilters = ({
  filters,
  dateRangeValue,
  // signingCompanys,
  _filterOnChange,
  _onChangeDatePicker,
}) => {
  return (
    <div className="filterWrapper">
      <FormGroup className="searchTable">
        <Label>Search</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <img
                src={require("../../../assets/img/searchIcon.svg").default}
                alt="searchIcon"
                loading="lazy"
              />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="File Number"
            value={filters.search}
            onChange={(e) => _filterOnChange("search", e.target.value, true)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="dateRange">
        <Label>Date Range</Label>
        <DateRangePicker
          className="dateRange"
          format="MM-dd-y"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          onChange={(dateRangeValue) => _onChangeDatePicker(dateRangeValue)}
          value={dateRangeValue}
        />
      </FormGroup>
      <FormGroup>
        <Label>Status</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            value={filters?.payStatusClient}
            name="payStatusClient"
            onChange={(e) => _filterOnChange("payStatusClient", e.target.value)}
          >
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="Paid">Paid</option>
          </Input>
        </div>
      </FormGroup>
      {/* <FormGroup>
        <Label>Company</Label>
        <div className="custom-select-wrapper">
          <Input
            type="select"
            value={filters.signingCompanyId}
            name="signingCompanyId"
            onChange={(e) =>
              _filterOnChange("signingCompanyId", e.target.value)
            }
          >
            <option value="">All</option>
            {signingCompanys.map((each) => (
              <option key={each._id} value={each._id}>
                {capitalize(each?.companyName)}
              </option>
            ))}
          </Input>
        </div>
      </FormGroup> */}
    </div>
  );
};

export default ClientConnectPayFilters;
