import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { generateUniqueFileNo } from "../../../helper-methods";

const FileNumberDuplicateModal = ({
  isOpen,
  toggle,
  FileNumberSubmitHandler,
  fileNumber,
  existingFileNos,
  keep,
}) => {
  const closeModal = () => {
    toggle();
  };

  const handleSubmit = () => {
    FileNumberSubmitHandler(generateUniqueFileNo(existingFileNos, fileNumber));
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      className="modal-dialog-scrollable"
      centered
    >
      <ModalBody className="text-center">
        <h4
          style={{
            fontWeight: 600,
            fontSize: 16,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Duplicate file Number found. Do you want to change the file Number?
        </h4>
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" size="lg" onClick={() => keep()}>
          Keep
        </Button>
        <Button color="primary" size="lg" onClick={handleSubmit}>
          {/* Keep */}
          Change
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FileNumberDuplicateModal;
