import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledCollapse,
  CardHeader,
} from "reactstrap";
import { connect } from "react-redux";

class FAQAGENT extends React.Component {
  _redirectToContact = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/contact`);
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              {/* <div className="pgTitleWrap">
                <h4>FAQ</h4>
              </div> */}
              <div className="faqSection">
                <h2 className="faqSectionHeader">About CloseWise</h2>

                <h2 className="faqSectionHeader">For Notaries</h2>

                <Card>
                  <CardHeader id="toggler1">
                    What is the difference between CloseWise for Free and
                    CloseWise Professional?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler1">
                    <CardBody>
                      <p>
                        CloseWise for free allows you to receive closing offers,
                        intuitively manage your schedule, automate client
                        updates, manage accounting, and automate mileage logs.
                        These features alone should increase and organize your
                        workflow, but <u> Closewise Professional </u>
                        adds additional features that automate time consuming
                        processes, adds enhanced features to the free accounting
                        and reporting, and gives you a better overview of your
                        business.
                        {/* For example: */}
                      </p>
                      {/* <span className="mt-4">
                          Tired of data entry or don’t have the time? Click to
                          upload or drag and drop. Done! This feature is
                          compatible with most major platform confirmation
                          formats and we are continuously adding more at your
                          request.
                        </span> */}
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler">
                    Can I use CloseWise Software to manage independent loan
                    signings that I didn’t receive through the CloseWise
                    platform?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler">
                    <CardBody>
                      <p>
                        Yes, the closewise platform and the auto upload features
                        were built just for this! We encourage you to use
                        CloseWise’ free or premium signing agent software to
                        manage the entirety of your notary business. For those
                        signings not received through CloseWise, just add them
                        to your dashboard. There are a few advantages to this:
                      </p>
                      <li>
                        You will have one central dashboard with intuitive
                        features to track all of your loan signings and payments
                        for services.
                      </li>
                      <li>
                        The more signings you manage with CloseWise, the higher
                        you will rank when companies search for notaries in your
                        area.
                      </li>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler2">Is there a mobile app?</CardHeader>
                  <UncontrolledCollapse toggler="#toggler2">
                    <CardBody>
                      <p>
                        Yes, there’s a mobile version of the site which runs in
                        the browser and functions like an app.
                      </p>
                      <li>There’s nothing to install.</li>
                      <li>You’ll always have the most current version.</li>
                      <li>
                        It works on all major platforms, such as iPhone and
                        Android.
                      </li>
                      <span className="mt-4">
                        For a tutorial on how to add the mobile app to your
                        iPhone or android device click here.
                      </span>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler3">
                    I currently use another method for managing my closings, why
                    should I use CloseWise?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler3">
                    <CardBody>
                      <p>
                        CloseWise is the best and most affordable all in one
                        business solution for Loan Closing Software on the web.
                        We take a contemporary approach to creating software for
                        signing services, title companies, and notaries to
                        manage and attract more business. Our cloud-based
                        pay-as-you go software has been designed by industry
                        veterans and made to be intuitive enough for new comers
                        to the industry. Closwise continues to improve its
                        workflow automation with input from our users on a
                        regular basis. This helps us to help you deliver the
                        best signing experience possible to your client and
                        saves you time and money.
                      </p>{" "}
                      <h6 className="font-weight-bold mt-3">
                        Safeguard Sensitive Data
                      </h6>
                      <p>
                        Transfer sensitive documents securely and have access to
                        a log of all interactions with an order you can share to
                        reduce your risk in the case of an audit or a dispute.
                      </p>
                      <h6 className="font-weight-bold mt-3">
                        Automate Your Workflow
                      </h6>
                      <p>
                        Save time and money by managing all signings and
                        communications from the CloseWise dashboard. Here
                        signing services, notaries, and escrow agents can
                        cooperate to smoothly view orders, assign notaries, send
                        documents securely, update order status, send invoices,
                        and receive payments.
                      </p>
                      <h6 className="font-weight-bold mt-3">
                        Accounting and Reporting
                      </h6>
                      <p>
                        Not only do we make your workflow more efficient, we
                        automate reporting for mileage and sending invoices.
                        This way you don’t have to spend valuable time
                        organizing, inputting data on extra platforms, or
                        sending emails. Save hours and reduce costs – spend more
                        time growing revenue and less time on daily business
                        maintenance. We have a dedicated engineering team that
                        is constantly improving our product based on your
                        feedback. Developing the best all in one business
                        management software for the signing industry is our
                        mission. Special inquiries can be sent
                        support@closewise.com.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler4">
                    How can using CloseWise help me build my notary business?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler4">
                    <CardBody>
                      <p>
                        There are a number of ways that CloseWise helps notaries
                        increase their loan signing volume. For example:
                      </p>

                      <span>In the signing business communication is key!</span>
                      <br />
                      <span>With the click of one button you can: </span>
                      <li> Contact the signer. (Click here) </li>
                      <li>
                        {" "}
                        Confirm the appointment or revise the time, which
                        automatically updates your client{" "}
                      </li>
                      <li>
                        {" "}
                        Send status updates letting your client know you arrived
                        and the final status before they even think to ask.
                      </li>
                      <li>
                        {" "}
                        Use your preferred mapping feature automatically to
                        Navigate to your appointment{" "}
                      </li>
                      <li>
                        {" "}
                        And that’s just one feature from just one screen{" "}
                      </li>
                      <p className="mt-3">
                        One of the keys is to fully complete your notary profile
                        with a professional photo, a clear description of your
                        work history as a notary, set working hours, your
                        standard fees, and all of the documents and information
                        requested. The more information available in your
                        profile, the higher you will rank in CloseWise notary
                        search results. Additionally, the more a title agent or
                        signing service can learn about you in your profile, the
                        more likely they will assign you to their loan closing.
                        To see a full description of how CloseWise will help you
                        build your notary business, check out our blog posts: 
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler5">
                    What do you mean by loan closing automation?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler5">
                    <CardBody>
                      <p>
                        There are so many repetitive tasks performed throughout
                        the loan closing process that require hours of time.
                        CloseWise software can take over the heavy lifting and
                        accomplish these tasks in milliseconds so that you can
                        be freed up to focus on initiatives that will grow your
                        business. We automate notary search, email and text
                        notifications, business performance data analysis, and
                        much more. CloseWise will do the business maintenance
                        for you.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler6">
                     Does CloseWise allow me to analyze the performance of my
                    business?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler6">
                    <CardBody>
                      <p>
                        Yes! CloseWise has analytics summaries that helps you
                        see how your business is performing. Set the dates for
                        the time period you want to analyze and your company
                        data for that period will be visually represented on
                        your summaries dashboard. You will see a summary of the
                        number of orders scheduled, completed, canceled,
                        on-hold, and not-signed-at-the-table, along with a bar
                        graph showing your orders per month. You can see
                        orders-per-client, along with your fees, notary fees,
                        average margin, and net per client. See our blog
                        post about how our mortgage analytics page can help you.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler7">
                    Why am I not receiving orders in my area?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler7">
                    <CardBody>
                      <p>
                        Check your notification settings by clicking the
                        settings icon at the top right of your screen when
                        logged into your account, to make sure you are set up to
                        receive order notification requests.
                      </p>
                      <p>
                        Order requests on CloseWise are based on location,
                        rating, jobs completed, working times, and fees set in
                        your profile. The more orders you add to CloseWise and
                        the better you set up your profile the more likely you
                        will be selected for order in your area!
                      </p>
                      <p>
                        Make sure your profile is set up correctly by following
                        the profile tutorial here:
                        <a
                          href="https://www.youtube.com/watch?v=Bk06U0ID2q8"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.youtube.com/watch?v=Bk06U0ID2q8
                        </a>
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler9">
                    Why won’t my confirmations upload automatically?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler9">
                    <CardBody>
                      <p>
                        <li>
                          {" "}
                          First, make sure you have a CloseWise Pro account{" "}
                        </li>
                        <li>Make sure your internet connection is working </li>
                        <li>
                          Confirmations that will upload currently include:
                        </li>
                        -Snapdocs email, Signing Order, ServiceLink, Timios,
                        Mortgage Connect. <br />
                        -If these don't upload you can submit a ticket @ <br />
                        To request a new auto-confirmation upload type submit
                        your request @
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler8">
                    Does Closewise provide training tutorials?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler8">
                    <CardBody>
                      <p>
                        Yes! We built CloseWise to be as intuitive as possible,
                        but if you need help getting the most out of closewise
                        you can visit our training tutorials at:
                        <a
                          href="https://www.closewise.com/product-training/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.closewise.com/product-training/
                        </a>
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler12">
                    Are there tutorials or user guides to help me utilize all
                    the CloseWise features?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler12">
                    <CardBody>
                      <p>
                        Yes! CloseWise has easy to follow user tutorial videos
                        that walk users through most all pages and functions of
                        the platform. From creating an account, schedule
                        tracking, accounting, to reporting! It’s as simple as
                        going to ?????? and following the tutorial guide!
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler11">
                    Can I adjust my notification settings?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler11">
                    <CardBody>
                      <p>
                        Check your notification settings by clicking the
                        settings icon at the top right of your screen when
                        logged into your account. On this page you can select or
                        unselect the types of notifications assistance you would
                        like to receive.
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>

                <Card>
                  <CardHeader id="toggler10">
                    My question is not included in the FAQ’s - can I create a
                    general support ticket?
                  </CardHeader>
                  <UncontrolledCollapse toggler="#toggler10">
                    <CardBody>
                      <p>
                        <a
                          href="http://closewise.freshdesk.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Help.closewise.com
                        </a>{" "}
                      </p>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(FAQAGENT);
