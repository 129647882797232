import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ShowTextModal = ({
  isOpen,
  data, // in string format as we're directly rendering it
  toggle,
  heading = "Description", // heading field is optional, if not provided will use default heading
}) => {
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
      <ModalHeader toggle={() => _closeModal()}>{heading}</ModalHeader>
      <ModalBody>
        <h5 className="text-center">{data}</h5>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ShowKycTextModal = ({
  isOpen,
  data,
  toggle,
  heading = "Send KYC Request",
  sendKycRequest,
}) => {
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered>
      <ModalHeader toggle={() => _closeModal()}>{heading}</ModalHeader>
      <ModalBody>
        <h5
          style={{
            fontSize: 16,
            textAlign: "center",
            lineHeight: 1.6,
            marginBottom: 0,
          }}
        >
          {data}
        </h5>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => sendKycRequest()}>
          Send KYC request
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { ShowTextModal, ShowKycTextModal };
