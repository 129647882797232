import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { DEFAULT_COVER_PICTURE } from "../../../config";
import {
  capitalize,
  checkPermission,
  errorHandler,
  formatDateAndTime,
  showToast,
} from "../../../helper-methods";
import {
  clientCreateCompanyNote,
  clientDeleteCompanyNote,
  clientGetAllCompanyNotes,
  clientUpdateCompanyNote,
} from "../../../http/http-calls";
import CustomPagination from "./CustomPagination";
import ReadMore from "./readMore";

const CompanyNotes = ({ data }) => {
  const userData = useSelector((state) => state?.userData);

  const userType = useMemo(() => {
    return userData?.user?.type?.toLowerCase();
  }, [userData]);

  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [commentsPayload, setCommentsPayload] = useState({
    skip: 0,
    limit: 5,
    companyId: data?._id,
    clientId: data?._id,
  });
  const [loading, setLoading] = useState(false);
  const [addCommentLoading, setAddCommentLoading] = useState(false);
  const [deleteCommentLoading, setDeleteCommentLoading] = useState(false);

  const _resetCommentState = () => {
    setTimeout(() => {
      setComment("");
    }, 0);
    setCommentId(null);
    setAddCommentLoading(false);
  };

  const _getAllComments = async (payload) => {
    try {
      setLoading(true);
      let res = await clientGetAllCompanyNotes({ userType, payload });
      setComments(res?.contents?.length ? res.contents : []);
      setCommentsCount(res?.totalCount || 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _sendComment = async (comment) => {
    try {
      if (!comment?.trim()?.length) {
        errorHandler({ reason: "Please add comment" });
        return;
      }
      if (!data?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      setAddCommentLoading(true);

      const payload = {
        text: comment.trim(),
        companyId: data?._id,
        clientId: data?._id,
      };

      if (commentId) {
        const newComments = [...comments];
        const findComment = newComments.find((each) => each._id === commentId);
        if (findComment) {
          findComment.text = payload.text;
          setComments(newComments);
        }
        await clientUpdateCompanyNote({ id: commentId, userType, payload });
      } else {
        const newComments = [...comments];
        newComments.unshift({
          isLoading: true,
          text: payload.text,
          userId: userData?.user?._id,
          name: userData?.user?.name?.first,
          createdAt: new Date(),
        });
        setComments(newComments);
        await clientCreateCompanyNote({ userType, payload });
        _onChangePagination();
      }

      showToast(
        `Comment has been ${commentId ? "updated" : "added"}`,
        "success"
      );

      _resetCommentState();
    } catch (error) {
      _onChangePagination();
      setAddCommentLoading(false);
      errorHandler(error);
    }
  };

  const _onKeyDownComment = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value?.trim().length &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      _sendComment(e.target.value);
    }
  };

  const _onChangePagination = (pageNo = 1) => {
    const newCommentsPayload = { ...commentsPayload };
    newCommentsPayload.skip = (pageNo - 1) * newCommentsPayload.limit;
    setCommentsPayload(newCommentsPayload);
    _getAllComments(newCommentsPayload);
  };

  const _deleteComment = (commentId) => {
    setDeleteCommentLoading(commentId);

    const newComments = [...comments];
    const findCommentIndex = newComments.findIndex(
      (each) => each._id === commentId
    );
    if (findCommentIndex > -1) {
      newComments.splice(findCommentIndex, 1);
      setComments(newComments);
    }

    clientDeleteCompanyNote({ userType, id: commentId })
      .then(async (res) => {
        Swal.fire("Deleted!", "Comment has been deleted.", "success");
        if (newComments.length === 0) {
          _onChangePagination();
        }
        setDeleteCommentLoading(false);
      })
      .catch((error) => {
        _onChangePagination();
        errorHandler(error);
        setDeleteCommentLoading(false);
      });
  };

  const _deleteCommentAlert = (comment) => {
    if (!comment?._id) {
      showToast("Comment not found", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this comment.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteComment(comment._id);
      }
    });
  };

  const _editComment = (comment = null) => {
    setComment(comment?.text || "");
    setCommentId(comment?._id || null);
  };

  useEffect(() => {
    if (data?._id) {
      const newCommentsPayload = { ...commentsPayload };
      newCommentsPayload.companyId = data?._id;
      newCommentsPayload.clientId = data?._id;
      setCommentsPayload(newCommentsPayload);
      _getAllComments(newCommentsPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id]);

  // eslint-disable-next-line no-unused-vars
  const _addedByLogo = (comment) => {
    if (comment.isLoading) {
      return DEFAULT_COVER_PICTURE;
    }
    if (comment?._createdByClient) {
      return comment._createdByClient.profilePicture || DEFAULT_COVER_PICTURE;
    }
    if (comment?._createdBy) {
      return comment._createdBy.profilePicture || DEFAULT_COVER_PICTURE;
    }
    if (comment?._client) {
      return comment._client.profilePicture || DEFAULT_COVER_PICTURE;
    }
    if (comment?._agent) {
      return comment._agent.profilePicture || DEFAULT_COVER_PICTURE;
    }
    if (comment?._signingCompany) {
      return comment._signingCompany.profilePicture || DEFAULT_COVER_PICTURE;
    }
    return "N/A";
  };

  const _addedByName = (comment) => {
    if (comment.isLoading) {
      return capitalize(comment.name) || "Loading";
    }
    if (comment?._createdBy) {
      if (comment?._createdBy?.userType !== "SigningCompany") {
        if (comment?._agent) {
          return comment._agent.name?.first && comment._agent.workType
            ? `${capitalize(comment._agent.name?.first)} of ${comment._agent.workType
            }`
            : capitalize(comment._agent.name?.first);
        }
      } else {
        if (comment?._signingCompany) {
          return comment._signingCompany.name?.first &&
            comment._signingCompany.companyName
            ? `${capitalize(comment._signingCompany.name?.first)} of ${comment._signingCompany.companyName
            }`
            : capitalize(comment._signingCompany.name?.first);
        }
      }
    }
    if (comment?._createdByClient) {
      if (comment?._client) {
        return comment._client.name?.first && comment._client.companyName
          ? `${capitalize(comment._client.name?.first)} of ${comment._client.companyName
          }`
          : capitalize(comment._client.name?.first);
      }
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Add Note {loading ? <Spinner size="sm" /> : null}</CardTitle>
      </CardHeader>

      <CardBody>
        {checkPermission("connectCompanies", "canUpdate") ? (
          <>
            <FormGroup className="floatingLabel">
              <Input
                type="textarea"
                rows="10"
                placeholder=""
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyDown={(e) => _onKeyDownComment(e)}
                disabled={addCommentLoading}
              />
              <Label>Write a note...</Label>
            </FormGroup>
            <div className="d-flex justify-content-center mt-3">
              {commentId ? (
                <Button color="primary" onClick={() => _resetCommentState()}>
                  Cancel
                </Button>
              ) : null}
              <Button
                color="primary"
                size="md"
                onClick={() => _sendComment(comment)}
                disabled={addCommentLoading}
              >
                {commentId ? "Update" : "Create"} Comment
              </Button>
            </div>
          </>
        ) : null}

        <div className="chatWrapper">
          {comments?.length ? (
            comments?.map((each, index) => (
              <>
                <div className="chatWrap">
                  <div className="chatItem" key={each?._id + index}>
                    <div className="chatHeader">
                      <h2>{_addedByName(each)} </h2>
                      {checkPermission("connectCompanies", "canUpdate") ? (
                        <div className="action">
                          {each?._id === commentId ? (
                            <Button
                              color="link"
                              onClick={() => _editComment()}
                              disabled={each?.isLoading}
                            >
                              <i className="fa fa-times" />
                            </Button>
                          ) : (
                            <>
                              <Button
                                color="link"
                                onClick={() => _editComment(each)}
                                disabled={each?.isLoading}
                              >
                                <img
                                  src={
                                    require("../../../assets/img/pencil.svg")
                                      .default
                                  }
                                  alt="pencil"
                                />
                              </Button>

                              <Button
                                color="link"
                                disabled={
                                  deleteCommentLoading || each?.isLoading
                                    ? true
                                    : false
                                }
                                onClick={() => _deleteCommentAlert(each)}
                              >
                                <img
                                  src={
                                    require("../../../assets/img/deleteIcon.svg")
                                      .default
                                  }
                                  alt="pencil"
                                />
                              </Button>
                            </>
                          )}
                        </div>
                      ) : null}
                    </div>

                    <div className="Chatcontent">
                      <ReadMore text={each?.text} />
                    </div>
                  </div>
                  <div className="date">
                    {formatDateAndTime(each?.createdAt)}
                  </div>
                </div>
              </>
            ))
          ) : (
            <>
              {loading ? (
                <Spinner />
              ) : (
                <div className="nodata">
                  <img
                    src={require("../../../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              )}
            </>
          )}

          <CustomPagination
            data={comments}
            dataCount={commentsCount}
            pageNumber={
              parseInt(commentsPayload.skip / commentsPayload.limit) + 1
            }
            pageSize={commentsPayload.limit}
            onPageChange={(pageNo) => _onChangePagination(pageNo)}
            isPageStartFromOne
            isHideForLessData
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default CompanyNotes;
